import { useAxios } from '../../api';
import { SpeakerBriefing } from '../../types';

type Props = {
  code?: string;
};

type ResponseType = {
  results: SpeakerBriefing[];
};

const useGetSpeakerBriefing = ({ code }: Props) => {
  const [{ data, loading, error }, refetch] = useAxios<ResponseType>({
    url: `api/groupevent/speakerbriefing/?group_event__code=${code}`,
  });

  return {
    speakerBriefingData: data && data.results,
    hasFinishedLoadingSpeakerBriefing: !loading,
    speakerBriefingError: error,
    refetchSpeakerBriefing: refetch,
  };
};

export default useGetSpeakerBriefing;
