import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUrlParam } from '../../utils/url';
import useVerifyAccount from '../../apiHooks/auth/useVerifyAccount';
import useResendVerification from '../../apiHooks/auth/useResendVerification';
import styles from './verify.module.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import VerifiedModal from '../../molecules/VerifiedModal';
import useFetchPartner from '../../apiHooks/auth/useFetchPartner';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: 30,
  },
  content: {
    maxWidth: '630px',
    maxHeight: '300px',
    margin: '15vh auto 0',
  },
};

const Verify = () => {
  const {
    verify,
    verifyError,
    invalidToken,
    alreadyVerified,
    userNotFoundError,
  } = useVerifyAccount();
  const { resendVerification, isLoadingResendVerification } =
    useResendVerification();
  const {
    volunteerProfile,
    userProfile,
    hasFinishedFetchingProfileAndLocation,
  } = useSelectProfileData();
  const { username } = userProfile || {};
  const hasProfile = !!volunteerProfile;
  const navigate = useNavigate();
  const volunteerId = getUrlParam('uid') as string;
  const confirmationToken = getUrlParam('ct') as string;
  //the verification will send back a id that is the partner id
  const partnerId = getUrlParam('id') as string;
  const [hasFinishedVerifying, setHasFinishedVerifying] = useState(false);
  const [verificationResent, setVerificationResent] = useState(false);
  const redirectTime = 5000;

  const { isLoadingPartner } = useFetchPartner({
    id: partnerId,
    setCookies: true,
  });

  useEffect(() => {
    (async () => {
      if (!hasFinishedVerifying) {
        try {
          await verify({ id: volunteerId, token: confirmationToken });
          setHasFinishedVerifying(true);
        } catch (err) {
          setHasFinishedVerifying(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let redirectTimeout: ReturnType<typeof setTimeout>;
    if (hasFinishedFetchingProfileAndLocation && hasFinishedVerifying) {
      if (verifyError) {
        if (alreadyVerified)
          redirectTimeout = setTimeout(
            () => navigate(hasProfile ? '/dashboard' : '/profile-create'),
            redirectTime
          );
        return;
      } else {
        redirectTimeout = setTimeout(
          () => navigate(`/profile-create`),
          redirectTime
        );
        return;
      }
    }

    return () => {
      if (!!redirectTimeout) clearTimeout(redirectTimeout);
    };
  }, [
    alreadyVerified,
    hasFinishedFetchingProfileAndLocation,
    hasFinishedVerifying,
    hasProfile,
    invalidToken,
    navigate,
    verifyError,
  ]);

  const onButtonPress = useCallback(async () => {
    if (invalidToken && username) {
      try {
        await resendVerification({ email: username });
        return setVerificationResent(true);
      } catch (err) {}
    }
    return navigate(hasProfile ? '/dashboard' : '/profile-create');
  }, [hasProfile, invalidToken, navigate, resendVerification, username]);

  return (
    <div className={styles.container}>
      {isLoadingPartner ||
      !hasFinishedVerifying ||
      !hasFinishedFetchingProfileAndLocation ? (
        <div className={styles.container}>
          <img
            className={styles.loadingSpinner}
            src="/loading.gif"
            alt="loading"
          />
        </div>
      ) : hasFinishedVerifying && !!verifyError ? (
        <VerifiedModal
          invalidToken={invalidToken}
          userNotFoundError={userNotFoundError}
          alreadyVerified={alreadyVerified}
          verificationResent={verificationResent}
          errorMessage={verifyError}
          customStyles={customStyles}
          onButtonPress={onButtonPress}
          loading={isLoadingResendVerification}
          hasProfile={hasProfile}
        />
      ) : (
        <VerifiedModal
          customStyles={customStyles}
          hasProfile={hasProfile}
          onButtonPress={onButtonPress}
        />
      )}
    </div>
  );
};

const VerifyHOC = () => {
  const { hasFinishedFetchingProfileAndLocation } = useSelectProfileData();

  return hasFinishedFetchingProfileAndLocation ? <Verify /> : null;
};

export default VerifyHOC;
