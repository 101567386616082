import { useState, useEffect } from 'react';

type WindowSize = {
  x?: number;
  y?: number;
};

const useWindowScrollPosition = () => {
  const [windowScrollPosition, setWindowScrollPosition] = useState<WindowSize>({
    x: window.pageXOffset,
    y: window.pageYOffset,
  });
  const handleScroll = () => {
    setWindowScrollPosition({
      x: window.pageXOffset,
      y: window.pageYOffset,
    });
  };

  useEffect(() => {
    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Call handler right away so state gets updated with initial window scroll position
    handleScroll();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Empty array ensures that effect is only run on mount

  return windowScrollPosition;
};

export default useWindowScrollPosition;
