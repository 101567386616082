import { useAxios } from '../../api';
import { FollowUpRequestResponse, FollowUpRequest } from '../../types';

const useGetFollowUpRequests = () => {
  const [{ data, loading, error }, executeCall] =
    useAxios<FollowUpRequestResponse>({
      url: `api/netpromoterscore/followuprequest/?limit=100`,
      method: 'GET',
    });

  const pendingFollowUpRequests = data?.results?.filter(
    (request: FollowUpRequest) => request.follow_up_status === 'requested'
  );

  return {
    allFollowUpRequests: data || [],
    pendingFollowUpRequests: pendingFollowUpRequests || [],
    isLoadingFollowUpRequests: loading,
    hasFinishedLoadingFollowUpRequest: !loading,
    allFollowUpRequestError: error,
    refetchFollowUpRequest: executeCall,
  };
};

export default useGetFollowUpRequests;
