import React, { memo, useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import { getTaxonomy } from '../../api/taxonomy';
import useWindowSize from '../../hooks/useWindowSize';

type TaxonomyPopupProps = {
  setSelectedTaxonomy: React.Dispatch<React.SetStateAction<number>>;
  selectedTaxonomy: number;
};

const TaxonomyPopup: React.FC<TaxonomyPopupProps> = ({
  setSelectedTaxonomy,
  selectedTaxonomy,
}: TaxonomyPopupProps) => {
  const value =
    selectedTaxonomy === undefined || selectedTaxonomy === null
      ? ''
      : selectedTaxonomy;
  const [skillsList, setSkills] = useState<any[]>([]);
  const handleChange = (event: any) => {
    const taxonomyId = parseInt(event.target.value);
    setSelectedTaxonomy(taxonomyId);
  };
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  useEffect(() => {
    const getSkillsList = async () => {
      try {
        const response = await getTaxonomy([]);

        const combinedList = response
          .map((category: { parent: { id: any; name: any }; child: any[] }) => {
            const categoryItem = {
              id: category.parent.id,
              name: category.parent.name,
              isCategory: true,
            };

            const childItems = category.child.map(
              (child: { id: any; name: any }) => ({
                id: child.id,
                name: child.name,
                isCategory: false,
              })
            );

            return [categoryItem, ...childItems];
          })
          .flat();

        setSkills(combinedList);
      } catch (e) {
        console.error(e);
      }
    };

    getSkillsList();
  }, []);
  const renderValue = (selected: any) => {
    if (selected !== '') {
      const selectedSkill = skillsList.find(
        (skill) => skill.id === selected
      )?.name;
      return selectedSkill ? (
        <span style={customTextStyle}>{selectedSkill}</span>
      ) : null;
    }
    return <span style={customTextStyleLabel}>Choose the topic</span>;
  };
  const customTextStyleLabel = {
    color: '#A3A3A3',
    fontFamily: 'Gilroy',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: ' 0.15px',
  };
  const customTextStyle = {
    color: '#333',
    fontFamily: 'Gilroy',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: ' 0.15px',
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      displayEmpty
      sx={{
        width: isMobile ? '240px' : '240px',
        borderRadius: '10px',
        background: '#FFF',
        height: '50px',
        color: '#333',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #E8E8E8',
        },
        '& .MuiSelect-select': {
          color: '#333',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '120%',
          letterSpacing: ' 0.15px',
        },
      }}
      renderValue={renderValue}
    >
      <MenuItem
        value=""
        style={{
          fontFamily: 'Gilroy',
          fontSize: '15px',
          color: '#A3A3A3',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '120%',
          letterSpacing: ' 0.15px',
        }}
      >
        <em>None</em>
      </MenuItem>
      {skillsList.map((skill) => (
        <MenuItem
          key={skill.id}
          value={skill.id}
          disabled={skill.isCategory}
          style={{
            paddingLeft: skill.isCategory ? 10 : 25,
            fontFamily: 'Gilroy',

            fontStyle: 'normal',

            lineHeight: '120%',
            letterSpacing: ' 0.15px',
            fontSize: skill.isCategory ? '14px' : '12px',
            fontWeight: skill.isCategory ? 'bold' : '500',
          }}
        >
          {skill.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default memo(TaxonomyPopup);
