import { useEffect } from 'react';
import { useAxios } from '../../api';

type Props = {
  id: number | string | null;
  timeSlotId?: number;
};

type ReturnProps = {
  hasOverlappingEvent?: any;
  isLoadingHasOverlappingEvent: boolean;
  hasFinishedLoadingHasOverlappingEvent: boolean;
  hasOverlappingEventError: any;
  refetchHasOverlappingEvent: () => void;
};

const useGetHasOverlappingEvent = ({ id, timeSlotId }: Props): ReturnProps => {
  const [{ data, loading, error }, executeCall] = useAxios<any>({
    url: `api/event/boostcallrequests/${id}/hasoverlappingevent/?time_slot_id=${timeSlotId}`,
    method: 'GET',
  });

  useEffect(() => {
    if (!!id) executeCall();
  }, [executeCall, id]);

  return {
    hasOverlappingEvent: data,
    isLoadingHasOverlappingEvent: loading,
    hasFinishedLoadingHasOverlappingEvent: !loading,
    hasOverlappingEventError: error,
    refetchHasOverlappingEvent: executeCall,
  };
};

export default useGetHasOverlappingEvent;
