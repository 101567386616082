import React, { useEffect, useState } from 'react';
import { getUrlParam } from '../../utils/url';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import useGetBoostCallEventData from '../../apiHooks/boostCalls/useGetBoostCallEventData';
import useGetBoostCallEventRequest from '../../apiHooks/boostCallRequests/useGetBoostCallEventRequest';
import { Tab, TabPanel, TabsList } from '../../styling/tabs';
import { displayIcon, tabs } from '../../components/Dashboard/utilities';

import PageNotFound from '../../templates/PageNotFound';
import FullScreenLoading from '../../components/FullScreenLoading';
import useWindowSize from '../../hooks/useWindowSize';
import NewCallDetails from '../NewCallDetails';
import ManageCallPage from '../NewCallDetails/manageCallPage';
import { useSystemTogglesContext } from '../../context/SystemToggles';

const ManageCallDetails = () => {
  const tab = '';
  let navigate = useNavigate();
  const [id] = useState(
    (getUrlParam('cid') as string) || (getUrlParam('id') as string)
  );
  const type = getUrlParam('type') as string;
  const isBoostCall = type === 'events';
  const isBoostCallRequest = type === 'requests';
  const [displayTabs, setDisplayTabs] = useState(tabs);
  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'Action Plans');
      setDisplayTabs(updatedTabs);
    }
  }, [showActionPlan]);
  // Get boostcall event
  const { boostCallEventData, isLoadingBoostCallEventData } =
    useGetBoostCallEventData({
      id: isBoostCall ? id : null,
    });
  const { boostCallEventRequest, isLoadingBoostCallEventRequest } =
    useGetBoostCallEventRequest({
      id: isBoostCallRequest ? id : null,
    });

  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  if (
    isLoading ||
    isLoadingBoostCallEventData ||
    isLoadingBoostCallEventRequest
  )
    return <FullScreenLoading />;
  else if (boostCallEventData || boostCallEventRequest)
    return (
      <div className="container__dashboard-page">
        {!isMobile ? (
          <div>
            <TabsUnstyled
              value={tab}
              onChange={(event: React.SyntheticEvent, newValue: any) =>
                navigate(`/dashboard/${newValue}`)
              }
            >
              <TabsList>
                {displayTabs.map((tab: any) => (
                  <Tab
                    key={tab.link}
                    value={tab.link}
                    sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                    {...a11yProps(0)}
                  >
                    <div className="dashboard-tab-icon-label" key={tab.link}>
                      {displayIcon(tab.label)} {tab.label}
                    </div>
                  </Tab>
                ))}
              </TabsList>
              <TabPanel value={tab} key={0} />
              <TabPanel value={tab} key={1} />
              <TabPanel value={tab} key={2} />
              <TabPanel value={tab} key={3} />
              <TabPanel value={tab} key={4} />
              <TabPanel value={tab} key={5} />
            </TabsUnstyled>
          </div>
        ) : null}
        <div className="dashboard-container-info">
          {isBoostCallRequest && boostCallEventRequest ? (
            <NewCallDetails event={boostCallEventRequest} />
          ) : null}
          {isBoostCall && boostCallEventData ? (
            <ManageCallPage event={boostCallEventData} />
          ) : null}
        </div>
      </div>
    );
  else return <PageNotFound />;
};

export default ManageCallDetails;
