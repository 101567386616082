import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  CustomFormControlLabel,
  CustomTypography,
} from '../../../styling/generalStyling';

import {
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material';
import api from '../../../api';
import { Disability } from '../types';

type Props = {
  setValue: (value: number, input: string, key: string) => void;
  value: any;
};

export default function EditDisabilityForm({ value, setValue }: Props) {
  
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    value.disabilities
  );
  const [otherDisability, setOtherDisability] = useState<string>(
    value.disability_info || ''
  );
  const [disabilityOption, setDisabilityOption] = useState<string>(
    value.disabilities.length > 0 ? 'yes' : 'no'
  );

  const [disabilitiesList, setDisabilitiesList] = useState<Disability[]>();
  const getDisabilityList = async () => {
    const disabilityOptions = await api.get('api/disabilities/disabilities');
    if (disabilityOptions.data?.results)
      setDisabilitiesList(disabilityOptions.data?.results);
  };

  useEffect(() => {
    getDisabilityList();
  }, []);

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDisabilityOption(e.target.value);
    if (e.target.value === 'no') setValue(-1, 'disability', '');
  };

  const handleCheckboxChange = (event: { target: { value: any } }) => {
    const option = parseInt(event.target.value);

    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((o) => o !== option)
        : [...prevOptions, option]
    );
    setValue(option, 'disability', otherDisability);
 
  };
  const handleInputChange = (event: { target: { value: any } }) => {
    setOtherDisability(event.target.value);
    setValue(8, 'disability', event.target.value);
  };
  return (
    <FormControl>
      <label className="disability-label">
      Do you have lived experience with a disability or long-term health condition (mental and / or physical)?
      </label>
      <div>
        <RadioGroup row value={disabilityOption} onChange={handleChange}>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </div>
      {disabilityOption === 'yes' && (
        <div className="disability-options">
          {disabilitiesList?.map((option) => (
            <CustomFormControlLabel
              key={option.id}
              control={
                <Checkbox
                  checked={selectedOptions.includes(option.id)}
                  onChange={handleCheckboxChange}
                  value={option.id}
                  name={option.name}
                />
              }
              label={<CustomTypography>{option.name}</CustomTypography>}
            />
          ))}
          <TextField
            label="If other, please specify (if desired)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={otherDisability}
            onChange={handleInputChange}
          />
        </div>
      )}
    </FormControl>
  );
}
