import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import EditInput from '../ProfileUtils/EditInput';

import api from '../../../api';
import { VolunteerProfile, SecurityPreferences } from '../types';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
} from '../../../styling/buttons';

let updatedSecurity: SecurityPreferences = {};
type Props = {
  data: VolunteerProfile;
};

const Security = ({ data }: Props) => {
  const [error, setError] = useState<String>('');

  const setValue = (value: any, input: keyof SecurityPreferences) => {
    updatedSecurity[input] = value;
    return updatedSecurity;
  };

  const handleSaveClick = async (updatedSecurity: SecurityPreferences) => {
    if (updatedSecurity?.email) {
      try {
        await api.patch(`api/core/users/me/`, {
          username: updatedSecurity.email,
        });
        window.location.reload();
      } catch (e: any) {
        setError(e.response.data.username);
        if (e.response.data.old_password) {
          setError(e.response.data.old_password[0]);
        }
        if (e.response.data.new_password) {
          setError(e.response.data.new_password[0]);
        }
      }
    }

    if (
      updatedSecurity?.new_password &&
      updatedSecurity?.confirm_new_password &&
      updatedSecurity?.old_password
    ) {
      if (
        updatedSecurity.new_password !== updatedSecurity.confirm_new_password
      ) {
        setError('New password and confirm password do not match');
        return;
      }

      try {
        await api.post(`api/core/changepassword/`, updatedSecurity);
        window.location.reload();
      } catch (e: any) {
        if (e.response.data.old_password) {
          setError(e.response.data.old_password[0]);
        }
        if (e.response.data.new_password) {
          setError(e.response.data.new_password[0]);
        }
      }
    }
  };
  return (
    <div className="profile_form">
      <EditInput
        openEdit={false}
        inputField="email"
        name={'Email'}
        input={data.username}
        value={data.username}
        setValue={setValue}
      />

      <EditInput
        openEdit={false}
        inputField="password"
        name={'Password'}
        input={'*********'}
        value={''}
        setValue={setValue}
      />
      <div className="error" style={{ margin: '10px' }}>
        {error}
      </div>
      <div className="update-profile-buttons">
        <CustomProfileSaveButton
          variant="contained"
          onClick={() => handleSaveClick(updatedSecurity)}
        >
          Update
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default Security;
