import { Attachments, Attachment } from '../types';
import apiInstance, { useAxios } from './index';
import { getErrorMessageFromResponse } from '../utils/error';

export type GetAttachmentsResponse = {
  results: File[];
};

const BASE_URL = 'api/attachment/attachment';

const buildURL = (base: string, params: Record<string, any>) => {
  const queryString = new URLSearchParams(params).toString();
  return `${base}?${queryString}`;
};

export const getAttachments = async (counter: number, offset = 0) => {
  const URL = buildURL(`${BASE_URL}/search/`, {
    limit: counter,
    offset: offset,
  });

  try {
    const response = await apiInstance.get(URL);
    const data = response.data;

    return {
      next: data?.next,
      count: data?.count || 0,
      attachments: data?.results,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const useGetAttachments = (counter: number) => {
  const URL = buildURL(`${BASE_URL}/search/`, {
    limit: counter,
    offset: 0,
  });

  const [{ data, loading, error }, refetch] = useAxios<Attachments>({
    url: URL,
  });

  const refetchAttachments = (counter: number, offset: number) => {
    refetch({
      url: buildURL(`${BASE_URL}/search/`, {
        direction: 'desc',
        limit: counter,
        offset: offset,
      }),
    });
  };

  return {
    next: data?.next,
    count: data?.count || 0,
    error,
    attachments: data?.results,
    hasFinishedLoadingAttachments: !loading,
    refetchAttachments,
  };
};

export const sortAttachments = async (
  counter: number,
  sort?: string,
  filter?: string,
  pageUrl?: string // New parameter for pagination URL
) => {
  const params: Record<string, any> = { limit: counter };
  sort = sort || 'desc';
  filter = filter || 'All';
  if (['asc', 'desc'].includes(sort)) {
    params.direction = sort;
  }

  if (['size', 'alphabetical'].includes(sort)) {
    params.order_option = sort;
  }

  if (['Uploaded', 'Received', 'All'].includes(filter)) {
    params.file_filter = filter.toLowerCase();
  }

  // Use the provided page URL if available, otherwise build a new URL
  const url = pageUrl || buildURL(`${BASE_URL}/search/`, params);

  try {
    const response = await apiInstance(url, {
      method: 'GET',
    });

    // Assuming the API response structure has 'next', 'count', and 'results'
    return {
      next: response.data.next,
      count: response.data.count || 0,
      attachments: response.data.results,
    };
  } catch (error) {
    return { error };
  }
};

export const getAttachmentStorage = async () =>
  await apiInstance('api/attachment/attachment/storage-usage/', {
    method: 'GET',
    params: {
      limit: '1',
    },
  });

export const useGetAttachmentStorage = () => {
  let URL = 'api/attachment/attachment/storage-usage/';
  const [{ data, loading, error }, refetch] = useAxios<any>({
    url: URL,
  });
  return {
    storage: data,
    error: error,
    hasFinishedLoadingStorage: !loading,
    refetchStorage: refetch,
  };
};

export const deleteAttachment = async (attachment: Attachment) => {
  try {
    await apiInstance({
      method: 'DELETE',
      url: `${BASE_URL}/${attachment.id}/delete/`,
    });

    return {
      success: true,
      message: 'Attachment deleted successfully.',
      attachmentId: attachment.id,
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error deleting attachment.',
      attachmentId: attachment.id,
      error: getErrorMessageFromResponse(error) || 'Unknown error',
    };
  }
};

export const searchAttachments = async (
  word: string,
  counter: number,
  sort?: string,
  filter?: string
) => {
  // Initialize the params object with the limit key
  const params: Record<string, any> = { limit: counter };

  // Handle sort
  sort = sort || 'desc';
  if (['asc', 'desc'].includes(sort)) {
    params.direction = sort;
  }
  if (['size', 'alphabetical'].includes(sort)) {
    params.order_option = sort;
  }

  // Handle filter
  filter = filter || 'All';
  if (['Uploaded', 'Received', 'All'].includes(filter)) {
    params.file_filter = filter.toLowerCase();
  }

  // Include the word (search term) in the params
  params.name = word;

  try {
    const response = await apiInstance(
      `api/attachment/attachment/search/`, // Assuming `apiInstance` builds the URL with params.
      {
        method: 'GET',
        params: params,
      }
    );

    return {
      success: true,
      message: 'Search completed successfully.',
      searchTerm: word,
      attachments: response.data.results || [],
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error during search.',
      searchTerm: word,
      attachments: [],
      error: getErrorMessageFromResponse(error) || 'Unknown error',
    };
  }
};

export const sendAttachment = async (files: FileList) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  try {
    await apiInstance(`${BASE_URL}/upload-file/`, {
      method: 'POST',
      data: formData,
    });

    return {
      success: true,
      message: 'Files uploaded successfully.',
      uploadedFilesCount: files.length,
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error uploading files.',
      uploadedFilesCount: files.length,
      error: getErrorMessageFromResponse(error) || 'Unknown error',
    };
  }
};
