import React from 'react';
import { useSelector } from 'react-redux';
import { selectGroupEventMasterclassName } from '../../../apiHooks/groupSessionTypeNames';
import { capitalizeWords } from '../../../utils/text';
import './styling/bookings.scss';

const BookingsEmptyPage = ({ tab }: { tab: number }) => {
  const masterclassName =
  useSelector(selectGroupEventMasterclassName) || 'masterclass';
  let display = 'bookings';
  if (tab === 1) display = 'completed bookings';
  else if (tab === 2) display = `${capitalizeWords(masterclassName)} replays`;
  return (
    <div className="empty-page-container">
      <img
        className="not-found-image"
        src={'/no-bookings.png'}
        alt={'not-found'}
      />
      <div className="not-found-text">No {display} yet...</div>
      <div className="not-found-text">
        {tab === 2
          ? `You will be able to see your ${capitalizeWords(masterclassName)} here`
          : ` You will be able to manage your ${display} from here`}
      </div>
    </div>
  );
};

export default BookingsEmptyPage;
