import React, { memo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Requests from './components/requests';
import Actions from './components/actions';
import Impact from './components/impact';
import Bookings from './components/bookings';
import ActionPlansDashboard from '../../pages/ActionPlan/actionPlansDashboard';
import { PlanDetailsManagement } from '../../pages/ActionPlan/planDetails';

type Props = {
  tab: string;
  subTab?: string;
  data: any;
};
const DashboardInfo = ({ tab, subTab, data }: Props) => {
  if (tab === 'requests')
    return (
      <Requests
        communityRequests={data.communityRequests}
        requested={data.upcomingRequests}
        groupEventRequests={data.groupEventRequestData}
        onDeclineGroupEventRequest={data.refetchGroupEventRequest}
        timeZoneString={data.timezone}
        interests={data.interests}
        subTab={subTab}
      />
    );
  else if (tab === 'actionplan') return <ActionPlansDashboard />;
  else if (tab === 'planDetails') return <PlanDetailsManagement />;
  else if (tab === 'actions')
    return (
      <Actions
        upcoming={data.upcomingCallsAndGroupEvents}
        requested={data.upcomingRequests}
        groupEventRequests={data.groupEventRequestData}
        onDeclineGroupEventRequest={data.refetchGroupEventRequest}
        timeZoneString={data.timezone}
        totalSessions={data.totalSessions}
        orgsHelped={data.orgsHelped}
        followup={data.followupRequests}
      />
    );
  else if (tab === 'impact')
    return <Impact totalSessions={data.totalSessions} subTab={subTab} />;
  else if (tab === 'bookings')
    return (
      <Bookings
        upcoming={data.upcomingCallsAndGroupEvents}
        groupEventsCompleted={data.boostCallGroupEventsCompleted}
        completed={data.completedBoostCallEvents}
        timeZoneString={data.timezone}
      />
    );
  else return null;
};

export default memo(DashboardInfo);
