import React, { useEffect } from 'react';
import useCustomTracking from '../../hooks/useCustomTracking';
import Button from '../../atoms/Button';
import { getUrlParam } from '../../utils/url';
import './group-event-call.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';

const GroupEventCall = () => {
  const customCallUrl = getUrlParam('custom_call_url') as string;
  const groupEventId = getUrlParam('id') as string;
  const { trackEvent, readyToTrack } = useCustomTracking();
  const { volunteerProfile } = useSelectProfileData();

  useEffect(() => {
    if (readyToTrack) {
      const now = new Date(Date.now()).toISOString();

      (async () => {
        try {
          await trackEvent({
            action: 'page-load',
            time: now,
            label: 'join-group-event-call',
            group_event_id: groupEventId,
            organisation_id: volunteerProfile?.id,
          });
        } catch (err) {}
        if (customCallUrl) {
          window.location.href = customCallUrl;
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customCallUrl, groupEventId, readyToTrack, trackEvent, volunteerProfile]);

  return (
    <div className="group-event-call__container">
      <div className="group-event-call__custom-call-container">
        <h3>Now redirecting you to your call</h3>
        <Button
          buttonType="primary"
          onClickFunction={() => window.open(customCallUrl, 'custom_call')}
          className="group-event-call__custom-call-button"
          text="Or click here to continue"
        />
      </div>
    </div>
  );
};

export default GroupEventCall;
