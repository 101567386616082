import React from 'react';
import './radio-button.scss';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../apiHooks/auth';

interface Props {
  selected: boolean;
}

const RadioButton = ({ selected }: Props) => {
  var themeStored = useSelector(selectTheme);

  return (
    <div
      className={selected ? 'radio-btn__selected' : 'radio-btn__unseleted'}
      style={{
        border: selected
          ? `7px solid ${themeStored?.palette?.primary?.main}`
          : '',
      }}
    ></div>
  );
};

export default RadioButton;
