import useFetchUserLocation from '../apiHooks/userLocation/useFetchUserLocation';
import useFetchUserProfile from '../apiHooks/userProfile/useFetchUserProfile';
import useFetchVolunteerProfile from '../apiHooks/volunteerProfile/useFetchVolunteerProfile';

type Props = { autoFetch: boolean };

// Used to access state.userProfile, state.userLocation & state.organisationProfile
// But does not automatically fire the actions to make the API calls
const useSelectProfileData = ({ autoFetch }: Props = { autoFetch: false }) => {
  // USER PROFILE
  const {
    userProfile,
    fetchUserProfile,
    setUserProfile,
    clearUserProfile,
    hasFinishedLoadingUserProfile,
  } = useFetchUserProfile({
    autoFetch,
  });

  const {
    volunteerProfile,
    fetchVolunteerProfile,
    setVolunteerProfile,
    clearVolunteerProfile,
    needsToFinishSignup,
    hasFinishedLoadingVolunteerProfile,
  } = useFetchVolunteerProfile({
    autoFetch,
  });

  // USER LOCATION
  const {
    userLocation,
    fetchUserLocation,
    setUserLocation,
    clearUserLocation,
    hasFinishedLoadingUserLocation,
  } = useFetchUserLocation({ autoFetch });

  // FETCH ALL
  const fetchAllProfileData = async () => {
    const [userProfileResult, userLocationResult, volunteerProfileResult] =
      await Promise.allSettled([
        fetchUserProfile(),
        fetchUserLocation(),
        fetchVolunteerProfile(),
      ]);

    return {
      userProfileResult,
      userLocationResult,
      volunteerProfileResult,
    };
  };

  const hasFinishedFetchingProfileAndLocation =
    hasFinishedLoadingUserProfile &&
    hasFinishedLoadingUserLocation &&
    hasFinishedLoadingVolunteerProfile;

  return {
    userProfile,
    userLocation,
    hasFinishedLoadingVolunteerProfile,
    volunteerProfile,
    fetchAllProfileData,
    hasFinishedFetchingProfileAndLocation,
    needsToFinishSignup,
    setUserProfile,
    clearUserProfile,
    setUserLocation,
    clearUserLocation,
    setVolunteerProfile,
    clearVolunteerProfile,
  };
};

export default useSelectProfileData;
