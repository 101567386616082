import React, { memo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './style.scss';
import Badge from '@mui/material/Badge';
import { SocialShareModal } from '../../../atoms/SocialShareButton';
import { formatDate } from '../../../utils/time';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import useGetJoinCall from '../../../hooks/useGetJoinCall';
import { GroupEvent, BoostCallEvent } from '../../../types';
import MessagesModal from '../../MessagesModal';
import RescheduleUpcomingCard from '../../RescheduleUpcomingCard';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../../styling/buttons';
import '../components/styling/card-styling.scss';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { modalStyle } from './style';
import MenteeDetailsModal from '../../../pages/NewCallDetails/menteeDetailsModal';
import moment from 'moment';
import { useSystemTogglesContext } from '../../../context/SystemToggles';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from '../../../apiHooks/sessionTitle';
import { selectGroupEventMasterclassName, selectGroupEventWebinarName, selectGroupEventWorkshopName } from '../../../apiHooks/groupSessionTypeNames';
import { capitalizeWords } from '../../../utils/text';

type Props = {
  appointmentData: any;
  timeZone: string;
  subject: string;
};
const baseUrl = process.env.REACT_APP_BASE_URL;

const NewUpcomingCard = ({ timeZone, subject, appointmentData }: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];
  const masterclassName =
    useSelector(selectGroupEventMasterclassName) || 'Masterclass';
  const webinarName = useSelector(selectGroupEventWebinarName) || 'Webinar';
  const workshopName = useSelector(selectGroupEventWorkshopName) || 'Workshop';
  const eventType =
  appointmentData.type === 'masterclass'
      ?capitalizeWords(masterclassName)
      :   appointmentData.type === 'webinar'
      ? capitalizeWords(webinarName)
      : capitalizeWords(workshopName);

  const newURL = baseUrl?.replace('volunteer', 'organisation');

  const navigate = useNavigate();

  const { joinCall } = useGetJoinCall({ callInfo: appointmentData });
  const [change, setChange] = useState(false);
  const {
    id: boostCallId,
    organisation: organisationData,
    volunteer_new_messages_count,
    start_time,
    title,
  } = (appointmentData as BoostCallEvent) || {};

  useEffect(() => {
    if (change === true) window.location.reload();
  }, [change]);

  const {
    interest,
    code,
    start_time: groupEventDate,
    volunteer: groupEventVolunteerProfile,
    type: groupEventType,
  } = (appointmentData as GroupEvent) || {};

  const id = code || boostCallId;
  const speakerBriefing = `/group-event-briefing?code=${code}`;
  const groupEventDetailsLink = `group-event-details?code=${code}`;

  const profileData = {
    name: organisationData?.first_name,
    picture: organisationData?.profile_picture,
    companyName: organisationData?.name,
    currentRole: organisationData?.current_role,
    showPresentedBy: false,
  };

  const dateFormatted = formatDate(start_time, timeZone);

  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };
  const [unreadMessages, setUnreadMessages] = useState<number>(
    volunteer_new_messages_count
  );
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleOpenShareModal = () => setOpenShareModal(true);
  const handleCloseShareModal = () => setOpenShareModal(false);
  const sessionTitle = useSelector(selectSessionTitle);
  if (title === '1-to-1 mentoring call')
    return (
      <div className="dashboard-box">
        <div className="upcoming-paper-box">
          <Paper key={id} elevation={0}>
            <div>
              <div className="rectangle-icon-container">
                <div className="upcoming-rectangle-icon">
                  {!moment().isAfter(moment(start_time)) ? (
                    <div className="rectangle-icon-text">Upcoming</div>
                  ) : (
                    <div className="rectangle-icon-text">Started</div>
                  )}
                </div>
              </div>
              <div className="dashboard-card__container">
                <div
                  onClick={() => setIsModalOpen(true)}
                  className="profile-photo-card"
                  style={{
                    cursor: 'pointer',
                    backgroundImage: profileData.picture
                      ? `url(${profileData.picture})`
                      : 'url(/avatar.png)',
                  }}
                ></div>
                <div className="dashboard-card-header-info">
                  <div className="dashboard-card-title">
                    <div className="dashboard-card-name">
                      {sessionTitle} with
                      <div className="upcoming-paper-first-name">
                        {profileData.name}
                      </div>
                    </div>
                  </div>
                  <div className="upcoming-paper-organisation">
                    {profileData.companyName}
                  </div>
                  <div className="upcoming-paper-topic">
                    {' '}
                    {appointmentData.top_priority_name}
                  </div>
                  <div className="upcoming-paper-time">
                    <div className="dashboard-paper-time-day">
                      <CalendarTodayOutlinedIcon
                        sx={{ paddingBottom: '3px' }}
                        fontSize="small"
                      />{' '}
                      {time.date?.day} {time.date?.monthThreeLetterFormat}{' '}
                      {time.date?.year}
                    </div>
                    <div className="dashboard-paper-time-hour">
                      <ScheduleIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />{' '}
                      {time.time?.hour}{' '}
                    </div>
                  </div>
                  <div className="upcoming-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        variant="contained"
                        onClick={() => joinCall()}
                      >
                        Join Session
                      </DashboardFilledButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        onClick={() => {
                          if (showMessagingUI) {
                            navigate(
                              `/messaging-centre?id=${appointmentData?.organisation?.id}`
                            );
                            return;
                          }
                          setOpenMessagesModal(true);
                          setUnreadMessages(0);
                        }}
                      >
                        <Badge
                          badgeContent={showMessagingUI ? 0 : unreadMessages}
                          color="info"
                          sx={{ padding: '3px' }}
                        >
                          Messages
                        </Badge>
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={() => setRescheduleModal(true)}
                        disabled={moment().isAfter(moment(start_time))}
                      >
                        Reschedule
                      </DashboardEmptyButton>
                    </div>{' '}
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={() =>
                          navigate(
                            `/call-details?type=events&cid=${appointmentData.id}`
                          )
                        }
                      >
                        Manage
                      </DashboardEmptyButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        {openMessagesModal ? (
          <MessagesModal
            boostCallEvent={appointmentData as BoostCallEvent}
            onClose={() => setOpenMessagesModal(false)}
            onReadMessages={() => setUnreadMessages(0)}
            openMessagesModal={openMessagesModal}
          />
        ) : null}
        {isModalOpen ? (
          <MenteeDetailsModal
            isOpen={isModalOpen}
            event={appointmentData}
            setIsModalOpen={setIsModalOpen}
          />
        ) : null}
        <RescheduleUpcomingCard
          profileData={profileData}
          timeZone={timeZone}
          id={id}
          time={time}
          setChange={setChange}
          setRescheduleModal={setRescheduleModal}
          rescheduleModal={rescheduleModal}
        />
      </div>
    );
  else
    return (
      <div className="dashboard-box">
        <div className="masterclass-paper-box">
          <Paper key={id} elevation={0}>
            <div>
              <div className="rectangle-icon-container">
                <div className="upcoming-rectangle-icon">
                  <div className="rectangle-icon-text">Upcoming</div>
                </div>
              </div>
              <div className="dashboard-card__container">
                <img
                  className="mastercard-photo-card"
                  src={'/masterclass.png'}
                  alt={'masterclass'}
                />
                <div className="dashboard-card-header-info">
                  <div className="dashboard-card-title">
                    <div className="dashboard-card-name">
                      Upcoming {eventType}
                    </div>
                  </div>
                  <div className="upcoming-paper-topic">
                    {appointmentData.title || interest.name}
                  </div>

                  <div className="upcoming-paper-time">
                    <div className="dashboard-paper-time-hour">
                      <ScheduleIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />{' '}
                      {time.time?.hour}{' '}
                    </div>
                    <div className="dashboard-paper-time-day">
                      <CalendarTodayOutlinedIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />{' '}
                      {time.date?.day} {time.date?.monthThreeLetterFormat}{' '}
                      {time.date?.year}
                    </div>
                  </div>
                  <div className="upcoming-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        variant="contained"
                        onClick={() => joinCall()}
                      >
                        Start Event
                      </DashboardFilledButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333', width: '150px' }}
                        onClick={() => {
                          navigate(speakerBriefing);
                        }}
                      >
                        Briefing
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={handleOpenShareModal}
                      >
                        Share
                      </DashboardEmptyButton>
                    </div>
                    <Modal
                      open={openShareModal}
                      onClose={handleCloseShareModal}
                      aria-labelledby="group-event-modal-title"
                    >
                      <Box sx={modalStyle}>
                        <SocialShareModal
                          link={`${newURL}${groupEventDetailsLink}`}
                          eventType={groupEventType}
                          subject={interest.name}
                          name={`${groupEventVolunteerProfile?.first_name} ${
                            groupEventVolunteerProfile?.last_name
                              ? groupEventVolunteerProfile?.last_name
                              : ''
                          }`}
                          companyName={groupEventVolunteerProfile?.employer}
                          startTime={groupEventDate}
                          onCloseClick={handleCloseShareModal}
                        />
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
};

export default memo(NewUpcomingCard);
