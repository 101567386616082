import React from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import styles from './faq-indicator.module.scss';
import { useSelector } from 'react-redux';
import { selectPartnerProfile } from '../../apiHooks/partnerProfile';

type Props = {
  className?: string;
};

const FaqIndicator = ({ className }: Props) => {
  const partnerProfile = useSelector(selectPartnerProfile);

  const faq = partnerProfile?.faq
    ? partnerProfile.faq
    : 'https://digitalboost.org.uk/frequently-asked-questions-mentor-platform/';

  return (
    <div className={`${styles.container} ${className ? className : ''}`}>
      <a href={faq} target="_blank" rel="noreferrer" className={styles.icon}>
        <HelpOutlineOutlinedIcon fontSize="medium" />
      </a>
      <div className={styles.text_container}>
        <span className={styles.text}>
          Check out our{' '}
          <a href={faq} target="_blank" rel="noreferrer">
            FAQs
          </a>{' '}
          and handy resources
        </span>
        <div className={styles.pointer} />
      </div>
    </div>
  );
};

export default FaqIndicator;
