import useRedux from '../../hooks/useRedux';
import useAutoFetch from '../../hooks/useAutoFetch';
import {
  selectUserProfile,
  fetchUserProfileAction,
  clearUserProfile as clearUserProfileAction,
  setUserProfile as setUserProfileAction,
  selectHasFinishedLoadingUserProfile,
  selectHasNotFetchedUserProfile,
} from './index';
import type { UserProfile } from '../../types';

type Props = { autoFetch: boolean };

const useFetchUserProfile = (
  { autoFetch = true }: Props = { autoFetch: true }
) => {
  // HOOKS
  const { useSelector, dispatch } = useRedux();
  useAutoFetch(fetchUserProfileAction, selectHasNotFetchedUserProfile, {
    autoFetch,
  });

  // ACTIONS
  const fetchUserProfile = () => dispatch(fetchUserProfileAction());
  const setUserProfile = (profile: UserProfile) =>
    dispatch(setUserProfileAction(profile));
  const clearUserProfile = () => dispatch(clearUserProfileAction());

  // SELECTORS
  const userProfile = useSelector(selectUserProfile);
  const hasFinishedLoadingUserProfile = useSelector(
    selectHasFinishedLoadingUserProfile
  );

  return {
    userProfile,
    fetchUserProfile,
    setUserProfile,
    clearUserProfile,
    hasFinishedLoadingUserProfile,
  };
};

export default useFetchUserProfile;
