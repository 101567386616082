import useSelectProfileData from '../../hooks/useSelectProfileData';

export function useGetPreferences() {
  const { volunteerProfile, userLocation, userProfile } =
    useSelectProfileData();
  let profile = { ...volunteerProfile, ...userProfile, ...userLocation };
  const {
    availability,
    industries,
    interests,
    mentor_countries,
    prefer_charities,
    prefer_educators,
    prefer_freelancers,
    prefer_health_services,
    prefer_students,
    prefer_unemployed_furloughed,
    prefer_social_enterprise,
    prefer_women,
    partner,
    one_off_mentoring,
    ongoing_mentoring,
    postcode,
    languages,
    id,
    user,
    is_beta_user,
    region,
    prefer_businesses,
  } = profile;
  const updatedPreferences = {
    availability,
    industries,
    interests,
    mentor_countries,
    prefer_charities,
    prefer_educators,
    prefer_freelancers,
    prefer_health_services,
    prefer_students,
    prefer_unemployed_furloughed,
    prefer_social_enterprise,
    prefer_women,
    partner,
    one_off_mentoring,
    ongoing_mentoring,
    postcode,
    languages,
    id,
    user,
    is_beta_user,
    region,
    prefer_businesses,
  };
  return updatedPreferences;
}
