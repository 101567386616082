import { useAxios } from '../../api';
import { getErrorMessageFromResponse } from '../../utils/error';

type SignupProps = {
  email: string;
  password: string;
  partnerId?: number | null;
  signup_options?: number[] | null;
};

const useSignup = () => {
  const [{ data, loading, error }, executeSignup] = useAxios(
    {
      url: `api/core/signup/`,
      method: 'POST',
      headers: {},
    },
    { manual: true }
  );

  const typedSignup = async ({
    email,
    password,
    partnerId,
    signup_options,
  }: SignupProps) =>
    executeSignup({
      data: {
        username: email,
        password: password,
        user_type: 'volunteer',
        partner_id: partnerId,
        signup_options: signup_options,
      },
    });

  return {
    signup: typedSignup,
    signupResponseData: data,
    isLoadingSignup: loading,
    signupError: error ? getErrorMessageFromResponse(error) : null,
  };
};

export default useSignup;
