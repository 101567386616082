import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Skills from './Components/Skills';
import Mentoring from './Components/Mentoring';
import LanguageTab from './Components/LanguageTab';
import Mentee from './Components/Mentee';
import { useGetPreferences } from './functions';

type Props = {
  tab: string;
};
const PreferenesInfo = ({ tab }: Props) => {
  const data = useGetPreferences();
  if (tab === 'skills' && data) return <Skills data={data} />;
  else if (tab === 'schedule') return <Mentoring data={data} />;
  else if (tab === 'language') return <LanguageTab data={data} />;
  else if (tab === 'mentee') return <Mentee data={data} />;
  else return null;
};

export default PreferenesInfo;
