import { useAxios } from '../../api';
import { BoostCallRequests } from '../../types';

const useGetBoostCallRequests = () => {
  const [{ data, loading, error }, refetch] = useAxios<BoostCallRequests>({
    url: `api/event/boostcallrequests/?limit=100&status=pending`,
  });
  const results = data?.results.filter((request) =>
    request.time_slots.every(
      (ts) =>
        ts.status !== 'time_slot_cancelled' && ts.status !== 'time_slot_expired'
    )
  );
  return {
    boostCallRequests: results || [],
    hasFinishedLoadingBoostCallRequests: !loading,
    boostCallRequestError: error,
    refetchBoostCallRequests: refetch,
  };
};

export default useGetBoostCallRequests;
