import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Badge from '@mui/material/Badge';
import { formatDate } from '../../../utils/time';
import './style.scss';
import { GroupEvent, BoostCallEvent } from '../../../types';
import MessagesModal from '../../MessagesModal';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../../styling/buttons';
import '../components/styling/card-styling.scss';
import useAcceptRescheduleBoostcall from '../../../apiHooks/boostCalls/useAcceptRescheduleBoostcall';
import { IconButton } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import useGetJoinCall from '../../../hooks/useGetJoinCall';
import MenteeDetailsModal from '../../../pages/NewCallDetails/menteeDetailsModal';
import { useNavigate } from 'react-router-dom';
import RescheduleDialog from './rescheduleDialog';
import ConfirmRescheduleDialog from './confirmRescheduleDialog';
import { useSystemTogglesContext } from '../../../context/SystemToggles';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from '../../../apiHooks/sessionTitle';

type Props = {
  appointmentData: any;
  timeZone?: string;
};
const NewRescheduleCard = ({ timeZone, appointmentData }: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const { joinCall } = useGetJoinCall({ callInfo: appointmentData });
  const [rescheduleModalOpen, setIsRescheduleModalOpen] = React.useState(false);
  const [confirmRescheduleModalOpen, setConfirmRescheduleModalOpen] =
    React.useState(false);

  const navigate = useNavigate();
  const {
    id: boostCallId,
    organisation: organisationData,
    volunteer_new_messages_count,
    reschedule_request_time,
    call_url_v2,
    start_time,
    reschedule_requested_by,
  } = (appointmentData as BoostCallEvent) || {};
  const { code } = (appointmentData as GroupEvent) || {};
  const id = code || boostCallId;

  const profileData = {
    name: organisationData?.first_name,
    picture: organisationData?.profile_picture,
    companyName: organisationData?.name,
    currentRole: organisationData?.current_role,
    showPresentedBy: false,
  };
  const sessionTitle = useSelector(selectSessionTitle);
  const rescheduleDateFormatted = formatDate(reschedule_request_time, timeZone);
  const currentDateFormatted = formatDate(start_time, timeZone);

  const rescheduledTime = {
    time: rescheduleDateFormatted?.time,
    date: rescheduleDateFormatted?.date,
  };

  const currentTime = {
    time: currentDateFormatted?.time,
    date: currentDateFormatted?.date,
  };
  const [unreadMessages, setUnreadMessages] = useState<number>(
    volunteer_new_messages_count
  );
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const { acceptRescheduleBoostCall } = useAcceptRescheduleBoostcall({
    id: boostCallId,
  });

  const acceptAndRefresh = async () => {
    try {
      const acceptParams = {
        newStartTime: reschedule_request_time,
        call_url_v2,
      };
      await acceptRescheduleBoostCall(acceptParams);
      setConfirmRescheduleModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  if (reschedule_requested_by === 'volunteer')
    return (
      <div className="dashboard-box">
        <div className="reschedule-paper-box">
          <Paper key={id} elevation={0}>
            <div>
              <div className="rectangle-icon-container">
                <div className="reschedule-rectangle-icon">
                  <div className="rectangle-icon-text">Upcoming</div>
                </div>
              </div>

              <div className="dashboard-card__container">
                <div
                  onClick={() => setIsModalOpen(true)}
                  className="profile-photo-card"
                  style={{
                    backgroundImage: profileData.picture
                      ? `url(${profileData.picture})`
                      : 'url(/avatar.png)',
                  }}
                ></div>

                <div className="dashboard-card-header-info">
                  <div className="dashboard-card-title">
                    <div className="dashboard-card-name">
                      {reschedule_requested_by === 'volunteer'
                        ? `${sessionTitle} with`
                        : 'Reschedule request by'}
                      <div className="request-paper-first-name">
                        {profileData.name}
                      </div>
                    </div>
                  </div>
                  <div className="reschedule-paper-organisation">
                    {profileData.companyName}
                  </div>
                  <div className="reschedule-paper-topic">
                    {appointmentData.top_priority_name}
                  </div>
                  <Dialog onClose={handleClose} open={open}>
                    <div className="close-dialog-icon">
                      <IconButton
                        color="primary"
                        onClick={handleClose}
                        sx={{ padding: '0px' }}
                      >
                        <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
                      </IconButton>
                    </div>

                    <div className="reschedule-dialog-title">
                      {' '}
                      Reschedule requested
                    </div>
                    <div className="reschedule-dialog-info">
                      <div>
                        You have requested to reschedule the appointment
                      </div>
                      <div className="reschedule-dialog-date">
                        Original Time:
                        <div className="reschedule-dialog-date-text">
                          {currentTime.date?.day}{' '}
                          {currentTime.date?.monthThreeLetterFormat}{' '}
                          {currentTime.date?.year} {currentTime.time?.hour}{' '}
                        </div>
                      </div>
                      <div className="reschedule-dialog-date">
                        Proposed New Time:{' '}
                        <div className="reschedule-dialog-date-text">
                          {' '}
                          {rescheduledTime.date?.day}{' '}
                          {rescheduledTime.date?.monthThreeLetterFormat}{' '}
                          {rescheduledTime.date?.year}{' '}
                          {rescheduledTime.time?.hour}{' '}
                        </div>
                      </div>
                      <div>
                        If {profileData.name} does not accept,the session will
                        remain at the original time unless you or{' '}
                        {profileData.name} cancel it.
                      </div>
                    </div>
                  </Dialog>
                  {reschedule_requested_by === 'volunteer' ? (
                    <div className="reschedule-helper-text">
                      You proposed a new time. If the mentee does not accept,
                      the session will remain at the original time unless you
                      cancel it.
                    </div>
                  ) : null}
                  <div className="reschedule-paper-time">
                    <div className="dashboard-paper-time-day">
                      <CalendarTodayOutlinedIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />
                      {currentTime.date?.day}{' '}
                      {currentTime.date?.monthThreeLetterFormat}{' '}
                      {currentTime.date?.year}
                    </div>
                    <div className="dashboard-paper-time-hour">
                      <ScheduleIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />
                      {currentTime.time?.hour}{' '}
                    </div>
                    <IconButton
                      color="primary"
                      onClick={handleClickOpen}
                      sx={{ backgroundColor: '#ff5f5f', padding: '3px' }}
                    >
                      <HistoryIcon sx={{ color: 'white' }} fontSize="medium" />
                    </IconButton>
                  </div>

                  <div className="upcoming-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        variant="contained"
                        onClick={() => joinCall()}
                      >
                        Join Session
                      </DashboardFilledButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        onClick={() => {
                          if (showMessagingUI) {
                            navigate(
                              `/messaging-centre?id=${appointmentData?.organisation?.id}`
                            );
                            return;
                          }
                          setOpenMessagesModal(true);
                        }}
                      >
                        <Badge
                          badgeContent={unreadMessages}
                          color="info"
                          sx={{ padding: '3px' }}
                        >
                          Messages
                        </Badge>
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={() =>
                          navigate(
                            `/call-details?type=events&cid=${appointmentData.id}`
                          )
                        }
                      >
                        Manage
                      </DashboardEmptyButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        {openMessagesModal ? (
          <MessagesModal
            boostCallEvent={appointmentData as BoostCallEvent}
            onClose={() => setOpenMessagesModal(false)}
            onReadMessages={() => setUnreadMessages(0)}
            openMessagesModal={openMessagesModal}
          />
        ) : null}
        {isModalOpen ? (
          <MenteeDetailsModal
            isOpen={isModalOpen}
            event={appointmentData}
            setIsModalOpen={setIsModalOpen}
          />
        ) : null}
      </div>
    );
  else
    return (
      <div className="dashboard-box">
        <div className="reschedule-paper-box">
          <Paper key={id} elevation={0}>
            <div>
              <div className="rectangle-icon-container">
                <div className="sent-reschedule-rectangle-icon">
                  <div className="rectangle-icon-text">Pending</div>
                </div>
              </div>
              <div className="dashboard-card__container">
                <div
                  className="profile-photo-card"
                  onClick={() => setIsModalOpen(true)}
                  style={{
                    cursor: 'pointer',
                    backgroundImage: profileData.picture
                      ? `url(${profileData.picture})`
                      : 'url(/avatar.png)',
                  }}
                ></div>

                <div className="dashboard-card-header-info">
                  <div className="dashboard-card-title">
                    <div className="dashboard-card-name">
                      Reschedule request by
                      <div className="request-paper-first-name">
                        {profileData.name}
                      </div>
                    </div>
                  </div>
                  <div className="reschedule-paper-organisation">
                    {profileData.companyName}
                  </div>
                  <div className="reschedule-paper-topic">
                    {appointmentData.top_priority_name}
                  </div>
                  <Dialog onClose={handleClose} open={open}>
                    <div className="close-dialog-icon">
                      <IconButton
                        color="primary"
                        onClick={handleClose}
                        sx={{ padding: '0px' }}
                      >
                        <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
                      </IconButton>
                    </div>

                    <div className="reschedule-dialog-title">
                      {' '}
                      Reschedule requested
                    </div>
                    <div className="reschedule-dialog-info">
                      <div>
                        {' '}
                        {profileData.name} has requested to reschedule this
                        session
                      </div>

                      <div className="reschedule-dialog-date">
                        Original Time:
                        <div className="reschedule-dialog-date-text">
                          {currentTime.date?.day}{' '}
                          {currentTime.date?.monthThreeLetterFormat}{' '}
                          {currentTime.date?.year} {currentTime.time?.hour}{' '}
                        </div>
                      </div>
                      <div className="reschedule-dialog-date">
                        Proposed New Time:{' '}
                        <div className="reschedule-dialog-date-text">
                          {' '}
                          {rescheduledTime.date?.day}{' '}
                          {rescheduledTime.date?.monthThreeLetterFormat}{' '}
                          {rescheduledTime.date?.year}{' '}
                          {rescheduledTime.time?.hour}{' '}
                        </div>
                      </div>
                      <div>
                        If you do not accept the new time, the session will
                        remain at the original time, unless you cancel it.
                      </div>
                    </div>
                  </Dialog>
                  <div className="reschedule-helper-text">
                    The learner has requested to reschedule for the following
                    new time
                  </div>
                  <div className="reschedule-paper-time">
                    <div className="dashboard-paper-time-day">
                      <CalendarTodayOutlinedIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />
                      {rescheduledTime.date?.day}{' '}
                      {rescheduledTime.date?.monthThreeLetterFormat}{' '}
                      {rescheduledTime.date?.year}
                    </div>
                    <div className="dashboard-paper-time-hour">
                      <ScheduleIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />
                      {rescheduledTime.time?.hour}{' '}
                    </div>
                    <IconButton
                      color="primary"
                      onClick={handleClickOpen}
                      sx={{ backgroundColor: '#ff5f5f', padding: '3px' }}
                    >
                      <HistoryIcon sx={{ color: 'white' }} fontSize="medium" />
                    </IconButton>
                  </div>

                  <div className="reschedule-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        variant="contained"
                        onClick={() => acceptAndRefresh()}
                      >
                        Accept New Time
                      </DashboardFilledButton>
                    </div>

                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#ff5f5f', width: '190px' }}
                        onClick={() => {
                          setIsRescheduleModalOpen(true);
                        }}
                      >
                        Decline New Time
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={() =>
                          navigate(
                            `/call-details?type=events&cid=${appointmentData.id}`
                          )
                        }
                      >
                        Manage
                      </DashboardEmptyButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        {openMessagesModal ? (
          <MessagesModal
            boostCallEvent={appointmentData as BoostCallEvent}
            onClose={() => setOpenMessagesModal(false)}
            onReadMessages={() => setUnreadMessages(0)}
            openMessagesModal={openMessagesModal}
          />
        ) : null}
        {rescheduleModalOpen ? (
          <RescheduleDialog
            isOpen={rescheduleModalOpen}
            event={appointmentData}
            setIsRescheduleModalOpen={setIsRescheduleModalOpen}
          />
        ) : null}
        {confirmRescheduleModalOpen ? (
          <ConfirmRescheduleDialog
            isOpen={confirmRescheduleModalOpen}
            event={appointmentData}
            setConfirmRescheduleModalOpen={setConfirmRescheduleModalOpen}
          />
        ) : null}
        {isModalOpen ? (
          <MenteeDetailsModal
            isOpen={isModalOpen}
            event={appointmentData}
            setIsModalOpen={setIsModalOpen}
          />
        ) : null}
      </div>
    );
};

export default NewRescheduleCard;
