import { Badge } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import './style.scss';
import moment from 'moment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useNavigate } from 'react-router-dom';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import useWindowSize from '../../hooks/useWindowSize';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../styling/buttons';
import { CustomCallPagePaper } from '../../styling/backgrounds';
import { useSystemTogglesContext } from '../../context/SystemToggles';

type Props = {
  event: any;
  setOpenMenteeModal?: any;
  setOpenMessagesModal?: any;
  messagesRead?: boolean;
};

const CompletedEvent = ({
  event,
  messagesRead,
  setOpenMenteeModal,
  setOpenMessagesModal,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);

  window.onpopstate = function () {
    window.location.reload();
  };

  return (
    <div>
      <div className="organisation-information-container">
        <div className="header-container">
          <div className="header-text"> This session was completed</div>
          <DashboardEmptyButton
            sx={{ fontSize: '14px' }}
            variant="text"
            onClick={() => setOpenMenteeModal(true)}
          >
            View learner details
          </DashboardEmptyButton>
        </div>
      </div>

      <CustomCallPagePaper>
        <div>
          <div className="time-container-text">Original time</div>
          <div className="time-container">
            <div className="time-container-text">
              {moment(event.start_time).format('DD MMMM YYYY')}
            </div>
            <div className="time-container-text">
              {moment(event.start_time).format('dddd')}
            </div>
            <div className="time-container-text">
              {moment(event.start_time).format('HH:mm')}
            </div>
          </div>
        </div>
        <div className="header-box-time-left-container">
          This session was completed
        </div>

        <div
          className="header-box-buttons"
          style={{
            width: !isMobile ? '100%' : '70vw',
            justifyContent: 'flex-start',
          }}
        >
          <div className="header-box-button">
            <DashboardFilledButton
              variant="contained"
              disabled={event.volunteer_sent_nps || event.organisation === null}
              onClick={() => navigate('/nps-score?id=' + event.id)}
              sx={{ width: '180px' }}
            >
              {event.volunteer_sent_nps ? `Feedback Given` : `Leave Feedback`}
            </DashboardFilledButton>
          </div>
          <div className="header-box-button">
            <DashboardEmptyButton
              variant="text"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingLeft: '2px',
              }}
              onClick={() => {
                if (showMessagingUI) {
                  navigate(`/messaging-centre?id=${event?.organisation?.id}`);
                  return;
                }
                setOpenMessagesModal(true);
              }}
            >
              <EmailOutlinedIcon sx={{ marginRight: '5px' }} />
              <Badge
                badgeContent={
                  !messagesRead ? event.volunteer_new_messages_count : 0
                }
                color="info"
                sx={{ padding: '3px' }}
              >
                Messages
              </Badge>
            </DashboardEmptyButton>
          </div>
          <div className="header-box-button">
            <DashboardEmptyButton
              disabled={event.organisationData === null}
              onClick={() => navigate(`/nps-score?id=${event.id}&no_show=1`)}
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingLeft: '2px',
              }}
            >
              <PhoneDisabledIcon sx={{ marginRight: '5px' }} />
              Call didn't happen?
            </DashboardEmptyButton>
          </div>
        </div>
      </CustomCallPagePaper>
    </div>
  );
};

export default memo(CompletedEvent);
