import React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { getCountry } from '../Components/Mentee';
import InputLabel from '@mui/material/InputLabel';
import {
  CustomFormControlLabel,
  CustomTypography,
} from '../../../styling/generalStyling';

type Props = {
  setMentorCountries: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
  mentorCountries: string[] | undefined;
  radioValue: string;
  setRadioValue: React.Dispatch<React.SetStateAction<string>>;
  countryList: Country[];
  selectedCountries: Country[];
  setSelectedCountries: React.Dispatch<React.SetStateAction<Country[]>>;
};

type Country = {
  code: string;
  name: string;
};
export default function EditLocationForm({
  selectedCountries,
  setSelectedCountries,
  countryList,
  mentorCountries,
  setMentorCountries,
  radioValue,
  setRadioValue,
}: Props) {
  if (selectedCountries.length === 249) setSelectedCountries([]);
  const [countrySelected, setCountrySelected] = React.useState<string>('');

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const country = getCountry(event.target.value.toString(), countryList);
    setCountrySelected(event.target.value);
    if (
      selectedCountries.filter(
        (existingSelection) => existingSelection === country
      )[0] === undefined
    ) {
      setSelectedCountries((selectedCountries) => [
        ...selectedCountries,
        country,
      ]);
      const sendMentorCountries = selectedCountries.map(
        (country) => country.code
      );
      setMentorCountries([...sendMentorCountries, country.code]);
    }
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl>
        <RadioGroup
          aria-labelledby="location-radio-buttons-group-label"
          name="location-buttons-group"
          value={radioValue}

        >
          <CustomFormControlLabel
            value="anyone"
            onClick={() => {
              setRadioValue('anyone');
            }}
            control={<Radio />}
            label={
              <CustomTypography>
                I prefer to mentor mentees from anywhere
              </CustomTypography>
            }
          />
          <CustomFormControlLabel
            value="countrySpecific"
            onClick={() => {
              setRadioValue('countrySpecific');
              if (mentorCountries && countryList.length > 0)
                mentorCountries
                  .map((country) => getCountry(country, countryList))
                  .filter((countryObj) => countryObj !== null);
            }}
            control={<Radio />}
            label={
              <CustomTypography>
                I prefer to mentor mentees from specific countries only
              </CustomTypography>
            }
          />
          {radioValue === 'countrySpecific' ? (
            <Box sx={{ minWidth: 120, marginTop: '15px' }}>
              <FormControl fullWidth>
                <InputLabel id="country-select-label">
                  <CustomTypography>Select a country</CustomTypography>
                </InputLabel>
                <Select
                  labelId="location-simple-select-label"
                  id="location-simple-select"
                  label="Select a country"
                  value={countrySelected}
                  onChange={handleChange}
                  sx={{ fontFamily: 'Gilroy' }}
                >
                  {countryList.map((country) => (
                    <MenuItem
                      sx={{
                        '&.MuiMenuItem-root': {
                          fontFamily: 'Gilroy',
                        },
                      }}
                      key={country.code}
                      value={country.code}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : null}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
