import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import EditInput from '../ProfileUtils/EditInput';
import api from '../../../api';
import { PrivacyPreferences, VolunteerProfile } from '../types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import '../style/general.scss';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import EditPartnerVolunteer from '../Forms/EditPartnerVolunteer';
import { CustomFormLabel } from '../../../styling/generalStyling';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
  DashboardEmptyButton,
} from '../../../styling/buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  fontFamily: 'Gilroy',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type Props = {
  data: VolunteerProfile;
};

let updatedPrivacy: PrivacyPreferences = {};

const Privacy = ({ data }: Props) => {
  const [preferences] = useState(data ? data.privacy_preferences : {show_last_name:undefined,show_employer:undefined,show_current_role:undefined, show_employment_history:undefined,show_linkedin_profile:undefined, newsletter:undefined,call_request_emails:undefined});
  let selectedPreferences = [];
  if (preferences && preferences?.show_last_name)
    selectedPreferences.push('Last Name');
  if (preferences && preferences?.show_employer)
    selectedPreferences.push('Employer');
  if (preferences && preferences?.show_current_role)
    selectedPreferences.push('Current Job');
  if (preferences && preferences?.show_employment_history)
    selectedPreferences.push('Past Experiences');
  if (preferences && preferences?.show_linkedin_profile)
    selectedPreferences.push('LinkedIn Profile');

    if (preferences && preferences?.show_disabilities)
    selectedPreferences.push('Disability');

  const setValue = (value: any, input: keyof PrivacyPreferences) => {
    updatedPrivacy[input] = value;
    return updatedPrivacy;
  };
  const handleSaveClick = async (privacy: any) => {
    try {
      await api.patch(`api/volunteer/volunteers/me/`, {
        privacy_preferences: privacy,
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  const saveUnlinkAccount = async (partnerId: number) => {
    try {
      await api.patch(`api/volunteer/volunteers/me/`, {
        partner: null,
        previous_partner: partnerId,
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  const relinkAccount = async (partnerId: number) => {
    try {
      await api.patch(`api/volunteer/volunteers/me/`, {
        partner: partnerId,
        previous_partner: null,
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  const yesNewsletterPreference = 'You agree to receive newsletters from us';
  const noNewsletterPreference =
    'You do not agree to receive newsletters from us';
  const yesMentoringCallPreference =
    'You agree to receive mentoring call requests from us';
  const noMentoringCallPreference =
    'You do not agree to receive mentoring call requests from us';
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPasswordError(null);
  };
  let navigate = useNavigate();
  const handleDeleteAccount = async () => {
    try {
      await api.post(`api/core/deleteaccount/`, {
        password: password,
      });
      Cookie.remove('accessTokenDB');
      Cookie.remove('refreshTokenDB');
      navigate('/signup?delete=true');
    } catch (e: any) {
      if (e.response.data.password) {
        setPasswordError(e.response.data.password[0]);
      }
      console.error(e);
    }
  };

  return (
    <div className="profile_form">
      <EditInput
        openEdit={false}
        inputField="newsletter"
        name={'Newsletter Preferences'}
        input={
          preferences && preferences?.newsletter
            ? yesNewsletterPreference
            : noNewsletterPreference
        }
        value={{
          preferences: preferences ? preferences.newsletter : null,
          partner: data.partner,
        }}
        setValue={setValue}
      />
      <EditInput
        openEdit={false}
        inputField="mentoringCall"
        name={'Mentoring Call Requests'}
        input={
          preferences && preferences.call_request_emails
            ? yesMentoringCallPreference
            : noMentoringCallPreference
        }
        value={preferences ? preferences.call_request_emails : null}
        setValue={setValue}
      />
      <EditInput
        openEdit={false}
        inputField="privacy"
        name={'Information shown to organisations'}
        input={selectedPreferences ? selectedPreferences.join(', ') : null}
        value={preferences}
        setValue={setValue}
      />
      {data.partner && data.partner !== null ? (
        <EditPartnerVolunteer
          onSave={saveUnlinkAccount}
          partnerId={data.partner}
        />
      ) : null}
      {data?.previous_partner ? (
        <EditPartnerVolunteer
          onSave={relinkAccount}
          partnerId={data.previous_partner}
          relink={true}
        />
      ) : null}
      <div>
        <CustomProfileCancelButton
          variant="outlined"
          sx={{ padding: '8px 10px', borderColor: '#B22222', width: '400px' }}
          onClick={handleOpen}
        >
          Do you want to delete your account?
        </CustomProfileCancelButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ fontFamily: 'Gilroy' }}>
              <h1>
                Are you sure you want to delete all your data and information on
                Digital Boost?
              </h1>
              <p>
                This cannot be undone and you will have to create a new account
                if you wish to use our service again.
              </p>
              <div className="delete-account__container">
                <label>
                  Enter your password to confirm deleting your account
                </label>
                <TextField
                  sx={{ width: '50vw' }}
                  type="password"
                  onChange={(event) => setPassword(event.target.value)}
                  label={<CustomFormLabel>Password</CustomFormLabel>}
                  variant="outlined"
                />
                {passwordError ? (
                  <span className="error">{passwordError}</span>
                ) : null}
                <div>
                  <DashboardEmptyButton
                    variant="outlined"
                    sx={{
                      padding: '8px 10px',
                      borderColor: '#B22222',
                      width: '300px',
                      marginRight: '10px',
                    }}
                    onClick={handleDeleteAccount}
                  >
                    Yes, I want to delete my account
                  </DashboardEmptyButton>

                  <DashboardEmptyButton
                    variant="outlined"
                    sx={{
                      padding: '8px 10px',
                      width: '300px',
                      marginLeft: '10px',
                    }}
                    onClick={handleClose}
                  >
                    No, I’ve changed my mind
                  </DashboardEmptyButton>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="update-profile-buttons">
        <CustomProfileSaveButton
          variant="contained"
          onClick={() => handleSaveClick(updatedPrivacy)}
        >
          Save
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default Privacy;
