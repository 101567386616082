import React from 'react';
import './dropdown-with-label.scss';

interface Props extends React.HTMLProps<HTMLSelectElement> {
  label?: string | undefined;
  options: { value: string; text: string }[];
  placeHolder?: string | undefined;
  onChange?: any;
  maxlength?: number;
  containerClassName?: string;
  selectClassName?: string;
}

const DropdownWithLabel = ({
  label,
  options,
  placeHolder,
  maxlength,
  onChange = () => {},
  containerClassName,
  selectClassName,
  ...props
}: Props) => {
  return (
    <div
      className={`dropdown-with-label ${
        containerClassName ? containerClassName : ''
      }`}
    >
      {label && <label>{label}</label>}

      <select onChange={onChange} {...props}>
        {placeHolder && (
          <option value="" selected disabled>
            {placeHolder}
          </option>
        )}
        {options.map((o, i) => (
          <option key={`${label}-option-${i}`} value={o.value}>
            {o.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownWithLabel;
