import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import AppProvider from './AppProvider';
import ProfileCreate from './components/ProfileCreate';
import LoginSignup from './pages/LoginSignup';
import ResetPassword from './pages/ResetPassword';
import LinkedInSignUp from './components/LinkedInSignUp';
import SignUpOption from './components/SignUpOption';
import ConfirmCall from './templates/ConfirmCall';
import DeclineCall from './templates/ConfirmCall/DeclineCall';
import ProposeAnotherCall from './templates/ConfirmCall/ProposeAnotherCall';
import ScrollToTop from './components/ScrollToTop';
import CancelCall from './templates/ConfirmCall/CancelCall';
import GroupEventDetails from './pages/GroupEventDetails';
import Nav from './components/Nav';
import SignupComplete from './components/SignupComplete';
import NpsScore from './components/NpsScore';
import GroupEventFeedback from './components/GroupEventFeedback';
import BoostCall from './pages/BoostCall';
import GroupEventCall from './pages/GroupEventCall';
import GroupEventExplainer from './pages/GroupEventExplainer';
import GroupEventAvailability from './pages/GroupEventAvailability';
import GroupEventConfirmation from './pages/GroupEventConfirmation';
import GroupEventBriefing from './pages/GroupEventBriefing';
import Verify from './pages/Verify';
import PageNotFound from './templates/PageNotFound';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import { getAccessToken, getRefreshToken } from './utils/auth';
import Footer from './components/Footer';
import Account from './pages/Account/index';
import Preferences from './pages/Preferences/index';
import { Helmet } from 'react-helmet';
import ManageCallDetails from './pages/CallDetails';
import { selectTheme } from './apiHooks/auth';
import useRedux from './hooks/useRedux';
import SharableProfile from './pages/SharableProfile';
import MessagingCentre from './pages/MessagingCentre';
import Resources from './pages/Resources/Resources';
import Redirect from './molecules/Redirect/Redirect';
import Library from './pages/Library';
import CookiebotOverlay from './utils/cookiebot';

export const nonAuthProtectedRoutes = [
  '/signup',
  '/redirect',
  '/login',
  '/resetpassword',
  '/linkedin-signup',
  '/register-option',
  '/verify',
  '/group-event-details',
];

function App() {
  const { useSelector } = useRedux();
  var themeStored = useSelector(selectTheme);
  const [theme, setTheme] = useState(createTheme(themeStored));
  const [hasConsent, setHasConsent] = useState(false);
  useEffect(() => {
    if (process.env.REACT_APP_LOGROCKET_ON === 'true') {
      setupLogRocketReact(LogRocket);
      LogRocket.init('0enqcp/volunteer-digital-boost');
    }
  }, []);
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const hostnameParts = url.hostname.split('.');
  let subdomain = hostnameParts[0];
  useEffect(() => {
    if (
      window.Cookiebot &&
      window.Cookiebot?.consent?.necessary &&
      window.Cookiebot?.hasResponse
    ) {
      setHasConsent(true);
      console.log('Consent has been given.');
    }
    const handleConsentReady = () => {
      setHasConsent(true);
    };

    window.addEventListener('CookiebotOnConsentReady', handleConsentReady);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', handleConsentReady);
    };
  }, []);

  useEffect(() => {
    setTheme(createTheme(themeStored));
  }, [themeStored]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Helmet>
          <title>Digital Boost Volunteer Portal</title>
          <meta name="description" content="Digital Boost Volunteer Portal" />
        </Helmet>
        {!hasConsent && subdomain !== 'localhost' && <CookiebotOverlay />}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <Router>
          <AppProvider>
            <Nav />
            <ScrollToTop>
              <div>
                <Routes>
                  <Route
                    path="/dashboard"
                    element={<Navigate replace to="/dashboard/actions" />}
                  />
                  <Route
                    path="/dashboard/actions"
                    element={<Dashboard tab="actions" />}
                  />

                  <Route
                    path="/dashboard/requests"
                    element={<Dashboard tab="requests" subTab="my-requests" />}
                  />
                  <Route
                    path="/dashboard/actionplan"
                    element={<Dashboard tab="actionplan" />}
                  />
                  <Route
                    path="/plan"
                    element={<Dashboard tab="planDetails" />}
                  />
                  <Route
                    path="/create-plan"
                    element={<Dashboard tab="newActionPlan" />}
                  />
                  <Route
                    path="/dashboard/requests/my-requests"
                    element={<Dashboard tab="requests" subTab="my-requests" />}
                  />
                  <Route
                    path="/dashboard/requests/community-requests"
                    element={
                      <Dashboard tab="requests" subTab="community-requests" />
                    }
                  />
                  <Route path="/redirect" element={<Redirect />} />
                  <Route
                    path="/dashboard/logout"
                    element={<Dashboard tab="logout" />}
                  />
                  <Route
                    path="/dashboard/impact"
                    element={<Dashboard tab="impact" subTab="activity" />}
                  />
                  <Route
                    path="/dashboard/impact/activity"
                    element={<Dashboard tab="impact" subTab="activity" />}
                  />
                  <Route
                    path="/dashboard/impact/feedback"
                    element={<Dashboard tab="impact" subTab="feedback" />}
                  />
                  <Route
                    path="/dashboard/bookings"
                    element={<Dashboard tab="bookings" />}
                  />
                  <Route
                    path="/dashboard/account"
                    element={<Account tab="profile" />}
                  />
                  <Route path="/boost-call" element={<BoostCall />} />
                  <Route
                    path="/signup"
                    element={<LoginSignup mode={'signup'} />}
                  />
                  <Route path="/profile-create" element={<ProfileCreate />} />
                  <Route path="/signup-complete" element={<SignupComplete />} />
                  <Route path="/confirm-call" element={<ConfirmCall />} />
                  <Route path="/nps-score" element={<NpsScore />} />
                  <Route path="/decline-call" element={<DeclineCall />} />
                  <Route
                    path="/propose-another-call"
                    element={<ProposeAnotherCall />}
                  />
                  <Route path="/cancel-call" element={<CancelCall />} />
                  <Route path="/resetpassword" element={<ResetPassword />} />
                  <Route
                    path="/login"
                    element={<LoginSignup mode={'login'} />}
                  />
                  <Route path="/library" element={<Library />} />
                  <Route path="/linkedin-signup" element={<LinkedInSignUp />} />
                  <Route path="/call-details" element={<ManageCallDetails />} />
                  <Route
                    path="/group-event-details"
                    element={<GroupEventDetails />}
                  />
                  <Route path="/register-option" element={<SignUpOption />} />
                  <Route
                    path="/dashboard/preferences"
                    element={<Preferences tab="skills" />}
                  />
                  <Route
                    path="/dashboard/preferences/skills"
                    element={<Preferences tab="skills" />}
                  />
                  <Route
                    path="/dashboard/preferences/schedule"
                    element={<Preferences tab="schedule" />}
                  />
                  <Route
                    path="/dashboard/preferences/language"
                    element={<Preferences tab="language" />}
                  />
                  <Route
                    path="/dashboard/preferences/mentee"
                    element={<Preferences tab="mentee" />}
                  />
                  <Route
                    path="/dashboard/account"
                    element={<Account tab="profile" />}
                  />
                  <Route
                    path="/dashboard/account/profile"
                    element={<Account tab="profile" />}
                  />
                  <Route
                    path="/dashboard/account/security"
                    element={<Account tab="security" />}
                  />
                  <Route
                    path="/dashboard/account/privacy"
                    element={<Account tab="privacy" />}
                  />
                  <Route path="/verify" element={<Verify />} />
                  <Route
                    path="/group-event-feedback"
                    element={<GroupEventFeedback />}
                  />
                  <Route
                    path="/group-event-explainer"
                    element={<GroupEventExplainer />}
                  />
                  <Route
                    path="/group-event-call"
                    element={<GroupEventCall />}
                  />
                  <Route
                    path="/group-event-availability"
                    element={<GroupEventAvailability />}
                  />
                  <Route
                    path="/group-event-confirmation"
                    element={<GroupEventConfirmation />}
                  />
                  <Route
                    path="/group-event-briefing"
                    element={<GroupEventBriefing />}
                  />
                  <Route
                    path="sharable-profile"
                    element={<SharableProfile />}
                  />
                  <Route
                    path="messaging-centre"
                    element={<MessagingCentre />}
                  />
                  <Route path="/dashboard/resources" element={<Resources />} />
                  <Route
                    path="/"
                    element={
                      !!getAccessToken() || !!getRefreshToken() ? (
                        <Navigate replace to="/dashboard" />
                      ) : (
                        <Navigate replace to="/login" />
                      )
                    }
                  />
                  <Route element={<Navigate replace to="/" />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </div>
            </ScrollToTop>
            <Footer />
          </AppProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
