// CookiebotOverlay.js
import React from 'react';
import './cookiebot.scss';

function CookiebotOverlay() {
  return (
    <div className="cookiebot-overlay">
      {window?.Cookiebot?.consent === undefined ? (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            height: 'auto',
            textAlign: 'center',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '15px',
            minWidth: '300px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            zIndex: 1000,
            fontFamily: 'Gilroy',
          }}
        >
          This application requires cookies. Please enable cookies and try again
          or
          <a href="mailto:contact@digitalboost.org.uk"> click here</a> to
          contact us for support.
        </div>
      ) : null}
    </div>
  );
}
export default CookiebotOverlay;
