import { Badge } from '@mui/material';
import React, { memo } from 'react';
import './style.scss';
import moment from 'moment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import { useNavigate } from 'react-router-dom';
import { DashboardEmptyButton, ManageCallButton } from '../../styling/buttons';
import useAcceptRescheduleBoostcall from '../../apiHooks/boostCalls/useAcceptRescheduleBoostcall';
import RescheduleDialog from '../../components/Dashboard/NewRescheduleCard/rescheduleDialog';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { CustomCallPagePaper } from '../../styling/backgrounds';
import { useSystemTogglesContext } from '../../context/SystemToggles';

type Props = {
  event: any;
  setOpenMenteeModal?: any;
  setOpenMessagesModal?: any;
  setRescheduleModal?: any;
  messagesRead?: boolean;
};

const RescheduleRequestByOrganisation = ({
  event,
  setOpenMenteeModal,
  setOpenMessagesModal,
  setRescheduleModal,
  messagesRead,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const { joinCall } = useGetJoinCall({ callInfo: event });
  const startTime = moment(event.start_time);
  const today = moment();
  const navigate = useNavigate();

  const [rescheduleModalOpen, setIsRescheduleModalOpen] = React.useState(false);

  const handleDeclineRescheduleOpen = () => {
    setIsRescheduleModalOpen(true);
  };

  const { acceptRescheduleBoostCall } = useAcceptRescheduleBoostcall({
    id: event.id,
  });

  const acceptAndRefresh = async () => {
    try {
      const acceptParams = {
        newStartTime: event.reschedule_request_time,
        call_url_v2: event.call_url_v2,
      };
      await acceptRescheduleBoostCall(acceptParams);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  window.onpopstate = function () {
    window.location.reload();
  };

  return (
    <div>
      <div className="organisation-information-container">
        <div className="header-container">
          <div className="header-text">
            {event.organisation.first_name} has asked to reschedule
          </div>
          <DashboardEmptyButton
            sx={{ fontSize: '14px' }}
            variant="text"
            onClick={() => setOpenMenteeModal(true)}
          >
            View learner details
          </DashboardEmptyButton>
        </div>
        <CustomCallPagePaper>
          <div className="header-box-container">
            <div className="header-box-proposed-time">
              <div>
                <div className="time-container-text">New proposed time</div>
                <div className="time-container">
                  <div className="time-container-text">
                    {moment(event.reschedule_request_time).format(
                      'DD MMMM YYYY'
                    )}
                  </div>
                  <div className="time-container-text">
                    {moment(event.reschedule_request_time).format('dddd')}
                  </div>
                  <div className="time-container-text">
                    {moment(event.reschedule_request_time).format('HH:mm')}
                  </div>
                </div>
              </div>
              <div>
                <div className="time-container-text">Original time</div>
                <div className="time-container-reschedule-requested">
                  <div className="time-container-box">
                    <div className="time-container-text">
                      {moment(event.start_time).format('DD MMMM YYYY')}
                    </div>
                    <div className="time-container-text">
                      {moment(event.start_time).format('dddd')}
                    </div>
                    <div className="time-container-text">
                      {moment(event.start_time).format('HH:mm')}
                    </div>
                  </div>
                  <DashboardEmptyButton
                    variant="text"
                    sx={{
                      fontFamily: 'Gilroy',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      textTransform: 'none',
                      paddingLeft: '2px',
                      width: '190px',
                    }}
                    onClick={() => joinCall()}
                  >
                    Join Session
                  </DashboardEmptyButton>
                </div>
              </div>
            </div>
          </div>

          {!moment(startTime).isSame(today, 'day') ? (
            <div className="header-box-time-left-container">
              Starts in
              <div className="time-left">
                {moment(startTime.diff(today)).format('D')}
              </div>{' '}
              days
            </div>
          ) : (
            <div className="header-box-time-left-container">
              <div className="starts-today-text">Starts today</div>
            </div>
          )}

          <div className="header-box-buttons">
            <ManageCallButton
              variant="contained"
              sx={{ width: '300px', maxWidth: '100%', marginRight: '10px' }}
              onClick={() => acceptAndRefresh()}
            >
              Accept new time
            </ManageCallButton>

            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  textTransform: 'none',
                  color: '#f23737',
                  paddingLeft: '2px',
                }}
                onClick={() => handleDeclineRescheduleOpen()}
              >
                <DangerousOutlinedIcon sx={{ marginRight: '5px' }} />
                Decline new time
              </DashboardEmptyButton>
            </div>

            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  textTransform: 'none',
                  paddingLeft: '2px',
                }}
                onClick={() => {
                  if (showMessagingUI) {
                    navigate(`/messaging-centre?id=${event?.organisation?.id}`);
                    return;
                  }
                  setOpenMessagesModal(true);
                }}
              >
                <EmailOutlinedIcon sx={{ marginRight: '5px' }} />
                <Badge
                  badgeContent={
                    !messagesRead ? event.volunteer_new_messages_count : 0
                  }
                  color="info"
                  sx={{ padding: '3px' }}
                >
                  Messages
                </Badge>
              </DashboardEmptyButton>
            </div>
            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  paddingLeft: '2px',
                }}
                onClick={() => setRescheduleModal(true)}
              >
                <HistoryOutlinedIcon sx={{ marginRight: '5px' }} />
                Reschedule
              </DashboardEmptyButton>
            </div>
            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#f23737',
                  paddingLeft: '2px',
                }}
                onClick={() => navigate(`/cancel-call?id=${event.id}`)}
              >
                <DangerousOutlinedIcon sx={{ marginRight: '5px' }} />
                Cancel session
              </DashboardEmptyButton>
            </div>
          </div>
        </CustomCallPagePaper>

        {rescheduleModalOpen ? (
          <RescheduleDialog
            isOpen={rescheduleModalOpen}
            event={event}
            setIsRescheduleModalOpen={setIsRescheduleModalOpen}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(RescheduleRequestByOrganisation);
