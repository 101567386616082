import React from 'react';
import './resource-card.scss';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideocamIcon from '@mui/icons-material/Videocam';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

type Props = {
  image: string | null;
  title: string;
  type: string;
  duration: string;
  clickCard: () => void;
};

const ResourceCard = ({ image, title, type, duration, clickCard }: Props) => {
  const getTypeIcon = () => {
    switch (type) {
      case 'pdf':
        return <PictureAsPdfIcon fontSize="small" />;
      case 'video':
        return <VideocamIcon fontSize="small" />;
      case 'article':
        return <ArticleIcon fontSize="small" />;
      case 'template':
        return <AssignmentIcon fontSize="small" />;
      default:
        return <VideocamIcon fontSize="small" />;
    }
  };

  return (
    <div className="resource-card-container" onClick={clickCard}>
      {image && (
        <div className="resource-card-image-container">
          <img src={image} alt="resource cover" />
        </div>
      )}

      <div className="resource-card-title">{title}</div>

      <div className="resource-card-row-wrapper">
        <div className="resource-card-wrapper">
          {getTypeIcon()}
          <div className="resource-card-text">{type}</div>
        </div>

        <div className="resource-card-wrapper">
          <AccessTimeFilledIcon fontSize="small" />
          <div className="resource-card-text">{duration}</div>
        </div>
      </div>
    </div>
  );
};

export default ResourceCard;
