import { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { useAxios } from '../../api';
import { getErrorMessageFromResponse } from '../../utils/error';
import { Partner } from '../../types';
import { useLocation } from 'react-router-dom';

type HookProps = {
  id: string | number;
  setCookies: boolean;
};

const useFetchPartner = ({ id, setCookies }: HookProps) => {
  const { pathname } = useLocation();
  const [{ data, loading, error }, executeFetchPartner] = useAxios<Partner>(
    {
      url: `api/partner/partners/${id}/`,
      method: 'GET',
      headers: {},
    },
    { manual: true }
  );

  useEffect(() => {
    if (!!id) executeFetchPartner();
  }, [executeFetchPartner, id]);
  const [hasConsent, setHasConsent] = useState<boolean>(false);

  useEffect(() => {
    if (
      window.Cookiebot &&
      window.Cookiebot?.consent?.necessary &&
      window.Cookiebot?.hasResponse
    ) {
      setHasConsent(true);
    }
    const handleConsentReady = () => {
      setHasConsent(true);
    };

    window.addEventListener('CookiebotOnConsentReady', handleConsentReady);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', handleConsentReady);
    };
  }, []);
  useEffect(() => {
    if (data && setCookies && hasConsent) {
      Cookie.set('partner_id', data.id.toString(), {
        expires: 1,
      });

      Cookie.set(
        'gdpr_permission_required',
        data.gdpr_permission_required.toString(),
        {
          expires: 1,
        }
      );

      Cookie.set('partner_name', data.name, {
        expires: 1,
      });
      return;
    } else if (
      !pathname.includes('verify') &&
      !pathname.includes('profile-create') &&
      !pathname.includes('linkedin-signup') &&
      !pathname.includes('register-option')
    ) {
      Cookie.remove('gdpr_permission_required');
      Cookie.remove('partner_name');
      Cookie.remove('partner_id');
      return;
    }
  }, [data, setCookies, pathname, hasConsent]);
  return {
    fetchPartner: executeFetchPartner,
    fetchPartnerResponseData: data,
    isLoadingPartner: loading,
    fetchPartnerError: error ? getErrorMessageFromResponse(error) : null,
  };
};

export default useFetchPartner;
