import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardFilledButton } from '../../../styling/buttons';
import './styling/requests.scss';

const DashboardCompleteProfile = () => {
  const navigate = useNavigate();
  return (
    <div className="empty-page-container">
      <img
        className="not-found-image"
        src={'/no-requests-searching-ilustration.png'}
        alt={'not-found'}
      />
      <div className="not-found-text">
        You do not have an active profile. Please complete your profile first.
      </div>
      <DashboardFilledButton
        variant="contained"
        sx={{
          width: ' 244px',
          marginTop: '12px',
        }}
        onClick={() => navigate('/profile-create')}
      >
        Complete profile
      </DashboardFilledButton>
    </div>
  );
};

export default DashboardCompleteProfile;
