import React, { memo, useEffect, useRef, useState } from 'react';
import { BoostCallEvent } from '../../types';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import { displayTime, getMomentWithTimezone } from '../../utils/time';
import { toTitleCase } from '../../utils/text';
import './addToCalendarButton.scss';
import { defaultDateFormat, defaultTimeFormat } from '../../utils/constants';
import { Helmet } from 'react-helmet';
import { DashboardEmptyButton } from '../../styling/buttons';
import DateRangeIcon from '@mui/icons-material/DateRange';

const baseUrl = process.env.REACT_APP_BASE_URL;
const currentURL = window.location.href;

// Extract the desired portion of the URL
const extractedURL =
  currentURL.indexOf('org.uk/') !== -1
    ? currentURL.substring(0, currentURL.indexOf('org.uk/') + 7)
    : baseUrl;
type Props = {
  callInfo: BoostCallEvent;
  timeZoneString: string;
};

// Note: This component relies on the 'addevent' third party <script> being loaded
const AddToCalendarButton = ({ callInfo, timeZoneString }: Props) => {
  const {
    id: callId,
    call_url: originalCallUrl,
    start_time: startTime,
    duration,
    title,
    partner_name,
    session_title,
    organisation,
    top_priority_name: topPriority,
  } = callInfo;
  const { url: dbCallUrl } = useGetJoinCall({ callInfo });
  const calendarButtonRef = useRef<HTMLDivElement>(null);

  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(false);
  // // Fixes a bug requiring a reload of the <script>
  useEffect(() => {
    if (callInfo) {
      // @ts-ignore
      setTimeout(() => window.addeventatc?.refresh(), 1000);
    }
  }, [callInfo]);

  useEffect(() => {
    if (shouldOpenCalendar) {
      (async () => {
        // 2 second delay needed to load calendar script
        calendarButtonRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        // @ts-ignore
        await window.addeventatc?.refresh();
        calendarButtonRef?.current?.click();
        setShouldOpenCalendar(false);
      })();
    }
  }, [shouldOpenCalendar, setShouldOpenCalendar, calendarButtonRef]);

  if (!organisation) return null;

  const businessName = organisation.name && organisation.name;
  const detailsLink = `${extractedURL}call-details?type=events&cid=${callId}`;
  const rescheduleCancelLink = `${baseUrl}call-details?type=events&event_update_options=true&id=${callId}`;

  return (
    <div className="addToCalendarWrapper">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.addevent.com/libs/atc/themes/fff-theme-6/theme.css"
          type="text/css"
          media="all"
        />
        <script
          type="text/javascript"
          src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
        ></script>
      </Helmet>

      <div
        title="Add to calendar"
        className="addeventatc"
        data-styling="none"
        ref={calendarButtonRef}
        onClick={() => {
          setShouldOpenCalendar(!shouldOpenCalendar);
        }}
      >
        <DashboardEmptyButton
          variant="text"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            paddingLeft: '2px',
          }}
        >
          <DateRangeIcon sx={{ marginRight: '5px' }} />
          <div>Add to calendar</div>
        </DashboardEmptyButton>
        <span className="start">
          {displayTime(
            startTime,
            timeZoneString,
            `${defaultDateFormat} ${defaultTimeFormat}`
          )}
        </span>
        <span className="end atc-hidden-text">
          {getMomentWithTimezone(startTime, timeZoneString)
            .add(duration, 'minutes')
            .format(`${defaultDateFormat} ${defaultTimeFormat}`)}
        </span>
        <span className="timezone atc-hidden-text">{timeZoneString}</span>
        <span className="title atc-hidden-text">{`${
          partner_name ? partner_name : 'Digital Boost'
        } ${toTitleCase(session_title ? session_title : title)} with ${
          organisation.first_name
        } ${organisation.last_name}`}</span>
        <span className="description atc-hidden-text">
          {`${partner_name ? partner_name : 'Digital Boost'} ${toTitleCase(
            session_title ? session_title : title
          )} with ${organisation.first_name} ${organisation.last_name}${
            businessName ? ` from ${businessName}` : ''
          } ${
            topPriority ? `about ${toTitleCase(topPriority)}` : ''
          }.\n \n For more details about this call, visit\n${detailsLink}\n\nIf you need to reschedule or cancel your call, click here:\n${rescheduleCancelLink}`}
        </span>
        <span className="location atc-hidden-text">
          {!!dbCallUrl ? dbCallUrl : originalCallUrl}
        </span>
      </div>
    </div>
  );
};

export default memo(AddToCalendarButton);
