import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import './button.scss';
import { useSelector } from 'react-redux';
import { selectPartnerProfile } from '../../apiHooks/partnerProfile';
export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  icon?: any;
  buttonType:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'red'
    | 'dark'
    | 'ghost'
    | 'white';
  onClickFunction?: any;
  selected?: boolean;
  className?: string;
  loading?: boolean;
  children?: React.ReactNode;
  active?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      text,
      icon,
      buttonType,
      onClickFunction,
      disabled,
      selected,
      className,
      children,
      loading,
      ...props
    },
    ref
  ) => {
    let customOverride = {};
    const partner = useSelector(selectPartnerProfile);

    if (partner?.primary_color && buttonType === 'primary') {
      customOverride = {
        background: partner?.primary_color,
        color: 'white',
      };
    }

    if (partner?.primary_color && buttonType === 'tertiary') {
      customOverride = {
        background: partner?.secondary_color,
        color: 'white',
      };
    }
    const state = disabled ? 'disabled' : '';
    const isSelected = selected ? 'selected' : '';
    const classNameString = className ? className : '';
    const disabledProp = { disabled: disabled || loading };

    return (
      <button
        ref={ref}
        onClick={onClickFunction}
        className={`button button-${buttonType} ${state} ${isSelected} ${classNameString}`}
        style={customOverride}
        {...disabledProp}
        {...props}
      >
        {loading && (
          <div className={`button__loading-container`}>
            <TailSpin color="#414141" height={20} width={20} />
          </div>
        )}
        <div className={`${loading ? 'button__content-hidden' : ''}`}>
          {icon}
          {text}
          {children}
        </div>
      </button>
    );
  }
);

export default Button;
