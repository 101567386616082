export const allowedFileExtensions = [
  // Image Files
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.heic',

  // Video Files
  '.mp4',
  '.avi',
  '.mkv',
  '.mov',

  // Audio Files
  '.mp3',

  // Document Files
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.pages',
  '.numbers',
  '.key',
  '.rtf',
  '.txt',

  // Archive Files
  '.zip',
  '.rar',
  '.7z',
  '.tar',

  // Extra Files
  '.psd',
  '.svg',
];
