import React from 'react';
import ToolTip from '../ToolTip';
import { replaceUnderscoreWithSpaces } from '../../utils/text';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styles from './timezone-indicator.module.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';

type Props = {
  className?: string;
};

const TimezoneIndicator = ({ className }: Props) => {
  const { userLocation } = useSelectProfileData();
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } =
    userLocation || {};

  return (
    <div className={`${styles.container} ${className ? className : ''}`}>
      <AccessTimeIcon fontSize="medium" />
      <div className={styles.text_container}>
        <span className={styles.text}>{`Your timezone is detected as`}</span>
        <div className={styles.timezone_container}>
          <span className={styles.timezone_text}>
            {replaceUnderscoreWithSpaces(timezone)}{' '}
          </span>
          <ToolTip
            text="Timezone is automatically detected based on your device settings. Times will be displayed according to this timezone."
            id="nav-timezone-tooltip"
            place="left"
            className="preferences__timezone-tooltip"
          />
        </div>
        <div className={styles.pointer} />
      </div>
    </div>
  );
};

export default TimezoneIndicator;
