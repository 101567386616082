import { useEffect } from 'react';
import moment from 'moment';
import { useAxios } from '../../api';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useAcceptBoostCallInvite from './useAcceptBoostCallInvite';
import useProposeNewTime from './useProposeNewTime';
import { BoostCallInvite, TimeSlot } from '../../types';
import useGetCallProviders from '../coreData/useGetCallProviders';

type Props = {
  code: number | string | null;
};

const useGetBoostCallInvite = ({ code }: Props) => {
  const { volunteerProfile } = useSelectProfileData();
  const { id: volunteerId } = volunteerProfile || {};

  // Note: For Volunteers, this endpoint only returns the Volunteer's own events
  const [{ data, loading, error }, executeCall] = useAxios<BoostCallInvite>(
    {
      url: `api/event/boostcallinvites/${code}/`,
    },
    {
      manual: true,
    }
  );

  const { defaultCallProvider } = useGetCallProviders();
  const callProvider =
    data?.custom_call_provider?.id.toString() ||
    defaultCallProvider?.id.toString() ||
    '3'; //Id 3 is Jitsi

  const acceptBoostCallInviteUtils = useAcceptBoostCallInvite({
    code,
    defaultCallProvider: callProvider,
  });
  const proposeNewTimeUtils = useProposeNewTime({
    code,
    defaultCallProvider: callProvider,
  });

  useEffect(() => {
    if (!!code) executeCall();
  }, [executeCall, code]);

  const scheduledTimeSlots = data?.time_slots.filter(
    ({ status }: TimeSlot) => status === 'time_slot_scheduled'
  );

  const multipleSessionsNotAllowed =
    !data?.allow_multiple_sessions &&
    scheduledTimeSlots &&
    scheduledTimeSlots?.length > 0;

  const hasAlreadyProposedNewTime = !!data?.time_slots.find(
    ({ status, volunteer }: TimeSlot) =>
      status === 'time_slot_proposed' && volunteer === volunteerId
  );

  const nonExpiredTimeslots = data?.time_slots.filter(
    ({ start_time, status }: TimeSlot) => {
      return (
        moment(start_time).isAfter(moment()) && status === 'time_slot_pending'
      );
    }
  );

  const hasAlreadyAcceptedOneTimeslot = data?.time_slots.find(
    (ts: TimeSlot) =>
      ts.volunteer_accepted === volunteerId &&
      ts.status !== 'time_slot_cancelled'
  );

  const selectedTimeSlot = data?.time_slots.find(
    (ts: TimeSlot) => ts.id === acceptBoostCallInviteUtils?.selectedTimeSlotId
  );

  return {
    boostCallInviteData: data,
    nonExpiredTimeslots,
    hasFinishedLoadingBoostCallInvite: !loading,
    boostCallInviteError: error,
    refetchBoostCallInvite: executeCall,
    acceptBoostCallInviteUtils,
    proposeNewTimeUtils,
    hasAlreadyAcceptedOneTimeslot,
    hasAlreadyProposedNewTime,
    multipleSessionsNotAllowed: !!multipleSessionsNotAllowed,
    selectedTimeSlot,
  };
};

export default useGetBoostCallInvite;
