import React, { useEffect, useState, useRef } from 'react';
import ImageUploader from 'react-images-upload';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import InputWithLabel from '../InputWithLabel';
import { getUrlParam } from '../../utils/url';
import { useUserProfile } from '../../context/UserProfile';
import { Experiences, VolunteerProfile, UserProfile } from '../../types';
import './linkedin-sign-up.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { DashboardFilledButton } from '../../styling/buttons';
import { useSelector } from 'react-redux';
import { selectPartnerProfile } from '../../apiHooks/partnerProfile';

const LinkedInSignUp = () => {
  const { setVolunteerProfileContext, setUserProfileContext } =
    useUserProfile();
  const { setUserProfile } = useSelectProfileData();
  const navigate = useNavigate();
  const code = getUrlParam('code');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [currentCompany, setCurrentCompany] = useState<string>('');

  const [currentRole, setCurrentRole] = useState('');
  const [profilePicture, setProfilePicture] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [roleError, setRoleError] = useState<boolean>(false);
  const [bioError, setBioError] = useState<boolean>(false);
  const [profilePictureError, setProfilePictureError] =
    useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [summary, setSummary] = useState<string>('');
  const [experiences, setExperiences] = useState<Experiences[]>([]);

  const profilePhotoEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const nameEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const roleEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const bioEl = useRef() as React.MutableRefObject<HTMLInputElement>;

  const partnerProfile = useSelector(selectPartnerProfile);

  const upDateProfilePhoto = (profileImage: any) => {
    let formData = new FormData();
    formData.append('profile_picture', profileImage[0]);

    api
      .patch(`api/core/users/me/`, formData)
      .then((profilePhotoResponse) => {
        const { profile_picture } = profilePhotoResponse?.data || {};
        setProfilePicture(profile_picture);
        setUserProfileContext({
          userProfile: profilePhotoResponse.data,
          hasFinishedUserProfileFetch: true,
        });
      })
      .catch((err: any) => console.log(err));
  };

  const handleExperienceChange =
    (index: number) => (e: React.FormEvent<HTMLInputElement>) => {
      const newExperiences: Experiences[] = [...experiences];
      const experience = newExperiences[index];

      if (
        experience[e.currentTarget.name as keyof Experiences] === 'from_year' ||
        experience[e.currentTarget.name as keyof Experiences] === 'to_year'
      ) {
        experience[e.currentTarget.name as 'from_year' | 'to_year'] = e
          .currentTarget.value.length
          ? parseInt(e.currentTarget.value)
          : null;
      } else {
        experience[e.currentTarget.name as 'role' | 'employer'] =
          e.currentTarget.value;
      }
      setExperiences(newExperiences);
    };

  const updateUser = async () => {
    const sendObject = {
      first_name: firstName,
      last_name: lastName,
      summary: summary,
    };
    try {
      const userProfileResponse = await api.patch<UserProfile>(
        `api/core/users/me/`,
        sendObject
      );

      if (userProfileResponse) {
        setUserProfile(userProfileResponse.data);
        setUserProfileContext({
          userProfile: userProfileResponse.data,
          hasFinishedUserProfileFetch: true,
        });
        return true;
      }
      return false;
    } catch (err: any) {
      if (err.response.data['non_field_errors']) {
        setErrorMessage(err.response.data['non_field_errors']);
      } else {
        setErrorMessage('Server Error');
      }
    }
  };

  const updateVolunteer = async () => {
    const sendObject = {
      current_role: currentRole,
      employer: currentCompany,
      employment_history: experiences,
    };
    try {
      const volunteerProfileResponse = await api.patch<VolunteerProfile>(
        `api/volunteer/volunteers/me/`,
        sendObject
      );

      if (volunteerProfileResponse) {
        setVolunteerProfileContext({
          volunteerProfile: volunteerProfileResponse.data,
          hasFinishedVolunteerProfileFetch: true,
        });
      }
    } catch (err: any) {
      if (err.response.data['non_field_errors']) {
        setErrorMessage(err.response.data['non_field_errors']);
      } else {
        setErrorMessage('Server Error');
      }
    }
  };

  const getLinkedInData = async () => {
    try {
      // if the callback fails (code has expired, page refresh etc), it should still call the rest of the endpoints
      try {
        let sendObject: { code: any; partner_id?: number } = { code };

        if (partnerProfile) {
          sendObject = { code, partner_id: partnerProfile.id };
        }

        await api.post(`api/core/linkedin_callback/`, sendObject);
      } catch (e) {
        console.error(e);
      }

      const liProfile = await api.get(`api/core/linkedinprofiles/me/`);
      setSummary(liProfile.data.summary);

      const profile = await api.get(`api/volunteer/volunteers/me/`);

      setCurrentRole(profile.data.current_role);
      setCurrentCompany(profile.data.employer);
      setProfilePicture(profile.data.profile_picture);
      setExperiences(profile.data.employment_history);
      setFirstName(profile.data.first_name);
      setLastName(profile.data.last_name);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getLinkedInData();
    // eslint-disable-next-line
  }, [partnerProfile]);

  const handleNext = async () => {
    if (
      !profilePicture ||
      !firstName ||
      !lastName ||
      !currentCompany ||
      !currentRole ||
      !summary
    ) {
      let elToFocus;
      if (!summary) {
        setBioError(true);
        elToFocus = bioEl;
      }
      if (!currentRole || !currentCompany) {
        setRoleError(true);
        elToFocus = roleEl;
      }
      if (!firstName || !lastName) {
        setNameError(true);
        elToFocus = nameEl;
      }
      if (!profilePicture) {
        setProfilePictureError(true);
        elToFocus = profilePhotoEl;
      }

      elToFocus?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      return;
    }

    await updateVolunteer();
    const res = await updateUser();

    if (res) navigate(`/profile-create?code=${code}`);
  };

  return (
    <div className="linkedin-sign-up">
      <div className="container">
        <h1> Welcome! Let’s set up your profile, it’s quick </h1>
        <div className="linkedin-signup__profile-image-container">
          <div
            style={{
              backgroundImage: profilePicture
                ? `url(${profilePicture})`
                : 'url(/avatar.png)',
            }}
            className="linkedin-signup__profile-image"
            ref={profilePhotoEl}
          />
          {!profilePicture && (
            <>
              <ImageUploader
                withIcon={false}
                withPreview={true}
                label=""
                singleImage={true}
                buttonText="Upload Profile Photo"
                onChange={(pictureFile) => upDateProfilePhoto(pictureFile)}
                imgExtension={[
                  '.jpeg',
                  '.jpg',
                  '.gif',
                  '.png',
                  '.gif',
                  '.svg',
                  '.peg',
                ]}
                maxFileSize={2248576}
                fileSizeError="File size is too big, please keep it below 2 MB"
                className="linkedin-signup__uploader-button"
              />
              {profilePictureError && (
                <h3 className="linkedin-signup__validation-error">
                  {' '}
                  Please upload a profile photo
                </h3>
              )}
            </>
          )}
        </div>

        <div className="error"> {errorMessage}</div>

        <div className="names" ref={nameEl}>
          <InputWithLabel
            required={true}
            defaultValue={firstName}
            type={'text'}
            label={'First Name'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (nameError && lastName) setNameError(false);
              setFirstName(e.currentTarget.value);
            }}
          />
          <InputWithLabel
            required={true}
            defaultValue={lastName}
            type={'text'}
            label={'Last Name'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (nameError && firstName) setNameError(false);
              setLastName(e.currentTarget.value);
            }}
          />
        </div>
        {nameError && (
          <span className="linkedin-signup__validation-error">
            {'First Name and Last Name are required fields'}
          </span>
        )}
        <div ref={roleEl}>
          <InputWithLabel
            required={true}
             onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (roleError && currentRole) setRoleError(false);
              setCurrentCompany(e.currentTarget.value);
            }}
            defaultValue={currentCompany}
            type={'text'}
            label={'Current Company'}
          />

          <InputWithLabel
            required={true}
             onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (roleError && currentCompany) setRoleError(false);
              setCurrentRole(e.currentTarget.value);
            }}
            defaultValue={currentRole}
            type={'text'}
            label={'Your Role'}
          />
        </div>
        {roleError && (
          <span className="linkedin-signup__validation-error">
            {'Current Company and Role are required fields'}
          </span>
        )}
        <div className="intro" ref={bioEl}>
          <InputWithLabel
             onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (bioError) setBioError(false);
              setSummary(e.currentTarget.value);
            }}
            defaultValue={summary}
            type={'text'}
            label={'Your Introduction'}
          />
        </div>
        {bioError && (
          <span className="linkedin-signup__validation-error">
            {'Please provide a brief introduction'}
          </span>
        )}

        {experiences && experiences.length > 0 && (
          <>
            <h3>Past Experience</h3>
            {experiences.map((experience, i: number) => {
              return (
                <div key={`experience-${i}`}>
                  <InputWithLabel
                    name="employer"
                    value={experience.employer}
                    type={'text'}
                    label={'Company'}
                    onChange={handleExperienceChange(i)}
                  />
                  <div className="names">
                    <InputWithLabel
                      name="role"
                      value={experience.role}
                      type={'text'}
                      label={'Role'}
                      onChange={handleExperienceChange(i)}
                    />
                    <div className="years">
                      <InputWithLabel
                        name="from_year"
                        value={experience.from_year?.toString()}
                        type={'number'}
                        label={'Start Year'}
                        onChange={handleExperienceChange(i)}
                      />
                      <InputWithLabel
                        name="to_year"
                        value={experience.to_year?.toString()}
                        type={'number'}
                        label={'End Year'}
                        onChange={handleExperienceChange(i)}
                      />
                    </div>
                  </div>
                  <div className="border" />
                </div>
              );
            })}
          </>
        )}

        <div className="linkedin-signup__button-container">
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '200px' }}
            onClick={handleNext}
          >
            Next: Location
          </DashboardFilledButton>
        </div>
      </div>
    </div>
  );
};

export default LinkedInSignUp;
