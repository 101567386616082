import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';
import type { RootState } from '../../store';
import { login } from '../../api/auth';
import { getErrorMessageFromResponse } from '../../utils/error';

/* --- SLICE --- */

interface AuthState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  error?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  signupEmail: string;
  resendVerificationStatus: 'pending' | 'fulfilled' | 'rejected';
  verifyAccountStatus: 'pending' | 'fulfilled' | 'rejected';
}

const initialState = {
  status: 'init',
  accessToken: null,
  refreshToken: null,
  error: null,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => initialState,
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      if (!!state.refreshToken) state.status = 'fulfilled';
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
      if (!!state.accessToken) state.status = 'fulfilled';
    },
  },
  // for the difference between reducers and extraReducers, see: https://redux-toolkit.js.org/api/createslice#extrareducers
  extraReducers: (builder) => {
    builder
      // login
      .addCase(loginAction.pending, (state, action) => {
        state.status = action.meta.requestStatus;
        state.error = null;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.status = action.meta.requestStatus;
        state.accessToken = action.payload?.access;
        state.refreshToken = action.payload?.refresh;
        state.error = null;
      })
      .addCase(loginAction.rejected, (state, action?: any) => {
        const { payload, meta } = action || {};
        state.error = payload || '';
        state.status = meta?.requestStatus;
      });
  },
});

/* --- SELECTORS --- */
//theme
export const selectTheme = (state: RootState) => state.theme.value;
// login
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectHasFinishedLoadingAuth = (state: RootState) =>
  selectAuthStatus(state) === 'fulfilled' ||
  selectAuthStatus(state) === 'rejected';
export const selectIsLoadingLogin = (state: RootState) =>
  selectAuthStatus(state) === 'pending';
export const selectLoginError = (state: RootState) => state.auth.error;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;

/* --- ACTIONS --- */

export const { logout, setAccessToken, setRefreshToken } = authSlice.actions;

/* --- THUNKS --- */

// login
export type AuthActionProps = {
  email: string;
  password: string;
};

export const loginAction = createAsyncThunk(
  `${authSlice.name}/login`,
  async ({ email, password }: AuthActionProps, { rejectWithValue }) => {
    try {
      const response = await login({ email, password });
      // set cookie that expires in a day
      Cookie.set('refreshTokenDB', response.data.refresh, { expires: 90 });
      Cookie.set('accessTokenDB', response.data.access, { expires: 1 });
      return response.data;
    } catch (error) {
      return rejectWithValue(getErrorMessageFromResponse(error));
    }
  }
);

export default authSlice.reducer;
