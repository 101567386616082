import React, { useRef, useEffect, useState, Fragment } from 'react';
import Modal from 'react-modal';
import { IoIosClose, IoIosRefresh, IoIosCheckmarkCircle } from 'react-icons/io';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import Message from '../Message';

import BlockModal from '../../atoms/BlockModal';

import { getErrorMessageFromResponse } from '../../utils/error';
import { formatDate } from '../../utils/time';
import { Message as MessageType, BoostCallEvent } from '../../types';
import './messages-modal.scss';
import useMessages from '../../apiHooks/messages/useMessages';
import { useUserProfile } from '../../context/UserProfile';
import { toTitleCase } from '../../utils/text';
import ButtonWithDropdown from '../../atoms/ButtonWithDropdown';
import Button from '../../atoms/Button';
import { NoStyleButton } from '../../styling/buttons';

type Props = {
  boostCallEvent: BoostCallEvent;
  onReadMessages: () => void;
  onClose: () => void;
  openMessagesModal: boolean;
};

const MessagesModal = ({
  boostCallEvent,
  onReadMessages,
  onClose,
  openMessagesModal,
}: Props) => {
  const { userLocation } = useUserProfile();
  const { timezone } = userLocation || {};
  const {
    id: boostCallEventId,
    organisation,
    start_time,
    title,
    block_comments,
    vol_blocked_comments,
    org_blocked_comments,
  } = boostCallEvent;

  const {
    refetchAndMarkRead,
    messages,
    hasFinishedLoadingMessages,
    clearMessages,
    /* --- sendMessage --- */
    sendMessage,
    sendMessageError,
    blockMessages,
    isLoadingBlockMessages,
    blockMessagesError,
  } = useMessages({ boostCallEventId: boostCallEventId });

  const bottomOfChatEl = useRef<null | HTMLDivElement>(null);
  const [text, setText] = useState<string>();
  const [error, setError] = useState<string | null>();
  const [refreshAnimation, setRefreshAnimation] = useState<boolean>();
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [blockModalOpen, setBlockModalOpen] = useState<boolean>();
  const [blocked, setBlocked] = useState<boolean>(vol_blocked_comments);

  const formattedDate = formatDate(start_time, timezone);
  const dateString = `${formattedDate?.date?.day} ${
    formattedDate?.date?.month
  } - ${formattedDate?.time?.hour} ${
    formattedDate?.time?.timeZoneCode ? formattedDate?.time?.timeZoneCode : ''
  }`;
  const { first_name: organisationFirstName } = organisation || {};
  const organisationName = organisationFirstName
    ? `${organisationFirstName} `
    : 'Inactive user';

  useEffect(() => {
    if (hasFinishedLoadingMessages) bottomOfChatEl?.current?.scrollIntoView();
  }, [hasFinishedLoadingMessages]);

  // Clear messages from state on dismount
  useEffect(() => {
    return () => {
      clearMessages();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshAnimation) setTimeout(() => setRefreshAnimation(false), 750);
  });

  const onModalOpen = () => {
    bottomOfChatEl?.current?.scrollIntoView();
    onReadMessages();
  };

  const onSend = async () => {
    if (!text) return setError('Message must include text');
    setError(null);
    try {
      await sendMessage({ text, boostCallEventId: Number(boostCallEventId) });
      refetchAndMarkRead();
      setMessageSent(true);
    } catch (err) {
      setError(getErrorMessageFromResponse(err));
    }
  };

  const onConfirmBlock = async () => {
    try {
      await blockMessages();
      setBlocked(!blocked);
      setBlockModalOpen(false);
    } catch (err) {}
  };

  useEffect(() => {
    if (messageSent) {
      const closeTimeout = setTimeout(onClose, 2000);
      setMessageSent(false);
      return () => clearTimeout(closeTimeout);
    }
  }, [messageSent, onClose]);

  const messageSentStyles = {
    maxWidth: '250px',
    maxHeight: '75px',
    margin: 'auto',
    padding: 0,
  };
  const messagesModalStyles = {
    maxWidth: '800px',
    margin: 'auto',
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 25, 0.45)',
      zIndex: 200,
    },
    content: messageSent ? messageSentStyles : messagesModalStyles,
  };

  return (
    <Modal
      isOpen={openMessagesModal}
      onAfterOpen={onModalOpen}
      onRequestClose={onClose}
      contentLabel="Messages"
      style={customStyles}
      ariaHideApp={false}
    >
      {messageSent ? (
        <div className="messages-modal__sent-notification">
          <IoIosCheckmarkCircle
            className="messages-modal__sent-icon"
            size={35}
            fill={'#00AB6B'}
          />
          <div className="messages-modal__sent-modal-close">
            <button className="messages-modal__header-button ">
              <IoIosClose size={25} onClick={onClose} />
            </button>
          </div>
          <span>Message sent!</span>
        </div>
      ) : (
        <div className="messages-modal__inner-container">
          {blockModalOpen && (
            <BlockModal
              isOpen={blockModalOpen}
              onClose={() => setBlockModalOpen(false)}
              onConfirmBlock={onConfirmBlock}
              blocked={blocked}
              loading={isLoadingBlockMessages}
              error={blockMessagesError}
            />
          )}
          <div className="messages-modal__header-icons-container">
            <ButtonWithDropdown
              className="messages-modal__header-button"
              buttonContent={<BiDotsHorizontalRounded size={35} />}
            >
              <button
                className="messages-modal__block-button"
                onClick={() => setBlockModalOpen(!blockModalOpen)}
              >
                {blocked ? 'Unblock' : 'Block'}
              </button>
            </ButtonWithDropdown>
            <button className="messages-modal__header-button ">
              <IoIosClose size={35} onClick={onClose} />
            </button>
          </div>
          <div className="messages-modal__header">
            <span className="messages-modal__header-title">{`${toTitleCase(
              title?.replace('call', 'session')
            )} | ${organisationName}`}</span>
            <span className="messages-modal__header-subtitle">
              {dateString}
            </span>
          </div>
          <div className="messages-modal__messages-scroller">
            {hasFinishedLoadingMessages ? (
              messages.map((m: MessageType, index: number) => {
                const user = m.volunteer
                  ? m.volunteer
                  : m.organisation
                  ? m.organisation
                  : null;
                return (
                  user && (
                    <Fragment key={`message-${boostCallEventId}-${index}`}>
                      <Message
                        text={m.comment}
                        user={user}
                        created={m.created}
                        className="messages-modal__message"
                      />
                    </Fragment>
                  )
                );
              })
            ) : (
              <div className="messages-modal__loading-container">
                <img
                  className="messages-modal__loading-spinner"
                  src="/loading.gif"
                  alt="loading"
                />
              </div>
            )}
            <div ref={bottomOfChatEl} />
          </div>
          <div className="messages-modal__input-container">
            <textarea
              autoFocus
              rows={5}
              disabled={!boostCallEvent.organisation}
              className="messages-modal__text-input"
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setText(e.target.value);
                if (error) setError(null);
              }}
            />
            <div className="messages-modal__button-container">
              <Button
                buttonType="ghost"
                className="messages-modal__refresh-button"
              >
                <IoIosRefresh
                  size={24}
                  onClick={() => {
                    refetchAndMarkRead();
                    setRefreshAnimation(true);
                  }}
                  className={`${
                    refreshAnimation ? 'messages-modal__refreshing' : ''
                  }`}
                  onAnimationEnd={() => {}}
                />
              </Button>
              <NoStyleButton
                sx={{ marginTop: '10px' }}
                variant="contained"
                onClick={onSend}
                disabled={
                  !text ||
                  blocked ||
                  org_blocked_comments ||
                  block_comments ||
                  !boostCallEvent.organisation
                }
                className="messages-modal__send-button"
              >
                Send
              </NoStyleButton>
            </div>
            {sendMessageError && (
              <span className="error">{sendMessageError}</span>
            )}
            {error && <span className="error">{error}</span>}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MessagesModal;
