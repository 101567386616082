import React from 'react';
import { useNavigate } from 'react-router-dom';
import './group-event-confirmation.scss';
import { DashboardFilledButton } from '../../styling/buttons';
import { getUrlParam } from '../../utils/url';

const GroupEventConfirmation = () => {
  const eventType = getUrlParam('eventType') as string;
  const navigate = useNavigate();
  const guidePDF = `https://digitalboost-community-management.s3.eu-west-2.amazonaws.com/Mentors/Hosting+your+first+Masterclass.pdf`;
  const confirmationTitle = `Thank you for submitting your event details! Our team will review this and we’ll send you a confirmation email when your event is set up.
  `;
  const confirmationExplanationText = `  Once your ${eventType} is confirmed, it’ll be added to your Dashboard and you’ll be able to see your speaker briefing, which includes questions from participants who have registered. `;

  return (
    <div className="group-event-confirmation__container">
      <div className="group-event-confirmation__title-container">
        <div className="group-event-confirmation__title-text-wrapper">
          <h1 className="group-event-confirmation__title">Thank you!</h1>
          <h2 className="group-event-confirmation__subtitle">{eventType}</h2>
        </div>
      </div>
      <div className="group-event-confirmation__body-wrapper">
        <p>{confirmationTitle}</p>
        <p className="group-event-confirmation__body-text">
          {confirmationExplanationText}
        </p>

        <h3 className="group-event-confirmation__body-subtitle">
          {'Getting ready'}
        </h3>
        <p className="group-event-confirmation__prepare-text">
          <span>
            While we get your {eventType} ready, have a look at this{' '}
            <a href={guidePDF} target="_blank" rel="noopener noreferrer">
              {' '}
              guide
            </a>{' '}
            with some tips for hosting a successful and engaged {eventType}. If
            you have any questions,{' '}
            <a href="mailto:contact@digitalboost.org.uk">
              please get in touch!
            </a>
          </span>
        </p>
        <DashboardFilledButton
          variant="contained"
          sx={{ width: '200px', alignSelf: 'center' }}
          onClick={() => navigate('/dashboard')}
        >
          Go to dashboard
        </DashboardFilledButton>
      </div>
    </div>
  );
};

export default GroupEventConfirmation;
