import { Badge } from '@mui/material';
import React, { memo } from 'react';
import './style.scss';
import moment from 'moment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { CustomCallPagePaper } from '../../styling/backgrounds';
import { DashboardEmptyButton } from '../../styling/buttons';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import { useNavigate } from 'react-router-dom';

type Props = {
  event: any;
  setOpenMenteeModal?: any;
  messagesRead?: boolean;
  setOpenMessagesModal?: any;
};

const CancelledEvent = ({
  event,
  setOpenMenteeModal,
  messagesRead,
  setOpenMessagesModal,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const navigate = useNavigate();

  window.onpopstate = function () {
    window.location.reload();
  };

  return (
    <div>
      <div className="organisation-information-container">
        <div className="header-container">
          <div className="header-text"> This session was cancelled</div>
          <DashboardEmptyButton
            sx={{ fontSize: '14px' }}
            variant="text"
            onClick={() => setOpenMenteeModal(true)}
          >
            View learner details
          </DashboardEmptyButton>
        </div>
      </div>

      <CustomCallPagePaper>
        <div>
          <div className="time-container-text">Original time</div>
          <div className="time-container">
            <div className="time-container-text">
              {moment(event.start_time).format('DD MMMM YYYY')}
            </div>
            <div className="time-container-text">
              {moment(event.start_time).format('dddd')}
            </div>
            <div className="time-container-text">
              {moment(event.start_time).format('HH:mm')}
            </div>
          </div>
        </div>
        <div className="header-box-time-left-container">
          This session was cancelled
        </div>
        <div
          className="header-box-buttons"
          style={{ width: '100%', justifyContent: 'flex-start' }}
        >
          <div className="header-box-button">
            <DashboardEmptyButton
              variant="text"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingLeft: '2px',
              }}
              onClick={() => {
                if (showMessagingUI) {
                  navigate(`/messaging-centre?id=${event?.organisation?.id}`);
                  return;
                }
                setOpenMessagesModal(true);
              }}
            >
              <EmailOutlinedIcon sx={{ marginRight: '5px' }} />
              <Badge
                badgeContent={
                  !messagesRead ? event.volunteer_new_messages_count : 0
                }
                color="info"
                sx={{ padding: '3px' }}
              >
                Messages
              </Badge>
            </DashboardEmptyButton>
          </div>
        </div>
      </CustomCallPagePaper>
    </div>
  );
};

export default memo(CancelledEvent);
