import { AxiosPromise } from 'axios';
import apiInstance from './index';
import type { Message } from '../types';
import { getErrorMessageFromResponse } from '../utils/error';

export type GetMessagesProps = {
  boostCallEventId: number;
};

export type GetMessagesResponse = {
  results: Message[];
  count: number;
  next: string | null;
  previous: string | null;
};

export const getMessages = ({
  boostCallEventId,
}: GetMessagesProps): AxiosPromise<GetMessagesResponse> =>
  apiInstance('api/event/boostcalleventscomments/', {
    method: 'GET',
    params: {
      boostcall_event: boostCallEventId,
      limit: '100',
    },
  });

export type MarkMessagesReadProps = {
  boostCallEventId: number;
};

export const markMessagesRead = ({ boostCallEventId }: MarkMessagesReadProps) =>
  apiInstance(`api/event/boostcalleventscomments/read_comment/`, {
    method: 'POST',
    data: {
      boostcall_event: boostCallEventId,
    },
  });

export type SendMessageProps = {
  text: string;
  boostCallEventId: number;
};

export const sendMessage = ({
  text,
  boostCallEventId,
}: SendMessageProps): AxiosPromise<Message> =>
  apiInstance('api/event/boostcalleventscomments/comment/', {
    method: 'POST',
    data: {
      comment: text,
      boostcall_event: boostCallEventId,
    },
  });

export type BlockMessagesProps = {
  boostCallEventId: number;
  blocked: boolean;
};

export const blockMessages = ({
  blocked,
  boostCallEventId,
}: BlockMessagesProps) =>
  apiInstance(`api/event/boostcallevents/${boostCallEventId}/blockcomments/`, {
    method: 'POST',
    data: {
      block_comments: blocked,
    },
  });

export const sendAttachmentInMessage = async (
  files: FileList,
  organisation_id: number | undefined,
  volunteer_id: number | undefined
) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }
  formData.append(
    'organisation_id',
    organisation_id ? organisation_id.toString() : 'null'
  );
  formData.append(
    'volunteer_id',
    volunteer_id ? volunteer_id.toString() : 'null'
  );

  try {
    await apiInstance(`/api/messagecentre/messages/sendattachment/`, {
      method: 'POST',
      data: formData,
    });

    return {
      success: true,
      message: 'Files sent successfully.',
      uploadedFilesCount: files.length,
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error sending files.',
      uploadedFilesCount: files.length,
      error: getErrorMessageFromResponse(error) || 'Unknown error',
    };
  }
};

export const sendAttachmentFromLibrary = async (
  file_ids: [number],
  organisation_id: number | undefined
) => {
  try {
    await apiInstance(`/api/messagecentre/messages/sharefromlibrary/`, {
      method: 'POST',
      data: { file_ids: file_ids, organisation_id: organisation_id },
    });

    return {
      success: true,
      message: 'Files sent successfully.',
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error sending files.',
      error: getErrorMessageFromResponse(error) || 'Unknown error',
    };
  }
};
