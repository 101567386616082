import React, { useState, useEffect } from 'react';
import axios from '../../../api';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

type Props = {
  setValue: (value: any, input: string) => void;
  value: string;
};

type Ethnicity = {
  id: 'string';
  name: 'string';
};
export default function EditEthnicityForm({ value, setValue }: Props) {
  const [ethnicity, setEthnicity] = React.useState(value);
  const [ethnicityList, setEthnicityList] = useState<Ethnicity[]>([]);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEthnicity(event.target.value);
    setValue(event.target.value, 'ethnicity');
  };
  const getEthnicityList = () => {
    return axios
      .get(`api/ethnicity/ethnicity/?limit=1000`, {})
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  };
  useEffect(() => {
    (async () => {
      getEthnicityList().then((response) => {
        setEthnicityList(response.data.results);
      });
    })();
  }, []);
  return (
    <Box sx={{ width: 240 }}>
      <FormControl fullWidth>
        <Select
          labelId="ethnicity-select-label"
          id="ethnicity-select"
          value={ethnicity}
          onChange={handleChange}
          sx={{ fontFamily: 'Gilroy' }}
        >
          {ethnicityList.map((eth) => (
            <MenuItem
              sx={{
                '&.MuiMenuItem-root': {
                  fontFamily: 'Gilroy',
                },
              }}
              key={eth.id}
              value={eth.id}
            >
              {eth.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
