import { useAxios } from '../../api';
import { StatsByMonth } from '../../types';

export const useGetTotalSessions = () => {
  const [{ data, loading, error }, refetch] = useAxios<StatsByMonth[]>(
    {
      url: `api/volunteer/volunteer-metrics/stats-by-month/`,
    },
    { manual: false }
  );
  return {
    totalSessions: data,
    hasFinishedLoadingTotalOrgsHelped: !loading,
    totalOrgsHelpedError: error,
    refetchTotalOrgsHelped: refetch,
  };
};

export default useGetTotalSessions;
