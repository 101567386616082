import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import './style.scss';

import { useNavigate } from 'react-router-dom';
import { getUrlParam } from '../../utils/url';

const appendSubdomainToURL = (
  subdomain: string | null,
  path: string | undefined
) => {
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  // Remove the 'id' URL parameter
  url.searchParams.delete('id');
  url.searchParams.delete('community');
  url.searchParams.delete('path');

  // Extract the domain from the URL
  const domain = cutStringFromWord(url.hostname, 'volunteer');

  // Construct the new URL with the subdomain
  const newURL = path
    ? `https://${subdomain}.${domain}${path}`
    : `https://${subdomain}.${domain}/login`;

  window.location.href = newURL;
  // Navigate to the new URL
};
const eliminateSubdomainToURL = (path: string | undefined) => {
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  url.searchParams.delete('id');
  url.searchParams.delete('community');
  url.searchParams.delete('path');

  // Remove the subdomain
  url.hostname = `${url.hostname.split('.').slice(1).join('.')}`;
  const newURL = path
    ? `https://${url.hostname}${path}`
    : `https://${url.hostname}/login`;

  // Navigate to the new URL
  window.location.href = newURL;
};

const cutStringFromWord = (str: string, word: string) => {
  const wordIndex = str.indexOf(word);
  if (wordIndex !== -1) {
    return str.substring(wordIndex);
  } else {
    return str;
  }
};

const Redirect = () => {
  const subdomain = getUrlParam('community') as string;
  const path = getUrlParam('path') as string;

  const [loading] = useState(true);
  const navigate = useNavigate();
  if (subdomain === undefined) navigate('/login');

  useEffect(() => {
    setTimeout(() => {
      Cookie.remove('loggedIn');
      Cookie.remove('accessTokenDB');
      Cookie.remove('refreshTokenDB');
      Cookie.remove('partner_id');
      if (subdomain === 'digitalboost') eliminateSubdomainToURL(path);
      else appendSubdomainToURL(subdomain, path);
    }, 3000);
  }, [subdomain, path]);

  return (
    <div className="redirect-wrapper">
      <div className="redirection-text">
        Redirecting you to your mentoring community
      </div>
      {loading ? <img src={'/loading.gif'} alt="loading" /> : null}
    </div>
  );
};

export default Redirect;
