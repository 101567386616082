import { useState } from 'react';
import { useAxios } from '../../api';
import { BoostCallEvent } from '../../types';

type Props = {
  id: number | string;
};

type DeclineProps = {
  cancel: boolean;
  newStartTime?: string;
  message?: string;
};

const useDeclineRescheduleBoostCall = ({ id }: Props) => {
  const [hasFinishedDecliningReschedule, setHasFinishedDecliningReschedule] =
    useState(false);

  const [{ data, loading, error }, executeCall] = useAxios<BoostCallEvent>(
    {
      url: `api/event/boostcallevents/${id}/decline-reschedule/`,
      method: 'POST',
    },
    { manual: true }
  );

  const declineRescheduleBoostCall = async ({
    cancel,
    newStartTime,
    message,
  }: DeclineProps) => {
    const messageParam = message ? { message } : {};
    try {
      await executeCall({
        data: {
          cancel_event: cancel,
          new_start_datetime: newStartTime, // used to verify that users are declining the correct reschedule time
          ...messageParam,
        },
      });
      setHasFinishedDecliningReschedule(true);
    } catch (err) {
      throw err;
    }
  };

  return {
    declineRescheduleBoostCall: declineRescheduleBoostCall,
    declineRescheduleBoostCallData: data,
    isLoadingDeclineRescheduleBoostCall: loading,
    hasFinishedDecliningRescheduleBoostCall: hasFinishedDecliningReschedule,
    declineRescheduleBoostCallError: error,
  };
};

export default useDeclineRescheduleBoostCall;
