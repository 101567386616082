import { fetchAllProfileData } from '../../apiCalls/user';
import { fetchAllProfileDataAndUpdateContext } from '../../utils/user';

import { useUserProfile } from '../../context/UserProfile';

const useFetchProfileData = () => {
  const {
    setUserProfileContext,
    setVolunteerProfileContext,
    setUserLocationContext,
    setPartnerContext,
    hasFinishedFetchingProfileAndLocation,
  } = useUserProfile();

  return {
    fetchAllProfileData,
    fetchAllProfileDataAndUpdateContext: () =>
      fetchAllProfileDataAndUpdateContext({
        fetchAllProfileData,
        setUserProfileContext,
        setVolunteerProfileContext,
        setUserLocationContext,
        setPartnerContext,
      }),
    hasFinishedFetchingProfileAndLocation,
  };
};

export default useFetchProfileData;
