import React from 'react';
import '../style/pastExperiences.scss';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Experiences } from '../types';

type Props = {
  data: Experiences[];
};
const PastExperiences = ({ data }: Props) => {
  return (
    <div className="pastExperiences__container">
      {data.map((entry, index) => (
        <div className="pastExperiences__entry">
          <div className="pastExperiences__entry-role">
            <FiberManualRecordIcon
              sx={{ margin: '1px 9px 3px 0', width: '10px', height: '10px' }}
              fontSize="small"
            />
            {entry.role}
          </div>
          <div className="pastExperiences__entry-employer">
            {entry.employer}
          </div>
          <div className="pastExperiences__entry-date">
            {entry.from_year} - {entry.to_year ? entry.to_year : ' Current'}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PastExperiences;
