import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardFilledButton } from '../../../styling/buttons';
import './styling/requests.scss';

const RequestsEmptyPage = () => {
  const navigate = useNavigate();
  return (
    <div className="empty-page-container">
      <img
        className="not-found-image"
        src={'/no-requests-found.png'}
        alt={'not-found'}
      />
      <div className="not-found-text">Looking for new requests?</div>

      <DashboardFilledButton
        variant="contained"
        sx={{
          width: ' 264px',
          marginTop: '12px',
        }}
        onClick={() => navigate('/dashboard/requests/community-requests')}
      >
        Check community requests
      </DashboardFilledButton>
    </div>
  );
};

export default RequestsEmptyPage;
