import React from 'react';
import './style.scss';
import Paper from '@mui/material/Paper';
import '../components/styling/card-styling.scss';
import { Button } from '@mui/material';

interface Props {
  handleChange: (event: any, newValue: string) => void;
  review: {
    created: Date;
    review: string;
    reviewer_name: string;
    id: number;
    organisation_name: string;
  };
}
const LatestFeedback = ({ handleChange, review }: Props) => {
  return (
    <div className="dashboard-box">
      <div className="latest-feedback-paper-box">
        <Paper elevation={0}>
          <div className="recent-feedback-container">
            <div className="recent-feedbacks-photo-card-container">
              <img
                className="recent-feedback-photo"
                src={'/lovely.png'}
                alt={'lovely'}
              />
            </div>
            <div className="feedback-card-container">
              <div className="feedback-card-title">
                Recent Personal Feedback
                <Button
                  sx={{ textTransform: 'none' }}
                  onClick={() => handleChange('change', 'feedback')}
                >
                  View All
                </Button>
              </div>
              <div className="feedback-card-info">{review.review}</div>
              <div className="feedback-card-title">
                {review.reviewer_name
                  ? `- ${review.reviewer_name} from ${review.organisation_name}`
                  : 'Anonymous Feedback'}
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default LatestFeedback;
