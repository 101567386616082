import { styled } from '@mui/system';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
export const palette = {
  primary: 'primary' || '#583372',
  lighterPrimary: '#CBA0EB',
};

export const Tab = styled(TabUnstyled)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'transparent',
  textTransform: 'none',
  width: '100%',
  height: '44px',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'black',
  orientation: 'vertical',
  padding: '12px 16px',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',

  '&:hover': {
    backgroundColor: `${theme.palette.secondary.main}`,
    color: 'black',
  },

  '&:focus': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&.tabUnstyledClasses.selected': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

export const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

export const TabsList = styled(TabsListUnstyled)`
  min-width: 280px;
  border-radius: 8px;
  orientation: vertical;
`;
