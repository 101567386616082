import useSelectProfileData from '../../hooks/useSelectProfileData';

export function useGetProfile() {
  const { volunteerProfile, userLocation, userProfile } =
    useSelectProfileData();
  const { profile_picture, ...selectedUser } = { ...userProfile };
  let profile = { ...volunteerProfile, ...userLocation, ...selectedUser };
  const {
    availability,
    industries,
    interests,
    mentor_countries,
    prefer_charities,
    prefer_educators,
    prefer_freelancers,
    prefer_health_services,
    prefer_students,
    prefer_unemployed_furloughed,
    prefer_social_enterprise,
    prefer_women,
    languages,
    id,
    user,
    region,
    prefer_businesses,
    ...updatedProfile
  } = profile;
  return updatedProfile;
}
