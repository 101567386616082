import { useAxios } from '../../api';
import { GenericTotal } from '../../types';

export const useGetTotalOrgsHelped = () => {
  const [{ data, loading, error }, refetch] = useAxios<GenericTotal>(
    {
      url: `api/volunteer/volunteer-stats/total-orgs-helped/`,
    },
    { manual: false }
  );

  return {
    totalOrgsHelped: data?.total,
    hasFinishedLoadingTotalOrgsHelped: !loading,
    totalOrgsHelpedError: error,
    refetchTotalOrgsHelped: refetch,
  };
};

export default useGetTotalOrgsHelped;
