import * as React from 'react';
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ReactHtmlParser from 'react-html-parser';
import useWindowSize from '../../../hooks/useWindowSize';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  text: string;
  id?: string;
};
export default function LatestNews({ text, id }: Props) {
  const { width = window.innerWidth } = useWindowSize();
  const isMobile = width < 950;

  const options = {
    transform: (node: any) => {
      if (node.type === 'tag' && node.name === 'a') {
        return (
          <a
            style={{ fontFamily: 'Gilroy', fontSize: 14, color: 'white' }}
            {...node.attribs}
          >
            {node.children?.[0]?.data}
          </a>
        );
      }
    },
  };

  return (
    <Stack id={id} sx={{ width: isMobile ? '85%' : '75%', marginLeft: '20px' }}>
      <Alert
        sx={{ textTransform: 'none', fontFamily: 'Gilroy' }}
        severity="info"
      >
        {ReactHtmlParser(text, options)}
      </Alert>
    </Stack>
  );
}
