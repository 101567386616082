import { AxiosResponse } from 'axios';
import { apiInstanceUnprotected } from '../api';
// Note: using unprotected axios instance since we don't want to interept 401/404s on auth calls

type LoginProps = {
  email: string;
  password: string;
};
type LoginResponse = { access: string; refresh: string };

export const login = ({
  email,
  password,
}: LoginProps): Promise<AxiosResponse<LoginResponse>> =>
  apiInstanceUnprotected(`api/token/`, {
    method: 'POST',
    data: {
      username: email,
      password: password,
      user_type: 'volunteer',
    },
  });
