import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  CustomFormLabel,
  CustomFormControlLabel,
  CustomTypography,
} from '../../../styling/generalStyling';

import '../style/profile.scss';

type Props = {
  setValue: (value: any, input: string) => void;
  value: string;
};
export default function EditGenderForm({ value, setValue }: Props) {
  return (
    <FormControl>
      <CustomFormLabel id="gender-radio-buttons-group-label">
        Please state which gender identity you most identify with. Providing
        your gender data is optional but it will help us better match you to a
        suitable learner in need.
      </CustomFormLabel>
      <RadioGroup
        aria-labelledby="gender-radio-buttons-group-label"
        defaultValue={value}
        name="radio-buttons-group"
  
      >
        <CustomFormControlLabel
          label={<CustomTypography>Woman</CustomTypography>}
          onClick={() => setValue('female', 'gender')}
          control={<Radio />}
          value="female"
        />
        <CustomFormControlLabel
          label={<CustomTypography>Man</CustomTypography>}
          onClick={() => setValue('male', 'gender')}
          control={<Radio />}
          value="male"
        />
        <CustomFormControlLabel
          label={<CustomTypography>Non Binary</CustomTypography>}
          onClick={() => setValue('non-binary', 'gender')}
          control={<Radio />}
          value="non-binary"
        />
        <CustomFormControlLabel
          label={
            <CustomTypography>
              I don't identify with any of these genders{' '}
            </CustomTypography>
          }
          onClick={() => setValue('dont-identify', 'gender')}
          control={<Radio />}
          value="dont-identify"
        />
        <CustomFormControlLabel
          label={<CustomTypography>Prefer not to say</CustomTypography>}
          onClick={() => setValue('other', 'gender')}
          control={<Radio />}
          value="other"
        />
      </RadioGroup>
    </FormControl>
  );
}
