import React, { useState, useEffect, useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import './style.scss';
import NewMessageCard from './NewMessageCard';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MessagingCentreButton } from '../../../styling/buttons';

interface NewMessageModalProps {
  open: boolean;
  handleClose: () => void;
  availableUsers: any;
  handleAddMessageUser: (user: any) => void;
  getAvailableUsers: any;
  hasNextAvailableUsers: boolean;
  handleGetNextAvailableUsers: () => void;
}

const NewMessageModal = ({
  open,
  handleClose,
  availableUsers,
  handleAddMessageUser,
  getAvailableUsers,
  hasNextAvailableUsers,
  handleGetNextAvailableUsers,
}: NewMessageModalProps) => {
  const [selectedUser, setSelectedUser] = useState<any>(undefined);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const closeModal = () => {
    setSelectedUser(undefined);
    handleClose();
  };

  const handleSelectUser = () => {
    handleAddMessageUser(selectedUser);
    setSelectedUser(undefined);
  };

  const debouncedSearch = useRef(
    _.debounce((value) => getAvailableUsers(value), 500)
  ).current;

  return open ? (
    <div className="new-message__overlay" onClick={closeModal}>
      <div className="new-message__body" onClick={(e) => e.stopPropagation()}>
        <div className="new-message__header">
          <p>Write a message</p>

          <button onClick={closeModal}>
            <CloseIcon fontSize="small" />
          </button>
        </div>

        <div className="new-message__search">
          <div className="search-input">
            <input
              placeholder="Search contacts"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                debouncedSearch(e.target.value);
              }}
            />

            {searchValue && searchValue.length > 0 && (
              <div
                className="search-input-clear-button"
                onClick={() => {
                  setSearchValue('');
                  debouncedSearch('');
                }}
              >
                <CloseIcon fontSize="small" />
              </div>
            )}
          </div>
        </div>

        <div id="newUserModalDiv" className="new-message__scrollable">
          <InfiniteScroll
            dataLength={availableUsers.length}
            next={handleGetNextAvailableUsers}
            hasMore={hasNextAvailableUsers}
            loader={<h4>Loading...</h4>}
            scrollableTarget="newUserModalDiv"
          >
            {availableUsers?.length > 0 ? (
              availableUsers?.map((item: any, idx: number) => (
                <NewMessageCard
                  key={idx}
                  id={item?.organisation?.id}
                  profilePicture={item?.organisation?.profile_picture}
                  name={item?.organisation?.first_name}
                  onClick={() => setSelectedUser(item)}
                  selectedUser={selectedUser}
                />
              ))
            ) : (
              <div className="new-message__no-users">No users available</div>
            )}
          </InfiniteScroll>
        </div>

        <div className="new-message__bottom">
          <MessagingCentreButton
            onClick={handleSelectUser}
            disabled={!selectedUser}
          >
            Write a message
          </MessagingCentreButton>
        </div>
      </div>
    </div>
  ) : null;
};

export default NewMessageModal;
