export const getErrorMessageFromResponse = (err: any) => {
  const defaultErrorMessage = "We're sorry, there's been an error";
  if (!err) return null;

  if (err?.detail) {
    return err?.detail;
  } else if (err?.response) {
    if (err?.response?.data) {
      if (typeof err?.response?.data === 'string')
        return err.response.data || defaultErrorMessage;
      if (
        typeof err?.response?.data[Object.keys(err.response.data)[0]] ===
        'string'
      ) {
        return (
          err?.response?.data[Object.keys(err.response.data)[0]] ||
          defaultErrorMessage
        );
      } else if (
        typeof err.response.data[Object.keys(err.response.data)[0]] ===
          'object' &&
        typeof err.response.data[Object.keys(err.response.data)[0]].length
      ) {
        return (
          err.response.data[Object.keys(err.response.data)[0]][0] ||
          defaultErrorMessage
        );
      }
    }
  } else if (err?.message) {
    return err.message;
  } else {
    return defaultErrorMessage;
  }
};
