import axios from 'axios';
import Cookie from 'js-cookie';
import LogRocket from 'logrocket';
import { useSelector } from 'react-redux';

import { nonAuthProtectedRoutes } from '../App';
import { RootState, store } from '../store';
import { UserProfile, VolunteerProfile } from '../types';

const apiUrl = process.env.REACT_APP_API_URL;

// this util checks current route against list of non-protected routes
// if protected: redirect to login
export const redirectProtecedRoutesToLogin = async () => {
  const { pathname, search } = window.location;
  const attemptedDomain = search ? pathname + search : false;
  const encodedAttemptedDomain = encodeURIComponent(attemptedDomain);

  // auth-protect only certain routes
  if (nonAuthProtectedRoutes.indexOf(pathname) >= 0) return;
  else
    window.location.href = attemptedDomain
      ? '/login?redirect=' + encodedAttemptedDomain
      : '/login';
};

// this util checks current route against list of non-protected routes
// if protected: redirect to finish signup
export const redirectToFinishSignup = async () => {
  const { pathname } = window.location;
  // auth-protect only certain routes
  if (
    nonAuthProtectedRoutes.indexOf(pathname) >= 0 ||
    pathname.includes('profile-create')
  )
    return;
  window.location.href = '/profile-create';
};
export const useGetAccessToken = (): string | null | undefined => {
  const accessTokenFromRedux = useSelector(
    (state: RootState) => state.auth.accessToken
  );
  const accessTokenFromCookie = Cookie.get('accessTokenDB');

  return accessTokenFromCookie || accessTokenFromRedux;
};
const currentState = store.getState();
const accessToken = currentState.auth.accessToken;
const refreshToken = currentState.auth.refreshToken;
// obtain the token at the point the function is called
export const getAccessToken = () => Cookie.get('accessTokenDB') || accessToken;
export const getRefreshToken = () =>
  Cookie.get('refreshTokenDB') || refreshToken;

export const loginWithOTLC = async (otlc: any) => {
  try {
    // Note: Using a different instance of axios from our api
    // This is so exception thrown by axios when the otlc is stale  won't get caught in the interceptor created by the axios-auth-refresh module
    const response = await axios.post(apiUrl + `api/token/otlc/`, {
      otlc: otlc,
      user_type: 'volunteer',
    });
    // set cookie that expires in a day
    Cookie.set('accessTokenDB', response.data.access, {
      expires: 1,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const loginWithRefreshToken = async (): Promise<string | undefined> => {
  const refreshToken = getRefreshToken();

  // Note: Using a different instance of axios from our api
  // This is so exception thrown by axios when the refreshToken itself is stale or missing won't get caught in the interceptor created by the axios-auth-refresh module
  try {
    const refreshResponse = await axios.post(`${apiUrl}api/token/refresh/`, {
      refresh: refreshToken,
    });
    const newAccessToken = refreshResponse.data.access;
    // set cookie that expires in a day
    Cookie.set('accessTokenDB', newAccessToken, {
      expires: 1,
    });
    return newAccessToken;
  } catch (err) {
    console.error('Error with the request');
    throw err;
  }
};

export async function logRocketIdentify({
  userProfile,
  volunteerProfile,
}: {
  userProfile: UserProfile;
  volunteerProfile: VolunteerProfile;
}) {
  try {
    if (
      volunteerProfile?.user &&
      userProfile?.username &&
      process.env.REACT_APP_LOGROCKET_ON === 'true'
    ) {
      const userFullName = `${volunteerProfile.first_name} ${volunteerProfile.last_name}`;
      const userId = volunteerProfile.user;
      const userEmail = userProfile.username;

      LogRocket.identify(userId.toString(), {
        name: userFullName,
        email: userEmail,
      });
    }
  } catch (err) {
    console.log('err:', err);
  }
}
