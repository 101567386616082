import { toTitleCase } from './text';

type MasterClassProps = {
  skill: string;
  link: string;
  eventType: string;
  eventStartTime: string;
};

export const getMasterclassSocialText = ({
  skill,
  link,
  eventType,
  eventStartTime,
}: MasterClassProps) => {
  return {
    linkedinText: `Want to learn more about ${toTitleCase(
      skill
    )}?\n\nI'm hosting a ${toTitleCase(
      skill
    )} ${eventType} on Digital Boost at ${eventStartTime}!\n\nPlease register here: ${link} \n\n#digitalboost #${toTitleCase(
      skill
    ).replace(/\s+/g, '')}`,
    facebookText: `Want to learn more about ${toTitleCase(
      skill
    )}?\n\nI'm hosting a ${toTitleCase(
      skill
    )} ${eventType} on Digital Boost at ${eventStartTime}!\n\nPlease register here: ${link} \n\n#digitalboost #${toTitleCase(
      skill
    ).replace(/\s+/g, '')}`,
    twitterText: `Want to learn more about ${toTitleCase(
      skill
    )}?\n\nI'm hosting a ${toTitleCase(
      skill
    )} ${eventType} on Digital Boost at ${eventStartTime}!\n\nRegister here:`,
  };
};
