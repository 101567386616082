import React from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './style.scss';
import { formatDate } from '../../../utils/time';
import Paper from '@mui/material/Paper';
import { GroupEvent, BoostCallEvent } from '../../../types';
import '../components/styling/card-styling.scss';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

type Props = {
  appointmentData: any;
  timeZone?: string;
  subject: string;
};

const NewRecordingCard = ({ timeZone, appointmentData }: Props) => {
  const { id: boostCallId, start_time } =
    (appointmentData as BoostCallEvent) || {};

  const { code } = (appointmentData as GroupEvent) || {};

  const id = code || boostCallId;

  const dateFormatted = formatDate(start_time, timeZone);

  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };

  return (
    <div className="recording-card__container">
      <div className="recording-card-paper-box">
        <Paper key={id} elevation={0}>
          <div>
            <div className="recording-dashboard-card__container">
              <div className="replay-card-header-info">
                <div className="recording-paper-topic">
                  {appointmentData.interest.name}
                </div>
                <div className="recording-paper-time">
                  <div className="dashboard-paper-time-day">
                    <CalendarTodayOutlinedIcon
                      sx={{ paddingBottom: '3px' }}
                      fontSize="small"
                    />{' '}
                    {time.date?.day} {time.date?.monthThreeLetterFormat}{' '}
                    {time.date?.year}
                  </div>
                  <div className="dashboard-paper-time-hour">
                    <ScheduleIcon
                      fontSize="small"
                      sx={{ paddingBottom: '3px' }}
                    />{' '}
                    {time.time?.hour}{' '}
                  </div>
                </div>
                <video id="booking-recordings" controls>
                  <source
                    src={appointmentData.recording_url}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default NewRecordingCard;
