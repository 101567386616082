import React, { useState, useEffect, memo } from 'react';
import './styling/actions.scss';
import { LoadMoreButton } from '../styling';
import DashboardEmptyPage from './dashboardEmptyPage';
import NewUpcomingCard from '../NewUpcomingCard';
import NewInviteCard from '../NewInviteCard';
import NewRequestedCard from '../NewRequestedCard';
import { BoostCallEvent, GroupEventRequest } from '../../../types';
import NewRescheduleCard from '../NewRescheduleCard';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DashboardSessionsChart from '../charts/DashboardSessionsChart';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NewCallDetails from '../../../pages/NewCallDetails';
import Cookie from 'js-cookie';
import ManageCallPage from '../../../pages/NewCallDetails/manageCallPage';
import useGetBoostCallEventData from '../../../apiHooks/boostCalls/useGetBoostCallEventData';
import { getUrlParam } from '../../../utils/url';
import useGetBoostCallEventRequest from '../../../apiHooks/boostCallRequests/useGetBoostCallEventRequest';
import LatestNews from './latestNews';
import { CheckboxCustomTypography } from '../../../styling/generalStyling';
import { SessionStatistic } from '../utilities';
import { useUserProfile } from '../../../context/UserProfile';
import useFetchPartner from '../../../apiHooks/auth/useFetchPartner';
import useGetNews from '../../../hooks/useGetNews';
import Slider from 'react-slick';

interface Props {
  upcoming: Array<any>;
  timeZoneString: string;
  requested: Array<any>;
  groupEventRequests?: GroupEventRequest[];
  onDeclineGroupEventRequest: () => void;
  totalSessions: SessionStatistic[];
  orgsHelped: number;
  followup: Array<any>;
}

const Actions = ({
  totalSessions,
  upcoming,
  requested,
  groupEventRequests,
  timeZoneString = '',
  onDeclineGroupEventRequest,
  orgsHelped,
  followup,
}: Props) => {
  const [id, setId] = useState(getUrlParam('id') as string);
  const type = getUrlParam('type') as string;
  const isBoostCall = type === 'events';
  const isBoostCallRequest = type === 'requests';

  const { news } = useGetNews();

  // Get boostcall event
  const { boostCallEventData } = useGetBoostCallEventData({
    id: isBoostCall ? id : null,
  });
  const { boostCallEventRequest } = useGetBoostCallEventRequest({
    id: isBoostCallRequest ? id : null,
  });

  var totalEvents = [
    ...upcoming,
    ...requested,
    ...followup,
    ...(groupEventRequests ? groupEventRequests : []),
  ].filter((event) => event.organisation !== null).length;
  const [displayEventDetails, setDisplayEventDetails] = useState(null);
  const [displayEventUpcomingDetails, setDisplayUpcomingEventDetails] =
    useState(null);
  if (displayEventDetails === null) Cookie.remove('timeSlotId');
  const [rescheduleRequest, setRescheduleRequest] = useState<BoostCallEvent>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [checked, setChecked] = React.useState<number[]>([]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const [hideGroupEvents, setHideGroupEvents] = useState<boolean>(true);

  const { volunteerProfile } = useUserProfile();

  useEffect(() => {
    if (
      volunteerProfile?.partner === null ||
      volunteerProfile?.partnerInfo?.hide_group_events === false
    ) {
      setHideGroupEvents(false);
    }
  }, [volunteerProfile]);

  const { fetchPartnerResponseData, isLoadingPartner } = useFetchPartner({
    id: volunteerProfile?.partner || '',
    setCookies: true,
  });
  useEffect(() => {
    const rescheduleRequestInUpcoming =
      upcoming &&
      upcoming.find(
        (u): u is BoostCallEvent =>
          u.reschedule_status &&
          u.reschedule_status === 'pending' &&
          u.reschedule_requested_by === 'organisation'
      );
    if (rescheduleRequestInUpcoming)
      setRescheduleRequest(rescheduleRequestInUpcoming);
  }, [upcoming]);

  const [counter, setCounter] = useState(4);

  requested.map((event, index) => (event['type'] = 'request'));

  const [displayedEvents, setDisplayedEvents] = useState<any[]>([]);
  useEffect(() => {
    const applyFilter = (events: any[]) => {
      const check1to1Request = (event: any) => {
        return (
          event.status === 'pending' &&
          event.type === 'request' &&
          event.organisation !== null
        );
      };
      const check1to1Upcoming = (event: any) => {
        return (
          event.status === 'scheduled' &&
          event.title === '1-to-1 mentoring call' &&
          event.organisation !== null
        );
      };
      const checkRescheduleRequests = (event: any) => {
        return (
          event.status === 'scheduled' &&
          event.reschedule_status === 'pending' &&
          event.organisation !== null
        );
      };

      const checkMasterclassInvites = (event: any) => {
        return event.type === 'masterclass' && event.status === 'invited';
      };

      const checkMasterclassUpcoming = (event: any) => {
        return event.type === 'masterclass' && event.status === 'scheduled';
      };
      var todisplayEvents: any[] = [];
      if (checked.includes(0) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(check1to1Request),
        ];
      if (checked.includes(1) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(check1to1Upcoming),
        ];
      if (checked.includes(2) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(checkRescheduleRequests),
        ];
      if (checked.includes(3) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(checkMasterclassInvites),
        ];
      if (checked.includes(4) === true)
        todisplayEvents = [
          ...todisplayEvents,
          ...events.filter(checkMasterclassUpcoming),
        ];
      setDisplayedEvents(todisplayEvents);
    };

    var eventsArray = [
      ...upcoming,
      ...requested,
      ...followup,
      ...(groupEventRequests ? groupEventRequests : []),
    ];

    if (rescheduleRequest) eventsArray.push(rescheduleRequest);
    if (checked.length > 0) applyFilter(eventsArray);
    else setDisplayedEvents(eventsArray);
  }, [
    checked,
    groupEventRequests,
    requested,
    rescheduleRequest,
    upcoming,
    followup,
  ]);

  const addArraytoArray = (arr1: any[], arr2: any[]) => {
    let i = 0;
    while (i < arr2.length) {
      arr1.push(arr2[i]);
      i++;
    }
    return arr1;
  };
  const sortByPriority = (events: any[]) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var topPriorityRequests = events
      .filter(
        (event) =>
          event.status === 'scheduled' && new Date(event.start_time) <= tomorrow
      )
      .sort(
        (event1: { start_time: string }, event2: { start_time: string }) =>
          new Date(event1.start_time).valueOf() -
          new Date(event2.start_time).valueOf()
      );
    var secondPriorityRequests = events
      .filter(
        (event) =>
          event.status === 'pending' ||
          event?.is_follow_up_wanted ||
          (event.reschedule_status === 'pending' &&
            event.reschedule_requested_by === 'organisation')
      )
      .sort(
        (event1, event2) =>
          new Date(
            event1.start_time ||
              event1.time_slots?.sort(
                (slot1: any, slot2: any) =>
                  (new Date(slot1.start_time) as any) -
                  (new Date(slot2.start_time) as any)
              )?.[0]?.start_time
          ).valueOf() -
          new Date(
            event2.start_time ||
              event2.time_slots?.sort(
                (slot1: any, slot2: any) =>
                  (new Date(slot1.start_time) as any) -
                  (new Date(slot2.start_time) as any)
              )?.[0]?.start_time
          ).valueOf()
      );

    var thirdPriority = events.filter(
      (event) =>
        event.status === 'scheduled' &&
        (event.reschedule_status !== 'pending' ||
          (event.reschedule_status === 'pending' &&
            event.reschedule_requested_by === 'volunteer')) &&
        new Date(event.start_time) > tomorrow
    );
    thirdPriority = thirdPriority.sort(
      (event1: { start_time: string }, event2: { start_time: string }) =>
        new Date(event1.start_time).valueOf() -
        new Date(event2.start_time).valueOf()
    );

    const top3Requests = [
      ...topPriorityRequests,
      ...secondPriorityRequests,
      ...thirdPriority,
    ];
    const remainingEvents = events.filter(
      ({ id: id1 }) => !top3Requests.some(({ id: id2 }) => id2 === id1)
    );

    var finalEventsArray: any[] = [];
    addArraytoArray(finalEventsArray, topPriorityRequests);
    addArraytoArray(finalEventsArray, secondPriorityRequests);
    addArraytoArray(finalEventsArray, thirdPriority);
    addArraytoArray(finalEventsArray, remainingEvents);

    //remove duplicates
    finalEventsArray = finalEventsArray.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    return finalEventsArray;
  };

  const numberOfFilters = () => {
    if (checked.length === 0)
      return <div className="filters-dashboard">Filters</div>;
    else if (checked.length === 5)
      return <div className="filters-dashboard">All</div>;
    else if (checked.length === 1)
      return <div className="filters-dashboard">1 filter</div>;
    else
      return <div className="filters-dashboard">{checked.length} filters</div>;
  };
  const navigate = useNavigate();
  const displayEvents = () => {
    const finalEvents = displayedEvents.filter(
      (event) =>
        event.organisation !== null && event.follow_up_status !== 'requested'
    );

    return sortByPriority(finalEvents)
      .slice(0, counter)
      .map((event, index) => {
        if (event.reschedule_status === 'pending')
          return (
            <NewRescheduleCard
              key={event.id}
              timeZone={timeZoneString}
              appointmentData={event}
            />
          );
        else if (event.status === 'scheduled')
          return (
            <NewUpcomingCard
              key={event.id}
              timeZone={timeZoneString}
              appointmentData={event}
              subject="Upcoming"
            />
          );
        if (event.type === 'request') {
          return (
            <NewRequestedCard
              type="1To1Request"
              key={event.id}
              timeZoneString={timeZoneString}
              appointment={event}
              setDisplayEventDetails={setDisplayEventDetails}
            />
          );
        } else if (event.follow_up_status !== 'requested' && !hideGroupEvents) {
          return (
            <NewInviteCard
              key={event.id}
              inviteData={event}
              onDecline={onDeclineGroupEventRequest}
            />
          );
        } else return null;
      });
  };
  const filterList = hideGroupEvents
    ? ['Requests', 'Upcoming sessions', 'Reschedule requests']
    : [
        'Requests',
        'Upcoming sessions',
        'Reschedule requests',
        'Masterclass invites',
        'Upcoming Masterclasses',
      ];
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  if (
    id &&
    isBoostCall &&
    boostCallEventData &&
    boostCallEventData.organisation
  )
    return (
      <ManageCallPage
        event={boostCallEventData}
        setDisplayUpcomingEventDetails={setId}
      />
    );
  else if (isBoostCallRequest && boostCallEventRequest)
    return <NewCallDetails event={boostCallEventRequest} />;
  else if (displayEventDetails)
    return <NewCallDetails event={displayEventDetails} />;
  else if (displayEventUpcomingDetails)
    return (
      <ManageCallPage
        event={displayEventUpcomingDetails}
        setDisplayUpcomingEventDetails={setDisplayUpcomingEventDetails}
      />
    );
  else
    return (
      <div className="new-dashboard-container">
        <div className="dashboard-actions-container">
          <div className="dashboard-title-component">
            <div className="dashboard-title-text">
              Welcome {volunteerProfile?.first_name}!
              <div>Here's what you have coming up</div>
            </div>
            <div>
              {totalEvents > 0 ? (
                <IconButton
                  color="primary"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <FilterAltOutlinedIcon />
                  {numberOfFilters()}
                </IconButton>
              ) : null}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className="filter-menu-title">Filter your actions</div>
                <List sx={{ padding: 0 }}>
                  {filterList.map((label, value) => {
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                      <ListItem
                        sx={{ marginBottom: '-15px', padding: 0 }}
                        key={value}
                        disablePadding
                      >
                        <ListItemButton
                          sx={{ paddingLeft: 0 }}
                          role={undefined}
                          onClick={handleToggle(value)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={
                              <CheckboxCustomTypography>
                                {label}
                              </CheckboxCustomTypography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Menu>
            </div>
          </div>
          {totalEvents > 0 ? (
            <div>
              {displayedEvents.filter((event) => event.organisation !== null)
                .length ? (
                <div className="dashboard-cards">
                  {displayEvents()}
                  <div className="loading-button-container">
                    {counter <
                    displayedEvents.filter(
                      (event) =>
                        event.organisation !== null &&
                        event.follow_up_status !== 'requested'
                    ).length ? (
                      <LoadMoreButton
                        variant="contained"
                        onClick={() => setCounter(counter + 4)}
                      >
                        Load more
                      </LoadMoreButton>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="filter-error-text">
                  Nothing found. Please try changing the filter to view results.
                </div>
              )}
            </div>
          ) : (
            <DashboardEmptyPage />
          )}
        </div>

        <div className="dashboard-impact-section">
          {!fetchPartnerResponseData?.hide_news &&
          !isLoadingPartner &&
          news?.length > 0 ? (
            <div className="dashboard-news-section">
              <div className="dashboard-impact-section-title">News</div>
              <Slider {...settings} className="news-carousel">
                {news.map((item: any) => (
                  <LatestNews id="news-item" text={item.content} />
                ))}
              </Slider>
            </div>
          ) : null}
          <div className="dashboard-impact-title-container">
            <div className="dashboard-impact-section-title">Your Impact</div>
            <Button
              sx={{
                textTransform: 'none',
                width: '100px',
                height: '20px',
                marginTop: '30px',
              }}
              onClick={() => navigate('/dashboard/impact')}
            >
              View
            </Button>
          </div>

          <DashboardSessionsChart
            totalSessions={totalSessions ? totalSessions : []}
            orgsHelped={orgsHelped}
          />
        </div>
      </div>
    );
};

export default memo(Actions);
