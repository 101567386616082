import { useAxios } from '../../api';
import { getErrorMessageFromResponse } from '../../utils/error';
import { SignupOption } from '../../types';

const useFetchSignupOptions = (partner_id: number | null) => {
  // should ok to not implement pagination as 20 terms/options seems excessive

  const [{ data, loading, error }, executeCall] = useAxios<{
    results: SignupOption[];
  }>({
    url: `api/partner/signupoptions/`,
    method: 'GET',
    params: {
      show_to: 'volunteer',
      partner_id: partner_id,
    },
  });

  return {
    signupOptions: data?.results || [],
    isLoadingSignupOptions: loading,
    hasFinishedLoadingSignupOptions: !loading,
    signupOptionsError: getErrorMessageFromResponse(error),
    refetchSignupOptions: executeCall,
  };
};

export default useFetchSignupOptions;
