import React from 'react';
import './footer.scss';
import Logo from '../Nav/Logo';
import { useSelector } from 'react-redux';
import { selectPartnerProfile } from '../../apiHooks/partnerProfile';

const Footer = () => {
  const partner = useSelector(selectPartnerProfile);
  const d = new Date();
  const year = d.getFullYear();
  const url = new URL(window.location.href);
  const forOrganisationUrl = url.hostname.replace('volunteer', 'organisation');
  const signUpLink = url.hostname + '/signup';

  const linkedIn =
    partner?.white_label && partner?.use_partner_details && partner?.linkedin
      ? partner.linkedin
      : 'https://www.linkedin.com/company/digitalboost-org';

  const twitter =
    partner?.white_label && partner?.use_partner_details && partner?.twitter
      ? partner.twitter
      : 'https://twitter.com/digitalboost_uk';

  const instagram =
    partner?.white_label && partner?.use_partner_details && partner?.instagram
      ? partner.instagram
      : 'https://instagram.com/digitalboost.org.uk';
  const facebook =
    partner?.white_label && partner?.use_partner_details && partner?.facebook
      ? partner.facebook
      : 'https://www.facebook.com/digitalboost.org.uk';
  const contact_us = partner?.contact_us
    ? partner.contact_us
    : 'https://digitalboost.org.uk/get-in-touch';
  const about_us = partner?.about_us
    ? partner.about_us
    : 'https://www.digitalboost.org.uk/about-us/about-us';
  const faq = partner?.faq
    ? partner.faq
    : 'https://digitalboost.org.uk/frequently-asked-questions';
  const our_partners = partner?.our_partners
    ? partner.our_partners
    : 'https://www.digitalboost.org.uk/partners';
  const our_blog = partner?.our_blog
    ? partner.our_blog
    : 'https://digitalboost.org.uk/blog/';
  const company_registration_info = partner?.company_registration_info ? (
    partner.company_registration_info
  ) : (
    <div>
      Digital Boost Upskilling, Ltd is a subsidiary of Founders4Schools <br />{' '}
      (registered charity number 1162197)
    </div>
  );

  return (
    <footer
      id="footer"
      className="footer"
      //to be uncommented when/if partners want customised footer for whitelabel
      // style={{
      //   backgroundColor: `${
      //     partner ? theme.palette.secondary.main : '#166b7a'
      //   }`,
      // }}
    >
      <div className="container-8 w-container">
        <div className="column-footer-main w-row">
          <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
            <div className="div-block-6 bottom-logo">
              <Logo />
              <div className="text-block-12">
                Copyright {year} - All rights reserved
              </div>
            </div>
          </div>
          <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
            <div className="div-block-6">
              <a
                data-gatrack="footer-volunteer-learnmore,clicked"
                href={`https://${forOrganisationUrl}`}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                For Organisations
              </a>
              <a
                data-gatrack="footer-aboutus,clicked"
                href={about_us}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                About Us
              </a>
              <a
                data-gatrack="footer-signuppage,clicked"
                href={`https://${signUpLink}`}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Sign Up
              </a>
              <a
                data-gatrack="footer-partners,clicked"
                href={our_partners}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Our Partners
              </a>
              <a
                data-gatrack="footer-partners,clicked"
                href={faq}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Frequently Asked Questions
              </a>
            </div>
          </div>
          <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
            <div className="div-block-6">
              <a
                data-gatrack="footer-faqs,clicked"
                href={our_blog}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Our Blog
              </a>
              <a
                data-gatrack="footer-contactus,clicked"
                href={contact_us}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Contact Us
              </a>
              <a
                data-gatrack="footer-privacypolicy,clicked"
                href="https://www.digitalboost.org.uk/legals/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Privacy Policy
              </a>
              <a
                data-gatrack="footer-termsofuse,clicked"
                href="https://digitalboost.org.uk/terms-conditions"
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Terms of use
              </a>
            </div>
          </div>
          <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
            <div className="div-block-6">
              {(!partner?.white_label ||
                !partner?.use_partner_details ||
                (partner?.white_label &&
                  partner?.use_partner_details &&
                  partner.linkedin)) && (
                <a
                  data-gatrack="footer-linkedin,clicked"
                  href={linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link-1 nolink"
                >
                  LinkedIn
                </a>
              )}
              {(!partner?.white_label ||
                !partner?.use_partner_details ||
                (partner?.white_label &&
                  partner?.use_partner_details &&
                  partner.instagram)) && (
                <a
                  data-gatrack="footer-instagram,clicked"
                  href={instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link-1 nolink"
                >
                  Instagram
                </a>
              )}
              {(!partner?.white_label ||
                !partner?.use_partner_details ||
                (partner?.white_label &&
                  partner?.use_partner_details &&
                  partner.facebook)) && (
                <a
                  data-gatrack="footer-facebook,clicked"
                  href={facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link-1 nolink"
                >
                  Facebook
                </a>
              )}
              {(!partner?.white_label ||
                !partner?.use_partner_details ||
                (partner?.white_label &&
                  partner?.use_partner_details &&
                  partner.twitter)) && (
                <a
                  data-gatrack="footer-twitter,clicked"
                  href={twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link-1 nolink"
                >
                  Twitter
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="charity">
          <div className="ko">{company_registration_info}</div>
          <div className="ko">{`Version ${process.env.REACT_APP_VERSION}`}</div>
        </div>
        <div className="div-block-27"></div>
      </div>
    </footer>
  );
};

export default Footer;
