import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../../components/DatePicker';
import { getUrlParam } from '../../utils/url';
import { filterByUKBusinessHours } from '../../utils/time';
import { capitalizeWords, toTitleCase } from '../../utils/text';
import { getErrorMessageFromResponse } from '../../utils/error';
import { useTaxonomyContext } from '../../context/Taxonomy';
import useGetGroupEventInvite from '../../apiHooks/groupEvents/useGetGroupEventRequests';
import useGetGroupEventAgenda from '../../apiHooks/groupEvents/useGetGroupEventAgenda';
import { acceptGroupEventInvite } from '../../apiCalls/groupEvents';
import { GroupEventRequest, ValueToggle } from '../../types';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import styles from './group-event-availability.module.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import HelpIcon from '@mui/icons-material/Help';
import Button from '../../atoms/Button';
import IconButton from '@mui/material/IconButton';
import { CustomMasterclassTypography } from '../../styling/generalStyling';
import { FormControl, OutlinedInput, Tooltip } from '@mui/material';
import useWindowSize from '../../hooks/useWindowSize';
import { DashboardEmptyButton } from '../../styling/buttons';
import { useSelector } from 'react-redux';
import {
  selectGroupEventMasterclassName,
  selectGroupEventWebinarName,
  selectGroupEventWorkshopName,
} from '../../apiHooks/groupSessionTypeNames';

type TextByStatus = {
  [status in GroupEventRequest['status']]: string;
};

const titleTextByStatus = (
  status: keyof TextByStatus,
  eventType: string
): string => {
  return (
    {
      invited: `Please submit your availability`,
      accepted: `You have already accepted this ${eventType} invitation`,
      declined: `You have already decline this ${eventType} invitation`,
      cancelled: `This ${eventType} invitation is no longer available`,
      expired: `This ${eventType} invitation is no longer available`,
    }[status] || '"Sorry - we could not find this event invitation"'
  );
};

const descriptionTextByStatus = (
  status: keyof TextByStatus,
  eventType: string,
  subject: string
): string => {
  return (
    {
      invited: `We’re so pleased that you want to host a ${eventType} for our community! 🤗 Fill out the details below and we’ll get your session set up. `,
      accepted: `Thank you again for accepting our invitation!\nWe look forward to your ${eventType} about ${subject}.`,
      declined: `Thank you again for considering our invitation.`,
      cancelled: `Thank you again for considering our invitation.`,
      expired: `Thank you again for considering our invitation.`,
    }[status] || ''
  );
};

const GroupEventAvailability = () => {
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const navigate = useNavigate();
  const { taxonomyById = {}, hasFinishedLoadingTaxonomy } =
    useTaxonomyContext();
  const requestId = getUrlParam('id') as string;
  if (!requestId) window.location.href = '/dashboard';
  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};
  const {
    groupEventRequestData,
    groupEventRequestError,
    hasFinishedLoadingGroupEventRequest,
  } = useGetGroupEventInvite({ id: requestId });
  var pluralize = require('pluralize');
  const { groupEventAgendaByInterestId, hasFinishedLoadingGroupEventAgenda } =
    useGetGroupEventAgenda();
  const groupEventRequest = groupEventRequestData && groupEventRequestData[0];
  const { interest, status, type } = groupEventRequest || {};
  const { name: interestName = '', id: interestId } = interest || {};
  const masterclassName =
    useSelector(selectGroupEventMasterclassName) || 'Masterclass';
  const webinarName = useSelector(selectGroupEventWebinarName) || 'Webinar';
  const workshopName = useSelector(selectGroupEventWorkshopName) || 'Workshop';
  const eventType =
    type === 'masterclass'
      ? capitalizeWords(masterclassName)
      : type === 'webinar'
      ? capitalizeWords(webinarName)
      : capitalizeWords(workshopName);

  const [eventTitle, setEventTitle] = useState<string | null>(null);
  const [eventDescription, setEventDescription] = useState<string | null>(null);
  // eslint-disable-next-line
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [hasConsentedToRecord, setHasConsentedToRecord] =
    useState<boolean>(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>('');
  const { toggles } = useSystemTogglesContext();
  const blockNonBusinessWorkingHours: ValueToggle =
    toggles.BLOCK_NON_BUSINESS_WORKING_HOURS as ValueToggle;
  const defaultDescription =
    interestId &&
    hasFinishedLoadingGroupEventAgenda &&
    groupEventAgendaByInterestId[interestId]
      ? groupEventAgendaByInterestId[interestId]
      : interestId && hasFinishedLoadingTaxonomy
      ? taxonomyById[interestId]?.description
      : ' ';
  const agendaTitleText = `🚀 Please tell us about your event`;

  const startingHour = 8;
  const endingHour = 18;

  const handleSubmit = useCallback(async () => {
    setFetchErrorMessage('');
    if (!startDate) return;
    setIsSubmitting(true);
    if (eventTitle === null || eventDescription === null) {
      setFetchErrorMessage('Please complete the required fields');
      setIsSubmitting(false);
    } else
      try {
        await acceptGroupEventInvite({
          id: requestId,
          date: startDate,
          recordingShareConsent: hasConsentedToRecord,
          description: eventDescription ? eventDescription : defaultDescription,
          title: eventTitle,
        });

        navigate(`/group-event-confirmation?eventType=${eventType}`);
      } catch (err) {
        const errorMessage = getErrorMessageFromResponse(err);
        const errorMessageToSet = errorMessage?.includes(
          'another event happening'
        )
          ? 'Our facilities are all booked at that time. Please, choose another time that is convenient for you.'
          : errorMessage;
        setFetchErrorMessage(errorMessageToSet);
        setIsSubmitting(false);
      }
  }, [
    eventType,
    startDate,
    eventDescription,
    requestId,
    hasConsentedToRecord,
    defaultDescription,
    navigate,
    eventTitle,
  ]);

  if (groupEventRequestError)
    return (
      <div className={styles.container} style={{ height: '60vh' }}>
        <h1 className={styles.erroText}>
          We're sorry - an error has occurred.
        </h1>
      </div>
    );

  return hasFinishedLoadingGroupEventRequest && groupEventRequestData ? (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.titleTextWrapper}>
          {status ? (
            <h1 className={styles.title}>
              {titleTextByStatus(status, eventType)}
            </h1>
          ) : (
            <h1 className={styles.title}>
              {'Sorry - we could not find this event invitation'}
            </h1>
          )}
          <CustomMasterclassTypography>{`${
            eventType && eventType.toUpperCase()
          } | ${
            interestName && interestName.toUpperCase()
          }`}</CustomMasterclassTypography>
        </div>
      </div>
      <div className={styles.bodyWrapper}>
        {status ? (
          <p className={styles.thankYouText}>
            {descriptionTextByStatus(
              status,
              eventType,
              toTitleCase(interestName)
            )}
          </p>
        ) : (
          <p className={styles.thankYouText}>
            If you think there has been a mistake, please get in touch with us
            at: <br />
            <a href="mailto:contact@digitalboost.org.uk">
              contact@digitalboost.org.uk
            </a>
          </p>
        )}
        {status === 'invited' && (
          <>
            <h4 className={styles.agendaTitle}>{agendaTitleText}</h4>

            <div className={styles.availabilityInputDetails}>
              <h4 style={{ width: '120px' }}>
                Event title{' '}
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>Example of event titles:</div>
                      <div>
                        <ul>
                          <li>Building an engaged audience on Instagram</li>
                          <li>Setting goals to push your business forward</li>
                          <li>5 ways to drive new customers to your website</li>
                          <li>How to secure grants for your business</li>
                        </ul>
                      </div>
                    </React.Fragment>
                  }
                >
                  <IconButton sx={{ padding: 0, margin: 0 }}>
                    <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />{' '}
                  </IconButton>
                </Tooltip>
              </h4>

              <FormControl
                sx={{
                  width: '100%',
                  minHeight: 'fit-content',
                  marginBottom: '18px',
                }}
              >
                <OutlinedInput
                  sx={{
                    fontFamily: 'Gilroy',
                    width: '100%',
                    borderRadius: '5px',
                    fontSize: isMobile ? '13px' : '16px',
                    lineHeight: isMobile ? '15.3px' : '18px',
                  }}
                  error={eventTitle !== null && eventTitle.length > 52}
                  multiline={false}
                  onChange={(event) => setEventTitle(event.target.value)}
                  placeholder=" Describe the focus of the session [max 52 characters]"
                />
                {eventTitle !== null && eventTitle.length > 52 ? (
                  <div>Please keep the event title below 52 characters</div>
                ) : null}
              </FormControl>
            </div>

            <div className={styles.availabilityInputDetails}>
              <h4 style={{ width: '120px' }}>
                Description{' '}
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        <div>
                          {' '}
                          We recommend making your sessions as actionable as
                          possible and it's important to reflect that in the
                          description!
                        </div>
                        <div>
                          For example, if you're running a {eventType} about
                          Content Creation you might write:
                        </div>
                        <br />
                        <div>In this {eventType} you'll learn:</div>
                        <ul>
                          <li>How to come up with content ideas</li>
                          <li>How to use hashtags to your advantage</li>
                          <li>How to use data to your advantage</li>
                        </ul>
                        <div>
                          You don't need to go into too much detail here, but
                          just give a general idea of what you'll be covering so
                          mentees know what to expect!
                        </div>
                      </div>
                    </React.Fragment>
                  }
                >
                  <IconButton sx={{ padding: 0, margin: 0 }}>
                    <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />{' '}
                  </IconButton>
                </Tooltip>
              </h4>

              <FormControl
                sx={{
                  width: '100%',
                  minHeight: 'fit-content',
                  marginBottom: '18px',
                }}
              >
                <OutlinedInput
                  sx={{
                    fontFamily: 'Gilroy',
                    width: '100%',
                    borderRadius: '5px',
                    fontSize: isMobile ? '13px' : '16px',
                    lineHeight: isMobile ? '15.3px' : '18px',
                  }}
                  error={
                    eventDescription !== null && eventDescription.length > 350
                  }
                  multiline={true}
                  onChange={(event) => setEventDescription(event.target.value)}
                  value={eventDescription}
                  placeholder=" Describe what the session will cover and what participants
          can expect to come away with [150 - 350 characters]"
                />
                <DashboardEmptyButton
                  variant="text"
                  sx={{ width: '200px' }}
                  onClick={() =>
                    eventDescription && eventDescription.length > 0
                      ? setEventDescription(
                          eventDescription + ' ' + defaultDescription
                        )
                      : setEventDescription(defaultDescription)
                  }
                >
                  Get some inspiration
                </DashboardEmptyButton>
                {eventDescription !== null && eventDescription.length > 350 ? (
                  <div>Please keep the description below 350 characters</div>
                ) : null}
              </FormControl>
            </div>
          </>
        )}
      </div>
      {status === 'invited' && (
        <>
          <div className={styles.bodyWrapper}>
            <h3 className={styles.availabilityTitle}>
              📆 Please select a date and time for your event
              <Tooltip
                title={`We host ${eventType}${
                  eventType === 'Masterclass' ? 'es' : 's'
                } on popular topics each week, so don’t worry if you see another ${eventType} about the same topic as yours! We welcome ${eventType}${
                  eventType === 'Masterclass' ? 'es' : 's'
                } on similar themes as we know each host will bring their own unique experience to the presentation and have different tips and tricks to share with our community!`}
              >
                <IconButton>
                  <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />{' '}
                </IconButton>
              </Tooltip>
            </h3>
            <p className={styles.availabilityText}>
              To give us time to promote your event and allow for more
              participants to be able to join, please select a date at least 10
              days in advance.
            </p>
            <div className={styles.datePickerContainer}>
              <DatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                filterTime={(selectedTime) =>
                  blockNonBusinessWorkingHours
                    ? filterByUKBusinessHours(
                        selectedTime,
                        startingHour,
                        endingHour
                      )
                    : true
                }
                className={styles.datePicker}
              />
            </div>
            <p className={styles.businessHoursText}>
              {' '}
              As most of our community is based in the UK,{' '}
              {pluralize(capitalizeWords(eventType))} are held between 8am and 6pm UK time. Please take this into
              account when selecting the same for your event. Times are
              displayed to you in your timezone, which is {timezone}.
            </p>
            {!!fetchErrorMessage && (
              <span className={styles.errorText}>{fetchErrorMessage}</span>
            )}
          </div>
          <div className={styles.bodyWrapper}>
            <label className={styles.recordingCheckboxLabel}>
              <input
                type="checkbox"
                id="recording-consent"
                name="recording-consent"
                value={`Has ${
                  hasConsentedToRecord ? '' : 'not '
                }consented to recording session`}
                className={styles.recordingXheckbox}
                checked={hasConsentedToRecord}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setHasConsentedToRecord(!hasConsentedToRecord)
                }
              />
              <span>{`We record our ${`${eventType}${
                eventType === 'Masterclass' ? 'es' : 's'
              }`} to share with participants after the session. We also sometimes upload them on our Resource Hub or on social media so that others can benefit from them too. Check this box if you’re okay with us sharing your recording. `}</span>
            </label>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              disabled={
                !startDate ||
                (blockNonBusinessWorkingHours &&
                  !filterByUKBusinessHours(
                    startDate,
                    startingHour,
                    endingHour
                  )) ||
                !(
                  eventTitle !== null &&
                  eventTitle.length <= 52 &&
                  eventTitle.length > 0
                ) ||
                !(
                  eventDescription !== null &&
                  eventDescription.length <= 350 &&
                  eventDescription.length > 0
                )
              }
              buttonType={'tertiary'}
              text={`Submit ${eventType} Availability`}
              loading={isSubmitting}
              onClickFunction={handleSubmit}
              className={styles.submitButton}
            />
          </div>
        </>
      )}
    </div>
  ) : (
    <div className={styles.loadingContainer}>
      <img className={styles.loadingSpinner} src="/loading.gif" alt="loading" />
    </div>
  );
};

export default GroupEventAvailability;
