import qs from 'qs';

export const getUrlParam = (param: string) =>
  qs.parse(
    window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
  )[param];

export const isDigitalBoostUrl = (url: string) => {
  return /volunteer\..*digitalboost\.org\.uk/g.test(url);
};

export const convertDBUrlToJitsi = (dbUrl: string) => {
  const { search } = new URL(dbUrl) || {};
  const urlParams = new URLSearchParams(search);
  const jitsi_room_name = urlParams.get('jitsi_room_name');
  return `https://meet.jit.si${jitsi_room_name}?config.requireDisplayName=true`;
};

export const validateUrl = (url: string) =>
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
    url
  );
