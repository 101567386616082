import api from '.';

export const getTaxonomy = (ids: number[]) => {
  let searchIdString = '';

  if (ids)
    ids.forEach((id) => {
      searchIdString += '&id=' + id;
    });

  return api
    .get(`api/taxonomy/interests/?limit=1000${searchIdString}`, {})
    .then((response: any) => {
      let taxonomy: any[] = [];

      response.data.results.forEach((skill: any) => {
        if (skill.parent) {
          if (taxonomy[skill.parent] && taxonomy[skill.parent].child) {
            taxonomy[skill.parent].child.push(skill);
          } else {
            taxonomy[skill.parent] = {
              child: [],
            };
            taxonomy[skill.parent].child.push(skill);
          }
        } else {
          taxonomy[skill.id] = {
            parent: skill,
            child: [],
          };
        }
      });

      return taxonomy;
    })
    .catch((err: any) => err);
};
