import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from '../../../api';
import {
  CustomFormLabel,
  CustomTextField,
} from '../../../styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value: { country: string; postcode: string };
};

type Country = {
  code: string;
  name: string;
};
export default function EditLocationForm({ value, setValue }: Props) {
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [country, setCountry] = useState(value.country);
  const [postcode] = useState(value.postcode);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCountry(event.target.value);
    setValue(event.target.value, 'country');
  };
  const getCountriesList = () => {
    return axios
      .get(`api/location/countries/?limit=1000`, {})
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  };
  useEffect(() => {
    (async () => {
      getCountriesList().then((response) => {
        setCountryList(response.data.results);
      });
    })();
  }, []);

  return (
    <Box sx={{ width: 240 }}>
      <FormControl fullWidth>
        <Select
          labelId="location-simple-select-label"
          id="location-simple-select"
          value={country}
          onChange={handleChange}
          sx={{ fontFamily: 'Gilroy' }}
        >
          {countryList.map((country) => (
            <MenuItem
              sx={{
                '&.MuiMenuItem-root': {
                  fontFamily: 'Gilroy',
                },
              }}
              key={country.code}
              value={country.code}
            >
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CustomTextField
        id="outlined-basic"
        sx={{ width: 240 }}
        onChange={(event) => setValue(event.target.value, 'postcode')}
        label={<CustomFormLabel> Postcode</CustomFormLabel>}
        defaultValue={postcode}
        variant="outlined"
      />
    </Box>
  );
}
