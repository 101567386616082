import React, { useState, useRef, useEffect } from 'react';
import './messaging-rebook-modal.scss';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useClickOutsideContainer from '../../hooks/useClickOutsideContainer';
import useSelectProfileData from '../../hooks/useSelectProfileData';

const orgBaseUrl = process.env.REACT_APP_ORG_URL;

interface Props {
  visible: boolean;
  onClose: () => void;
  volunteerProfileId: number;
}

const MessagingRebookModal = ({
  visible,
  onClose,
  volunteerProfileId,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { volunteerProfile, hasFinishedFetchingProfileAndLocation } =
    useSelectProfileData();
  const [copyText, setCopyText] = useState<string>('Copy');

  useClickOutsideContainer(ref, onClose);

  useEffect(() => {
    if (copyText === 'Copied!') {
      setTimeout(() => {
        setCopyText('Copy');
      }, 3000);
    }
  }, [copyText]);

  const copyToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    volunteerProfile?.partner
      ? navigator.clipboard.writeText(
          `${orgBaseUrl}volunteer-details?volunteer[]=${volunteerProfileId}&id=${volunteerProfile?.partner}`
        )
      : navigator.clipboard.writeText(
          `${orgBaseUrl}volunteer-details?volunteer[]=${volunteerProfileId}`
        );
    setCopyText('Copied!');
  };

  return visible && hasFinishedFetchingProfileAndLocation ? (
    <div className="messaging-rebook-overlay" onClick={onClose} ref={ref}>
      <div
        className="messaging-rebook-body"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="messaging-rebook-close-container" onClick={onClose}>
          <CloseIcon />
        </div>

        <p className="resources-mobile-title">
          Share your volunteer profile with them
        </p>
        <button onClick={copyToClipboard}>
          <ContentCopyIcon sx={{ height: 16 }} />
          {copyText}
        </button>
        <p>
          Grab the link to your profile and paste it in the messaging chat to
          let them book directly with you
        </p>
        <p>
          If you want to see how your profile looks like, click in the sharing
          icon in the top bar{' '}
          <span>
            <img src="/share.svg" alt="share" />
          </span>
        </p>
      </div>
    </div>
  ) : null;
};

export default MessagingRebookModal;
