import { createContext, useContext } from 'react';
import {
  VolunteerProfile,
  UserLocation,
  UserProfile,
  VolunteerProfileContext,
  UserLocationContext,
  UserProfileContext,
  PartnerContext,
  Partner,
} from '../types';

export type UserContextType = {
  volunteerProfile: VolunteerProfile | null | undefined;
  userLocation: UserLocation | undefined;
  userProfile: UserProfile | null | undefined;
  partner: Partner | undefined;
  setVolunteerProfileContext: (
    volunteerProfileContext: VolunteerProfileContext
  ) => void;
  setUserLocationContext: (userLocationContext: UserLocationContext) => void;
  setUserProfileContext: (userProfileContext: UserProfileContext) => void;
  setPartnerContext: (partnerContext: PartnerContext) => void;
  hasFinishedFetchingProfileAndLocation: boolean;
};

export const UserContext = createContext<UserContextType>({
  volunteerProfile: undefined,
  userProfile: undefined,
  userLocation: undefined,
  partner: undefined,
  setVolunteerProfileContext: (volunteerProfile) => {},
  setUserProfileContext: (userProfile) => {},
  setUserLocationContext: (userLocation) => {},
  setPartnerContext: (partner) => {},
  hasFinishedFetchingProfileAndLocation: false,
});
export const useUserProfile = () => useContext(UserContext);
