import React from 'react';
import RDatePicker from 'react-datepicker';
import { Placement, Modifiers } from 'popper.js'; // the datepicker calendar UI package
import moment from 'moment';
import './date-picker.scss';
import { defaultTimeFormat } from '../../utils/constants';
import TimeSlotTag from '../../atoms/TimeSlotTag';

interface datePickerProps {
  startDate: Date | null;
  setStartDate: (date: any) => void;
  setShowNext?: (arg: boolean) => void;
  placeHolderText?: string;
  dayBuffer?: number;
  filterTime?: (date: Date) => boolean;
  position?: Placement;
  className?: string;
  wrapperClassName?: string;
}

interface examplePickerProps {
  value?: any;
  onClick?: any;
  onRemove?: () => void;
  className?: string;
}

const ExampleCustomInput = ({
  value,
  onClick,
  onRemove,
  className,
}: examplePickerProps) =>
  value ? (
    <TimeSlotTag
      time={value}
      onClick={onClick}
      onRemove={value ? onRemove : undefined}
      className={className}
    />
  ) : (
    <button
      className={`date-picker-custom-input ${!value ? 'not-selected' : ''} ${
        className ? className : ''
      }`}
      onClick={onClick}
      data-cy="select-availability-custom-input"
    >
      Add Date & Time
    </button>
  );

// Gets props to force DatePicker to render calendar in particular position (e.g. 'bottom' | 'left')
// see: https://github.com/Hacker0x01/react-datepicker/issues/1246#issuecomment-426004214
const getDatePickerCalendarPositionProps = (
  position?: Placement
): Modifiers | {} =>
  position
    ? {
        popperPlacement: position,
        popperModifiers: {
          flip: {
            behavior: [position], // don't allow it to flip to be another position
          },
          preventOverflow: {
            enabled: false, // tell it not to try to stay within the view (this usually prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false, // turn off since needs preventOverflow to be enabled
          },
        },
      }
    : {};

const DatePicker = ({
  startDate,
  setStartDate,
  setShowNext,
  placeHolderText,
  dayBuffer = 5,
  filterTime,
  position,
  className,
  wrapperClassName,
}: datePickerProps) => {
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  const isUKBusinessHour = (time: Date) => {
    const ukTime = moment(time).tz('Europe/London');
    const hour = ukTime.hour();
    return hour >= 8 && hour < 18;
  };
  return (
    <RDatePicker
      placeholderText={placeHolderText || 'Click to select a date'}
      dateFormat="Pp"
      timeFormat={defaultTimeFormat}
      selected={startDate}
      filterTime={(time) => isUKBusinessHour(time)}
      onChange={(date) => setStartDate(date)}
      customInput={
        <ExampleCustomInput
          onClick={(date: any) => setStartDate(date)}
          value={startDate}
          className={className}
        />
      }
      className={`date-picker__input-container `}
      wrapperClassName={`date-picker__wrapper ${wrapperClassName}`}
      onInputClick={() => {
        const defaultDate = moment()
          .add(dayBuffer, 'day')
          .minute(Math.round(moment().minute() / 15) * 15)
          .second(0)
          .toDate();
        const filteredTime = !!filterTime ? filterTime(defaultDate) : true;
        !startDate && filteredTime && setStartDate(defaultDate);
        setShowNext && setShowNext(true);
      }}
      showTimeSelect
      filterDate={(date) =>
        moment().add(dayBuffer - 1, 'day') < moment(date) && isWeekday(date)
      }
      timeIntervals={15}
      disabledKeyboardNavigation
      {...getDatePickerCalendarPositionProps(position)}
    />
  );
};

export default DatePicker;
