import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useAxios } from '../../api';
import useAcceptBoostCallRequest, {
  AcceptBoostCallRequestReturnProps,
} from './useAcceptBoostCallRequest';
import useDeclineBoostCallRequest, {
  DeclineBoostCallReturnProps,
} from './useDeclineBoostCallRequest';
import { BoostCallRequest, TimeSlot } from '../../types';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { getErrorMessageFromResponse } from '../../utils/error';

type Props = {
  id: number | string | null;
};

type ReturnProps = {
  boostCallEventRequest?: BoostCallRequest;
  isLoadingBoostCallEventRequest: boolean;
  hasFinishedLoadingBoostCallEventRequest: boolean;
  boostCallEventRequestError: any;
  refetchBoostCallEventRequest: () => void;
  timeSlotProposedByVolunteer?: TimeSlot;
  scheduledTimeSlots?: TimeSlot[];
  showRequestInfo: boolean;
  multipleSessionNotAllowed: boolean;
  defaultCallProvider: string;
  acceptBoostCallRequestUtils: AcceptBoostCallRequestReturnProps;
  declineBoostCallRequestUtils: DeclineBoostCallReturnProps;
};

const useGetBoostCallEventRequest = ({ id }: Props): ReturnProps => {
  const { volunteerProfile } = useSelectProfileData();
  const { id: volunteerId } = volunteerProfile || {};
  const [{ data, loading, error }, executeCall] = useAxios<BoostCallRequest>(
    {
      url: `api/event/boostcallrequests/${id}/`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!!id) executeCall();
  }, [executeCall, id]);

  const defaultCallProvider =
    data?.invite?.custom_call_provider?.id.toString() || '3';
  const acceptBoostCallRequestUtils = useAcceptBoostCallRequest({
    id,
    defaultCallProvider: defaultCallProvider,
  });
  const declineBoostCallRequestUtils = useDeclineBoostCallRequest({ id });

  const timeSlotProposedByVolunteer = data?.time_slots.find(
    (ts: TimeSlot) =>
      ts.status === 'time_slot_proposed' && ts.volunteer === volunteerId
  );

  const scheduledTimeSlots = data?.time_slots.filter(
    ({ start_time, status }: TimeSlot) =>
      moment(start_time).isAfter(moment()) && status === 'time_slot_scheduled'
  );

  const showRequestInfo = !!data?.time_slots.find(
    (ts) =>
      ts.status === 'time_slot_proposed' ||
      ts.status === 'time_slot_pending' ||
      ts.status === 'time_slot_expired' ||
      ts.status === 'time_slot_cancelled' ||
      ts.status === 'time_slot_scheduled'
  );

  const multipleSessionNotAllowed =
    !data?.allow_multiple_sessions &&
    scheduledTimeSlots &&
    scheduledTimeSlots?.length > 0;

  return {
    boostCallEventRequest: data,
    isLoadingBoostCallEventRequest: loading,
    hasFinishedLoadingBoostCallEventRequest: !loading,
    boostCallEventRequestError: getErrorMessageFromResponse(error),
    refetchBoostCallEventRequest: executeCall,
    timeSlotProposedByVolunteer,
    scheduledTimeSlots,
    showRequestInfo,
    multipleSessionNotAllowed: !!multipleSessionNotAllowed,
    defaultCallProvider,
    acceptBoostCallRequestUtils,
    declineBoostCallRequestUtils,
  };
};

export default useGetBoostCallEventRequest;
