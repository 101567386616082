import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../api';

type Props = {
  id: number | string | null;
};

type DeclineBoostCallRequestProps = {
  reason?: string;
};

export type DeclineBoostCallReturnProps = {
  declineBoostCallRequest: (arg: any) => void;
  declineAndRedirect: (path: string, reason?: string) => void;
  declineBoostCallRequestData: any;
  isLoadingDeclineBoostCallRequest: boolean;
  declineBoostCallRequestError: any;
};

const useDeclineBoostCallRequest = ({
  id,
}: Props): DeclineBoostCallReturnProps => {
  const navigate = useNavigate();

  const [{ data, loading, error }, executeCall] = useAxios(
    {
      url: `api/event/boostcallrequests/${id}/declineinvite/`,
      method: 'POST',
    },
    { manual: true }
  );

  const declineBoostCallRequest = ({
    reason,
  }: DeclineBoostCallRequestProps = {}) =>
    executeCall({
      data: {
        reason: reason || 'unavailable',
      },
    });

  const declineAndRedirect = async (path: string, reason?: string) => {
    try {
      await declineBoostCallRequest({ reason });
      navigate(path);
    } catch (err) {
      // err is already handled by declineBoostCallRequestError variable
      console.log('err:', err);
    }
  };

  return {
    declineBoostCallRequest,
    declineAndRedirect,
    declineBoostCallRequestData: data,
    isLoadingDeclineBoostCallRequest: loading,
    declineBoostCallRequestError: error,
  };
};

export default useDeclineBoostCallRequest;
