// EditCardMobilePopup.js

import React, { memo, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TaxonomyPopup from '../taxonomyPopup';
import SelectAvailabilityTask from '../selectAvailabilityTask';
import { updateActionTask } from '../../../api/actionPlan';
import { CardTextField, DashboardFilledButton } from '../../../styling/buttons';
import { CardsType } from '../../../types';
interface EditCardMobilePopupProps {
  onClose: () => void;
  cardDetails: CardsType;
  cardEdit: boolean;
  setCounterChange: () => void;
}

const EditCardMobilePopup = ({
  onClose,
  cardDetails,
  cardEdit,
  setCounterChange,
}: EditCardMobilePopupProps) => {
  const [editCardPopupName, setEditCardPopupName] = useState<
    string | undefined
  >(undefined);
  const color =
    cardDetails.status === 'todo'
      ? '#FCEBEB'
      : cardDetails.status === 'in_progress'
      ? '#FFF9E4'
      : '#ecfbf0';

  const [newDate, setNewDate] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string>('');
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<any>(
    cardDetails?.interest?.id
  );

  const [errorMessage] = useState<null | string>(null);

  const handleUpdateCard = async () => {
    if (cardDetails) {
      const EditCardPopupDetails: any = {
        title: editCardPopupName ? editCardPopupName : cardDetails.title,
        description: description,
        interest: cardDetails.interest,
        status: cardDetails.status,
        action_plan: 0,
        created: '',
        created_by: 0,
        deadline: newDate ? newDate : cardDetails.deadline,
        id: cardDetails.id,
      };
      try {
        const result = await updateActionTask(
          EditCardPopupDetails,
          cardDetails.status,
          selectedTaxonomy
        );

        if (result.success) {
          setCounterChange();
          onClose();
        }
      } catch (error) {
        console.error('Error creating task:', error);
      }
    } else {
      console.error('Error: Name and date are required to create a task');
    }
  };
  useEffect(() => {
    setEditCardPopupName(undefined);
    setNewDate(undefined);
    setDescription('');
    if (cardDetails?.interest?.id)
      setSelectedTaxonomy(cardDetails?.interest?.id);
    else setSelectedTaxonomy(undefined);
  }, [cardDetails]);
  return (
    <div>
      <Dialog
        open={cardEdit}
        onClose={onClose}
        sx={{
          padding: 0,
          '.MuiPaper-root': {
            padding: 0,
            borderRadius: '15px',
            background: ' #FFF',
            width: '300px',
            minHeight: '372px',
            maxHeight: '90vh',
            paddingLeft: '10px',
            backgroundColor: `${color}`,
            maxWidth: '100vw',
            flexShrink: 0,
          },
        }}
      >
        <div className="close-dialog-icon">
          <div className="card-title">Edit task</div>
          <IconButton
            color="primary"
            onClick={async () => {
              onClose();
            }}
            sx={{ padding: '0px', marginRight: '30px' }}
          >
            <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
          </IconButton>
        </div>

        <DialogContent sx={{ padding: 0, width: '280px' }}>
          <div className="empty-card-container">
            <div className="row-card-mobile">
              <div className="table-text">Task name</div>
              <CardTextField
                sx={{
                  width: '240px',
                  margin: 0,
                }}
                defaultValue={cardDetails.title}
                type="text"
                onChange={(e) => setEditCardPopupName(e.target.value)}
              />

              <div className="row-card-mobile-no-padding">
                <div className="table-text"> Deadline</div>
                <SelectAvailabilityTask
                  setNewDate={setNewDate}
                  date={cardDetails.deadline}
                />
              </div>
              <div className="row-card-mobile-no-padding">
                <div className="table-text"> Topic</div>
                <TaxonomyPopup
                  setSelectedTaxonomy={setSelectedTaxonomy}
                  selectedTaxonomy={selectedTaxonomy}
                />
              </div>
              <div className="row-card-mobile-no-padding">
                <div className="table-text"> Description</div>
                <CardTextField
                  multiline
                  inputProps={{ maxLength: 800 }}
                  sx={{
                    width: '250px',
                  }}
                  defaultValue={cardDetails.description}
                  placeholder="Add a description"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignSelf: 'center',
            paddingBottom: '30px',
            width: ' 100%',
            justifyContent: 'center',
          }}
        >
          <DashboardFilledButton
            onClick={handleUpdateCard}
            sx={{
              width: '240px',
              height: '52px',
              padding: '12px 36px',
            }}
          >
            Save{' '}
          </DashboardFilledButton>
        </DialogActions>
        {errorMessage ? (
          <div className="error-message">{errorMessage}</div>
        ) : null}
      </Dialog>
    </div>
  );
};

export default memo(EditCardMobilePopup);
