import { styled } from '@mui/system';
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Paper,
} from '@mui/material';
const breakpoints = {
  mobile: '750px',
  tablet: '900px',
  screen: '1200px',
};

export const StyledBox = styled(Box)({
  position: 'relative',
  width: '350px',
  maxWidth: '60%',
  height: '30px',

  [`@media (max-width: ${breakpoints.mobile})`]: {
    width: '90vw',
    maxWidth: '350px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  [`@media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.screen})`]:
    {
      gridColumn: '3',
      gridRow: '2',
      width: '350px',
      justifySelf: 'flex-end',
      display: 'flex',
      maxWidth: '100%',
      height: 'auto',

      flexDirection: 'column',
    },
});

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: ' 30px',
  borderRadius: '15px',
  boxShadow: '0px 0px 1px 0',
  border: ' 1px',
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#FFFF',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#D9D9D9',
  },
  [`@media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}`]:
    {
      width: '100%',
      height: '30px',
    },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  flexShrink: 0,
  boxShadow: '0px 0px 1px 0',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  width: '203px',
  height: '40px',
  marginRight: '33px',

  // For mobile
  [`@media (max-width: ${breakpoints.mobile})`]: {
    width: '100%',
    marginRight: '0',
    maxWidth: '350px',
  },

  // For tablet
  [`@media (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.screen})`]:
    {
      marginRight: '0',
      width: '250px',
    },
}));
