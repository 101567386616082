import api from '../api';
import { getErrorMessageFromResponse } from '../utils/error';

type GetCallDetailsProps = {
  type: string;
  id: string | number;
};

type CancelCallProps = {
  id: string | number;
};

export const getCallDetails = async ({ type, id }: GetCallDetailsProps) => {
  const callInfoResponse = await api.get<any>(
    `api/event/boostcall${type}/${id}/`
  );
  if (callInfoResponse.status < 200 || callInfoResponse.status >= 400)
    throw callInfoResponse;
  return callInfoResponse.data;
};

export const cancelBoostCall = async ({ id }: CancelCallProps) => {
  // This doesn't actually delete the database record
  // It just marks the status as 'cancelled'
  return api.delete(`api/event/boostcallevents/${id}/`);
};

type Props = {
  code: string | number;
  callProvider: string;
  timeSlot: string;
  callUrl?: string;
  handleErrorMessage: (errorMessage: string) => void;
};

export const confirmBoostCallInvite = () => {
  const confirmCall = async ({
    code,
    callProvider,
    timeSlot,
    callUrl,
    handleErrorMessage,
  }: Props) =>
    api
      .post(`api/event/boostcallinvites/${code}/acceptinvite/`, {
        time_slot: timeSlot,
        call_url: callUrl,
        call_provider: +callProvider,
      })
      .then((response: { data: any }) => {
        return response.data;
      })
      .catch((error: any) => {
        const errorMessage = getErrorMessageFromResponse(error);
        handleErrorMessage(errorMessage);
        return false;
      });

  return {
    confirmCall,
  };
};
