import { red } from '@mui/material/colors';
import palette from './palette.scss';

// Create a theme instance.
var theme = {
  palette: {
    primary: {
      main: palette.primary, //button colours
      light: palette.light, //background colours
      dark: palette.dark, //background colours
      contrastText: palette.contrastTextPrimary,
    },
    secondary: {
      main: palette.secondary, //button colours
    },

    error: {
      main: red.A400,
    },
  },
};

export default theme;
