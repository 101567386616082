import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getUrlParam } from '../../utils/url';
import 'react-toastify/dist/ReactToastify.css';

import LoginSignupModal from '../../components/LoginSignupModal';

import './login-signup.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { useFetchPremiumPartnerbySubdomain } from '../../apiCalls/user';

type Props = {
  mode?: 'signup' | 'login';
};

const LoginSignupContainer = ({ mode = 'signup' }: Props) => {
  const navigate = useNavigate();
  const { userProfile, hasFinishedFetchingProfileAndLocation } =
    useSelectProfileData();
  useFetchPremiumPartnerbySubdomain();
  const loggedIn = hasFinishedFetchingProfileAndLocation && !!userProfile;
  const deleteNotification = getUrlParam('delete');
  const redirectPathEncoded = getUrlParam('redirect') as string;
  const redirectPathDecoded =
    redirectPathEncoded && decodeURI(redirectPathEncoded);

  useEffect(() => {
    if (loggedIn) {
      const redirectPath = redirectPathDecoded || '/dashboard';
      navigate(redirectPath);
    }
  }, [loggedIn, navigate, redirectPathDecoded]);

  useEffect(() => {
    if (deleteNotification) {
      setTimeout(() => {
        toast.error('You have successfully deleted your account', {
          autoClose: 9000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 500);
    }
  }, [deleteNotification]);
  return (
    <div className="login-signup">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LoginSignupModal mode={mode} className="login-signup__modal" />
    </div>
  );
};

export default LoginSignupContainer;
