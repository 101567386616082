import React, { useCallback, useEffect, useState } from 'react';
import api from '../../api';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { Taxonomies, MappedTaxonomy } from '../../types';
import './sign-up-skills.scss';
import ReactTooltip from 'react-tooltip';

const skillImgMap = new Map([
  [1, '/product-development.png'],
  [2, '/design-branding.png'],
  [3, '/customer_service.png'],
  [4, '/b2b_sales_marketing.png'],
  [5, '/infrastructure.png'],
  [6, '/digital_marketing_implementation.png'],
  [7, '/e_commerce.png'],
  [8, '/data_analytics.png'],
  [9, '/security_data.png'],
  [10, '/business_strategy.png'],
  [11, '/business_operations.png'],
  [61, '/payment_money.png'],
  [67, '/sustainability.png'],
  [72, '/initial_diagnosis.png'],
  [74, '/personal_development.png'],
]);

interface Props {
  handleCheckboxClick: any;
  skillsList: any[];
}

const SignUpSkills = ({ skillsList, handleCheckboxClick }: Props) => {
  const [taxonomy, setTaxonomy] = useState<MappedTaxonomy[]>([]);
  const [openParentSkills, setOpenParentSkill] = useState<number | false>(
    false
  );

  const getTaxonomy = useCallback(async () => {
    try {
      const response = await api.get<Taxonomies>(
        `api/taxonomy/interests?limit=1000`,
        {}
      );
      const taxonomy: MappedTaxonomy[] = [];

      const parents = response.data.results.filter(
        (skill: any) => skill.parent === null
      );
      parents.forEach((skill: any) => {
        taxonomy[skill.id] = {
          parent: skill,
          child: [],
        };
      });
      response.data.results.forEach((skill: any) => {
        if (typeof skill.parent === 'number') {
          taxonomy[skill.parent].child.push(skill);
        }
      });

      setTaxonomy(taxonomy.flat(0));
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    getTaxonomy();
  }, [getTaxonomy]);

  const sortItems = (array: MappedTaxonomy[] | undefined) => {
    return (
      array &&
      array.sort((a, b) =>
        a.parent.order === b.parent.order
          ? 0
          : a.parent.order > b.parent.order
          ? 1
          : -1
      )
    );
  };

  return (
    <div className="sign-up-skills">
      <ReactTooltip />

      {sortItems(taxonomy)?.map((skill) => {
        const key = `parent-skill-${skill.parent.id}`;

        if (skill.parent) {
          return (
            <div key={key}>
              <div
                onClick={() =>
                  openParentSkills === skill.parent.id
                    ? setOpenParentSkill(false)
                    : setOpenParentSkill(skill.parent.id)
                }
                className="select-skill"
              >
                <img
                  height={'80px'}
                  style={{ marginBottom: '10px' }}
                  src={skillImgMap.get(skill.parent.id)}
                  alt={skill.parent.name}
                />
                <div style={{ color: '#333' }}>{skill.parent.name}</div>
                <div className="arrow">
                  {skill.parent.id === openParentSkills && <IoIosArrowUp />}
                  {skill.parent.id !== openParentSkills && <IoIosArrowDown />}
                </div>
              </div>

              {skill.parent.id === openParentSkills && (
                <div
                  className={`sub-skills sub-skills-${
                    skill.parent.id === openParentSkills
                  }`}
                >
                  {skill.child.map((childSkill) => {
                    let checked = false;

                    skillsList.forEach((setSkills) => {
                      if (setSkills.id === childSkill.id) {
                        checked = true;
                      }
                    });

                    setTimeout(() => {
                      // react tool-tip needs this for conditional renders
                      ReactTooltip.rebuild();
                    }, 200);

                    return (
                      <div
                        data-tip={childSkill.volunteer_description}
                        key={`child-skill${childSkill.id}`}
                        onClick={() =>
                          handleCheckboxClick(childSkill.id, childSkill.name)
                        }
                      >
                        <input
                          readOnly={true}
                          checked={checked}
                          type={'checkbox'}
                        />
                        <label>{childSkill.name}</label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default SignUpSkills;
