import React, { useState } from 'react';
import { formatDate } from '../../../utils/time';
import './style.scss';
import { BoostCallEvent } from '../../../types';
import {
  CustomFormLabel,
  CustomTextField,
} from '../../../styling/generalStyling';
import { DashboardFilledButton } from '../../../styling/buttons';
import '../components/styling/card-styling.scss';
import useDeclineRescheduleBoostcall from '../../../apiHooks/boostCalls/useDeclineRescheduleBoostcall';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { sendMessage } from '../../../api/messagingCentre';

type Props = {
  event: any;
  setIsRescheduleModalOpen: any;
  isOpen: boolean;
};
const RescheduleDialog = ({
  isOpen,
  setIsRescheduleModalOpen,
  event,
}: Props) => {
  const {
    id: boostCallId,
    organisation: organisationData,
    start_time,
    reschedule_request_time,
  } = (event as BoostCallEvent) || {};
  const [declineMessage, setDeclineMessage] = useState<string>(
    'Sorry, I cannot make it but I am available at'
  );
  const profileData = {
    name: organisationData?.first_name,
    picture: organisationData?.profile_picture,
    companyName: organisationData?.name,
    currentRole: organisationData?.current_role,
    showPresentedBy: false,
  };

  const rescheduleDateFormatted = formatDate(
    reschedule_request_time,
    event.timeZone
  );
  const currentDateFormatted = formatDate(start_time, event.timeZone);

  const rescheduledTime = {
    time: rescheduleDateFormatted?.time,
    date: rescheduleDateFormatted?.date,
  };

  const currentTime = {
    time: currentDateFormatted?.time,
    date: currentDateFormatted?.date,
  };

  const { declineRescheduleBoostCall } = useDeclineRescheduleBoostcall({
    id: boostCallId,
  });

  const cancelRescheduleRequest = async () => {
    if (!declineMessage) return;

    try {
      await sendMessage({
        organisationId: event?.organisation?.id,
        text: declineMessage,
      });
      await declineRescheduleBoostCall({
        newStartTime: reschedule_request_time,
        cancel: false,
        message: declineMessage,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog open={isOpen}>
      <div className="close-dialog-icon">
        <IconButton
          color="primary"
          onClick={() => setIsRescheduleModalOpen(false)}
          sx={{ padding: '0px' }}
        >
          <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
        </IconButton>
      </div>

      <div className="reschedule-dialog-title"> Reschedule request</div>
      <div className="reschedule-dialog-info">
        <div className="reschedule-dialog-date">
          Original Time:
          <div className="reschedule-dialog-date-text">
            {currentTime.date?.day} {currentTime.date?.monthThreeLetterFormat}{' '}
            {currentTime.date?.year} {currentTime.time?.hour}{' '}
          </div>
        </div>
        <div className="reschedule-dialog-date">
          Proposed New Time:{' '}
          <div className="reschedule-dialog-date-text">
            {' '}
            {rescheduledTime.date?.day}{' '}
            {rescheduledTime.date?.monthThreeLetterFormat}{' '}
            {rescheduledTime.date?.year} {rescheduledTime.time?.hour}{' '}
          </div>
        </div>
        <div>
          We're sorry you can't make the proposed time. Can you meet at another
          time? Just send a message to {profileData.name} to find the right time
          for both of you. You can then submit another reschedule request.
        </div>

        <CustomTextField
          id="outlined-basic"
          multiline
          minRows={3}
          onChange={(event) => setDeclineMessage(event.target.value)}
          label={<CustomFormLabel>Send a message</CustomFormLabel>}
          defaultValue={declineMessage}
          variant="outlined"
        />
        <div>
          If you do not accept the new time, the session will remain at the
          original time, unless you cancel it.
        </div>
        <div className="reschedule-dashboard-button-container">
          <DashboardFilledButton
            variant="contained"
            sx={{
              borderRadius: '110px',
            }}
            onClick={() => cancelRescheduleRequest()}
          >
            Decline new time and send message
          </DashboardFilledButton>
        </div>
      </div>
    </Dialog>
  );
};

export default RescheduleDialog;
