import React from 'react';
import Modal from 'react-modal';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { TailSpin } from 'react-loader-spinner';

import styles from './verified-modal.module.scss';
import Button from '../../atoms/Button';

type Props = {
  customStyles?: {
    overlay: React.CSSProperties;
    content: React.CSSProperties;
  };
  onButtonPress: () => void;
  invalidToken?: boolean;
  userNotFoundError?: boolean;
  alreadyVerified?: boolean;
  verificationResent?: boolean;
  errorMessage?: string;
  hasProfile: boolean;
  loading?: boolean;
};

const defaultCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 25, 0.45)',
    zIndex: 30,
  },
  content: {
    maxWidth: '630px',
    maxHeight: '200px',
    margin: 'auto',
  },
};

const VerifiedModal = ({
  customStyles = defaultCustomStyles,
  onButtonPress,
  invalidToken,
  userNotFoundError,
  alreadyVerified,
  verificationResent,
  errorMessage,
  hasProfile,
  loading,
}: Props) => {
  const title = errorMessage
    ? errorMessage
    : 'Your account has been verified successfully!';
  const subtitle = invalidToken
    ? 'Looks like your verification link has expired. Not to worry, we can send you a new link.'
    : alreadyVerified || !errorMessage
    ? `You will now be redirected to ${
        hasProfile ? 'your Dashboard' : 'finish your signup'
      }.`
    : '';
  const buttonText = invalidToken
    ? 'Resend Confirmation Email'
    : hasProfile
    ? 'Go to your Dashboard'
    : 'Finish setting up your profile';
  return (
    <Modal
      isOpen={true}
      contentLabel="Messages"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          {!errorMessage && (
            <IoIosCheckmarkCircle
              className={styles.icon}
              size={32}
              fill={'#00AB6B'}
            />
          )}
          <h3 className={styles.title}>{title}</h3>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.bodyText}>{subtitle}</p>
        </div>
        {(!errorMessage || alreadyVerified) && (
          <TailSpin color="#414141" height={20} width={20} />
        )}
        {!invalidToken && !userNotFoundError && (
          <div className={styles.textContainer}>
            <p className={styles.resendText}>{'Or click below to continue'}</p>
          </div>
        )}
        {invalidToken && (
          <span
            className={`${styles.verificationResent} ${
              verificationResent ? styles.verificationResentVisible : ''
            }`}
            aria-hidden={!verificationResent}
          >
            {verificationResent ? 'Verification resent!' : 'Placeholder'}
          </span>
        )}
        <div className={styles.buttonContainer}>
          {!userNotFoundError && (
            <Button
              buttonType={'secondary'}
              text={buttonText}
              onClickFunction={onButtonPress}
              loading={loading}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VerifiedModal;
