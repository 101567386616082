import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import './style.scss';
import { Button } from '@mui/material';

interface BlockMessageModalProps {
  open: boolean;
  handleClose: () => void;
  selectedUser?: any;
  handleBlock?: () => void;
  blocked: boolean;
}

const BlockMessageModal = ({
  open,
  handleClose,
  handleBlock,
  selectedUser,
  blocked,
}: BlockMessageModalProps) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const closeModal = () => {
    handleClose();
  };

  return open ? (
    <div className="block-message__overlay" onClick={closeModal}>
      <div className="block-message__body" onClick={(e) => e.stopPropagation()}>
        <div className="block-message__header">
          <p>{blocked ? 'Unblock confirmation' : 'Block confirmation'}</p>

          <button onClick={closeModal}>
            <CloseIcon fontSize="small" />
          </button>
        </div>

        <div className="block-message__block-text-strong">
          Are you sure you want to {blocked ? 'unblock' : 'block'}{' '}
          {selectedUser && selectedUser?.organisation?.first_name}?
        </div>
        <div className="block-message__block-text">
          You {blocked ? 'will' : "won't"} be able to send and receive messages
          from them.
        </div>

        <div className="block-message__bottom">
          <Button
            className="block-message__bottom-button"
            onClick={handleBlock}
          >
            {blocked ? 'Unblock' : 'Block'} confirm
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default BlockMessageModal;
