import { useState, useEffect, useCallback } from 'react';
import { useAxios } from '../../api';
import { getErrorMessageFromResponse } from '../../utils/error';
import { validateUrl, getUrlParam } from '../../utils/url';

type Props = {
  code: number | string | null;
  defaultCallProvider: string;
};

export type ProposeNewTimeReturnProps = {
  proposeNewTime: (proposedStartDate?: any, link?: any) => Promise<void>;
  proposeNewTimeData: any;
  isLoadingProposeNewTime: boolean;
  proposeNewTimeError: string;
  proposedStartDate?: Date;
  setProposedStartDate: (arg: Date) => any;
  selectedCallProvider: string;
  setSelectedCallProvider: (arg: string) => void;
  callUrl: string;
  setCallUrl: (arg: string) => void;
  hasSentProposeNewTime: boolean;
  setHasSentProposeNewTime: (arg: boolean) => void;
  needsCallUrl: boolean;
};

const useProposeNewTime = ({
  code,
  defaultCallProvider,
}: Props): ProposeNewTimeReturnProps => {
  const callUrlParam = getUrlParam('callUrl') as string;
  const [proposedStartDate, setProposedStartDate] = useState<Date>();

  const [selectedCallProvider, setSelectedCallProvider] =
    useState<string>(defaultCallProvider);
  const [callUrl, setCallUrl] = useState<string>(callUrlParam || '');
  const [hasSentProposeNewTime, setHasSentProposeNewTime] = useState(false);
  const needsCallUrl = selectedCallProvider !== '3'; // Id 3 is Jitsi
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [{ data, loading, error }, executeCall] = useAxios<any>(
    {
      url: `api/event/boostcallinvites/${code}/proposeinvite/`,
      method: 'POST',
    },
    { manual: true }
  );

  useEffect(() => {
    if (!!defaultCallProvider) setSelectedCallProvider(defaultCallProvider);
  }, [executeCall, defaultCallProvider]);
  const proposeNewTime = useCallback(
    async (startDate, link) => {
      setErrorMessage('');
      try {
        if (!proposedStartDate && !startDate)
          throw new Error('Please choose a date and time');
        if (needsCallUrl && !link.trim())
          throw new Error('Please enter a meeting link');
        if (needsCallUrl && !validateUrl(link))
          throw new Error('Please enter a valid url');
        await executeCall({
          data: {
            time_slots: [
              {
                start_time: startDate
                  ? startDate?.toISOString()
                  : proposedStartDate?.toISOString(),
              },
            ],
            call_url: link.trim(),
            call_provider: +selectedCallProvider,
          },
        });
        setHasSentProposeNewTime(true);
      } catch (ex) {
        setErrorMessage(getErrorMessageFromResponse(ex));
        throw ex;
      }
    },
    [executeCall, needsCallUrl, selectedCallProvider, proposedStartDate]
  );

  return {
    proposeNewTime,
    proposeNewTimeData: data,
    isLoadingProposeNewTime: loading,
    proposeNewTimeError: errorMessage || getErrorMessageFromResponse(error),
    proposedStartDate,
    setProposedStartDate,
    selectedCallProvider,
    setSelectedCallProvider,
    hasSentProposeNewTime,
    callUrl,
    setCallUrl,
    setHasSentProposeNewTime,
    needsCallUrl,
  };
};

export default useProposeNewTime;
