import React, { useState } from 'react';

import './button-with-dropdown.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  buttonContent?: any;
  children?: any;
}

const ButtonWithDropdown = ({
  className,
  buttonClassName,
  dropdownClassName,
  buttonContent,
  children,
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  return (
    <>
      {dropdownOpen && (
        <div
          className="button-with-dropdown__overlay"
          aria-hidden
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(false);
          }}
        />
      )}
      <div
        className={`button-with-dropdown__container ${
          className ? className : ''
        }`}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
          className={`button-with-dropdown__button ${
            buttonClassName ? buttonClassName : ''
          }`}
        >
          {buttonContent}
        </button>
        {dropdownOpen && (
          <ul
            className={`button-with-dropdown__dropdown ${
              dropdownClassName ? dropdownClassName : ''
            }`}
            onClick={() => setDropdownOpen(false)}
          >
            {children}
          </ul>
        )}
      </div>
    </>
  );
};

export default ButtonWithDropdown;
