import api from '.';

export const getResources = async ({
  contentType,
  textDebouncedValue,
  selectedSkillList,
}: any) => {
  const filters = new URLSearchParams({
    ...(contentType && { content_type: contentType }),
    ...(textDebouncedValue.length > 0 && { text: textDebouncedValue }),
    ...(selectedSkillList.length > 0 && { interests: selectedSkillList[0] }),
  });
  const filtersString = filters.toString();

  return api.get<any>(
    filtersString.length > 0
      ? `api/resourcecenter/resource/searchresource/?${filtersString}`
      : `api/resourcecenter/resource/searchresource/`,
    {}
  );
};

export const resourceClick = async (body: any) => {
  return api.post<any>(`api/resourcecenter/volunteerresourceclick/`, body);
};
