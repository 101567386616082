import useRedux from '../../hooks/useRedux';
import useAutoFetch from '../../hooks/useAutoFetch';
import {
  selectUserLocation,
  fetchUserLocationAction,
  setUserLocation as setUserLocationAction,
  clearUserLocation as clearUserLocationAction,
  selectHasFinishedLoadingUserLocation,
  selectHasNotFetchedUserLocation,
} from './index';
import type { UserLocation } from '../../types';

type Props = { autoFetch: boolean };

const instanceOfUserLocation = (object: any): object is UserLocation => {
  return 'postcode' in object;
};

const useFetchUserLocation = (
  { autoFetch = true }: Props = { autoFetch: true }
) => {
  // HOOKS
  const { useSelector, dispatch } = useRedux();
  useAutoFetch(fetchUserLocationAction, selectHasNotFetchedUserLocation, {
    autoFetch,
  });

  // ACTIONS
  const fetchUserLocation = () => dispatch(fetchUserLocationAction());
  const setUserLocation = (location: UserLocation) => {
    if (instanceOfUserLocation(location)) {
      dispatch(setUserLocationAction(location));
    }
  };

  const clearUserLocation = () => dispatch(clearUserLocationAction());

  // SELECTORS
  const userLocation = useSelector(selectUserLocation);
  const hasFinishedLoadingUserLocation = useSelector(
    selectHasFinishedLoadingUserLocation
  );

  return {
    userLocation,
    fetchUserLocation,
    setUserLocation,
    clearUserLocation,
    hasFinishedLoadingUserLocation,
  };
};

export default useFetchUserLocation;
