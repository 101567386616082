import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Attachment } from '../../types';

// Create a theme instance.
var attachments = {
  count: 0,
  next: null,
  attachments: [] as Attachment[],
};
export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: {
    value: attachments,
  },
  reducers: {
    updateAttachments: (state, action) => {
      return { ...state, value: action.payload };
    },
    deleteAttachmentFromRedux: (state, action) => {
      const attachmentIdToDelete = action.payload;
      const updatedAttachments = state.value.attachments.filter(
        (attachment) => attachment.id !== attachmentIdToDelete
      );
      return {
        ...state,
        value: {
          ...state.value,
          attachments: updatedAttachments,
        },
      };
    },
  },
});

export const { updateAttachments, deleteAttachmentFromRedux } =
  attachmentsSlice.actions;
/* --- SELECTORS --- */
export const selectAttachments = (state: RootState) => state.attachments.value;

export default attachmentsSlice.reducer;
