import React, { useEffect } from 'react';
import InputWithLabel from '../../components/InputWithLabel';
import './call-url-input.scss';

type Props = {
  setCallURL: (url: string) => void;
  callUrl: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
};

const CallUrlInput = ({
  setCallURL,
  className,
  containerClassName,
  disabled,
  callUrl,
}: Props) => {
  useEffect(() => {
    if (disabled) {
      setCallURL('');
    }
  }, [disabled, setCallURL]);
  return (
    <InputWithLabel
      defaultValue={callUrl}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setCallURL(e.target.value);
      }}
      disabled={disabled}
      className={`${className ? className : ''} call-url-input__input`}
      containerClassName={containerClassName}
    />
  );
};
export default CallUrlInput;
