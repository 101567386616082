import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useAxios } from '../../api';
import { getMomentWithTimezone } from '../../utils/time';
import { BoostCallEvent } from '../../types';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { getErrorMessageFromResponse } from '../../utils/error';

type Props = {
  id: number | string | null;
};

const useGetBoostCallEventData = ({ id }: Props) => {
  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};

  const [{ data, loading, error }, executeCall] = useAxios<BoostCallEvent>(
    {
      url: `api/event/boostcallevents/${id}/`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!!id) executeCall();
  }, [executeCall, id]);

  const callHasNotYetStarted =
    data && timezone && !!moment().isBefore(moment(data.start_time));
  const callHasEnded =
    data &&
    timezone &&
    !moment().isBefore(
      getMomentWithTimezone(data.start_time, timezone).add(
        data.duration,
        'minutes'
      )
    );
  const callIsHappening = !callHasNotYetStarted && !callHasEnded;
  const callStartInPast = !!(callIsHappening || callHasEnded);
  // text to be used on Join Call button - changes depending on when call start time is
  const buttonText =
    data &&
    (callHasNotYetStarted
      ? `Starts ${moment().utc().to(moment(data.start_time))} - Join Call`
      : callIsHappening
      ? `Started ${moment()
          .utc()
          .to(getMomentWithTimezone(data.start_time, timezone))} - Join Call`
      : `Call finished ${moment()
          .utc()
          .to(
            getMomentWithTimezone(data.start_time, timezone).add(
              data.duration,
              'minutes'
            )
          )}`);

  return {
    boostCallEventData: data,
    isLoadingBoostCallEventData: loading,
    hasFinishedLoadingBoostCallEventData: !loading,
    boostCallEventDataError: getErrorMessageFromResponse(error),
    refetchBoostCallEventData: executeCall,
    callHasNotYetStarted,
    callIsHappening,
    callHasEnded,
    callStartInPast,
    buttonText,
  };
};

export default useGetBoostCallEventData;
