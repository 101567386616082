import React from 'react';
import './style.scss';

const NoFilesMessage = () => {
  return (
    <div className="no-files-message">
      Welcome to your Library!
      <div>
        {' '}
        A place where you can store all your documents and notes from your
        mentoring sessions 💪{' '}
      </div>
      <div> You will be able to manage all the files received here.</div>
    </div>
  );
};

export default NoFilesMessage;
