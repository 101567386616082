import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import AccountInfo from './AccountInfo';
import './style/index.scss';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { CustomHeaderTypography } from '../../styling/generalStyling';
import ProfileCompletion from './Components/ProfileCompletion';
import { TabsList, Tab } from '../../styling/tabs';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}
let display = 0;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  if (value === 'security') display = 1;
  else if (value === 'privacy') display = 2;
  else if (value === 'profile') display = 0;
  return (
    <div
      role="tabpanel"
      hidden={display !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    ></div>
  );
}
const Account = ({ tab }: { tab: string }) => {
  let history = useNavigate();

  const tabs = [
    {
      label: 'Profile',
      link: 'profile',
    },
    {
      label: 'Login and Security',
      link: 'security',
    },
    {
      label: 'Data, Privacy and Communication',
      link: 'privacy',
    },
  ];

  const [value, setValue] = React.useState(tab);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    history(`/dashboard/account/${newValue}`, { replace: true });
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  const displayIcon = (label: string) => {
    if (label === 'Profile') return <AccountBoxOutlinedIcon />;
    else if (label === 'Login and Security') return <HttpsOutlinedIcon />;
    else if (label === 'Data, Privacy and Communication')
      return <PolicyOutlinedIcon />;
    else return null;
  };
  return (
    <div className="container__account-page">
      <div>
        <CustomHeaderTypography>Account</CustomHeaderTypography>
        <TabsUnstyled value={value} onChange={handleChange}>
          <TabsList>
            {tabs.map((tab) => (
              <Tab key={tab.link} value={tab.link} {...a11yProps(0)}>
                <div className="dashboard-tab-icon-label" key={tab.link}>
                  {displayIcon(tab.label)} {tab.label}
                </div>
              </Tab>
            ))}
          </TabsList>
          <TabPanel value={value} index={0} key={0} />
          <TabPanel value={value} index={1} key={1} />
          <TabPanel value={value} index={2} key={2} />
        </TabsUnstyled>
        <ProfileCompletion />
      </div>
      <div className="container__info">
        <AccountInfo tab={value} />
      </div>
    </div>
  );
};
export default Account;
