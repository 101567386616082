import React, { useEffect, useState } from 'react';
import '../style/pastExperiences.scss';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Experiences } from '../types';
import {
  CustomFormLabel,
  CustomTextField,
} from '../../../styling/generalStyling';
import api from '../../../api';

type Props = {
  pastExperience: Experiences[];
  savedPressed: number;
};

export default function EditPastExperienceForm({
  pastExperience,
  savedPressed,
}: Props) {
  const [formValues, setFormValues] = useState(pastExperience);

  useEffect(() => {
    const saveExperience = async () => {
      const finalValues = formValues.filter(
        (entry) =>
          entry.employer.length > 0 &&
          entry.role.length > 0 &&
          entry.from_year !== null
      );
      try {
        await api.patch(`api/volunteer/volunteers/me/`, {
          employment_history: finalValues,
        });
      } catch (e) {
        console.error(e);
      }
    };

    if (savedPressed > 0) saveExperience();
  }, [savedPressed, formValues]);

  const handleChange = (value: string | null, key: string, index: number) => {
    setMessagesError(null);
    let newFormValues = formValues.map((item, position) => {
      if (position === index) item = { ...item, ...{ [`${key}`]: value } };
      return item;
    });
    setFormValues(newFormValues);
  };
  const [messageError, setMessagesError] = useState<string | null>('');
  const addFormFields = () =>
    setFormValues((formValues) => [
      ...formValues,
      {
        from_year: null,
        to_year: null,
        employer: '',
        role: '',
        checked: false,
      },
    ]);

  const removeFormFields = (i: number) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  return (
    <div className="past-experiences-form__container">
      <CustomFormLabel id="past-experiences-label">
        Please complete all required fields.
      </CustomFormLabel>
      <form>
        <div className="error">{messageError}</div>
        {formValues.map((element, index) => (
          <div className="pastExperiences__entry-form-entry-row" key={index}>
            <div>
              <CustomTextField
                type="text"
                name="role"
                error={element.role.length === 0}
                value={element.role || ''}
                label={<CustomFormLabel>Role</CustomFormLabel>}
                onChange={(event) =>
                  handleChange(event.target.value, 'role', index)
                }
              />
            </div>
            <CustomTextField
              type="text"
              name="employer"
              error={element.employer.length === 0}
              value={element.employer || ''}
              label={<CustomFormLabel>Employer</CustomFormLabel>}
              onChange={(event) =>
                handleChange(event.target.value, 'employer', index)
              }
            />
            <CustomTextField
              name="from_year"
              error={element.from_year === null}
              value={element.from_year || ''}
              label={<CustomFormLabel>From year</CustomFormLabel>}
              onChange={(event) =>
                Number(event.target.value) || event.target.value.length < 1
                  ? handleChange(event.target.value, 'from_year', index)
                  : setMessagesError(
                      'From Year field needs to be a number, otherwise your entry will not be saved'
                    )
              }
            />

            <div className="current-year-form-select">
              <CustomTextField
                disabled={element.to_year === null}
                name="to_year"
                value={element.to_year || ''}
                label={<CustomFormLabel>To Year</CustomFormLabel>}
                onChange={(event) =>
                  Number(event.target.value) || event.target.value.length < 1
                    ? handleChange(event.target.value, 'to_year', index)
                    : setMessagesError(
                        'To Year field needs to be a number, otherwise your entry will not be saved'
                      )
                }
              />

              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={element.to_year !== null ? false : true}
                    name={`currentJob-${index}`}
                    onChange={() => {
                      element.to_year !== null
                        ? handleChange(null, 'to_year', index)
                        : handleChange('', 'to_year', index);
                    }}
                  />
                }
                label={
                  <CustomFormLabel>
                    I am currently working in this role
                  </CustomFormLabel>
                }
              />
            </div>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => removeFormFields(index)}
            >
              <RemoveCircleOutlineIcon
                sx={{ alignSelf: 'center' }}
                fontSize="small"
              />
            </IconButton>
          </div>
        ))}
        <div className="button-section">
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              lineHeight: '1.3',
              color: '#414141',
              fontFamily: 'Gilroy',
              fontWeight: 'bold',
            }}
            onClick={() => addFormFields()}
          >
            Add new item
          </Button>
        </div>
      </form>
    </div>
  );
}
