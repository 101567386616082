import { useEffect, useState } from 'react';
import { getSystemToggles } from '../apiCalls/systemToggles';
import { FormattedToggles, ToggleFromResponse } from '../types';

type Props = {
  isBetaUser?: boolean;
};

const useGetSystemToggles = ({ isBetaUser = false }: Props) => {
  const [toggles, setToggles] = useState<FormattedToggles>({});
  const [hasFinishedLoadingToggles, setHasFinishedLoadingToggles] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const { results = [] } = await getSystemToggles();
        const newFormattedToggles = results.reduce(
          (acc: FormattedToggles, cur: ToggleFromResponse) => {
            const {
              toggle_name,
              enabled,
              enabled_to_beta_users,
              value,
              ...rest
            } = cur;
            const enabledForUser =
              enabled || (enabled_to_beta_users && isBetaUser);
            if (!!value) {
              acc[toggle_name] = { enabled: enabledForUser, value, ...rest };
            } else {
              acc[toggle_name] = enabledForUser;
            }
            return acc;
          },
          {}
        );
        setToggles(newFormattedToggles);
        setHasFinishedLoadingToggles(true);
      } catch (e) {
        console.log('toggle fetch error:', e);
        setHasFinishedLoadingToggles(true);
      }
    })();
  }, [isBetaUser]);

  return { toggles, setToggles, hasFinishedLoadingToggles };
};

export default useGetSystemToggles;
