import { useAxios } from '../../api';
import { BoostCallEvents, BoostCallEvent } from '../../types';
const useGetBoostCallGroupEventsCompleted = () => {
  const [{ data, loading, error }, refetch] = useAxios<BoostCallEvents>({
    url: `api/groupevent/groupevent/?status=complete`,
  });
  return {
    boostCallGroupEventsCompleted: (data?.results as BoostCallEvent[]) || [],
    boostCallGroupEventsCompletedCount: data?.count || 0,
    hasFinishedLoadingBoostCallGroupEventsCompleted: !loading,
    boostCallGroupEventsCompletedError: error,
    refetchBoostCallGroupEventsCompleted: refetch,
  };
};

export default useGetBoostCallGroupEventsCompleted;
