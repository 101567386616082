import useRedux from '../../hooks/useRedux';
import useAutoFetch from '../../hooks/useAutoFetch';
import {
  fetchVolunteerProfileAction,
  setVolunteerProfile as setVolunteerProfileAction,
  clearVolunteerProfile as clearVolunteerProfileAction,
  selectVolunteerProfile,
  selectHasFinishedLoadingVolunteerProfile,
  selectHasNotFetchedVolunteerProfile,
} from './index';
import type { VolunteerProfile } from '../../types';

type Props = { autoFetch: boolean };

const useFetchVolunteerProfile = (
  { autoFetch = true }: Props = { autoFetch: true }
) => {
  // HOOKS
  const { useSelector, dispatch } = useRedux();
  useAutoFetch(
    fetchVolunteerProfileAction,
    selectHasNotFetchedVolunteerProfile,
    { autoFetch }
  );

  // ACTIONS
  const fetchVolunteerProfile = () => dispatch(fetchVolunteerProfileAction());
  const setVolunteerProfile = (profile: VolunteerProfile) =>
    dispatch(setVolunteerProfileAction(profile));
  const clearVolunteerProfile = () => dispatch(clearVolunteerProfileAction());

  // SELECTORS
  const volunteerProfile = useSelector(selectVolunteerProfile);
  const hasFinishedLoadingVolunteerProfile = useSelector(
    selectHasFinishedLoadingVolunteerProfile
  );
  const needsToFinishSignup =
    hasFinishedLoadingVolunteerProfile && !volunteerProfile;

  return {
    volunteerProfile,
    fetchVolunteerProfile,
    setVolunteerProfile,
    clearVolunteerProfile,
    needsToFinishSignup,
    hasFinishedLoadingVolunteerProfile,
  };
};

export default useFetchVolunteerProfile;
