import { useAxios } from '../../api';
import { GroupEvents } from '../../types';

type Props = {
  code?: string;
};

const useGetGroupEvent = ({ code }: Props) => {
  const idParam = code ? { code } : {};

  // Note: For Volunteers, this endpoint only returns the Volunteer's own events
  const [{ data, loading, error }, refetch] = useAxios<GroupEvents>({
    url: 'api/groupevent/groupevent/',
    params: {
      limit: 100,
      status: 'scheduled',
      ...idParam,
    },
  });

  return {
    groupEventData: data && data.results,
    hasFinishedLoadingGroupEvents: !loading,
    groupEventError: error,
    refetchGroupEvent: refetch,
  };
};

export default useGetGroupEvent;
