import React from 'react';
import Box from '@mui/material/Box';
import {
  CustomTextField,
  CustomFormLabel,
} from '../../../styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value: string;
};
export default function EditIntroductionForm({ value, setValue }: Props) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { width: '50vw', minWidth: '250px' },
      }}
      noValidate
      autoComplete="off"
    >
      <CustomTextField
        id="outlined-basic"
        multiline
        minRows={3}
        onChange={(event) => setValue(event.target.value, 'summary')}
        label={<CustomFormLabel>Introduction</CustomFormLabel>}
        defaultValue={value}
        variant="outlined"
      />
    </Box>
  );
}
