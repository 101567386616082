import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import { BoostCallEvent } from '../../types';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import { displayTime, getMomentWithTimezone } from '../../utils/time';
import { toTitleCase } from '../../utils/text';
import { getUrlParam } from '../../utils/url';
import './addToCalendarButton.scss';
import { defaultDateFormat, defaultTimeFormat } from '../../utils/constants';

const baseUrl = process.env.REACT_APP_BASE_URL;
const currentURL = window.location.href;

// Extract the desired portion of the URL
const extractedURL =
  currentURL.indexOf('org.uk/') !== -1
    ? currentURL.substring(0, currentURL.indexOf('org.uk/') + 7)
    : baseUrl;
type Props = {
  callInfo: BoostCallEvent;
  timeZoneString: string;
  text: string;
  color?: string;
  type?: 'primary' | 'secondary' | 'tertiary' | 'red' | 'dark';
  className?: string;
  disableStyling?: boolean;
};

// Note: This component relies on the 'addevent' third party <script> being loaded
const RescheduleAddToCalendarButton = ({
  callInfo,
  timeZoneString,
  text,
  type = 'primary',
}: Props) => {
  const {
    id: callId,
    call_url: originalCallUrl,
    reschedule_request_time: rescheduleRequestTime,
    duration,
    start_time,
    title,
    organisation,
    top_priority_name: topPriority,
  } = callInfo;

  const { url: dbCallUrl } = useGetJoinCall({ callInfo });
  const calendarButtonRef = useRef<HTMLButtonElement>(null);

  const addToCalendarParam = getUrlParam('add_to_calendar') as string;
  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(
    !!addToCalendarParam
  );
  const [openingCalendar, setOpeningCalendar] = useState(!!addToCalendarParam);

  // // Fixes a bug requiring a reload of the <script>
  useEffect(() => {
    if (callInfo) {
      // @ts-ignore
      setTimeout(() => window.addeventatc?.refresh(), 1000);
    }
  }, [callInfo]);

  useEffect(() => {
    if (shouldOpenCalendar) {
      (async () => {
        // 2 second delay needed to load calendar script
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setOpeningCalendar(false);
        calendarButtonRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        // @ts-ignore
        await window.addeventatc?.refresh();
        calendarButtonRef?.current?.click();
        setShouldOpenCalendar(false);
      })();
    }
  }, [shouldOpenCalendar, setShouldOpenCalendar, calendarButtonRef]);

  if (!organisation) return null;

  const businessName = organisation.name && organisation.name;
  const detailsLink = `${extractedURL}call-details?type=events&cid=${callId}`;
  const rescheduleCancelLink = `${extractedURL}call-details?type=events&event_update_options=true&id=${callId}`;

  return (
    <Button
      title="Add Event to Calendar"
      text={text}
      buttonType={type}
      className={`addeventatc`}
      ref={calendarButtonRef}
      loading={openingCalendar}
    >
      <span className="start atc-hidden-text">
        {displayTime(
          rescheduleRequestTime ? rescheduleRequestTime : start_time,
          timeZoneString,
          `${defaultDateFormat} ${defaultTimeFormat}`
        )}
      </span>
      <span className="end atc-hidden-text">
        {getMomentWithTimezone(rescheduleRequestTime, timeZoneString)
          .add(duration, 'minutes')
          .format(`${defaultDateFormat} ${defaultTimeFormat}`)}
      </span>
      <span className="timezone atc-hidden-text">{timeZoneString}</span>
      <span className="title atc-hidden-text">{`Digital Boost ${toTitleCase(
        title
      )} with ${organisation.first_name} ${organisation.last_name}`}</span>
      <span className="description atc-hidden-text">
        {`Digital Boost ${toTitleCase(title)} with ${organisation.first_name} ${
          organisation.last_name
        }${businessName ? ` from ${businessName}` : ''} ${
          topPriority ? `about ${toTitleCase(topPriority)}` : ''
        }.\n \n For more details about this call, visit\n${detailsLink}\n\nIf you need to reschedule or cancel your call, click here:\n${rescheduleCancelLink}`}
      </span>
      <span className="location atc-hidden-text">
        {!!dbCallUrl ? dbCallUrl : originalCallUrl}
      </span>
    </Button>
  );
};

export default RescheduleAddToCalendarButton;
