import { Badge } from '@mui/material';
import React, { memo } from 'react';
import './style.scss';
import moment from 'moment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import { useNavigate } from 'react-router-dom';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { DashboardEmptyButton, ManageCallButton } from '../../styling/buttons';
import NewAddToCalendarButton from '../../components/NewAddToCalendarButton';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { CustomCallPagePaper } from '../../styling/backgrounds';
import { useSystemTogglesContext } from '../../context/SystemToggles';
type Props = {
  event: any;
  setOpenMenteeModal?: any;
  setOpenMessagesModal?: any;
  setRescheduleModal?: any;
  messagesRead?: boolean;
};

const RescheduleRequestByVolunteer = ({
  event,
  setOpenMenteeModal,
  setOpenMessagesModal,
  setRescheduleModal,
  messagesRead,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const { joinCall } = useGetJoinCall({ callInfo: event });
  const startTime = moment(event.start_time);
  const today = moment();
  const navigate = useNavigate();
  const { userLocation } = useSelectProfileData();
  const timeZoneString =
    userLocation && userLocation.timezone ? userLocation.timezone : '';

  window.onpopstate = function () {
    window.location.reload();
  };

  return (
    <div className="organisation-information-container">
      <div className="header-container">
        <div className="header-text">You have sent a reschedule request</div>
        <DashboardEmptyButton
          sx={{ fontSize: '14px' }}
          variant="text"
          onClick={() => setOpenMenteeModal(true)}
        >
          View learner details
        </DashboardEmptyButton>
      </div>
      <CustomCallPagePaper elevation={0}>
        <div>
          <div className="header-box-proposed-time">
            <div>
              <div className="time-container-text">New proposed time</div>
              <div className="time-container">
                <div className="time-container-text">
                  {moment(event.reschedule_request_time).format('DD MMMM YYYY')}
                </div>
                <div className="time-container-text">
                  {moment(event.reschedule_request_time).format('dddd')}
                </div>
                <div className="time-container-text">
                  {moment(event.reschedule_request_time).format('HH:mm ')}
                </div>
              </div>
            </div>
            <div>
              <div className="time-container-text">Original time</div>
              <div className="time-container">
                <div className="time-container-text">
                  {moment(event.start_time).format('DD MMMM YYYY')}
                </div>
                <div className="time-container-text">
                  {moment(event.start_time).format('dddd')}
                </div>
                <div className="time-container-text">
                  {moment(event.start_time).format('HH:mm')}
                </div>
              </div>
            </div>
          </div>
        </div>

        {!moment(startTime).isSame(today, 'day') ? (
          <div className="header-box-time-left-container">
            Starts in
            <div className="time-left">
              {moment(startTime.diff(today)).format('D')}
            </div>{' '}
            days
          </div>
        ) : (
          <div className="header-box-time-left-container">
            <div className="starts-today-text">Starts today</div>
          </div>
        )}
        <div
          className="helper-text"
          style={{ marginBottom: '20px', marginTop: '10px' }}
        >
          Waiting for {event.organisation.first_name} to accept your request. If
          the mentee does not accept, the session will go ahead at the original
          time.
        </div>
        <div className="header-box-buttons">
          <ManageCallButton variant="contained" onClick={() => joinCall()}>
            Join session
          </ManageCallButton>

          <div className="header-box-button">
            <DashboardEmptyButton
              variant="text"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingLeft: '2px',
              }}
              onClick={() => {
                if (showMessagingUI) {
                  navigate(`/messaging-centre?id=${event?.organisation?.id}`);
                  return;
                }
                setOpenMessagesModal(true);
              }}
            >
              <EmailOutlinedIcon sx={{ marginRight: '5px' }} />
              <Badge
                badgeContent={
                  !messagesRead ? event.volunteer_new_messages_count : 0
                }
                color="info"
                sx={{ padding: '3px' }}
              >
                Messages
              </Badge>
            </DashboardEmptyButton>
          </div>
          <div className="header-box-button">
            <DashboardEmptyButton
              variant="text"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingLeft: '2px',
              }}
              onClick={() => setRescheduleModal(true)}
            >
              <HistoryOutlinedIcon
                sx={{ marginLeft: '5px', marginRight: '5px' }}
              />
              Reschedule
            </DashboardEmptyButton>
          </div>

          <div className="header-box-button">
            <DashboardEmptyButton
              variant="text"
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#f23737',
                paddingLeft: '2px',
              }}
              onClick={() => navigate(`/cancel-call?id=${event.id}`)}
            >
              <DangerousOutlinedIcon sx={{ marginRight: '5px' }} />
              Cancel session
            </DashboardEmptyButton>
          </div>
          <div className="header-box-button">
            <div className="header-box-button">
              <NewAddToCalendarButton
                callInfo={event}
                timeZoneString={timeZoneString}
              />
            </div>
          </div>
        </div>
      </CustomCallPagePaper>
    </div>
  );
};

export default memo(RescheduleRequestByVolunteer);
