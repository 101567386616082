import { useEffect } from 'react';
import Cookie from 'js-cookie';
import { getUrlParam } from '../../utils/url';
import { getAccessToken, getRefreshToken } from '../../utils/auth';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useRedux from '../../hooks/useRedux';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  loginAction,
  logout as logoutAction,
  setAccessToken as setAccessTokenAction,
  setRefreshToken as setRefreshTokenAction,
  selectIsLoadingLogin,
  selectLoginError,
  selectAccessToken,
  selectRefreshToken,
  AuthActionProps,
} from '.';

const useLogin = () => {
  const { useSelector, dispatch } = useRedux();
  const { fetchAllProfileData } = useSelectProfileData();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const redirectPathEncoded = getUrlParam('redirect') as string;
  const redirectPath = redirectPathEncoded && decodeURI(redirectPathEncoded);
  const defaultRedirect =
    pathname === '/select-availability' ? '/select-availability' : '/dashboard';

  // ACTIONS
  const login = async ({ email, password }: AuthActionProps) => {
    const loginResult = await dispatch(loginAction({ email, password }));
    if (!loginResult || loginResult.type !== 'auth/login/fulfilled') {
      return;
    }

    const profileFetchResult = await fetchAllProfileData();
    const needsToFinishSignup =
      profileFetchResult.volunteerProfileResult.status === 'rejected' ||
      (profileFetchResult.volunteerProfileResult.status === 'fulfilled' &&
        !profileFetchResult.volunteerProfileResult.value.payload);

    if (needsToFinishSignup) {
      navigate('/profile-create');
    } else if (!!redirectPath) {
      navigate(redirectPath);
    } else {
      navigate(defaultRedirect);
    }
  };

  const logout = async () => {
    navigate('/login');
    await dispatch(logoutAction());
    Cookie.remove('accessTokenDB');
    Cookie.remove('refreshTokenDB');
  };

  // SELECTORS
  const isLoadingLogin = useSelector(selectIsLoadingLogin);
  const loginError = useSelector(selectLoginError);
  const accessToken = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);

  const accessTokenCookie = getAccessToken();
  const refreshTokenCookie = getRefreshToken();

  // KEEP TOKENS IN STATE
  useEffect(() => {
    if (accessTokenCookie && !accessToken) {
      dispatch(setAccessTokenAction(accessTokenCookie));
    }
  }, [accessToken, accessTokenCookie, dispatch]);

  useEffect(() => {
    if (refreshTokenCookie && !refreshToken) {
      dispatch(setRefreshTokenAction(refreshTokenCookie));
    }
  }, [dispatch, refreshToken, refreshTokenCookie]);

  return {
    login,
    logout,
    isLoadingLogin,
    loginError,
    accessToken,
    refreshToken,
  };
};

export default useLogin;
