import { createSlice } from '@reduxjs/toolkit';
import { Disability } from '../../pages/Account/types';
import { RootState } from '../../store';


// Initial state for disabilities
const initialDisabilitiesState = {
  disabilitiesList: [] as Disability[],
  loading: false,
  error: null as string | null,
};

export const disabilitiesSlice = createSlice({
  name: 'disabilities',
  initialState: initialDisabilitiesState,
  reducers: {
    fetchDisabilitiesStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDisabilityList: (state, action) => {
      state.loading = false;
      state.disabilitiesList = action.payload;
    },
    setDisabilitieError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDisabilitiesStart,
  fetchDisabilityList,
  setDisabilitieError,
} = disabilitiesSlice.actions;

/* --- SELECTORS --- */
export const selectDisabilities = (state: RootState) => state.disabilities.disabilitiesList;
export const selectDisabilitiesLoading = (state: RootState) => state.disabilities.loading;
export const selectDisabilitiesError = (state: RootState) => state.disabilities.error;

export default disabilitiesSlice.reducer;
