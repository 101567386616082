import { useEffect } from 'react';

const useClickOutsideContainer = (
  ref: any,
  handleClickOutsideMenu: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOutsideMenu();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutsideMenu, ref]);
};

export default useClickOutsideContainer;
