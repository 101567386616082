import api from '../api';
import { GroupEventRequests, GroupEvent, GroupEvents } from '../types';

type GetGroupEventInviteProps = {
  id: string | number;
  limit?: number;
};
type DeclineGroupEventRequestProps = {
  id: string | number;
};

type GetGroupEventProps = {
  code: string;
  limit?: number;
};

type AcceptGroupEventInviteProps = {
  id: string | number;
  date: Date;
  title: string;
  recordingShareConsent: boolean;
  description: string;
};

export const getGroupEventInvite = async ({ id }: GetGroupEventInviteProps) => {
  const groupEventInviteResponse = await api.get<GroupEventRequests>(
    `api/groupevent/groupeventrequest/?id=${id}&limit=1`
  );
  if (
    groupEventInviteResponse.status < 200 ||
    groupEventInviteResponse.status >= 400
  )
    throw groupEventInviteResponse;
  return groupEventInviteResponse.data.results[0];
};
export const getGroupEvent = async ({ code }: GetGroupEventProps) => {
  const groupEventResponse = await api.get<GroupEvents>(
    `api/groupevent/groupevent/?code=${code}}&limit=1`
  );
  if (groupEventResponse.status < 200 || groupEventResponse.status >= 400)
    throw groupEventResponse;
  return groupEventResponse.data.results[0];
};

export const acceptGroupEventInvite = async ({
  id,
  date,
  recordingShareConsent,
  description,
  title,
}: AcceptGroupEventInviteProps) => {
  const acceptGroupEventInviteResponse = await api.post<GroupEvent>(
    `api/groupevent/groupeventrequest/${id}/acceptinvite/`,
    {
      date_time: date,
      recording_share_consent: recordingShareConsent,
      description: description,
      title: title,
    }
  );
  if (
    acceptGroupEventInviteResponse.status < 200 ||
    acceptGroupEventInviteResponse.status >= 400
  )
    throw acceptGroupEventInviteResponse;

  return acceptGroupEventInviteResponse.data;
};

export const declineGroupEventRequest = async ({
  id,
}: DeclineGroupEventRequestProps) => {
  const response = await api.post(
    `api/groupevent/groupeventrequest/${id}/declineinvite/`,
    {}
  );

  return response;
};

export const getSpeakerBriefing = async ({ code }: GetGroupEventProps) => {
  const speakerBriefingResponse = await api.get(
    `api/groupevent/speakerbriefing/?code=${code}}&limit=100`
  );
  if (
    speakerBriefingResponse.status < 200 ||
    speakerBriefingResponse.status >= 400
  )
    throw speakerBriefingResponse;
  return speakerBriefingResponse.data;
};
