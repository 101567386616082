import { useState } from 'react';
import useRedux from '../../hooks/useRedux';
import useGetCancellationReasons from '../../apiHooks/boostCalls/useGetCancellationReasons';
import {
  cancelBoostCallEventAction,
  selectHasCancelledBoostCallEvent,
  selectIsLoadingCancelBoostCallEvent,
  selectCancelBoostCallEventError,
} from './index';

type Props = {
  id: number;
};

const useCancelBoostCallEvent = ({ id }: Props) => {
  //  HOOKS
  const { useSelector, dispatch } = useRedux();
  const [selectedReasonId, setSelectedReasonId] = useState<number>();
  const [reasonText, setReasonText] = useState<string>();
  const isLoadingCancelBoostCall = useSelector(
    selectIsLoadingCancelBoostCallEvent
  );

  // SELECTORS
  const hasFinishedCancelling = useSelector(selectHasCancelledBoostCallEvent);
  const cancelBoostCallError = useSelector(selectCancelBoostCallEventError);

  const { hasFinishedLoadingCancellationReasons, cancellationReasons } =
    useGetCancellationReasons();

  // ACTIONS
  const cancelBoostCall = () =>
    dispatch(
      cancelBoostCallEventAction({
        id,
        setErrorMessage: (err) => console.log(err),
        cancellationReasonId: selectedReasonId,
        message: reasonText,
      })
    );

  return {
    cancelBoostCall: cancelBoostCall,
    isLoadingCancelBoostCall,
    hasCancelledBoostcall: hasFinishedCancelling,
    cancelBoostCallError,
    hasFinishedLoadingCancellationReasons,
    reasonText,
    setReasonText,
    cancellationReasons,
    selectedReasonId,
    setSelectedReasonId,
  };
};

export default useCancelBoostCallEvent;
