import React, { useState, useEffect } from 'react';
import api from '../../../api';
import { VolunteerPreferences } from '../types';
import { Language } from '../../../types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from '../../../api';
import CloseIcon from '@mui/icons-material/Close';

import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
  SkillButton,
} from '../../../styling/buttons';
import { CustomTypography } from '../../../styling/generalStyling';

type Props = {
  data: VolunteerPreferences;
};

const LanguageTab = ({ data }: Props) => {
  const [languagesList, setLanguageList] = useState<Language[]>([]);
  const [language, setLanguage] = React.useState('');
  const [currentLanguagesNames, setCurrentLanguagesNames] = useState<
    Language[]
  >(data.languages);

  const handleSaveClick = async () => {
    var newLanguageList = currentLanguagesNames.map(
      (language) => language.code
    );
    newLanguageList.push(language);
    newLanguageList = newLanguageList.filter((value) => value.length > 0);
    try {
      await api.patch(`api/location/locations/me/`, {
        languages: newLanguageList,
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  const getLanguages = () => {
    return axios
      .get(`api/location/languages/?limit=1000`, {})
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  };
  const getLanguage = (languageCode: string, languagesList: Language[]) => {
    const result = languagesList.filter(
      (language) => language.code === languageCode
    );
    if (result[0]) return result[0];
    else return null;
  };
  useEffect(() => {
    (async () => {
      getLanguages().then((response) => {
        setLanguageList(response.data.results);
        const intersection = response.data.results.filter((lang: any) =>
          data.languages.includes(lang.code)
        );
        setCurrentLanguagesNames(intersection);
      });
    })();
  }, [data.languages]);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLanguage(event.target.value);
    const language = getLanguage(event.target.value.toString(), languagesList);
    if (
      language &&
      currentLanguagesNames.filter(
        (existingSelection) => existingSelection === language
      )[0] === undefined
    ) {
      setCurrentLanguagesNames((currentLanguagesNames) => [
        ...currentLanguagesNames,
        language,
      ]);
    }
  };

  return (
    <div className="mentoring-preferences__container">
      <div className="mentoring-preferences-title">
        {' '}
        What languages can you mentor in?
      </div>
      <div>
        {currentLanguagesNames
          ? currentLanguagesNames.map((value) => (
              <div className="mentoring-preferences-button">
                <SkillButton
                  onClick={() => {
                    setCurrentLanguagesNames(
                      currentLanguagesNames.filter(
                        (language) => language !== value
                      )
                    );
                  }}
                  endIcon={<CloseIcon />}
                >
                  {value.name}
                </SkillButton>
              </div>
            ))
          : null}
      </div>
      <Box sx={{ width: 240 }}>
        <FormControl fullWidth>
          <InputLabel id="language-select-label">
            <CustomTypography>Select a language</CustomTypography>
          </InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={language}
            label="Select a language"
            onChange={handleChange}
            sx={{ fontFamily: 'Gilroy' }}
          >
            {languagesList.length > 0
              ? languagesList.map((lang) => (
                  <MenuItem
                    sx={{
                      '&.MuiMenuItem-root': {
                        fontFamily: 'Gilroy',
                      },
                    }}
                    key={lang.code}
                    value={lang.code}
                  >
                    {lang.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </Box>
      <div className="update-preferences-buttons">
        <CustomProfileSaveButton
          disabled={currentLanguagesNames.length === 0}
          variant="contained"
          onClick={() => handleSaveClick()}
        >
          Save
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default LanguageTab;
