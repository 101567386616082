import { BoostCallEventsState } from './index';
import { BoostCallEvent } from '../../types';

export const getCompletedEvents = (boostCallEvents: BoostCallEvent[]) =>
  boostCallEvents
    .filter((event) => event.status === 'complete')
    .sort(
      (a, b) =>
        new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
    ); // sort by date (soonest first)

export const getCancelledEvents = (boostCallEvents: BoostCallEvent[]) =>
  boostCallEvents
    .filter((event) => event.status === 'cancelled')
    .sort(
      (a, b) =>
        new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
    ); // sort by date (soonest first)

export const getUpcomingEvents = (boostCallEvents: BoostCallEvent[]) =>
  boostCallEvents.filter((event) => event.status === 'scheduled');

export const setBoostCallEventStatusCancelled = (
  boostCallEvents: BoostCallEvent[],
  id: number
): BoostCallEvent[] =>
  boostCallEvents.map((boostCallEvent) =>
    boostCallEvent.id === id
      ? { ...boostCallEvent, status: 'cancelled' }
      : boostCallEvent
  );

//
export const setBoostCallEventRescheduleStatus = (
  boostCallEvents: BoostCallEvent[],
  id: number,
  status: BoostCallEventsState['rescheduleType'],
  rescheduleRequestTime?: string
) => {
  const proposeProps = {
    reschedule_status: 'pending',
    reschedule_request_time: rescheduleRequestTime,
    reschedule_requested_by: 'organisation',
  };
  const declineProps = {
    reschedule_status: 'declined',
    reschedule_request_time: null,
    reschedule_requested_by: null,
  };
  const acceptProps = {
    reschedule_status: 'rescheduled',
  };
  return boostCallEvents.map((event: BoostCallEvent) =>
    event.id === id
      ? {
          ...event,
          ...(status === 'propose'
            ? proposeProps
            : status === 'accept'
            ? acceptProps
            : status === 'decline'
            ? declineProps
            : {}),
        }
      : event
  );
};
