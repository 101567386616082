import React, { useState } from 'react';
import Modal from 'react-modal';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import useResendVerification from '../../apiHooks/auth/useResendVerification';

import styles from './verification-modal.module.scss';
import { DashboardEmptyButton } from '../../styling/buttons';

type Props = {
  email: string;
};

const VerificationModal = ({ email }: Props) => {
  const { resendVerification, resendVerificationError } =
    useResendVerification();
  const [verificationResent, setVerificationResent] = useState(false);
  const notificationTextVisible = verificationResent || resendVerificationError;

  const onResendClick = async () => {
    if (!email) return;
    setVerificationResent(false);
    try {
      await resendVerification({ email });
      setVerificationResent(true);
    } catch (err) {}
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 25, 0.45)',
      zIndex: 30,
    },
    content: {
      maxWidth: '630px',
      maxHeight: notificationTextVisible ? '355px' : '325px',
      margin: 'auto',
    },
  };

  return (
    <Modal
      isOpen={true}
      contentLabel="Verification"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <IoIosCheckmarkCircle
            className={styles.icon}
            size={32}
            fill={'#00AB6B'}
          />
          <h3 className={styles.title}>
            A verification link has been sent to your email address
          </h3>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.bodyText}>
            {
              "If you haven't received it, double-check that the email you entered is correct and/or check your spam folder."
            }
          </p>
          <p className={styles.bodyText}>
            {"If you're still having problems, "}
            <a
              className={styles.link}
              href={'https://www.digitalboost.org.uk/contact'}
            >
              {'contact us!'}
            </a>
          </p>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.resendText}>{'Need a new verification code?'}</p>
        </div>
        <div className={styles.buttonContainer}>
          <DashboardEmptyButton
            sx={{ width: '300px', maxWidth: '90%' }}
            variant="outlined"
            onClick={onResendClick}
          >
            Resend Verification Email
          </DashboardEmptyButton>
        </div>
        {notificationTextVisible && (
          <span
            className={`${styles.notificationText} ${
              resendVerificationError ? styles.errorText : ''
            }`}
          >
            {resendVerificationError
              ? resendVerificationError
              : 'Verification resent!'}
          </span>
        )}
      </div>
    </Modal>
  );
};

export default VerificationModal;
