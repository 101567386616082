import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  setGroupEventMasterclassTypeName,
  setGroupEventWebinarTypeName,
  setGroupEventWorkshopTypeName,
} from '../../apiHooks/groupSessionTypeNames';
import { selectPartnerProfile } from '../../apiHooks/partnerProfile';
import { setSessionTitle } from '../../apiHooks/sessionTitle';

import './nav.scss';

const Logo = () => {
  const partnerProfile = useSelector(selectPartnerProfile);

  const dispatch = useDispatch();

  const handleSetTitle = useCallback(
    (title: string) => {
      dispatch(setSessionTitle(title));
    },
    [dispatch]
  );
  let logoSrc = undefined;

  useEffect(() => {
    if (partnerProfile?.session_title) {
      handleSetTitle(partnerProfile.session_title);
    } else {
      handleSetTitle('1:1 Session');
    }

    if (partnerProfile?.masterclass_title) {
      dispatch(
        setGroupEventMasterclassTypeName(partnerProfile?.masterclass_title)
      );
    }
    if (partnerProfile?.webinar_title) {
      dispatch(setGroupEventWebinarTypeName(partnerProfile?.webinar_title));
    }
    if (partnerProfile?.workshop_title) {
      dispatch(setGroupEventWorkshopTypeName(partnerProfile?.workshop_title));
    }
  }, [partnerProfile, handleSetTitle, dispatch]);

  if (partnerProfile?.white_label && partnerProfile?.partner_logo_url)
    logoSrc = partnerProfile.partner_logo_url;
  else logoSrc = '/main-logo.png';
  return (
    <Link to="/dashboard/actions">
      <img className="nav__logo" src={logoSrc} alt="digital boost" />
    </Link>
  );
};

export default memo(Logo);
