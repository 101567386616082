import { createContext, useContext } from 'react';
import { AxiosError } from 'axios';
import { Taxonomy } from '../types';

export type TaxonomyContextType = {
  taxonomyData: Taxonomy[];
  taxonomyDataFormatted: any;
  taxonomyById: { [id: number]: Omit<Taxonomy, 'id'> };
  hasFinishedLoadingTaxonomy: boolean;
  taxonomyError: AxiosError<any> | undefined;
  refetchTaxonomy?: () => void;
};

export const TaxonomyContext = createContext<TaxonomyContextType>({
  taxonomyData: [],
  taxonomyDataFormatted: {},
  taxonomyById: {},
  hasFinishedLoadingTaxonomy: false,
  taxonomyError: undefined,
  refetchTaxonomy: () => {},
});
export const useTaxonomyContext = () => useContext(TaxonomyContext);
