import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { Tab, TabPanel, TabsList } from '../../styling/tabs';
import { displayIcon, tabs } from '../../components/Dashboard/utilities';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactTooltip from 'react-tooltip';

import useWindowSize from '../../hooks/useWindowSize';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { useGetProfile } from '../Account/functions';
import { Button } from '@mui/material';
import './style.scss';
import axios from '../../api';
import { useGetPreferences } from '../Preferences/functions';
import { Language, Taxonomies, Taxonomy } from '../../types';
import api from '../../api';
import { CustomShareProfilePaper } from '../../styling/backgrounds';
import { useSystemTogglesContext } from '../../context/SystemToggles';

const daysOfWeek: { [arg: number]: string } = {
  0: 'Mon',
  1: 'Tue',
  2: 'Wed',
  3: 'Thu',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun',
};

const orgBaseUrl = process.env.REACT_APP_ORG_URL;

const SharableProfile = () => {
  const newURL = orgBaseUrl?.replace('volunteer', 'organisation');
  const tab = '';
  let navigate = useNavigate();
  const { volunteerProfile } = useSelectProfileData();
  const profileData = useGetProfile();
  const preferencesData = useGetPreferences();

  const [isMobile, setIsMobile] = useState(false);
  const [aboutMeExpanded, setAboutMeExpanded] = useState<boolean>(false);
  const [currentLanguagesNames, setCurrentLanguagesNames] = useState<
    Language[]
  >([]);
  const [currentSkills, setCurrentSkills] = useState<Taxonomy[]>([]);
  const [copyText, setCopyText] = useState<string>('Copy');
  const [displayTabs, setDisplayTabs] = useState(tabs);
  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'Action Plans');
      setDisplayTabs(updatedTabs);
    }
  }, [showActionPlan]);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const getLanguages = () => {
    return axios
      .get(`api/location/languages/?limit=1000`, {})
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  };

  useEffect(() => {
    (async () => {
      getLanguages().then((response) => {
        const intersection = response.data.results.filter((lang: any) =>
          preferencesData?.languages?.includes(lang.code)
        );
        setCurrentLanguagesNames(intersection);
      });
    })();
  }, [preferencesData.languages]);

  useEffect(() => {
    var subCategories: Taxonomy[] = [];
    var currentSkillsList: Taxonomy[] = [];
    const getSkillsList = async () => {
      try {
        const response = await api.get<Taxonomies>(
          `api/taxonomy/interests/?limit=1000`,
          {}
        );
        subCategories = response.data.results.filter(
          (value) => value.parent !== null
        );

        if (volunteerProfile?.interests)
          volunteerProfile?.interests?.forEach((skillId: number) => {
            const skill = subCategories.filter(
              (category) => category.id === skillId
            )[0];
            if (skill) currentSkillsList.push(skill);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getSkillsList().then(() => {
      setCurrentSkills(currentSkillsList);
    });
  }, [volunteerProfile?.interests]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [currentSkills]);

  const copyToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigator.clipboard.writeText(
      `${newURL}volunteer-details?volunteer[]=${volunteerProfile?.id}&id=${volunteerProfile?.partner}`
    );
    setCopyText('Copied!');
  };

  const body = (
    <div className="vol-details-wrapper">
      <div className="vol-details-container">
        <div className="vol-details-header">
          <img
            src={profileData?.profile_picture}
            alt="volunteer profile"
            className="vol-details-photo"
          />
        </div>

        <div className="vol-details-info-container">
          <div className="vol-details-info-left">
            <div className="vol-details-name">
              {profileData?.first_name}{' '}
              {profileData?.last_name &&
                `${profileData?.last_name?.substring(0, 1)}.`}
            </div>
            <div className="vol-details-employer">
              {profileData?.current_role} | {profileData?.employer}
            </div>

            {profileData?.summary?.length &&
            profileData?.summary?.length > 0 ? (
              <>
                <div className="vol-details-label">About Me</div>
                {profileData?.summary && profileData?.summary?.length < 180 ? (
                  <div className="vol-details-summary">
                    {profileData?.summary}
                  </div>
                ) : (
                  <>
                    <div className="vol-details-summary">
                      {aboutMeExpanded
                        ? profileData?.summary
                        : `${profileData?.summary?.substring(0, 180)}...`}
                    </div>
                    <Button
                      onClick={() => setAboutMeExpanded(!aboutMeExpanded)}
                      className="vol-details-read-button"
                    >
                      {aboutMeExpanded ? 'Read less' : 'Read more'}
                    </Button>
                  </>
                )}
              </>
            ) : null}

            {currentLanguagesNames && currentLanguagesNames?.length > 0 ? (
              <>
                <div className="vol-details-label">Languages</div>
                <div className="vol-details-summary">
                  {currentLanguagesNames.map((l: Language, index: number) => (
                    <span key={`language-${index}`}>{`${l.name}${
                      currentLanguagesNames &&
                      index < currentLanguagesNames?.length - 1
                        ? ', '
                        : ''
                    }`}</span>
                  ))}
                </div>
              </>
            ) : null}
          </div>

          <div className="vol-details-info-right">
            <div className="vol-details-label">Availability</div>
            <div className="vol-details-day-wrapper">
              {Object.entries(daysOfWeek).map(([i, day], index) => (
                <div key={`${volunteerProfile?.id}-day-${index}`}>
                  <div
                    className={
                      !!volunteerProfile?.availability?.days?.includes(
                        Number(i)
                      )
                        ? 'vol-details-day-tag'
                        : 'vol-details-day-tag vol-details-day-tag-disabled'
                    }
                  >
                    {day}
                  </div>
                </div>
              ))}
            </div>

            <div className="vol-details-label">Time</div>
            <div className="vol-details-time">
              {preferencesData?.availability?.start_hour &&
                `${preferencesData?.availability?.start_hour < 10 ? '0' : ''}${
                  preferencesData?.availability?.start_hour
                }:${
                  preferencesData?.availability?.start_minute < 10 ? '0' : ''
                }${preferencesData?.availability?.start_minute}`}{' '}
              to{' '}
              {preferencesData?.availability?.end_hour &&
                `${preferencesData?.availability?.end_hour < 10 ? '0' : ''}${
                  preferencesData?.availability?.end_hour
                }:${preferencesData?.availability?.end_minute < 10 ? '0' : ''}${
                  preferencesData?.availability?.end_minute
                }`}
            </div>

            <div className="vol-details-divider" />

            <div className="vol-details-help">
              {profileData?.first_name} can help on the following topics. Please
              select one to discuss in this session.
            </div>

            <div className="vol-details-interests-wrapper">
              {currentSkills?.map((interest: any) => (
                <div className="vol-details-interests">
                  <div>
                    {interest?.name}{' '}
                    <span
                      className="vol-details-question"
                      data-tip={interest?.description}
                    >
                      <img
                        width="5px"
                        height="8px"
                        src="/question.png"
                        alt="question"
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="vol-details-share-container">
        <div>
          This is your profile as others see it. You can edit it{' '}
          <span onClick={() => navigate('/dashboard/account/profile')}>
            here
          </span>
        </div>
        <CustomShareProfilePaper className="vol-details-share-box">
          <button onClick={copyToClipboard}>
            <ContentCopyIcon sx={{ height: 16 }} />
            {copyText}
          </button>
          <p>
            Share this link to let learners request sessions directly with you.
          </p>
        </CustomShareProfilePaper>
      </div>
    </div>
  );

  return (
    <div className="container__dashboard-page">
      {!isMobile ? (
        <div>
          <TabsUnstyled
            value={tab}
            onChange={(event: React.SyntheticEvent, newValue: any) =>
              navigate(`/dashboard/${newValue}`)
            }
          >
            <TabsList>
              {displayTabs.map((tab: any) => (
                <Tab
                  key={tab.link}
                  value={tab.link}
                  sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                  {...a11yProps(0)}
                >
                  <div className="dashboard-tab-icon-label" key={tab.link}>
                    {displayIcon(tab.label)} {tab.label}
                  </div>
                </Tab>
              ))}
            </TabsList>
            <TabPanel value={tab} key={0} />
            <TabPanel value={tab} key={1} />
            <TabPanel value={tab} key={2} />
            <TabPanel value={tab} key={3} />
            <TabPanel value={tab} key={4} />
            <TabPanel value={tab} key={5} />
          </TabsUnstyled>
        </div>
      ) : null}
      <div className="dashboard-container-info">
        <ReactTooltip
          key="mentor-search-filters"
          className="tooltip"
          place={'top'}
          arrowColor={'#333333'}
          multiline={true}
        />
        {body}
      </div>
    </div>
  );
};

export default SharableProfile;
