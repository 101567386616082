import React from 'react';
import './youtube-modal.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
  youtubeId: string | null;
}

const YoutubeModal = ({ visible, onClose, youtubeId }: Props) => {
  return visible ? (
    <div className="youtube-overlay" onClick={onClose}>
      <div className="youtube-body" onClick={(e) => e.stopPropagation()}>
        <iframe
          src={`https://www.youtube.com/embed/${youtubeId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  ) : null;
};

export default YoutubeModal;
