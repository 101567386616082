import React, { useEffect, useState } from 'react';
import JitsiContainer from '../../components/JitsiContainer';
import Button from '../../atoms/Button';
import { getUrlParam, convertDBUrlToJitsi } from '../../utils/url';
import { redirectProtecedRoutesToLogin } from '../../utils/auth';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import { getCallDetails } from '../../apiCalls/boostCalls';
import './boost-call.scss';

const BoostCall = () => {
  const { toggles, hasFinishedLoadingToggles } = useSystemTogglesContext();
  const jitsiRoomName = getUrlParam('jitsi_room_name') as string;
  const customCallUrl = getUrlParam('custom_call_url') as string;

  if (customCallUrl) {
    window.location.href = customCallUrl;
  }

  if (hasFinishedLoadingToggles && !toggles['DB_HOSTED_CALLS']) {
    window.location.replace(convertDBUrlToJitsi(window.location.href));
  }
  const callId = getUrlParam('call_id') as string;
  const defaultJitsiOptions = {
    roomName: jitsiRoomName,
    requireDisplayName: true,
  };
  const [hasLoaded, setHasLoaded] = useState({});
  const [callDetails, setCallDetails] = useState<any>();
  const [jitsiOptions, setJitsiOptions] = useState<any>(defaultJitsiOptions);

  useEffect(() => {
    if (customCallUrl) return;
    (async () => {
      if (callId) {
        try {
          const callDetailsData = await getCallDetails({
            id: callId,
            type: 'events',
          });
          setCallDetails(callDetailsData);
          setJitsiOptions({
            ...defaultJitsiOptions,
            userInfo: {
              displayName: callDetailsData.volunteer.first_name,
            },
          });
          setHasLoaded(true);
        } catch (err) {
          // If a different user tries to access this call, this will return a 404
          redirectProtecedRoutesToLogin();
        }
      } else {
        // Do we want to allow users to do call with no callId?
        // call_id url param should always be there in theory, but don't want to kick them out if it's not...?
        setHasLoaded(true);
      }
    })();
    // eslint-disable-next-line
  }, [callId]);

  if (customCallUrl) {
    return (
      <div className="boost-call_container">
        <div className="boost-call__custom-call-container">
          <h3>If your call doesn't open</h3>
          <Button
            buttonType="primary"
            onClickFunction={() => window.open(customCallUrl, 'custom_call')}
            className="boost-call__custom-call-button"
            text="Click here"
          />
        </div>
      </div>
    );
  }

  if (!jitsiRoomName) {
    return null;
  }

  const canInitialiseJitsi = jitsiOptions && (!callId || callDetails);

  return hasLoaded ? (
    <div className="boost-call__containerr">
      <div className="boost-call__call-wrapper">
        {canInitialiseJitsi && <JitsiContainer jitsiOptions={jitsiOptions} />}
      </div>
    </div>
  ) : (
    <div className="boost-call__loading-container">
      <img
        className="boost-call__loading-spinner"
        src="/loading.gif"
        alt="loading"
      />
    </div>
  );
};

export default BoostCall;
