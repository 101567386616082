import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import type { Partner } from '../../types';

/* --- SLICE --- */

interface PartnerProfileState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  profile?: Partner | null;
  error?: string | null;
}

const initialState = {
  status: 'init',
  profile: null,
  error: null,
} as PartnerProfileState;

const PartnerProfileSlice = createSlice({
  name: 'PartnerProfile',
  initialState,
  reducers: {
    clearPartnerProfile: (state) => {
      return initialState;
    },
    setPartnerProfile: (state, action) => {
      state.profile = action.payload;
    },
    setPartnerProfileStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

/* --- SELECTORS --- */

// fetchPartnerProfile
export const selectPartnerProfile = (state: RootState) =>
  state.partnerProfile.profile;
export const selectPartnerProfileStatus = (state: RootState) =>
  state.partnerProfile.status;
export const selectHasNotFetchedPartnerProfile = (state: RootState) =>
  selectPartnerProfileStatus(state) === 'init';
export const selectHasFinishedLoadingPartnerProfile = (state: RootState) =>
  selectPartnerProfileStatus(state) === 'fulfilled' ||
  selectPartnerProfileStatus(state) === 'rejected';

/* --- ACTIONS --- */

export const {
  clearPartnerProfile,
  setPartnerProfile,
  setPartnerProfileStatus,
} = PartnerProfileSlice.actions;

export default PartnerProfileSlice.reducer;
