import Cookie from 'js-cookie';
import { useAxios } from '../../api';
import useFetchProfileData from './useFetchProfileData';
import { getErrorMessageFromResponse } from '../../utils/error';
import useSelectProfileData from '../../hooks/useSelectProfileData';

type VerifyProps = {
  id: string;
  token: string;
};

const useVerifyAccount = () => {
  const { fetchAllProfileData } = useSelectProfileData();
  const { fetchAllProfileDataAndUpdateContext } = useFetchProfileData();
  const [{ data, loading, error }, executeVerify] = useAxios(
    {
      url: `api/core/verify/`,
      method: 'POST',
      headers: {},
    },
    { manual: true }
  );

  const typedVerify = async ({ id, token }: VerifyProps) => {
    const verifyResponse = await executeVerify({
      data: {
        user_id: id,
        confirmation_token: token,
      },
    });

    // set cookie that expires in a day
    Cookie.set('refreshTokenDB', verifyResponse.data.refresh, { expires: 90 });
    Cookie.set('accessTokenDB', verifyResponse.data.access, { expires: 1 });
    // Fetch UserProfile, VolProfile, Location
    fetchAllProfileDataAndUpdateContext();

    await fetchAllProfileData();

    return verifyResponse;
  };

  const invalidToken = error
    ? !!error.response?.data.includes('Token is invalid')
    : false;
  const userNotFoundError = error
    ? !!error.response?.data.includes('User not found')
    : false;
  const alreadyVerified = error
    ? !!error.response?.data.includes('Account already verified')
    : false;

  return {
    verify: typedVerify,
    verifyResponseData: data,
    isLoadingVerify: loading,
    verifyError: error ? getErrorMessageFromResponse(error) : null,
    invalidToken,
    alreadyVerified,
    userNotFoundError,
  };
};

export default useVerifyAccount;
