import moment from 'moment';

export const formatDate = (dateString: string) => {
  const date = moment(dateString);

  const formattedDate = date.format('DD-MM-YYYY');
  return formattedDate;
};

export const truncateFileName = (word: string) => {
  if (word.length > 15) {
    return word.slice(0, 10) + '...' + word.slice(-3);
  }
  return word;
};
