import useFetchPartner from '../apiHooks/auth/useFetchPartner';
import { getUrlParam } from './url';
import Cookie from 'js-cookie';
import useSelectProfileData from '../hooks/useSelectProfileData';
import { useNavigate } from 'react-router-dom';
export const getAccessTokenCookie = () => Cookie.get('accessTokenDB');

export const redirectToF4S = () => {
  const currentUrl = window.location.href;

  if (currentUrl.includes('m4g')) {
    const newUrl = currentUrl.replace('m4g', 'f4s');
    window.location.href = newUrl;
  }
};

export const useRedirectToPartnerWebsite = async () => {
  const partnerId = getUrlParam('id') as string;

  const { fetchPartnerResponseData: partner, isLoadingPartner } =
    useFetchPartner({
      id: parseInt(partnerId) || '',
      setCookies: false,
    });

  const loggedIn = getAccessTokenCookie();
  const { volunteerProfile, hasFinishedLoadingVolunteerProfile } =
    useSelectProfileData();
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const navigate = useNavigate();
  const encodedPath = encodeURIComponent(url.pathname + url.search + url.hash);
  const currentSubdomain = url.hostname.split('.')[0];

  if (
    currentSubdomain !== 'localhost' &&
    window.location.href.toString().indexOf('/redirect') === -1
  ) {
    // Check if the user is not logged in
    // Redirect the user to the partner's website
    if (
      !loggedIn &&
      !isLoadingPartner &&
      partner?.white_label &&
      partner.subdomain &&
      currentSubdomain !== partner.subdomain
    ) {
      navigate(`/redirect?community=${partner.subdomain}&path=${encodedPath}`);
    }
    //Check if the user is logged in and partner is whitelabel
    else if (
      hasFinishedLoadingVolunteerProfile &&
      volunteerProfile &&
      loggedIn &&
      volunteerProfile.partnerInfo?.white_label &&
      volunteerProfile.partnerInfo?.subdomain
    ) {
      // Check if the current URL subdomain is different from the partner subdomain
      if (currentSubdomain !== volunteerProfile.partnerInfo?.subdomain) {
        navigate(
          `/redirect?community=${volunteerProfile.partnerInfo.subdomain}&path=${encodedPath}`
        );
      }
    } else if (
      loggedIn &&
      hasFinishedLoadingVolunteerProfile &&
      volunteerProfile &&
      volunteerProfile.partner === null &&
      currentSubdomain !== 'volunteer'
    ) {
      if (window.location.href.indexOf('group-event-details') === -1)
        navigate(`/redirect?community=digitalboost&path=${encodedPath}`);
    } else if (
      loggedIn &&
      hasFinishedLoadingVolunteerProfile &&
      volunteerProfile &&
      volunteerProfile.partnerInfo &&
      !volunteerProfile.partnerInfo?.white_label &&
      currentSubdomain !== 'volunteer'
    ) {
      navigate(`/redirect?community=digitalboost&path=${encodedPath}`);
    }
  }
};
