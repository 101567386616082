import { FormControl, NativeSelect } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, Tooltip } from 'recharts';

import useWindowSize from '../../../hooks/useWindowSize';

interface Props {
  totalSessions: any[];
}

export default function NumberOfMinutesMentoredChart({ totalSessions }: Props) {
  var year = moment().year();
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const [selectedYear, setSelectedYear] = useState<number>(year);
  const [displayData, setDisplayData] = useState<any>([]);
  const [statsNumber, setMinutesMentoredNumber] = useState(0);
  const [statsThisMonth, setMinutesMentoredThisMonth] = useState(0);

  useEffect(() => {
    const newData = [
      {
        name: 'J',
        duration: 0,
        month: 1,
      },
      {
        name: 'F',
        duration: 0,
        month: 2,
      },
      {
        name: 'M',
        duration: 0,
        month: 3,
      },
      {
        name: 'A',
        duration: 0,
        month: 4,
      },
      {
        name: 'M',
        duration: 0,
        month: 5,
      },
      {
        name: 'J',
        duration: 0,
        month: 6,
      },
      {
        name: 'J',
        duration: 0,
        month: 7,
      },
      {
        name: 'A',
        duration: 0,
        month: 8,
      },
      {
        name: 'S',
        duration: 0,
        month: 9,
      },
      {
        name: 'O',
        duration: 0,
        month: 10,
      },
      {
        name: 'N',
        duration: 0,
        month: 11,
      },
      {
        name: 'D',
        duration: 0,
        month: 12,
      },
    ];
    const d = new Date();
    let month = d.getMonth() + 1;
    const updateData = () => {
      totalSessions
        .filter((item) => Number(item.year) === Number(selectedYear))
        .forEach(function (item, index) {
          var result = newData.find(
            (dataEntry: { month: number }) => dataEntry.month === item.month
          );
          if (result) result.duration = item.duration;
        });
      setMinutesMentoredNumber(
        newData.reduce((n: any, { duration }: any) => n + duration, 0)
      );
      const minutesMentored = newData.find(
        (dataEntry: { month: number }) => dataEntry.month === month
      )?.duration;
      setMinutesMentoredThisMonth(minutesMentored ? minutesMentored : 0);
      setDisplayData(newData);
    };
    updateData();
  }, [selectedYear, totalSessions]);

  var yearsToDisplay = [];
  for (var i = 1; i < year - 2018; i++) yearsToDisplay.push(2019 + i);

  const handleChange = (event: any) => {
    setSelectedYear(event.target.value);
  };

  return (
    <div className="chart-paper-box">
      <FormControl sx={{ alignItems: 'flex-end', width: '100%' }}>
        <NativeSelect
          onChange={handleChange}
          defaultValue={selectedYear}
          disableUnderline
          inputProps={{
            id: 'uncontrolled-native',
          }}
          sx={{
            fontSize: 'Gilroy',
            fontWeight: 'bold',
            color: '#2f80ed',
            textTransform: 'none',
          }}
        >
          {yearsToDisplay.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <div className="tootltip-statistics">
        <div className="tootltip-statistics-icon">
          <div className="minutes-mentored-photo-card-container">
            <img
              className="minutes-mentored-photo"
              src={'/security-time.png'}
              alt={'security-time'}
            />
          </div>
          <div className="tootltip-statistics-icon-info">
            <div>{statsNumber}</div>
            <div className="chart-text-container">
              <div className="chart-bold-text">Minutes mentored</div>
              <div className="chart-text">
                This month: {statsThisMonth ? statsThisMonth : 0} min
              </div>
            </div>
          </div>
        </div>
      </div>
      <AreaChart
        width={isMobile ? 300 : 459}
        height={245}
        data={displayData}
        margin={{
          top: isMobile ? 100 : 25,
          right: 30,
          left: 20,
          bottom: 0,
        }}
      >
        <XAxis dataKey="name" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="duration"
          stroke="#06a764"
          fill="#b2d5ef"
        />
      </AreaChart>
    </div>
  );
}
