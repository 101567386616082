import { useAxios } from '../../api';
import { GroupEventRequests } from '../../types';

type Props = {
  id?: string | number;
  status?: 'scheduled' | 'invited';
};

const useGetGroupEventRequests = ({ id, status }: Props) => {
  const idParam = id ? { id } : {};
  const statusParam = status ? { status } : {};

  const [{ data, loading, error }, refetch] = useAxios<GroupEventRequests>({
    url: 'api/groupevent/groupeventrequest/',
    params: {
      limit: 100,
      ...statusParam,
      ...idParam,
    },
  });
  return {
    groupEventRequestData: data && data.results,
    hasFinishedLoadingGroupEventRequest: !loading,
    groupEventRequestError: error,
    refetchGroupEventRequest: refetch,
  };
};

export default useGetGroupEventRequests;
