import React from 'react';

import './style.scss';
import { MessagingCentreButton } from '../../../styling/buttons';

interface UnselectedMessageProps {
  onClickButton?: () => void;
}

const UnselectedMessage = ({ onClickButton }: UnselectedMessageProps) => (
  <div className="unselected-message-container">
    <img
      src="unselected-messages.png"
      alt="Messages"
      className="unselected-message-image"
    />

    <div className="unselected-message-title">Messages</div>
    <div className="unselected-message-subtitle">
      Messages can be sent to learners once a session request is accepted.
    </div>
    <MessagingCentreButton onClick={onClickButton}>
      Write a message
    </MessagingCentreButton>
  </div>
);

export default UnselectedMessage;
