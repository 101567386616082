import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FaCalendarTimes } from 'react-icons/fa';
import './confirm-call.scss';
import FullScreenLoading from '../../components/FullScreenLoading';
import useGetBoostCallEventData from '../../apiHooks/boostCalls/useGetBoostCallEventData';
import { getUrlParam } from '../../utils/url';

import styles from './cancel-call.module.scss';
import useCancelBoostCallEvent from '../../apiHooks/boostCallEvents/useCancelBoostCallEvent';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../styling/buttons';
import { CustomTypography } from '../../styling/generalStyling';
import { cancelBoostCallEvent } from '../../api/boostCallEvents';

const CancelCall = () => {
  const navigate = useNavigate();
  const [callId, setCallId] = useState<null | number>(null);
  const [selectedReasonId, setSelectedReasonId] = useState<undefined | number>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<undefined | number>(
    undefined
  );
  const [callCancelled, setCallCancelled] = useState<undefined | boolean>(
    undefined
  );
  const [reasonText, setReasonText] = useState<undefined | string>(undefined);
  useEffect(() => {
    const id = Number(getUrlParam('id') as string);
    setCallId(id);
  }, [setCallId]);

  // apiHooks
  const { boostCallEventData, isLoadingBoostCallEventData } =
    useGetBoostCallEventData({ id: callId });
  const { status: callStatus } = boostCallEventData || {};
  const cancelBoostCall = () => {
    cancelBoostCallEvent({
      id: callId || 0,
      setErrorMessage,
      cancellationReasonId: selectedReasonId || -1,
      message: reasonText,
    }).then(() => setCallCancelled(true));
  };

  const { hasFinishedLoadingCancellationReasons, cancellationReasons } =
    useCancelBoostCallEvent({ id: callId || 0 });

  if (!hasFinishedLoadingCancellationReasons || isLoadingBoostCallEventData) {
    return <FullScreenLoading />;
  }
  if (callStatus === 'cancelled' || callStatus === 'expired') {
    return (
      <div className="cancel-call-container">
        <CustomTypography
          sx={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'center' }}
        >
          {' '}
          Thank you
        </CustomTypography>
        <div>
          <CustomTypography sx={{ textAlign: 'center' }}>
            We confirm we have received your cancellation request and that the
            meeting will no longer go ahead.
          </CustomTypography>
          <CustomTypography sx={{ textAlign: 'center' }}>
            Please make sure you remove the session from your calendar.
          </CustomTypography>
        </div>
        <div className={styles.optionsButtons}>
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '250px', alignSelf: 'center' }}
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Back to Dashboard
          </DashboardFilledButton>
        </div>
      </div>
    );
  }

  if (callCancelled) {
    return (
      <div className="cancel-call-container">
        <CustomTypography
          sx={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'center' }}
        >
          You have successfully cancelled the session
        </CustomTypography>
        <CustomTypography sx={{ textAlign: 'center' }}>
          We have sent an email notifying{' '}
          {boostCallEventData?.organisation.first_name || 'your mentee'}.
        </CustomTypography>
        <div>
          <CustomTypography sx={{ textAlign: 'center' }}>
            <FaCalendarTimes size={15} /> Please make sure you remove the
            session from your calendar.
          </CustomTypography>
        </div>
        <div className="cancel-call-container-buttons">
          <DashboardFilledButton
            variant="contained"
            sx={{
              width: '250px',
            }}
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Back to Dashboard
          </DashboardFilledButton>
          <DashboardEmptyButton
            variant="outlined"
            sx={{
              width: '280px',
            }}
            onClick={() => {
              navigate('/dashboard/preferences/skills');
            }}
          >
            Preferences
          </DashboardEmptyButton>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h2 className={styles.header}>We get that life happens.</h2>
        <div className={styles.container}>
          <div>
            {!!errorMessage && <h1 className={styles.error}>{errorMessage}</h1>}

            <p className={styles.text}>
              Please let{' '}
              {boostCallEventData?.organisation.first_name || 'your mentee'}{' '}
              know why you're cancelling.
            </p>
            <br />
            <br />
            <div className={styles.optionsButtons}>
              {cancellationReasons.map((reason, i) => {
                return (
                  <label key={i}>
                    <input
                      type="radio"
                      value={reason.id}
                      checked={selectedReasonId === reason.id}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSelectedReasonId(reason.id)
                      }
                    />
                    <span>{reason.description}</span>
                  </label>
                );
              })}

              <p className={styles.text} style={{ marginTop: '20px' }}>
                Feel free to leave a message for{' '}
                {boostCallEventData?.organisation.first_name || 'your mentee'}.{' '}
                <span className={styles.reasonText}>[optional]</span>
              </p>
              <textarea
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setReasonText(e.target.value)
                }
              />
              {/* {errorCancellationReason && (
                <p className={styles.textError}>
                  Please select a cancellation reason.
                </p>
              )} */}

              <DashboardFilledButton
                variant="contained"
                sx={{
                  width: '200px',
                  marginTop: '20px',
                  alignSelf: 'flex-end',
                }}
                onClick={cancelBoostCall}
                disabled={!selectedReasonId}
              >
                Cancel Call
              </DashboardFilledButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CancelCall;
