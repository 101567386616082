import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton } from '@mui/material';

import SelectAvailability from '../../components/RescheduleUpcomingCard/selectAvailability';
import SelectTime from '../../components/RescheduleUpcomingCard/selectTime';
import moment from 'moment';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { TimeSlot } from '../../types';
import { defaultTimeFormat } from '../../utils/constants';
import { displayTime } from '../../utils/time';
import { DashboardFilledButton } from '../../styling/buttons';

type Props = {
  event: { invite: any; organisation: any };
  proposeNewTimeModal: boolean;
  setProposeNewTimeModal: any;
  setNewTime: any;
};

const ProposeNewTime = ({
  event,
  proposeNewTimeModal,
  setProposeNewTimeModal,
  setNewTime,
}: Props) => {
  const { volunteerProfile, userLocation } = useSelectProfileData();
  const myVolunteerId = volunteerProfile?.id;

  const [newDate, setNewDate] = useState('');
  const [newTime, setNewDateTime] = useState('');
  const { timezone } = userLocation || {};

  const closeReschedule = () => {
    setProposeNewTimeModal(false);
  };

  const getHourAndMinute = (time: string) => {
    const hourMinute = time.split(':');
    return hourMinute;
  };
  const [loadingButton, setLoadingButton] = useState(false);

  const setTime = async () => {
    setLoadingButton(true);
    var updatedDate = moment(newDate);
    const updatedTime = getHourAndMinute(newTime);
    updatedDate.add(updatedTime[0], 'hours');
    updatedDate.add(updatedTime[1], 'minutes');
    setNewTime(updatedDate);

    closeReschedule();
    setLoadingButton(false);
  };
  return (
    <div>
      <Modal
        open={proposeNewTimeModal}
        onClose={closeReschedule}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="reschedule-box-container">
          <div className="reschedule-close-dialog-icon">
            <IconButton
              color="primary"
              onClick={closeReschedule}
              sx={{ padding: '0px' }}
            >
              <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
            </IconButton>
          </div>
          <div className="reschedule-card-container">
            <div className="reschedule-card-title">
              Propose new time to {event.organisation?.first_name}
            </div>
            <div className="request-help-text">
              Original time(s) proposed:
              <div className="propose-new-time-paper-time-slots">
                {event.invite?.time_slots
                  .filter(
                    (timeSlot: { volunteer: null }) =>
                      timeSlot.volunteer === null ||
                      timeSlot.volunteer === myVolunteerId
                  )
                  .map((timeSlot: TimeSlot) => (
                    <div className="request-help-text">
                      {displayTime(
                        timeSlot.start_time,
                        timezone,
                        `DD MMM ${defaultTimeFormat}`
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Divider light />

            <div className="reschedule-card-text"> Select a date and time</div>
            <div className="reschedule-card-date-time-reason">
              <div className="reschedule-card-date-time">
                <SelectAvailability setNewDate={setNewDate} />
                <SelectTime
                  setNewTime={setNewDateTime}
                  newTime={newTime}
                  selectedDay={newDate}
                />
              </div>
            </div>
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '190px' }}
              disabled={newDate.length < 1 || newTime.length < 1}
              onClick={() => setTime()}
            >
              Confirm{' '}
              {loadingButton ? (
                <CircularProgress
                  color="success"
                  sx={{ height: '10px', weight: '10px' }}
                />
              ) : (
                false
              )}
            </DashboardFilledButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProposeNewTime;
