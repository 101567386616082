import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import './resources-search-filters.scss';
import RadioButton from '../RadioButton';
import ReactTooltip from 'react-tooltip';
import { Taxonomy } from '../../types';

interface Props {
  taxonomy: Taxonomy[];
  addRemoveSkillId: any;
  selectedSkillList: number[];
  setVisibleIdList: Function;
  visibleIdList: (number | string)[];
}

const ResourcesSearchFilters = ({
  taxonomy,
  addRemoveSkillId,
  selectedSkillList,
  setVisibleIdList,
  visibleIdList,
}: Props) => {
  const sortItems = (array: Taxonomy[] | undefined) => {
    return (
      array &&
      array.sort((a, b) =>
        a.order === b.order ? 0 : a.order > b.order ? 1 : -1
      )
    );
  };

  const makeVisible = (skillId: number | string | string) => {
    let newVisibleList: (number | string)[] = [];

    if (visibleIdList.includes(skillId)) {
      newVisibleList = [];
    } else {
      newVisibleList.push(skillId);
    }

    setVisibleIdList(newVisibleList);

    if (skillId > 13 && skillId !== 61) {
      addRemoveSkillId(skillId);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [visibleIdList]);

  return (
    <Grid container className="resources-search-filters">
      <ReactTooltip
        key="resources-search-filters"
        className="tooltip"
        place={'top'}
        arrowColor={'#333333'}
        multiline={true}
      />

      <Grid className="resources-skill-list">
        {sortItems(taxonomy)?.map((skill) => (
          <Grid key={`skill-${skill.id}`}>
            <Grid
              container
              display="inline-box"
              alignItems="center"
              gap="9px"
              className={`parent-skill ${
                visibleIdList.includes(skill.id) ? 'parent-selected' : ''
              }`}
              onClick={() => makeVisible(skill.id)}
              key={`skill-parent${skill.id}`}
              data-cy={`skill-parent-${skill.id}`}
            >
              {visibleIdList.includes(skill.id) ? (
                <img src="/minus.png" className="minus" alt="minus" />
              ) : (
                <img src="/plus.png" className="plus" alt="plus" />
              )}
              <span
                className={visibleIdList.includes(skill.id) ? 'selected' : ''}
              >
                {skill.name}
              </span>
              <span className="question" data-tip={skill.description}>
                <img
                  width={'5px'}
                  height="8px"
                  src="/question.png"
                  alt="question"
                  onClick={(e) => e.stopPropagation()}
                />
              </span>
            </Grid>

            {visibleIdList.includes(skill.id) && (
              <Grid
                container
                direction="column"
                key={`container-${skill.id}`}
                className="container"
              >
                {sortItems(skill.child)?.map((childSkill: any) => {
                  return (
                    <div
                      key={childSkill.id}
                      className="child-skill"
                      onClick={() => addRemoveSkillId(childSkill.id)}
                      data-cy={`child-skill-${childSkill.id}`}
                    >
                      <RadioButton
                        selected={selectedSkillList.includes(childSkill.id)}
                      />
                      <div
                        className={
                          selectedSkillList.includes(childSkill.id)
                            ? 'selected-label'
                            : ''
                        }
                      >
                        {childSkill.name}{' '}
                        <span
                          className="question"
                          data-tip={childSkill.description}
                        >
                          <img
                            width="5px"
                            height="8px"
                            src="/question.png"
                            alt="question"
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ResourcesSearchFilters;
