import { OrganisationProfile } from '../types';

export const toTitleCase = (s: string): string =>
  s.replace(/^(.)|\s+(.)/g, (s: any, c: any, d: any) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );

export const replaceUnderscoreWithSpaces = (s: string): string =>
  s.replace(/_/g, ' ');

export const getOrgTypeString = (orgProfile: Partial<OrganisationProfile>) => {
  return `${
    orgProfile.org_type === 'health_service' ||
    orgProfile.org_type === 'unemployed_furloughed'
      ? ''
      : 'A'
  }${orgProfile.org_type === 'educational_institute' ? 'n' : ''}${
    orgProfile.org_type
      ? ` ${toTitleCase(orgProfile.org_type.split('_').join(' '))} `
      : ''
  }`;
};

export const removeWhiteSpace = (inputString: string) => {
  // Use a regular expression to replace all white spaces with an empty string
  return inputString.replace(/\s/g, '');
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export const capitalizeWords=(input:string)=>{
  return input.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
};
export const formatToOneDecimal = (num: number) => {
  return num.toFixed(1);
};

export const roundToNextWhole = (num: number) => {
  return Math.ceil(num);
};
