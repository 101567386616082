import apiInstance from '../api';
import { getAccessToken, getRefreshToken } from '../utils/auth';
import { UserProfile, VolunteerProfile, UserLocation, Partner } from '../types';

// Note: We are gradually moving away from individual axios calls toward calls made with axios-hooks
// axios-hooks allows for automatica management fo call-state (e.g. loading, error etc.), as well as grouping related business logic together
// As such new api calls should be created using axios-hooks - not in the /apiCalls/ directory
// See /apiHooks/ directory for examples

export const getUserProfile = async () => {
  const response = await apiInstance.get<UserProfile>(`api/core/users/me/`);
  if (response.status === 404) throw new Error('User Details not found');
  return response.data;
};

export const getVolunteerProfile = async () => {
  if (!!getAccessToken() || !!getRefreshToken()) {
    const response = await apiInstance.get<VolunteerProfile>(
      `api/volunteer/volunteers/me/`
    );
    if (response?.data.partner) {
      const partnerResponse = await apiInstance.get<Partner>(
        `api/partner/partners/${response.data.partner}/`
      );
      response.data.partnerInfo = partnerResponse.data;
    }
    return response.data;
  }
};

export const getUserLocation = async () => {
  if (!!getAccessToken() || !!getRefreshToken()) {
    const response = await apiInstance.get<UserLocation>(
      `api/location/locations/me/`
    );
    return response.data;
  }
};

export type FetchAllProfileData = () => Promise<{
  userProfileResult: PromiseSettledResult<UserProfile | undefined>;
  volunteerProfileResult: PromiseSettledResult<VolunteerProfile | undefined>;
  userLocationResult: PromiseSettledResult<UserLocation | undefined>;
}>;

export const fetchAllProfileData: FetchAllProfileData = async () => {
  const [userProfileResult, volunteerProfileResult, userLocationResult] =
    await Promise.allSettled([
      getUserProfile(),
      getVolunteerProfile(),
      getUserLocation(),
    ]);

  return {
    userProfileResult,
    volunteerProfileResult,
    userLocationResult,
  };
};
