import { useSystemTogglesContext } from '../context/SystemToggles';
import useWindowSize from '../hooks/useWindowSize';
import { BoostCallEvent, GroupEvent } from '../types';
import { validateUrl } from '../utils/url';

const baseUrl = process.env.REACT_APP_BASE_URL;

type Props = {
  callInfo?: BoostCallEvent | GroupEvent;
};

type ReturnProps = {
  url: string;
  joinCall: () => void;
};

export const useGetJoinCall = ({ callInfo }: Props): ReturnProps => {
  const { toggles } = useSystemTogglesContext();
  const { width, height } = useWindowSize();
  const {
    call_url: callUrl,
    call_url_v2: callUrlV2,
    id,
  } = (callInfo as BoostCallEvent) || {};
  if (!callInfo || !callUrl) return { joinCall: () => {}, url: '' };

  let newCallUrl = callUrl;
  if (!newCallUrl.match(/^[a-zA-Z]+:\/\//)) {
    newCallUrl = 'http://' + newCallUrl;
  }
  if (!validateUrl(newCallUrl)) {
    return { joinCall: () => {}, url: '' };
  }

  const { hostname, pathname = '' } = new URL(newCallUrl) || {};

  const newWindowDimensions = `width=${Math.ceil(
    width * 0.98
  )},height=${Math.ceil(height * 0.98)}`;

  const baseUrlNoTrailingSlash = baseUrl?.replace(/\/$/, '');

  const groupEventUrl = !!(callInfo as GroupEvent).type
    ? `${baseUrlNoTrailingSlash}/group-event-call?custom_call_url=${encodeURI(
        newCallUrl
      )}&id=${(callInfo as GroupEvent).id}`
    : '';

  const url = !toggles['DB_HOSTED_CALLS']
    ? newCallUrl
    : !!(callInfo as GroupEvent).type
    ? groupEventUrl
    : callUrlV2
    ? `${baseUrlNoTrailingSlash}${callUrlV2}`
    : newCallUrl;

  const joinCall = () => {
    if (toggles['DB_HOSTED_CALLS']) {
      if (!!(callInfo as GroupEvent).type) {
        return window.open(groupEventUrl, '_blank', newWindowDimensions);
      }
      if (callUrlV2) {
        const isJitsi = /.*[?&](jitsi_room_name)/g.test(callUrlV2);
        if (isJitsi) {
          window.open(callUrlV2, '_blank', newWindowDimensions);
        } else {
          window.open(callUrlV2);
        }
      } else if (hostname === 'meet.jit.si') {
        const roomName = pathname.replace(/\/+$/, '');
        window.open(
          `/boost-call?jitsi_room_name=${roomName}&call_id=${id}`,
          '_blank',
          newWindowDimensions
        ); // convert JITSI url to DB url
      } else {
        window.open(
          `/boost-call?custom_call_url=${encodeURI(newCallUrl)}&call_id=${id}`,
          '_blank',
          newWindowDimensions
        ); // handle for all other services
      }
    } else {
      window.open(newCallUrl);
    }
  };
  return { joinCall, url };
};

export default useGetJoinCall;
