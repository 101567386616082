import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../api';
import { validateUrl, getUrlParam } from '../../utils/url';
import { getErrorMessageFromResponse } from '../../utils/error';
import { BoostCallEvent } from '../../types';

type Props = {
  id: number | string | null;
  defaultCallProvider: string;
};

export type AcceptBoostCallRequestReturnProps = {
  acceptBoostCallRequest: () => void;
  acceptAndRedirect: (path: string) => void;
  acceptBoostCallRequestData: BoostCallEvent | undefined;
  isLoadingAcceptBoostCallRequest: boolean;
  acceptBoostCallRequestError: any;
  selectedProvider: string;
  setSelectedProvider: (arg: string) => void;
  callUrl: string;
  setCallUrl: (arg: string) => void;
  selectedTimeSlot: number | undefined;
  setSelectedTimeSlot: (arg: number) => void;
  showCallUrlInput: boolean;
};

const useAcceptBoostCallRequest = ({
  id,
  defaultCallProvider,
}: Props): AcceptBoostCallRequestReturnProps => {
  const navigate = useNavigate();
  const timeSlotIdParam = getUrlParam('timeslotid') as string;
  const [selectedProvider, setSelectedProvider] =
    useState<string>(defaultCallProvider);
  const [callUrl, setCallUrl] = useState('');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number | undefined>(
    +timeSlotIdParam
  );
  const [validationError, setValidationError] = useState<string | null>();
  const showCallUrlInput =
    !!selectedTimeSlot && !!selectedProvider && selectedProvider !== '3'; // Jitsi is '3'

  const [{ data, loading, error }, executeCall] = useAxios<BoostCallEvent>(
    {
      url: `api/event/boostcallrequests/${id}/acceptinvite/`,
      method: 'POST',
    },
    { manual: true }
  );

  const acceptBoostCallRequest = useCallback(async () => {
    setValidationError(null);
    if (callUrl && !validateUrl(callUrl)) {
      const urlError = 'Please enter a valid URL';
      setValidationError(urlError);
      throw new Error(urlError);
    }

    const jitsiOption = '3';

    return executeCall({
      data: {
        time_slot: selectedTimeSlot,
        call_provider: +selectedProvider,
        ...(callUrl && selectedProvider !== jitsiOption
          ? { call_url: callUrl }
          : {}),
      },
    });
  }, [callUrl, executeCall, selectedTimeSlot, selectedProvider]);

  useEffect(() => {
    if (defaultCallProvider) setSelectedProvider(defaultCallProvider);
  }, [defaultCallProvider]);

  const acceptAndRedirect = async (path: string) => {
    try {
      await acceptBoostCallRequest();
      navigate(path);
    } catch (err) {
      // err is already handled by acceptBoostCallRequestError variable
      console.log('err:', err);
    }
  };

  return {
    acceptBoostCallRequest,
    acceptAndRedirect,
    acceptBoostCallRequestData: data,
    isLoadingAcceptBoostCallRequest: loading,
    acceptBoostCallRequestError:
      getErrorMessageFromResponse(error) || validationError,
    selectedProvider,
    setSelectedProvider,
    callUrl,
    setCallUrl,
    selectedTimeSlot,
    setSelectedTimeSlot,
    showCallUrlInput,
  };
};

export default useAcceptBoostCallRequest;
