import React, { useState } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './style.scss';
import { formatDate } from '../../../utils/time';
import Paper from '@mui/material/Paper';
import { BoostCallEvent } from '../../../types';
import '../components/styling/card-styling.scss';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Badge } from '@mui/material';

import MessagesModal from '../../MessagesModal';
import { useNavigate } from 'react-router-dom';
import MenteeDetailsModal from '../../../pages/NewCallDetails/menteeDetailsModal';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../../styling/buttons';
import { useSystemTogglesContext } from '../../../context/SystemToggles';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from '../../../apiHooks/sessionTitle';

type Props = {
  appointmentData: any;
  timeZone?: string;
  subject: string;
};

const NewCompletedCard = ({ timeZone, appointmentData }: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const {
    id: boostCallId,
    organisation: organisationData,
    start_time,
    volunteer_sent_nps,
    volunteer_new_messages_count,
  } = (appointmentData as BoostCallEvent) || {};
  const navigate = useNavigate();
  const [unreadMessages, setUnreadMessages] = useState<number>(
    volunteer_new_messages_count
  );
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const id = boostCallId;

  const profileData = {
    name: organisationData?.first_name,
    picture: organisationData?.profile_picture,
    companyName: organisationData?.name,
    currentRole: organisationData?.current_role,
    showPresentedBy: false,
  };

  const dateFormatted = formatDate(start_time, timeZone);

  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const sessionTitle = useSelector(selectSessionTitle);
  return (
    <div className="dashboard-box">
      <div className="completed-paper-box">
        <Paper key={id} elevation={0}>
          <div className="completed-card__container">
            <div
              onClick={() => setIsModalOpen(true)}
              className="profile-photo-card"
              style={{
                cursor: 'pointer',
                backgroundImage: profileData.picture
                  ? `url(${profileData.picture})`
                  : 'url(/avatar.png)',
              }}
            ></div>

            <div className="completed-dashboard-card-header-info">
              <div className="dashboard-card-title">
                {profileData.name ? (
                  <div className="dashboard-card-name">
                    {sessionTitle} with
                    <div className="completed-paper-first-name">
                      {profileData.name}
                    </div>
                  </div>
                ) : (
                  <div className="dashboard-card-name">Inactive User</div>
                )}
              </div>
              <div className="completed-paper-organisation">
                {profileData.companyName}
              </div>
              <div className="completed-paper-topic">
                {' '}
                {appointmentData.top_priority_name}
              </div>
              <div className="completed-paper-time">
                <div className="dashboard-paper-time-day">
                  <CalendarTodayOutlinedIcon
                    sx={{ paddingBottom: '3px' }}
                    fontSize="small"
                  />{' '}
                  {time.date?.day} {time.date?.monthThreeLetterFormat}{' '}
                  {time.date?.year}
                </div>
                <div className="dashboard-paper-time-hour">
                  <ScheduleIcon
                    fontSize="small"
                    sx={{ paddingBottom: '3px' }}
                  />{' '}
                  {time.time?.hour}{' '}
                </div>
              </div>
              <div className="completed-paper-button-wrapper">
                <div>
                  <DashboardFilledButton
                    variant="contained"
                    disabled={volunteer_sent_nps || organisationData === null}
                    onClick={() => navigate('/nps-score?id=' + id)}
                    sx={{ width: '180px' }}
                  >
                    {volunteer_sent_nps ? `Feedback Given` : `Leave Feedback`}
                  </DashboardFilledButton>
                </div>
                <div className="completed-button-container">
                  <DashboardEmptyButton
                    onClick={() => {
                      if (showMessagingUI) {
                        navigate(
                          `/messaging-centre?id=${appointmentData?.organisation?.id}`
                        );
                        return;
                      }
                      setOpenMessagesModal(true);
                      setUnreadMessages(0);
                    }}
                    sx={{ width: '100%' }}
                  >
                    <Badge
                      badgeContent={unreadMessages}
                      color="info"
                      sx={{ padding: '3px' }}
                    >
                      Messages
                    </Badge>
                  </DashboardEmptyButton>
                </div>
                <div className="completed-button-container">
                  <DashboardEmptyButton
                    disabled={organisationData === null}
                    onClick={() => navigate(`/nps-score?id=${id}&no_show=1`)}
                    sx={{ width: '100%', color: '#333' }}
                  >
                    Call didn't happen?
                  </DashboardEmptyButton>
                </div>
                <div className="dashboard-link-button-container">
                  <DashboardEmptyButton
                    sx={{ color: '#333333' }}
                    onClick={() =>
                      navigate(
                        `/call-details?type=events&cid=${appointmentData.id}`
                      )
                    }
                  >
                    Manage
                  </DashboardEmptyButton>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
      {openMessagesModal ? (
        <MessagesModal
          boostCallEvent={appointmentData as BoostCallEvent}
          onClose={() => setOpenMessagesModal(false)}
          onReadMessages={() => setUnreadMessages(0)}
          openMessagesModal={openMessagesModal}
        />
      ) : null}
      {isModalOpen ? (
        <MenteeDetailsModal
          isOpen={isModalOpen}
          event={appointmentData}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
};

export default NewCompletedCard;
