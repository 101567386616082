import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import './tooltip.scss';

type Props = {
  text: string;
  id: string; // used to ensure the correct tooltip text is rendered by the correct ReactToolTip component
  place?: 'left' | 'bottom' | 'top' | 'right';
  className?: string;
};

const ToolTip = ({ text, id, place, className }: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const placeProps = place ? { place } : {};

  return (
    <>
      <span
        className={`tooltip__wrapper ${className ? className : ''}`}
        data-tip={text}
        data-for={id}
      >
        <img width={'5px'} height="8px" src="/question.png" alt="question" />
      </span>
      <ReactTooltip id={id} multiline={true} {...placeProps} />
    </>
  );
};

export default ToolTip;
