import { useAxios } from '../../api';
import { BoostCallEvents, BoostCallEvent } from '../../types';

const useGetBoostCallEventsScheduled = () => {
  const [{ data, loading, error }, refetch] = useAxios<BoostCallEvents>({
    url: `api/event/boostcallevents/search/?status=scheduled`,
  });

  return {
    boostCallEventsScheduledCount: data?.count || 0,
    boostCallEventsScheduled: (data?.results as BoostCallEvent[]) || [],
    hasFinishedLoadingBoostCallEventsScheduled: !loading,
    boostCallEventsScheduledError: error,
    refetchBoostCallEventsScheduled: refetch,
  };
};

export default useGetBoostCallEventsScheduled;
