import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './Components/Profile';
import Security from './Components/Security';
import Privacy from './Components/Privacy';
import { useGetProfile } from './functions';

type Props = {
  tab: string;
};
const AccountInfo = ({ tab }: Props) => {
  const data = useGetProfile();

  if (tab === 'profile') return <Profile data={data} />;
  else if (tab === 'security') return <Security data={data} />;
  else if (tab === 'privacy') return <Privacy data={data} />;
  else return null;
};

export default AccountInfo;
