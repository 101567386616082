import React from 'react';

import './style.scss';

const NoMessages = () => (
  <div className="no-messages-wrapper">
    <img
      src="no-messages.svg"
      alt="No messages"
      className="no-messages-image"
    />
    <div className="no-messages-title">No messages yet</div>
    <div className="no-messages-subtitle">
      Messages can be sent to learners once a session request is accepted.
    </div>
  </div>
);

export default NoMessages;
