import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const CustomTypography = styled(Typography)({
  width: '149px',
  height: '30px',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
});

export const RequestDetailsButton = styled(Button)({
  fontFamily: 'Gilroy',
  backgroundColor: 'c2c2c2',
  borderRadius: '32px',
  textTransform: 'none',
  width: '145px',
  height: '44px',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#0f0f22',
  '&:hover': {
    backgroundColor: '#fff',
  },
});

export const modalStyle = {
  paddingTop: '10px',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
  width: ' 554px',
  height: '250px',
  margin: '9px 29px 217px 76px',
  bgcolor: ' #fff',
  boxShadow: 24,
  p: 4,
};
