import React, { useEffect, useState } from 'react';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import Button from '../../atoms/Button';

import styles from './share-link.module.scss';
import { useNavigate } from 'react-router-dom';

type Props = {
  className?: string;
  shareModal?: boolean;
};

const orgBaseUrl = process.env.REACT_APP_ORG_URL;

const ShareLink = ({ className, shareModal }: Props) => {
  const navigate = useNavigate();

  const urlLocation = window.location.href;
  const { volunteerProfile } = useSelectProfileData();
  const [copyText, setCopyText] = useState<string>('Copy');
  const shareVolunteerUrl = `${orgBaseUrl}volunteer-details?volunteer[]=${volunteerProfile?.id}`;
  const [displayShare, setDisplayShare] = useState<boolean>(false);

  const copyToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigator.clipboard.writeText(shareVolunteerUrl);
    setCopyText('Copied!');
  };

  const shareClick = () => {
    setDisplayShare(!displayShare);
    setCopyText('Copy');
  };

  const closeClick = () => {
    setDisplayShare(false);
  };

  const handleChildElementClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };
  useEffect(() => {
    closeClick();
  }, [urlLocation]);
  return (
    <div
      className={`${styles.share_container} ${className ? className : ''}`}
      onClick={() =>
        shareModal ? shareClick() : navigate('/sharable-profile')
      }
    >
      <img className={styles.icon} src="./share.svg" alt="share link" />
      {shareModal && <div className={styles.shareTxt}>Share</div>}
      <div
        className={`${
          shareModal
            ? styles.share_text_container_modal
            : styles.share_text_container
        } ${displayShare ? styles.share_text_container_visible : ''}`}
        onClick={handleChildElementClick}
      >
        <div className={styles.closeIcon} onClick={closeClick}>
          x
        </div>
        <span className={styles.text}>
          Share this link to allow mentees to request sessions directly with
          you.
        </span>
        <span className={styles.linkText}>{shareVolunteerUrl}</span>
        <div className={styles.shareLinkPointer} />
        <div className={styles.shareLinkButton}>
          <Button
            text={copyText}
            buttonType="primary"
            onClickFunction={copyToClipboard}
            className={'social-share__social-share-modal-copy-button'}
          />
        </div>
      </div>
    </div>
  );
};

export default ShareLink;
