type BusinessHealthColors = {
  [arg: number]: { color: string; text: string };
};

export const businessHealthColors: BusinessHealthColors = {
  1: { color: '#ee716c', text: 'Very Poor' },
  2: { color: '#f29d50', text: 'Poor' },
  3: { color: '#dbbd3f', text: 'Neutral' },
  4: { color: '#9dbf3b', text: 'Good' },
  5: { color: '#4ba46a', text: 'Very Good' },
};
