import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getVolunteerProfile } from '../../api/user';
import { logout } from '../auth';
import type { RootState } from '../../store';
import type { VolunteerProfile } from '../../types';

/* --- SLICE --- */

interface VolunteerProfileState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  profile?: VolunteerProfile | null;
  error?: string | null;
}

const initialState = {
  status: 'init',
  profile: null,
  error: null,
} as VolunteerProfileState;

const volunteerProfileSlice = createSlice({
  name: 'volunteerProfile',
  initialState,
  reducers: {
    clearVolunteerProfile: (state) => {
      return initialState;
    },
    setVolunteerProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // logout
      .addCase(logout, () => initialState)
      // fetchVolunteerProfile
      .addCase(fetchVolunteerProfileAction.pending, (state, action) => {
        state.status = action.meta.requestStatus;
        state.error = null;
      })
      .addCase(fetchVolunteerProfileAction.fulfilled, (state, action) => {
        state.status = action.meta.requestStatus;
        state.profile = action.payload;
      })
      .addCase(fetchVolunteerProfileAction.rejected, (state, action) => {
        const { error } = action;
        state.error = error.message;
        state.status = action.meta.requestStatus;
      });
  },
});

/* --- SELECTORS --- */

// fetchVolunteerProfile
export const selectVolunteerProfile = (state: RootState) =>
  state.volunteerProfile.profile;
export const selectVolunteerProfileStatus = (state: RootState) =>
  state.volunteerProfile.status;
export const selectHasNotFetchedVolunteerProfile = (state: RootState) =>
  selectVolunteerProfileStatus(state) === 'init';
export const selectHasFinishedLoadingVolunteerProfile = (state: RootState) =>
  selectVolunteerProfileStatus(state) === 'fulfilled' ||
  selectVolunteerProfileStatus(state) === 'rejected';

/* --- ACTIONS --- */

export const { clearVolunteerProfile, setVolunteerProfile } =
  volunteerProfileSlice.actions;

/* --- THUNKS --- */

// fetchVolunteerProfile
export const fetchVolunteerProfileAction = createAsyncThunk(
  `${volunteerProfileSlice.name}/fetchVolunteerProfile`,
  async (arg, thunkAPI) => {
    const response = await getVolunteerProfile();
    return response;
  }
);

export default volunteerProfileSlice.reducer;
