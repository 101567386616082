import { Paper } from '@mui/material';
import { styled } from '@mui/system';

export const CustomPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  width: 'auto',
  maxWidth: '80vw',
  flexWrap: 'wrap',
  height: 'auto',
  display: 'flex',
  padding: '24px',
  justifyContent: 'space-between',
  gridTemplateColumns: ' 1fr 1fr',
}));

export const CustomCallPagePaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,

  width: 'auto',
  maxWidth: '80vw',
  flexWrap: 'wrap',
  height: 'auto',
  display: 'flex',
  padding: '24px',
  justifyContent: 'space-between',
  gridTemplateColumns: ' 1fr 1fr',
}));

export const CustomShareProfilePaper = styled(Paper)(({ theme }) => ({
  color: 'pink',
  width: 'auto',
  maxWidth: '80vw',
  flexWrap: 'wrap',
  height: 'auto',
  display: 'flex',
  padding: '24px',
  justifyContent: 'space-between',
  gridTemplateColumns: ' 1fr 1fr',
}));
