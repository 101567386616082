import React from 'react';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import './signup-complete.scss';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../styling/buttons';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useLogin from '../../apiHooks/auth/useLogin';

const SignupComplete = () => {
  const { logout } = useLogin();
  let name = qs.parse(
    window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
  ).name;
  const { volunteerProfile } = useSelectProfileData();
  const navigate = useNavigate();
  return (
    <div className="signup-complete__container">
      <div className="signup-complete__inner-container">
        <h1>Welcome {name}! We will get you matched soon</h1>
        <div>
          We have sent you a confirmation email. Please check your inbox
          regularly for requests.
        </div>

        <div style={{ padding: '20px' }} />
        {volunteerProfile ? (
          <div className="signup-complete__buttons-container">
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '250px', marginTop: '10px' }}
              onClick={() => navigate('/dashboard/preferences/skills')}
            >
              View Your Preferences
            </DashboardFilledButton>

            <div style={{ marginRight: 25 }} />
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '250px', marginTop: '10px' }}
              onClick={() => navigate('/dashboard/account/profile')}
            >
              Complete Your Profile
            </DashboardEmptyButton>
          </div>
        ) : (
          <div className="signup-complete__buttons-container">
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '250px', marginTop: '10px' }}
              onClick={() => logout()}
            >
              View Your Preferences
            </DashboardFilledButton>

            <div style={{ marginRight: 25 }} />
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '250px', marginTop: '10px' }}
              onClick={() => logout()}
            >
              Complete Your Profile
            </DashboardEmptyButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignupComplete;
