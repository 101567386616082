import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import './filePopupStyle.scss';
import useWindowSize from '../../hooks/useWindowSize';
import {
  DashboardFilledButton,
  DashboardEmptyButton,
} from '../../styling/buttons';
import { Attachment } from '../../types';

interface DeleteFilePopupProps {
  open: boolean;
  onClose: () => void;
  handleDeleteSelectedFile: (file: Attachment) => Promise<void>;
  deleteFile: any;
}

const DeleteFilePopup: React.FC<DeleteFilePopupProps> = ({
  open,
  onClose,
  handleDeleteSelectedFile,
  deleteFile,
}) => {
  const handleClose = () => {
    onClose();
  };
  const { width } = useWindowSize();
  const isMobile = width < 750;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        borderRadius: '10px',
        border: '1px solid #A3A3A3',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          paddingTop: isMobile ? '50px' : '63px',
          width: isMobile ? '250px' : '550px',
          height: isMobile ? '120' : '253px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: isMobile ? '20px' : '52px',
          }}
        >
          <div className="popup-text">
            Are you sure you want to delete this file from your library?
          </div>
          <div>
            <DashboardFilledButton
              variant="contained"
              sx={{
                width: isMobile ? '80px' : '189px',
                height: isMobile ? '35px' : '48px',
              }}
              onClick={() => handleDeleteSelectedFile(deleteFile)}
            >
              Yes
            </DashboardFilledButton>
            <DashboardEmptyButton
              variant="outlined"
              sx={{
                width: isMobile ? '80px' : '189px',
                height: isMobile ? '35px' : '48px',
                marginLeft: isMobile ? '10px' : '22px',
              }}
              onClick={onClose}
            >
              No
            </DashboardEmptyButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteFilePopup;
