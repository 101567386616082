import { useEffect, useState } from 'react';
import { getNews } from '../apiCalls/news';

const useGetNews = () => {
  const [news, setNews] = useState<any>([]);
  const [hasFinishedLoadingNews, setHasFinishedLoadingNews] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const { results = [] } = await getNews();
        setNews(results);
        setHasFinishedLoadingNews(true);
      } catch (e) {
        console.log('news fetch error:', e);
        setHasFinishedLoadingNews(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { news, setNews, hasFinishedLoadingNews };
};

export default useGetNews;
