import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import useWindowSize from '../../hooks/useWindowSize';
import useLogin from '../../apiHooks/auth/useLogin';
import useWindowScrollPosition from '../../hooks/useWindowScrollPosition';
import { getUrlParam } from '../../utils/url';
import useClickOutsideContainer from '../../hooks/useClickOutsideContainer';
import Logo from './Logo';
import './nav.scss';
import ShareLink from '../ShareLink';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import TimezoneIndicator from '../../atoms/TimezoneIndicator';
import { Alert, Tooltip } from '@mui/material';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../styling/buttons';
import MessagingIndicator from '../../atoms/MessagingIndicator';
import FaqIndicator from '../../atoms/FaqIndicator';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import { useSystemTogglesContext } from '../../context/SystemToggles';
const Nav = () => {
  const navigate = useNavigate();
  const {
    userProfile,
    volunteerProfile,
    hasFinishedFetchingProfileAndLocation,
  } = useSelectProfileData();
  const { pathname } = useLocation();
  const { logout } = useLogin();
  const { width = window.innerWidth } = useWindowSize();
  const showHamburger = width < 950;
  const wrapperRef = useRef(null);

  const { y = window.pageYOffset } = useWindowScrollPosition();

  const navExclusionArray = [
    '/premium-partner',
    '/partner-login',
    '/premium-partner-preferences',
    !getUrlParam('custom_call_url') && '/boost-call',
  ];

  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenuAndExecute = (func: () => void) => {
    return () => {
      setMenuOpen(false);
      func();
    };
  };
  const handleClickOutsideMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  };
  const { toggles } = useSystemTogglesContext();
  const showAttachmentCenter = toggles['ATTACHMENT_CENTRE'];
  const showActionPlan = toggles['ACTION_PLAN'];
  const onDesktop =
    window.location.href.includes('/bookings') ||
    window.location.href.includes('/actions') ||
    window.location.href.includes('/requests') ||
    window.location.href.includes('/impact');

  useClickOutsideContainer(wrapperRef, handleClickOutsideMenu);

  const goDashboard = () => {
    navigate('/dashboard', { state: { href: window.location.href } });
  };
  const goAccount = () => {
    navigate('/dashboard/account/profile');
  };
  const goBookings = () => navigate('/dashboard/bookings');
  const goRequests = () => {
    navigate('/dashboard/requests');
  };
  const goPreferences = () => {
    navigate('/dashboard/preferences/skills');
  };
  const goImpact = () => {
    navigate('/dashboard/impact');
  };
  const goResources = () => {
    navigate('/dashboard/resources');
  };
  const goActionPlan = () => {
    navigate('/dashboard/actionplan');
  };
  if (navExclusionArray.indexOf(pathname) > -1) return null;

  const renderLoginAndSignupButtons = () => (
    <>
      {pathname !== '/login' && (
        <div
          onClick={closeMenuAndExecute(() => {
            navigate('/login');
          })}
          className="quick-link nav__logged-out-link"
        >
          Login
        </div>
      )}
      {pathname !== '/signup' && (
        <DashboardFilledButton
          variant="contained"
          sx={{ width: '200px', borderRadius: '0' }}
          onClick={closeMenuAndExecute(() => navigate('/signup'))}
        >
          Sign up - it's free
        </DashboardFilledButton>
      )}
    </>
  );
  if (
    (volunteerProfile === undefined || volunteerProfile === null) &&
    !window.location.href.includes('/login') &&
    !window.location.href.includes('/signup-complete') &&
    !window.location.href.includes('/signup') &&
    !window.location.href.includes('/resetpassword')
  )
    return (
      <div className="nav-no-profile">
        <Alert
          severity="info"
          sx={{
            fontSize: showHamburger ? '14px' : '18px',
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          Please complete your profile to gain access to our platform! If you
          drop before the completion your data will not be saved.
        </Alert>
        <DashboardEmptyButton
          color="info"
          variant="text"
          sx={{ height: '54px' }}
          onClick={closeMenuAndExecute(logout)}
          className="nav__menu-box-link"
        >
          Logout
        </DashboardEmptyButton>
      </div>
    );
  else
    return (
      <>
        <nav className={`nav ${y > 0 ? 'nav__shadow' : ''}`}>
          <div className="nav-container">
            <Logo />
            {hasFinishedFetchingProfileAndLocation ? (
              <div className="nav-link-container">
                {volunteerProfile ? (
                  <>
                    <div
                      className="nav-link nav__burger-menu-container"
                      ref={wrapperRef}
                    >
                      <div className="nav__profile-wrapper">
                        {width > 950 && !onDesktop ? (
                          <div
                            onClick={closeMenuAndExecute(goDashboard)}
                            className="nav__menu-box-link"
                          >
                            Dashboard
                          </div>
                        ) : null}
                        <ShareLink className="nav__share-link-indicator" />
                        <TimezoneIndicator className="nav__timezone-indicator" />
                        <FaqIndicator className="nav__faq-indicator" />
                        {showAttachmentCenter ? (
                          <Tooltip
                            title="Manage your files"
                            placement="bottom"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  width: '200px',
                                  fontFamily: 'Gilroy',
                                  padding: '10px',
                                  borderRadius: '3px',
                                  fontWeight: '500',
                                  fontSize: '16px',
                                  textAlign: 'center',

                                  boxShadow: ' 0px 1px 6px rgba(0, 0, 0, 0.3)',
                                  bgcolor: 'white',
                                  color: 'black',
                                  '& .MuiTooltip-arrow': {
                                    color: 'black',
                                  },
                                },
                              },
                            }}
                          >
                            <TopicOutlinedIcon
                              fontSize="medium"
                              style={{ cursor: 'pointer' }}
                              className="nav__faq-indicator"
                              onClick={() => navigate('/library')}
                            />
                          </Tooltip>
                        ) : null}
                        <MessagingIndicator className="nav__messaging-indicator" />
                        <div
                          className="nav__pic-and-name"
                          onClick={closeMenuAndExecute(goAccount)}
                        >
                          <div className="profile-pic">
                            {volunteerProfile.profile_picture && (
                              <img
                                height={'31px'}
                                width={'31x'}
                                alt="avatar"
                                src={volunteerProfile.profile_picture}
                              />
                            )}
                            {!volunteerProfile.profile_picture && (
                              <img
                                height={'31px'}
                                alt="avatar"
                                src={'/avatar.png'}
                              />
                            )}
                          </div>
                          <span className="name">
                            {width > 950 ? volunteerProfile.first_name : null}
                          </span>
                        </div>
                        <Hamburger
                          toggled={menuOpen}
                          toggle={setMenuOpen}
                          size={20}
                        />
                      </div>
                      {menuOpen && (
                        <div className="menu-box">
                          <div
                            onClick={closeMenuAndExecute(goDashboard)}
                            className="nav__menu-box-link"
                          >
                            Dashboard
                          </div>
                          {width <= 950 ? (
                            <div>
                              <div
                                onClick={closeMenuAndExecute(goBookings)}
                                className="nav__menu-box-link"
                              >
                                Bookings
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goRequests)}
                                className="nav__menu-box-link"
                              >
                                Requests
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goImpact)}
                                className="nav__menu-box-link"
                              >
                                Your Impact
                              </div>
                              {showActionPlan ? (
                                <div
                                  onClick={closeMenuAndExecute(goActionPlan)}
                                  className="nav__menu-box-link"
                                  data-cy="Dashboard-link"
                                >
                                  Action Plan
                                </div>
                              ) : null}
                              <div
                                onClick={closeMenuAndExecute(goResources)}
                                className="nav__menu-box-link"
                              >
                                Resources
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goAccount)}
                                className="nav__menu-box-link"
                              >
                                Account
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goPreferences)}
                                className="nav__menu-box-link"
                              >
                                Preferences
                              </div>
                            </div>
                          ) : null}

                          <div
                            onClick={closeMenuAndExecute(logout)}
                            className="nav__menu-box-link"
                          >
                            Logout
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    className="nav__logged-out-link-container"
                    ref={wrapperRef}
                  >
                    {showHamburger && (
                      <Hamburger
                        toggled={menuOpen}
                        toggle={setMenuOpen}
                        size={20}
                      />
                    )}
                    {(width >= 950 || menuOpen) && (
                      <div className="nav__logged-out-link-wrapper">
                        {!userProfile ? (
                          renderLoginAndSignupButtons()
                        ) : (
                          <div
                            onClick={closeMenuAndExecute(logout)}
                            className="quick-link nav__logged-out-link"
                          >
                            Logout
                          </div>
                        )}
                        {width > 950 && <div style={{ height: '48px' }}></div>}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="nav-link-container">
                <div
                  className="quick-link nav__hidden"
                  style={{ height: '48px' }}
                >
                  .
                </div>
              </div>
            )}
          </div>
        </nav>
        <div className="nav__sizing-placeholder" />
      </>
    );
};

export default Nav;
