import { useAxios } from '../../api';
import { Attendee } from '../../types';

type Props = {
  code?: string;
  status?: string;
};

type ResponseType = {
  results: Attendee[];
};

const useGetAttendees = ({ code, status }: Props) => {
  const [{ data, loading, error }, refetch] = useAxios<ResponseType>({
    url: `api/groupevent/attendee/?group_event__code=${code}&status=${status}&limit=100`,
  });

  return {
    attendeesData: data && data.results,
    hasFinishedLoadingAttendees: !loading,
    attendeesError: error,
    refetchAttendees: refetch,
  };
};

export default useGetAttendees;
