import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import './confirm-call.scss';
import api from '../../api';
import qs, { ParsedQs } from 'qs';
import { DashboardFilledButton } from '../../styling/buttons';

const ConfirmCall = () => {
  const navigate = useNavigate();

  const [selectedDeclineReason, setSelectedDeclineReason] =
    useState<any>(undefined);
  const [code, setCode] = useState<
    string | string[] | ParsedQs | ParsedQs[] | undefined
  >('');
  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getDataFromCode = () => {
    let code = qs.parse(
      window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
    ).code;

    api
      .get(`api/event/boostcallinvites/${code}/`, {})
      .then(() => {

        setCode(code);
      })
      .catch((err: any) => console.log(err));
  };

  const declineCall = (code: any) => {
    api
      .post(
        `api/event/boostcallinvites/${code}/declineinvite/`,
        { reason: selectedDeclineReason },
        {}
      )
      .then((response: any) => {
        setIsSent(true);
      })
      .catch((err: any) => {
        setErrorMessage(err.response.data[Object.keys(err.response.data)[0]]);
      });
  };

  useEffect(() => {
    getDataFromCode();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="thank-you-container">
      {isSent ? (
        <div className="thank-you">
          <h3>Your call has been successfully declined</h3>

          <DashboardFilledButton
            variant="contained"
            sx={{ width: '200px' }}
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Return To Dashboard
          </DashboardFilledButton>
        </div>
      ) : (
        <div className="confirm-call">
          {errorMessage && <h3 className="error">{errorMessage}</h3>}

          <h3>
            We’re sorry you weren’t able to accept this request. To help us send
            you better matches, could you tell us why?
          </h3>

          <div className="time-slots">
            <div className={'time-slot'}>
              <input
                type="radio"
                value={'unskilled'}
                checked={selectedDeclineReason === 'unskilled'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedDeclineReason(e.target.value)
                }
              />
              <label
                className={
                  selectedDeclineReason === 'unskilled' ? 'selected' : ''
                }
              >
                I don’t think I have the right skills to help this time
              </label>
            </div>

            <div className={'time-slot'}>
              <input
                type="radio"
                value={'unavailable'}
                checked={selectedDeclineReason === 'unavailable'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedDeclineReason(e.target.value)
                }
              />
              <label
                className={
                  selectedDeclineReason === 'unavailable' ? 'selected' : ''
                }
              >
                I’m not free at the proposed time(s)
              </label>
            </div>

            <div className={'time-slot'}>
              <input
                type="radio"
                value={'too_many_requests'}
                checked={selectedDeclineReason === 'too_many_requests'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedDeclineReason(e.target.value)
                }
              />
              <label
                className={
                  selectedDeclineReason === 'too_many_requests'
                    ? 'selected'
                    : ''
                }
              >
                I’ve received too many requests lately
              </label>
            </div>

            <div className={'time-slot'}>
              <input
                type="radio"
                value={'no_time'}
                checked={selectedDeclineReason === 'no_time'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedDeclineReason(e.target.value)
                }
              />
              <label
                className={
                  selectedDeclineReason === 'no_time' ? 'selected' : ''
                }
              >
                I don’t have time for mentoring right now
              </label>
            </div>
          </div>
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '180px' }}
            onClick={() => declineCall(code)}
          >
            Decline Request
          </DashboardFilledButton>
        </div>
      )}
    </div>
  );
};

export default ConfirmCall;
