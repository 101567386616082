import React, { useEffect } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { TimeSelect } from '../../styling/generalStyling';
import { CustomTimeLabel } from '../Dashboard/styling';

type Props = {
  setNewTime: any;
  selectedDay: string;
  newTime: string;
};

const SelectTime = ({ setNewTime, selectedDay, newTime }: Props) => {
  const [selectedTime, setSelectedTime] = React.useState(newTime);

  const isToday =
    moment(selectedDay).format('MMM DD YY') === moment().format('MMM DD YY');
  var startTime = moment().utc().set({ hour: 1, minute: 0 });
  var endTime = moment().utc().set({ hour: 23, minute: 59 });
  var minimumTime = 0;
  var minimumHour = 1;
  if (isToday) {
    minimumHour = moment().hour();
    minimumTime =
      (Math.round(moment().add(15, 'minute').minute() / 15) * 15) % 60;
    if (minimumTime === 0) minimumHour++;
    if (minimumHour === 24) minimumHour = 1;
  }
  var timeStops = [];
  startTime = moment().utc().set({ hour: minimumHour, minute: minimumTime });
  endTime = moment().utc().set({ hour: 23, minute: 59 });

  useEffect(() => {
    const timeUpdate = () => {
      setSelectedTime('');
      setNewTime('');
    };
    timeUpdate();
  }, [selectedDay, setNewTime, setSelectedTime]);
  while (startTime <= endTime) {
    let newMoment = moment(startTime).format('HH:mm');
    timeStops.push(newMoment);
    startTime.add(15, 'minutes');
  }

  const handleChange = (event: any) => {
    setSelectedTime(event.target.value as string);
    setNewTime(event.target.value as string);
  };
  return (
    <Box sx={{ width: 250 }}>
      <FormControl fullWidth>
        <CustomTimeLabel
          sx={{ fontFamily: 'Gilroy' }}
          id="demo-simple-select-label"
        >
          Select time
        </CustomTimeLabel>
        <TimeSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedTime}
          label="Select time"
          onChange={handleChange}
        >
          {timeStops.map((time, index) => (
            <MenuItem sx={{ fontFamily: 'Gilroy' }} key={index} value={time}>
              {time}
            </MenuItem>
          ))}
        </TimeSelect>
      </FormControl>
    </Box>
  );
};

export default SelectTime;
