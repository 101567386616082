import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import './reschedule-upcoming-card.scss';
import { Popover } from '@mui/material';
import { CustomTimeLabel } from '../Dashboard/styling';
import {
  AvailabilityActionPlanButton,
  DatePopperButton,
} from '../../styling/buttons';

type Props = {
  setNewDate: Dispatch<SetStateAction<string>>;
  position?: string;
};

const SelectAvailability = ({ setNewDate, position }: Props) => {
  const [calendarDay, setCalendarDay] = useState<any>(null);
  useEffect(() => {});
  const setDate = (date: any, save: string) => {
    if (save === 'calendar') {
      setCalendarDay(date);
      setNewDate(date);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  if (position === 'actionPlan')
    return (
      <div style={{ width: '190px' }}>
        <AvailabilityActionPlanButton
          sx={{ border: 'none' }}
          aria-describedby={id}
          variant="outlined"
          onClick={handleClick}
        >
          {calendarDay ? (
            calendarDay.toString().slice(0, 10)
          ) : (
            <CustomTimeLabel sx={{ textAlign: 'left', width: '100%' }}>
              Select date
            </CustomTimeLabel>
          )}
        </AvailabilityActionPlanButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DatePicker
            dateFormat="Pp"
            selected={calendarDay}
            onChange={(date) => {
              setDate(date, 'calendar');
              handleClose();
            }}
            minDate={new Date(moment().toDate())}
            disabledKeyboardNavigation
            inline
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div>
                <div className="calendar-date">
                  {moment(date).format('MMMM YYYY')}
                </div>
                <Grid>
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <IoIosArrowBack size={14} />
                  </button>
                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <IoIosArrowForward size={14} />
                  </button>
                </Grid>
              </div>
            )}
          />
        </Popover>
      </div>
    );

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Box sx={{ width: 250 }}>
          <DatePopperButton
            aria-describedby={id}
            variant="outlined"
            onClick={handleClick}
          >
            {calendarDay ? (
              calendarDay.toString().slice(0, 10)
            ) : (
              <CustomTimeLabel sx={{ textAlign: 'left', width: '100%' }}>
                Select date
              </CustomTimeLabel>
            )}
          </DatePopperButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DatePicker
              dateFormat="Pp"
              selected={calendarDay}
              onChange={(date) => {
                setDate(date, 'calendar');
                handleClose();
              }}
              minDate={new Date(moment().toDate())}
              disabledKeyboardNavigation
              inline
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ padding: '0 14px' }}
                >
                  <div className="calendar-date">
                    {moment(date).format('MMMM YYYY')}
                  </div>
                  <Grid>
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <IoIosArrowBack size={14} />
                    </button>
                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <IoIosArrowForward size={14} />
                    </button>
                  </Grid>
                </Grid>
              )}
            />
          </Popover>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SelectAvailability;
