import { useState, useCallback, useEffect } from 'react';
import { useAxios } from '../../api';
import { getUrlParam, validateUrl } from '../../utils/url';
import { getErrorMessageFromResponse } from '../../utils/error';
import { BoostCallEvent } from '../../types';

type Props = {
  code: number | string | null;
  defaultCallProvider: string;
};

export type AcceptBoostCallInviteReturnProps = {
  acceptBoostCallInvite: () => void;
  acceptBoostCallInviteResponse: BoostCallEvent | undefined;
  isLoadingAcceptBoostCallInvite: boolean;
  acceptBoostCallInviteError: string;
  selectedTimeSlotId?: number | string;
  setSelectedTimeSlotId: (arg: number) => void;
  selectedCallProvider: string;
  setSelectedCallProvider: (arg: string) => void;
  callUrl: string;
  setCallUrl: (arg: string) => void;
  hasSentAcceptBoostCallInvite: boolean;
  setHasSentAcceptBoostCallInvite: (arg: boolean) => void;
  needsCallUrl: boolean;
};

const useAcceptBoostCallInvite = ({
  code,
  defaultCallProvider,
}: Props): AcceptBoostCallInviteReturnProps => {
  const callUrlParam = getUrlParam('callUrl') as string;
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState<number>(
    +(getUrlParam('time_slot') as string)
  );
  const [selectedCallProvider, setSelectedCallProvider] =
    useState<string>(defaultCallProvider);
  const [callUrl, setCallUrl] = useState<string>(callUrlParam || '');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasSentAcceptBoostCallInvite, setHasSentAcceptBoostCallInvite] =
    useState(false);
  const needsCallUrl = selectedCallProvider !== '3'; // Id 3 is Jitsi

  const [{ data, loading, error }, executeCall] = useAxios<BoostCallEvent>(
    {
      url: `api/event/boostcallinvites/${code}/acceptinvite/`,
      method: 'POST',
    },
    { manual: true }
  );

  const acceptBoostCallInvite = useCallback(async () => {
    setErrorMessage('');

    try {
      if (!selectedTimeSlotId) throw new Error('Please select a time-slot');
      if (needsCallUrl && !callUrl.trim())
        throw new Error('Please enter a meeting link');
      if (needsCallUrl && !validateUrl(callUrl))
        throw new Error('Please enter a valid url');

      executeCall({
        data: {
          time_slot: selectedTimeSlotId,
          call_provider: +selectedCallProvider,
          ...(callUrl ? { call_url: callUrl } : {}),
        },
      });
    } catch (ex) {
      setErrorMessage(getErrorMessageFromResponse(ex));
      throw ex;
    }
  }, [
    callUrl,
    executeCall,
    needsCallUrl,
    selectedTimeSlotId,
    selectedCallProvider,
  ]);

  useEffect(() => {
    if (!!defaultCallProvider) setSelectedCallProvider(defaultCallProvider);
  }, [executeCall, defaultCallProvider]);

  return {
    acceptBoostCallInvite,
    acceptBoostCallInviteResponse: data,
    isLoadingAcceptBoostCallInvite: loading,
    acceptBoostCallInviteError:
      errorMessage || getErrorMessageFromResponse(error),
    selectedTimeSlotId,
    setSelectedTimeSlotId,
    selectedCallProvider,
    setSelectedCallProvider,
    callUrl,
    setCallUrl,
    hasSentAcceptBoostCallInvite,
    setHasSentAcceptBoostCallInvite,
    needsCallUrl,
  };
};

export default useAcceptBoostCallInvite;
