import { useState } from 'react';
import { useAxios } from '../../api';
import { BoostCallEvent } from '../../types';

type Props = {
  id: number | string;
};

type AcceptRescheduleProps = {
  newStartTime?: string;
  newCallUrl?: string;
};

const useAcceptRescheduleBoostCall = ({ id }: Props) => {
  const [hasFinishedAcceptingReschedule, setHasFinishedAcceptingReschedule] =
    useState(false);

  const [{ data, loading, error }, executeCall] = useAxios<BoostCallEvent>(
    {
      url: `api/event/boostcallevents/${id}/accept-reschedule/`,
      method: 'POST',
    },
    { manual: true }
  );

  const acceptRescheduleBoostCall = async ({
    newStartTime,
    newCallUrl,
  }: AcceptRescheduleProps) => {
    if (!newStartTime) throw new Error('No start time provided');
    const newCallUrlParam = newCallUrl ? { new_call_url: newCallUrl } : {};
    try {
      await executeCall({
        data: {
          new_start_datetime: newStartTime,
          ...newCallUrlParam,
        },
      });
      setHasFinishedAcceptingReschedule(true);
    } catch (err) {
      throw err;
    }
  };

  return {
    acceptRescheduleBoostCall: acceptRescheduleBoostCall,
    acceptRescheduleBoostCallData: data,
    isLoadingAcceptRescheduleBoostCall: loading,
    hasFinishedAcceptingRescheduleBoostCall: hasFinishedAcceptingReschedule,
    acceptRescheduleBoostCallError: error,
  };
};

export default useAcceptRescheduleBoostCall;
