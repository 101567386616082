import { useAxios } from '../../api';
import { GroupEvents } from '../../types';

type Props = {
  code?: string;
};

const useGetGroupEventCompleted = ({ code }: Props) => {
  const idParam = code ? { code } : {};

  // Note: For Volunteers, this endpoint only returns the Volunteer's own events
  const [{ data, loading, error }, refetch] = useAxios<GroupEvents>({
    url: 'api/groupevent/groupevent/',
    params: {
      limit: 100,
      status: 'complete',
      ...idParam,
    },
  });

  return {
    groupEventCompletedData: data && data.results,
    hasFinishedLoadingGroupEventsCompleted: !loading,
    groupEventCompletedError: error,
    refetchGroupEventCompleted: refetch,
  };
};

export default useGetGroupEventCompleted;
