import React from 'react';
import './styling/requests.scss';

const DashboardEmptyPage = () => {
  return (
    <div className="empty-page-container">
      <img
        className="not-found-image"
        src={'/no-requests-searching-ilustration.png'}
        alt={'not-found'}
      />
      <div className="not-found-text">
        You have no actions - check back soon!
      </div>
    </div>
  );
};

export default DashboardEmptyPage;
