import {
  TypedUseSelectorHook,
  useDispatch as useDispatchOriginal,
  useSelector as useSelectorOriginal,
} from 'react-redux';
import type { RootState, AppDispatch } from '../store';

const useRedux = () => {
  // Use throughout your app instead of original `useDispatch` and `useSelector`
  const useDispatch = () => useDispatchOriginal<AppDispatch>();
  const dispatch = useDispatch();
  const useSelector: TypedUseSelectorHook<RootState> = useSelectorOriginal;

  return {
    useSelector,
    dispatch,
  };
};

export default useRedux;
