import React, { useEffect, useState } from 'react';
import { DashboardEmptyButton, StyledTrashButton } from '../../styling/buttons';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeletePlanPopup from './deletePlanPopup';
import PageNotFound from '../../templates/PageNotFound';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  deleteActionPlan,
  getActionPlan,
  getActionTasksPagination,
  updateActionPlan,
  updateActionTask,
} from '../../api/actionPlan';
import NewCard from './newCard';
import ExistingCard from './existingCard';
import EditCardPopup from './EditCardPopup';
import DeleteCardPopup from './deleteCardPopup';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { getUrlParam } from '../../utils/url';
import { formatDate } from './utils';
import MentorsPopup from './mentorsPopup';
import { capitalizeFirstLetter } from '../../utils/text';
import { CustomTextFieldActionPlanName } from '../../styling/generalStyling';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { ActionPlanType, CardsType } from '../../types';
import useWindowSize from '../../hooks/useWindowSize';
import PlanDetailsMobile from './mobile/planDetailsMobile';

const PlanDetails = () => {
  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  const planId = getUrlParam('planId')?.toString() || '';
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [planDetails, setPlanDetails] = useState<ActionPlanType | undefined>(
    undefined
  );
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [cardDetails, setCardDetails] = useState<CardsType | undefined>();

  const [tasks, setTasks] = useState<CardsType[]>([]);
  const [ticketStatus, setTicketStatus] = useState<string | undefined>(
    undefined
  );
  const [cardEdit, setCardEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(0);
  const [changedOwnership, setChangedOwnership] = useState<boolean>(false);
  const [changeTitle, setChangeTitle] = useState<boolean>(false);
  const [deleteCardPopup, setDeleteCardPopup] = useState<boolean>(false);
  const [deleteCardId, setDeleteCardId] = useState<number | undefined>(
    undefined
  );
  const [actionPlanName, setActionPlanName] = useState('');
  const handleEditPlanName = () => {
    setChangeTitle(true);
  };
  const setCounterChange = () => {
    setCounter(counter + 1);
  };
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setActionPlanName(event.target.value);
  };
  const allowOwnerActions =
    planDetails?.my_permission === 'owner' ? true : false;
  const allowEditorActions =
    planDetails?.my_permission === 'owner' ||
    planDetails?.my_permission === 'editor'
      ? true
      : false;
  useEffect(() => {
    if (open === false) {
      setCardEdit(false);
      setOpen(false);
      setTicketStatus(undefined);
      setCardDetails(undefined);
    }
  }, [open]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getActionPlan(planId);
        if (result.success) {
          setPlanDetails(result.plan?.[0]);
          setActionPlanName(result.plan?.[0].name);
        } else {
          console.log('error');
        }
      } catch (error) {
        console.log('error', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [planId, changedOwnership]);
  const savePlanTitle = async () => {
    try {
      if (planDetails)
        await updateActionPlan(actionPlanName, planDetails?.id, 'open');
    } catch (error) {
      console.error('Error creating action plan:', error);
    } finally {
      setChangeTitle(false);
    }
  };
  const openDeleteCardPopup = (cardId: number) => {
    setDeleteCardId(cardId);
    setDeleteCardPopup(true);
  };
  const deletePlan = async () => {
    await deleteActionPlan(planDetails?.id.toString() || '')
      .then((response) => {
        if (response.success) {
          navigate('/dashboard/actionplan');
        } else {
          console.error('Error deleting plan:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error while deleting plan:', error);
      });
  };
  useEffect(() => {
    const fetchAllTasks = async () => {
      let allTasks: CardsType[] = [];
      let nextUrl = '';

      try {
        while (nextUrl !== null) {
          const result = await getActionTasksPagination(planId, 10, nextUrl);
          if (result.success) {
            allTasks = [...allTasks, ...result.tasks];
            nextUrl = result.pagination?.nextUrl;
          } else {
            console.log('Error fetching data');
            break;
          }
        }
        setTasks(allTasks);
      } catch (error) {
        console.log('Error:', error);
      }
    };

    fetchAllTasks();
  }, [counter, planId]);

  const [deletePlanPopup, setDeletePlanPopup] = useState(false);
  const handleAddNewCard = (status: string) => {
    setTicketStatus(status);
    setOpen(true);
  };

  const handleEditCard = (card: CardsType) => {
    setCardDetails(card);
    setCardEdit(true);
  };
  const handleUpdateActionTask = async (card: CardsType, status: string) => {
    await updateActionTask(card, status)
      .then((response) => {
        if (response.success) {
          setCounterChange();
        } else {
          console.error('Error updating task:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error in task:', error);
      });
  };
  if (loading)
    return (
      <div className="center-in-page">
        <img
          width="75px"
          height="75px"
          src={'/loading-icon.svg'}
          alt="loading-icon"
        />
      </div>
    );

  if (showActionPlan && planDetails) {
    return (
      <div className="actionplan-container">
        <div className="actionplan-topbar">
          <div className="actionplan-title-page">Action Plan</div>
        </div>
        <Button
          variant="text"
          sx={{
            textTransform: 'none',
            color: '#000000',
            fontWeight: '800',

            fontFamily: 'Gilroy',
          }}
          className="go-back-text"
          onClick={() => {
            navigate('/dashboard/actionplan');
          }}
        >
          <img
            src={'/arrowleft.svg'}
            alt="left arrow button"
            style={{ marginRight: '10px' }}
          />
          {` back to Action Plans`}
        </Button>
        {planDetails ? (
          <div className="plan-details-container">
            <div className="row-plan-details">
              <div className="table-text" style={{ marginLeft: '25px' }}>
                Plan name
              </div>
              <div className="vertical-divider" />
              <div className="table-text-options"> Permission</div>
              <div className="vertical-divider" />
              <div className="table-text-options"> Visibility</div>
            </div>
            <div className="row-plan-details">
              <div
                style={{
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <CustomTextFieldActionPlanName
                  disabled={!changeTitle}
                  value={actionPlanName}
                  onChange={handleChange}
                  variant="standard"
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000000',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                    },
                    color: ' #333',
                    fontFamily: 'Gilroy',
                    marginLeft: '25px',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '120%',
                    letterSpacing: '0.18px',
                    width: '100%',
                    height: '53px',
                    textAlign: 'center',
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{ maxLength: 50 }}
                />{' '}
                {allowEditorActions ? (
                  <div
                    className="card-button"
                    style={{
                      width: '70px',
                      display: 'inline-flex',
                      alignItems: 'center',
                      fontFamily: 'Gilroy',
                      fontSize: '12px',
                    }}
                  >
                    {changeTitle ? (
                      <div
                        style={{
                          padding: '4px',
                          backgroundColor: '#d9ead3',
                          borderRadius: '10px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          columnGap: '3px',
                        }}
                        onClick={() => savePlanTitle()}
                      >
                        <CheckOutlinedIcon fontSize="small" />
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: '4px',
                          backgroundColor: '#eeeeee',
                          borderRadius: '10px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          columnGap: '3px',
                        }}
                        onClick={() => handleEditPlanName()}
                      >
                        <CreateOutlinedIcon fontSize="small" />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <div className="vertical-divider" />
              <div className="table-text-options">
                <LockOpenIcon />
                <div> {capitalizeFirstLetter(planDetails?.my_permission)} </div>
              </div>
              <div className="vertical-divider" />
              {allowOwnerActions ? (
                <div
                  style={{ cursor: 'pointer' }}
                  className="table-text-options"
                  onClick={() => setOpenSharePopup(true)}
                >
                  <RemoveRedEyeOutlinedIcon />
                  <div>Share with others</div>
                </div>
              ) : (
                <div className="table-text-options">
                  <VisibilityOffOutlinedIcon /> <div>You don't have access</div>
                </div>
              )}
            </div>
            <div className="divider" />
            <div
              className="row-plan-details"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '20px',
                gridTemplateColumns: '190px 100px 100px',
              }}
            >
              {allowOwnerActions ? (
                <DashboardEmptyButton
                  variant="text"
                  onClick={() => setDeletePlanPopup(true)}
                  sx={{
                    justifyContent: 'flex-end',
                    color: '#333',
                    fontFamily: 'Gilroy',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '120%',
                    letterSpacing: '0.14px',
                    padding: 0,
                    minWidth: '40px',
                  }}
                >
                  <StyledTrashButton
                    sx={{ backgroundColor: '#3a3a3a', marginRight: '5px' }}
                  />{' '}
                  Delete plan
                </DashboardEmptyButton>
              ) : null}
            </div>
            <div className="divider" />

            <div className="status-column-container">
              <div className="status-column to-do">
                <div className=" to-do">To do</div>
                {tasks
                  ?.filter((card: CardsType) => card.status === 'todo')
                  .map((card) => (
                    <div key={card.id} className="task-card-container">
                      <div
                        className="task-card-info"
                        onClick={() => {
                          setOpenCard(true);
                          setCardDetails(card);
                        }}
                      >
                        <div className="card-name-displayed">
                          {' '}
                          {card.title?.length > 20
                            ? `${card.title.slice(0, 20)}...`
                            : card.title}{' '}
                        </div>
                        <div className="card-description">
                          {card.description?.length > 80
                            ? `${card.description.slice(0, 80)}...`
                            : card.description}
                        </div>
                      </div>
                      <div className="card-buttons">
                        <div className="card-date">
                          <img
                            src={'/deadline-icon.svg'}
                            alt="deadline icon"
                            height="15px"
                            width="15px"
                          />{' '}
                          <div style={{ height: '10px' }}>
                            {' '}
                            {card.deadline
                              ? formatDate(card.deadline)
                              : 'No deadline set'}
                          </div>
                        </div>
                        {allowEditorActions ? (
                          <div className="card-buttons">
                            <div
                              className="card-button"
                              onClick={() => handleEditCard(card)}
                            >
                              <img src={'/pencil-card.svg'} alt="edit card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() =>
                                handleUpdateActionTask(card, 'in_progress')
                              }
                            >
                              <img src={'/arrow-card.svg'} alt="move card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() => openDeleteCardPopup(card.id)}
                            >
                              <img src={'/card-bin.svg'} alt="delete card" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                {allowEditorActions ? (
                  <DashboardEmptyButton
                    onClick={() => handleAddNewCard('todo')}
                    sx={{
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '120%',
                      letterSpacing: '0.15px',
                      justifyContent: 'left',
                      display: 'inline-flex',
                      columnGap: '5px',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    <img
                      src={'/plus.svg'}
                      alt="plus button"
                      width="15px"
                      height="15px"
                    />
                    <div style={{ height: '15px' }}>Create a new task</div>
                  </DashboardEmptyButton>
                ) : null}
              </div>

              <div className="status-column in-progress">
                <div className="in-progress">In Progress</div>
                {tasks
                  ?.filter((card: CardsType) => card.status === 'in_progress')
                  .map((card) => (
                    <div key={card.id} className="task-card-container">
                      <div
                        className="task-card-info"
                        onClick={() => {
                          setOpenCard(true);
                          setCardDetails(card);
                        }}
                      >
                        <div className="card-name-displayed">
                          {' '}
                          {card.title?.length > 20
                            ? `${card.title.slice(0, 20)}...`
                            : card.title}{' '}
                        </div>
                        <div className="card-description">
                          {card.description?.length > 80
                            ? `${card.description.slice(0, 80)}...`
                            : card.description}
                        </div>
                      </div>
                      <div className="card-buttons">
                        <div className="card-date">
                          <img
                            src={'/deadline-icon.svg'}
                            alt="deadline icon"
                            height="15px"
                            width="15px"
                          />{' '}
                          <div style={{ height: '10px' }}>
                            {' '}
                            {card.deadline
                              ? formatDate(card.deadline)
                              : 'No deadline set'}
                          </div>
                        </div>
                        {allowEditorActions ? (
                          <div className="card-buttons">
                            <div
                              className="card-button"
                              onClick={() => handleEditCard(card)}
                            >
                              <img src={'/pencil-card.svg'} alt="edit card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() =>
                                handleUpdateActionTask(card, 'todo')
                              }
                            >
                              <img src={'/right-arrow.svg'} alt="move card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() =>
                                handleUpdateActionTask(card, 'completed')
                              }
                            >
                              <img src={'/arrow-card.svg'} alt="move card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() => openDeleteCardPopup(card.id)}
                            >
                              <img src={'/card-bin.svg'} alt="delete card" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                {allowEditorActions ? (
                  <DashboardEmptyButton
                    onClick={() => handleAddNewCard('in_progress')}
                    sx={{
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '120%',
                      letterSpacing: '0.15px',
                      justifyContent: 'left',
                      display: 'inline-flex',
                      columnGap: '5px',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    <img
                      src={'/plus.svg'}
                      alt="plus button"
                      width="15px"
                      height="15px"
                    />
                    <div style={{ height: '15px' }}>Create a new task</div>
                  </DashboardEmptyButton>
                ) : null}
              </div>
              <div className="status-column completed">
                <div className="completed">Completed</div>
                {tasks
                  ?.filter((card) => card.status === 'completed')
                  .map((card) => (
                    <div key={card.id} className="task-card-container">
                      <div className="task-card-info">
                        <div
                          className="card-name-displayed"
                          onClick={() => {
                            setOpenCard(true);
                            setCardDetails(card);
                          }}
                        >
                          {' '}
                          {card.title?.length > 20
                            ? `${card.title.slice(0, 20)}...`
                            : card.title}{' '}
                        </div>
                        <div
                          className="card-description"
                          onClick={() => {
                            setOpenCard(true);
                            setCardDetails(card);
                          }}
                        >
                          {card.description?.length > 80
                            ? `${card.description.slice(0, 80)}...`
                            : card.description}
                        </div>
                      </div>
                      <div className="card-buttons">
                        <div
                          className="card-date"
                          style={{
                            textDecoration: card.deadline
                              ? 'line-through'
                              : undefined,
                          }}
                        >
                          <img
                            src={'/deadline-icon.svg'}
                            alt="deadline icon"
                            height="15px"
                            width="15px"
                          />{' '}
                          <div style={{ height: '10px' }}>
                            {' '}
                            {card.deadline
                              ? formatDate(card.deadline)
                              : 'No deadline set'}
                          </div>
                        </div>
                        {allowEditorActions ? (
                          <div className="card-buttons">
                            <div
                              className="card-button"
                              onClick={() => handleEditCard(card)}
                            >
                              <img src={'/pencil-card.svg'} alt="edit card" />
                            </div>
                            <div
                              className="card-button"
                              onClick={() =>
                                handleUpdateActionTask(card, 'in_progress')
                              }
                            >
                              <img src={'/right-arrow.svg'} alt="move card" />
                            </div>
                            <img
                              src={'/card-bin.svg'}
                              alt="delete card"
                              onClick={() => openDeleteCardPopup(card.id)}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>

              {cardDetails ? (
                <ExistingCard
                  setCounterChange={setCounterChange}
                  permission={planDetails.my_permission}
                  cardDetails={cardDetails}
                  isOpen={openCard}
                  onClose={() => setOpenCard(false)}
                  setCardEdit={setCardEdit}
                />
              ) : null}
              {cardDetails ? (
                <EditCardPopup
                  setCounterChange={setCounterChange}
                  cardDetails={cardDetails}
                  cardEdit={cardEdit}
                  onClose={() => setCardEdit(false)}
                />
              ) : null}
              {openSharePopup ? (
                <MentorsPopup
                  setChangedOwnership={setChangedOwnership}
                  planId={planDetails.id}
                  open={openSharePopup}
                  handleClose={() => setOpenSharePopup(false)}
                />
              ) : null}
              <NewCard
                cardDetails={cardDetails}
                setCounterChange={setCounterChange}
                isOpen={open}
                planId={planId}
                status={ticketStatus ? ticketStatus : ''}
                onClose={() => setOpen(false)}
              />
            </div>
            {deletePlanPopup ? (
              <DeletePlanPopup
                open={deletePlanPopup}
                onClose={() => setDeletePlanPopup(false)}
                handleDeleteSelectedPlan={deletePlan}
                deletePlan={planDetails.id}
              />
            ) : null}
            {deleteCardPopup ? (
              <DeleteCardPopup
                setCounterChange={setCounterChange}
                open={deleteCardPopup}
                onClose={() => setDeleteCardPopup(false)}
                deleteCardId={deleteCardId ? deleteCardId : 0}
              />
            ) : null}
          </div>
        ) : (
          <PageNotFound />
        )}
      </div>
    );
  } else return <PageNotFound />;
};

export default PlanDetails;

export const PlanDetailsManagement = () => {
  const { width } = useWindowSize();
  const isMobile = width && width < 950;
  if (isMobile) return <PlanDetailsMobile />;
  else return <PlanDetails />;
};
