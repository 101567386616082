import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { businessHealthColors } from '../../utils/businessHealthColors';
import useWindowSize from '../../hooks/useWindowSize';
import HelpIcon from '@mui/icons-material/Help';

interface Props {
  business_health: number;
}

const BusinessHealthTooltip = ({ business_health }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);

  return (
    <div
      className="business-health-icon"
      style={{
        backgroundColor: businessHealthColors[business_health]?.color,
      }}
    >
      <Tooltip
        open={showTooltip}
        onClick={() => setShowTooltip(true)}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        leaveTouchDelay={isMobile ? 5000 : 1500}
        title={
          <React.Fragment>
            <div className="health-tooltip-container">
              <div className="health-tooltip-title">
                Business Health: {businessHealthColors[business_health].text}
              </div>
              <div className="health-descriptions">
                This displays the mentee’s view on their business’s current
                health. You may wish to support business in poor health first.
              </div>
              <div className="business-health-colors">
                <div
                  className="color-box"
                  style={{
                    backgroundColor: businessHealthColors[1].color,
                  }}
                >
                  1
                </div>
                <div
                  className="color-box"
                  style={{
                    backgroundColor: businessHealthColors[2].color,
                  }}
                >
                  2
                </div>
                <div
                  className="color-box"
                  style={{
                    backgroundColor: businessHealthColors[3].color,
                  }}
                >
                  3
                </div>
                <div
                  className="color-box"
                  style={{
                    backgroundColor: businessHealthColors[4].color,
                  }}
                >
                  4
                </div>
                <div
                  className="color-box"
                  style={{
                    backgroundColor: businessHealthColors[5].color,
                  }}
                >
                  5
                </div>
              </div>
              <div className="health-descriptions">
                <div>Very poor</div>
                <div>Neutral</div>
                <div>Very good</div>
              </div>
            </div>
          </React.Fragment>
        }
      >
        <IconButton sx={{ fontSize: 'small', padding: 0, marginLeft: '4px' }}>
          <div className="business-health-tooltip">
            <div> Health</div>
            <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
          </div>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default BusinessHealthTooltip;
