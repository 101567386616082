import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { VolunteerProfile } from '../types';
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import '../style/profileCompletion.scss';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import { CustomTypography } from '../../../styling/generalStyling';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useGetProfile } from '../functions';
import useWindowSize from '../../../hooks/useWindowSize';

function CircularProgressWithLabel(value: any) {
  return (
    <Box sx={{ position: 'absolute' }}>
      <CircularProgress
        sx={{ color: 'white', position: 'absolute' }}
        size="100px"
        className="circle"
        variant="determinate"
        value={100}
      />
      <CircularProgress
        sx={{ color: '#ff5f5f', position: 'absolute' }}
        size="100px"
        className="top-circle"
        variant="determinate"
        value={value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100px',
          width: '100px',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="black"
          style={{ fontSize: '24px' }}
        >
          {`${parseInt(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
const checkUncompletedFields = (profile: VolunteerProfile) => {
  var pendingFields = [];
  if (profile.profile_picture === null) pendingFields.push('Add photo');
  if (profile.first_name === null) pendingFields.push('Add name');
  if (profile.gender === null) pendingFields.push('Add gender');
  if (profile.summary === null || profile.summary?.length === 0)
    pendingFields.push('Add introduction');
  if (profile.ethnicity === null) pendingFields.push('Add ethnicity');
  if (profile.countryName === null) pendingFields.push('Add location');
  if (profile.employer === null) pendingFields.push('Add Employer');
  if (
    profile.employment_history === null ||
    profile.employment_history?.length === 0
  )
    pendingFields.push('Add employment history');
  return pendingFields;
};

const ProfileCompletion = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  const totalFields = 8;
  const profile = useGetProfile();
  const uncompletedFields = checkUncompletedFields(profile);
  const progress = (
    ((totalFields - uncompletedFields.length) / totalFields) *
    100
  ).toFixed(0);
  return (
    <div className="profile-completion__Container">
      <div className="profile-completion-top-line">
        <div className="profile-input_name">Profile Completion</div>
        <div className="profile-completion-tooltip">
          <div className="profile-input_name">Why</div>
          <Tooltip
            open={showTooltip}
            onClick={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
            leaveTouchDelay={isMobile ? 5000 : 1500}
            placement={isMobile ? 'top-start' : 'right'}
            title="Your profile helps mentees decide if you have the right skills and background to help them."
          >
            <IconButton sx={{ fontSize: 'small', marginTop: '24px' }}>
              <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="profile-completion-statistics">
        {CircularProgressWithLabel(progress)}
        <List sx={{ marginLeft: '100px' }}>
          {uncompletedFields.map((value, index) => (
            <ListItem
              key={index}
              sx={{
                '.MuiListItemIcon-root': {
                  minWidth: '20px',
                  marginRight: '5px',
                },
              }}
            >
              <ListItemIcon>
                <CircleOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={<CustomTypography>{value}</CustomTypography>}
              />
            </ListItem>
          ))}
          {uncompletedFields.length === 0 ? (
            <div className="profile-completed-text">
              Your profile is complete. Thank you!
            </div>
          ) : null}
        </List>
      </div>
    </div>
  );
};

export default ProfileCompletion;
