import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import PreferenesInfo from './PreferenesInfo';
import './style/index.css';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { CustomHeaderTypography } from '../../styling/generalStyling';
import { TabsList, Tab } from '../../styling/tabs';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}
let display = 0;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  if (value === 'skills') display = 0;
  else if (value === 'schedule') display = 1;
  else if (value === 'language') display = 2;
  return (
    <div
      role="tabpanel"
      hidden={display !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    ></div>
  );
}
const Preferences = ({ tab }: { tab: string }) => {
  let history = useNavigate();

  const tabs = [
    {
      label: 'Skills',
      link: 'skills',
    },
    {
      label: 'Mentoring Days and Times',
      link: 'schedule',
    },
    {
      label: 'Language Preferences',
      link: 'language',
    },
    {
      label: 'Who to Support',
      link: 'mentee',
    },
  ];

  const displayIcon = (label: string) => {
    if (label === 'Skills') return <HistoryEduOutlinedIcon />;
    else if (label === 'Mentoring Days and Times')
      return <DateRangeOutlinedIcon />;
    else if (label === 'Language Preferences') return <TranslateOutlinedIcon />;
    else if (label === 'Who to Support')
      return <SettingsAccessibilityOutlinedIcon />;
    else return null;
  };
  const [value, setValue] = React.useState(tab);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    history(`/dashboard/preferences/${newValue}`, { replace: true });
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  return (
    <div className="mentoring-preferences-page">
      <div>
        <CustomHeaderTypography>Preferences</CustomHeaderTypography>
        <TabsUnstyled value={value} onChange={handleChange}>
          <TabsList>
            {tabs.map((tab) => (
              <Tab key={tab.link} value={tab.link} {...a11yProps(0)}>
                <div className="dashboard-tab-icon-label" key={tab.link}>
                  {displayIcon(tab.label)} {tab.label}
                </div>
              </Tab>
            ))}
          </TabsList>
          <TabPanel value={value} index={0} key={0} />
          <TabPanel value={value} index={1} key={1} />
          <TabPanel value={value} index={2} key={2} />
        </TabsUnstyled>
      </div>
      <div className="container__info">
        <PreferenesInfo tab={value} />
      </div>
    </div>
  );
};
export default Preferences;
