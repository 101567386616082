import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardFilledButton } from '../../styling/buttons';

import styles from './not-found.module.scss';

const OrganisationNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <h1>Mentee not available anymore</h1>
      <p>
        We can't seem to find the page you're looking for. Click the button
        below to go to dashboard.
      </p>

      <DashboardFilledButton
        variant="contained"
        sx={{ width: '250px', marginTop: '10px' }}
        onClick={() => navigate('/dashboard/actions')}
      >
        Go to dashboard
      </DashboardFilledButton>
    </div>
  );
};

export default OrganisationNotFound;
