import React, { useRef } from 'react';
import moment from 'moment-timezone';
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from 'react-share';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import { toTitleCase } from '../../utils/text';
import { getMasterclassSocialText } from '../../utils/social';
import './social-share-button.scss';
import { defaultTimeFormat } from '../../utils/constants';
import { RequestDetailsButton } from '../../components/Dashboard/NewUpcomingCard/style';
import useSelectProfileData from '../../hooks/useSelectProfileData';

type Props = {
  skill: string;
  link: string;
  hostName: string;
  eventType: string;
  hostCompanyName?: string;
  eventStartTime: string;
  type?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'link'
    | 'red'
    | 'dark'
    | 'ghost'
    | 'white';
  className?: string;
};

type SocialButtonProps = {
  platform: 'linkedin' | 'facebook' | 'twitter';
  icon?: React.ReactElement;
  text?: string;
  className?: string;
};

type SocialShareModalProps = {
  link?: string;
  eventType: string;
  subject: string;
  name: string;
  companyName?: string;
  startTime: string;
  className?: string;
  onCloseClick?: () => void;
};

const platforms = {
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  facebook: 'Facebook',
};

const icons = {
  linkedin: <img src="/linkedin-logo.svg" alt="linkedin" />,
  twitter: <img src="/twitter-logo.png" alt="twitter" />,
  facebook: <img src="/facebook-logo.png" alt="facebook" />,
};

const SocialButton = ({ platform, className }: SocialButtonProps) => {
  return (
    <div
      className={`social-share-button__social-button social-share-button__${platform} ${
        className ? className : ''
      }`}
    >
      {icons[platform]}
      <span className="social-share-button__social-button-text">
        {platforms[platform]}
      </span>
    </div>
  );
};

export const SocialButtons = ({
  skill,
  link,
  eventStartTime,
  eventType,
}: Props) => {
  const formattedTime = moment(eventStartTime).format(
    `${defaultTimeFormat} DD-MM-YYYY`
  );

  const { facebookText, twitterText, linkedinText } = getMasterclassSocialText({
    skill,
    link,
    eventType: toTitleCase(eventType),
    eventStartTime: formattedTime,
  });
  const newLink = link?.replace('volunteer', 'organisation');
  return (
    <div className="social-share-button__modal-inner-container">
      <div className="masterclass-share-title">
        Share this {toTitleCase(eventType)}
      </div>
      <div className="social-share-button__buttons-wrapper">
        <LinkedinShareButton
          url={newLink}
          title={linkedinText}
          summary={linkedinText}
        >
          <SocialButton platform="linkedin" />
        </LinkedinShareButton>
        <TwitterShareButton
          url={newLink}
          hashtags={[toTitleCase(skill).replace(/[\s+]|[/+]/g, '')]}
          title={twitterText}
        >
          <SocialButton platform="twitter" />
        </TwitterShareButton>
        <FacebookShareButton
          url={newLink}
          quote={facebookText}
          title={facebookText}
        >
          <SocialButton platform="facebook" />
        </FacebookShareButton>
      </div>
    </div>
  );
};

export const SocialShareModal = ({
  link,
  eventType,
  subject,
  name,
  companyName,
  startTime,
  className,
  onCloseClick,
}: SocialShareModalProps) => {
  let linkToUse = link ? link : window.location.href;
  const { toggles } = useSystemTogglesContext();
  const showSocialShare = !!toggles['GROUP_EVENT_SOCIAL_SHARE'];
  const inputRef = useRef<HTMLInputElement>(null);
  const { volunteerProfile } = useSelectProfileData();
  const partnerId = volunteerProfile?.partner;
  if (partnerId) linkToUse = linkToUse + `&id=${partnerId}`;
  // Check if copying is possible
  if (!document.queryCommandSupported('copy')) return null;

  const copyToClipboard = (e: any) => {
    inputRef?.current?.select();
    document.execCommand('copy');
    e?.target?.focus();
  };
  return (
    <div className={`social-share__modal ${className}`}>
      <div className="close-share-modal">
        {onCloseClick && (
          <IconButton sx={{ color: '#000' }} onClick={onCloseClick}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <div className="share-event-container">
        {showSocialShare && (
          <SocialButtons
            skill={subject}
            link={linkToUse}
            hostName={name}
            hostCompanyName={companyName}
            eventStartTime={startTime}
            eventType={eventType}
            type={'white'}
          />
        )}
        <div className="share-event-prompt-text">Or copy the link</div>
        <div className={'social-share__social-share-modal-copy-container'}>
          <input
            ref={inputRef}
            value={linkToUse}
            className={'social-share__social-share-modal-copy-input'}
          />
          <RequestDetailsButton onClick={copyToClipboard}>
            {' '}
            Copy{' '}
          </RequestDetailsButton>
        </div>
      </div>
    </div>
  );
};
