import React, { memo } from 'react';
import Linkify from 'react-linkify';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import './style.scss';
import { Message } from '../../../../types';
import api from '../../../../api';

interface MessageComponentProps {
  message?: any;
}
const getFileNameFromPath = (message: Message) => {
  const parts = message.message.split('/');
  if (parts) return parts?.pop(); // Removes the extension
  else return 'No name';
};
const getFilePath = (message: Message) => {
  const regex = /path=([^&]*)/;
  const match = message.message.match(regex);
  return match ? match[1] : null;
};
const MessageComponent = ({ message }: MessageComponentProps) => {
  const getFileInfo = async (filePath: string) => {
    return await api
      .get(`api/attachment/attachment/get?path=${filePath}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const contentType = response.headers['content-type'];
        const element = document.createElement('a');
        const file = new Blob([response.data], {
          type: contentType,
        });
        element.href = URL.createObjectURL(file);
        element.download = filePath;
        document.body.appendChild(element);
        element.click();
      });
  };

  const handleDownload = async (message: Message) => {
    const path = getFilePath(message);
    if (!path) {
      const element = document.createElement('a');
      element.href = message.message;
      element.target = '_blank';
      element.rel = 'noopener noreferrer';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else await getFileInfo(path);
  };
  const containerId = `messageContainer-${message.id}`;
  const yourself = message?.sent_by === 'volunteer';
  const renderMessageContent = (message: any) => {
    if (message.is_attachment)
      return (
        <div
          onClick={() => handleDownload(message)}
          className={`message-component-text ${yourself && 'right-side'}`}
        >
          <AttachFileIcon
            style={{ verticalAlign: 'middle', marginRight: '5px' }}
          />
          {getFileNameFromPath(message) || getFilePath(message)}
        </div>
      );
    else if (message.url) {
      return (
        <a href={message.url} target="_blank" rel="noreferrer">
          {message.url}
        </a>
      );
    } else {
      return <span>{message.message}</span>;
    }
  };

  return (
    <div className={`message-component-container ${yourself && 'align-right'}`}>
      <div className="message-component-photo-wrapper">
        {!yourself ? (
          <img
            className="message-component-photo"
            src={message?.organisation?.profile_picture || 'avatar.png'}
            alt="User"
          />
        ) : null}

        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) =>
            message.is_attachment ? (
              <button
                onClick={() => handleDownload(message)}
                key={key}
                className={`message-component__link-text ${
                  yourself ? 'message-components__link-text-right' : ''
                }`}
                style={{
                  background: 'none',
                  border: 'none',
                }}
              >
                {decoratedText}
              </button>
            ) : (
              <a
                target="blank"
                rel="noreferrer"
                href={decoratedHref}
                key={key}
                className={`message-component__link-text ${
                  yourself && 'message-components__link-text-right'
                }`}
                style={{ marginLeft: '5px' }}
              >
                {decoratedText}
              </a>
            )
          }
        >
          <div
            id={containerId}
            className={`message-component-text ${yourself && 'right-side'}`}
          >
            {message && renderMessageContent(message)}
          </div>
        </Linkify>
      </div>
    </div>
  );
};

export default memo(MessageComponent);
