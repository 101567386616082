import React, { useState } from 'react';
import './resources-mobile-filters.scss';
import { Taxonomy } from '../../types';

import CloseIcon from '@mui/icons-material/Close';
import RadioButton from '../RadioButton';
import ResourcesSearchFilters from '../ResourcesSearchFilters';
import { DashboardFilledButton } from '../../styling/buttons';

export interface ApplyFiltersProps {
  contentType: string | undefined;
  selectedSkillList: number[];
}

interface Props {
  visible: boolean;
  onClose: () => void;
  applyFilters: ({ contentType, selectedSkillList }: ApplyFiltersProps) => void;
  taxonomy: any;
  contentTypeData: {
    value: string;
    label: string;
  }[];
}

const ResourcesMobileFilters = ({
  visible,
  onClose,
  applyFilters,
  taxonomy,
  contentTypeData,
}: Props) => {
  const [contentType, setContentType] = useState<string | undefined>(undefined);
  const [searchTopics, setSearchTopics] = useState('');
  const [filteredTaxonomy, setFilteredTaxonomy] = useState<any>(taxonomy);
  const [selectedSkillList, setSelectedSkillList] = useState<number[]>([]);
  const [visibleIdList, setVisibleIdList] =
    useState<number[]>(selectedSkillList);
  const [selectAll, setSelectAll] = useState(false);

  const handleSearchTaxonomy = (e: any) => {
    setSearchTopics(e.target.value);

    const array = [...taxonomy] as Taxonomy[];
    const filtered = array
      .map((taxonomy) => {
        return (
          taxonomy?.child &&
          taxonomy?.child?.filter((child) =>
            child?.name?.toLowerCase().includes(e.target.value.toLowerCase())
          )?.length > 0 && {
            ...taxonomy,
            child: taxonomy?.child?.filter((child) =>
              child?.name?.toLowerCase().includes(e.target.value.toLowerCase())
            ),
          }
        );
      })
      ?.filter((e) => e);
    setFilteredTaxonomy(filtered);
  };

  const addRemoveSkillId = (skillId: number) => {
    let newSelectedSkillList: number[] = [];

    if (selectedSkillList.includes(skillId)) {
      const index = newSelectedSkillList.indexOf(skillId);
      newSelectedSkillList.splice(index, 1);
    } else {
      newSelectedSkillList.push(skillId);
    }

    setSelectAll(false);
    setSelectedSkillList(newSelectedSkillList);
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    setSelectedSkillList([]);
    setVisibleIdList([]);
  };

  const handleApplyFilters = () => {
    applyFilters({
      contentType,
      selectedSkillList,
    });
  };

  const handleFilterContentType = (selectedValue: any) => {
    if (contentType === selectedValue) {
      setContentType(undefined);
    } else {
      setContentType(selectedValue);
    }
  };

  return visible ? (
    <div className="resource-mobile-overlay" onClick={onClose}>
      <div
        className="resource-mobile-body"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="resource-mobile-close-container" onClick={onClose}>
          <CloseIcon />
        </div>

        <div className="resources-mobile-label">Filter content type</div>

        <div className="resources-mobile-content-type-wrapper">
          <div
            className="resources-mobile-content-type-container"
            key={-1}
            onClick={() => handleFilterContentType(undefined)}
          >
            <div
              className={`resouces-mobile-content-type-text ${
                contentType === undefined &&
                'resources-mobile-content-type-text-selected'
              }`}
            >
              All
            </div>
            <RadioButton selected={contentType === undefined} />
          </div>
          {contentTypeData.map((type, idx) => (
            <div
              className="resources-mobile-content-type-container"
              key={idx}
              onClick={() => handleFilterContentType(type.value)}
            >
              <div
                className={`resouces-mobile-content-type-text ${
                  contentType === type.value &&
                  'resources-mobile-content-type-text-selected'
                }`}
              >
                {type?.label}
              </div>
              <RadioButton selected={contentType === type.value} />
            </div>
          ))}
        </div>

        <div className="resources-mobile-label">Learning topics</div>
        <input
          value={searchTopics}
          onChange={handleSearchTaxonomy}
          placeholder="Search topics"
          className="resources-mobile-learning-input"
        />

        <div
          className="resources-mobile-selec-all-container"
          onClick={handleSelectAll}
        >
          <div
            className={`resouces-mobile-select-all-text ${
              selectAll && 'resources-mobile-select-all-text-selected'
            }`}
          >
            Select all
          </div>
          <RadioButton selected={selectAll} />
        </div>

        <ResourcesSearchFilters
          addRemoveSkillId={addRemoveSkillId}
          taxonomy={filteredTaxonomy}
          selectedSkillList={selectedSkillList}
          visibleIdList={visibleIdList}
          setVisibleIdList={setVisibleIdList}
        />

        <div className="resources-mobile-button-container">
          <DashboardFilledButton
            sx={{ width: '200px' }}
            variant="contained"
            onClick={handleApplyFilters}
          >
            Apply filters
          </DashboardFilledButton>
        </div>
      </div>
    </div>
  ) : null;
};

export default ResourcesMobileFilters;
