import React, { useState, useEffect } from 'react';
import { Partner } from '../../../types';
import api from '../../../api';
import { DashboardEmptyButton } from '../../../styling/buttons';

interface Props {
  partnerId: number;
  onSave: (partnerId: number) => void;
  relink?: boolean;
}
const EditPartnerVolunteer = ({ partnerId, onSave, relink = false }: Props) => {
  const [partner, setPartner] = useState<Partner>();

  useEffect(() => {
    (async () => {
      const partnerResponse = await api.get<Partner>(
        `api/volunteer/partners/${partnerId}/`,
        {}
      );
      setPartner(partnerResponse.data);
    })();
  }, [partnerId]);

  return (
    <div>
      <div className="profile-input_name">Partner Volunteer Program</div>
      {partner ? (
        <div>
          {!relink && (
            <div>
              You are currently sharing your call statistics (call time,
              frequency, call history) with {partner.name}. {partner.name} can
              see the number of mentoring calls you have had, and your call
              history on Digital Boost.
            </div>
          )}

          <div>
            <DashboardEmptyButton
              variant="outlined"
              sx={{
                padding: '8px 50px',
                margin: '4px 2px',
                marginTop: '10px',
                borderRadius: '20px',
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
              onClick={() => onSave(partnerId)}
            >
              {relink
                ? `Relink my account with ${partner.name}`
                : `Unlink my account with ${partner.name}`}
            </DashboardEmptyButton>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EditPartnerVolunteer;
