import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AddToCalendarButton from '../../atoms/AddToCalendarButton';
import Button from '../../atoms/Button';
import DropdownWithLabel from '../../components/DropdownWithLabel';
import useGetBoostCallInvite from '../../apiHooks/boostCallInvites/useGetBoostCallInvite';
import { getUrlParam } from '../../utils/url';

import './confirm-call.scss';
import useGetCallProviders from '../../apiHooks/coreData/useGetCallProviders';
import useGetHasOverlappingEvent from '../../apiHooks/boostCallRequests/useGetHasOverlappingEvent';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import ToolTip from '../../atoms/ToolTip';
import CallUrlInput from '../../atoms/CallUrlInput';

const ConfirmCall = () => {
  const navigate = useNavigate();
  const code = getUrlParam('code') as string;
  const {
    boostCallInviteData,
    acceptBoostCallInviteUtils,
    nonExpiredTimeslots,
    hasAlreadyAcceptedOneTimeslot,
    hasFinishedLoadingBoostCallInvite,
    boostCallInviteError,
    multipleSessionsNotAllowed,
    selectedTimeSlot,
  } = useGetBoostCallInvite({ code });
  const {
    acceptBoostCallInvite,
    acceptBoostCallInviteResponse,
    isLoadingAcceptBoostCallInvite,
    selectedTimeSlotId,
    setSelectedTimeSlotId,
    setSelectedCallProvider,
    callUrl,
    setCallUrl,
    needsCallUrl,
    acceptBoostCallInviteError,
  } = acceptBoostCallInviteUtils;
  const { userLocation, hasFinishedFetchingProfileAndLocation } =
    useSelectProfileData();
  const { timezone } = userLocation || {};

  const {
    callProviderDropDownOptions,
    defaultCallProvider,
    hasFinishedLoadingCallProviders,
  } = useGetCallProviders();

  const { hasOverlappingEvent, hasFinishedLoadingHasOverlappingEvent } =
    useGetHasOverlappingEvent({
      id: boostCallInviteData
        ? boostCallInviteData.boostcall_event_request_id
        : null,
      timeSlotId: selectedTimeSlotId as number,
    });

  const proposeAnotherTime = () => {
    window.location.href =
      '/propose-another-call?code=' + code + '&callUrl=' + callUrl;
  };

  const onConfirm = async () => {
    try {
      await acceptBoostCallInvite();
      window.scrollTo(0, 0);
    } catch (err) {}
  };

  if (
    !hasFinishedLoadingBoostCallInvite ||
    !hasFinishedFetchingProfileAndLocation ||
    !hasFinishedLoadingCallProviders
  ) {
    return (
      <div className="confirm-call__loading-container">
        {boostCallInviteError && (
          <h1 className="confirm-call__error ">{boostCallInviteError}</h1>
        )}
        <img
          className="confirm-call__loading-spinner"
          src="/loading.gif"
          alt="loading"
        />
      </div>
    );
  }

  if (boostCallInviteData?.volunteer_name) {
    if (multipleSessionsNotAllowed) {
      return (
        <div className="confirm-call confirm-call__container">
          <div className="confirm-call__content-wrapper">
            <h2>Sorry!</h2>
            <p>
              This mentoring request has already been accepted by another
              mentor.
            </p>
            <p>
              Thank you for volunteering - please keep an eye out for future
              requests!
            </p>
            <br />
            <Button
              text={'Back To Dashboard'}
              buttonType="tertiary"
              onClickFunction={() => {
                navigate('/dashboard');
              }}
            />
          </div>
        </div>
      );
    }

    if (acceptBoostCallInviteResponse) {
      return (
        <div className="thank-you-container">
          <div className="thank-you">
            <h3>
              Thank you {boostCallInviteData.volunteer_name}, <br />
              your call is confirmed!
            </h3>
            <p>
              We have sent you and your mentee,{' '}
              {boostCallInviteData.requester_name} from{' '}
              {boostCallInviteData.requester_company}, a confirmation email with
              the date, time and a link to the call.
              <br />
              <br />
              Thank you for volunteering with us.
              <br />
              The Digital Boost Team
            </p>
            <div className="button-wrapper">
              <Button
                text={'Back To Dashboard'}
                buttonType="secondary"
                onClickFunction={() => {
                  navigate('/dashboard');
                }}
              />
              {acceptBoostCallInviteResponse && timezone ? (
                <AddToCalendarButton
                  callInfo={acceptBoostCallInviteResponse}
                  type="primary"
                  text="Add Event To Calendar"
                  timeZoneString={timezone}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    } else {
      if (hasAlreadyAcceptedOneTimeslot) {
        return (
          <div className="appointments-expired-container">
            <div className="appointments-expired">
              <h3>You have already accepted a time-slot for this request.</h3>
              <p>
                Please visit your Dashboard to see details of the appointment.
              </p>
              <div className="button-wrapper">
                <Button
                  text="Change Mentoring Preferences"
                  buttonType="secondary"
                  onClickFunction={() => navigate('/dashboard/preferences')}
                />
                <Button
                  text="Go to Dashboard"
                  buttonType="primary"
                  onClickFunction={() => navigate('/')}
                />
              </div>
            </div>
          </div>
        );
      }
      if (
        !nonExpiredTimeslots?.length ||
        (selectedTimeSlot && selectedTimeSlot.status !== 'time_slot_pending')
      ) {
        return (
          <div className="appointments-expired-container">
            <div className="appointments-expired">
              <h3>Thank you, but this mentoring request has expired.</h3>
              <p>
                This can happen when all the timeslots selected have expired or
                the request has been accepted by someone else. Stay tuned for
                more mentoring requests that suit your skills!
              </p>
              <div className="button-wrapper">
                <Button
                  text="Change Mentoring Preferences"
                  buttonType="secondary"
                  onClickFunction={() => navigate('/dashboard/preferences')}
                />
                <Button
                  text="Back to Dashboard"
                  buttonType="primary"
                  onClickFunction={() => navigate('/dashboard')}
                />
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="confirm-call__container">
          <h1> Confirm your availability </h1>

          <h3>
            Select one of the below options. Your selected time is highlighted
          </h3>

          <div className="confirm-call__time-slots">
            {nonExpiredTimeslots?.map((availableTimeslot: any, i: number) => {
              return (
                <div className={'time-slot'} key={`time-slot-${i}`}>
                  <input
                    type="radio"
                    value={availableTimeslot.id}
                    checked={
                      !!selectedTimeSlotId &&
                      availableTimeslot.id === +selectedTimeSlotId
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSelectedTimeSlotId(+e.target.value);
                    }}
                    id={`timeslot-${availableTimeslot.id}`}
                  />
                  <label
                    className={
                      !!selectedTimeSlotId &&
                      availableTimeslot.id === +selectedTimeSlotId
                        ? 'selected'
                        : ''
                    }
                    htmlFor={`timeslot-${availableTimeslot.id}`}
                  >
                    {moment(availableTimeslot.start_time).format(
                      'MMMM Do YYYY, HH:mm'
                    )}
                  </label>
                </div>
              );
            })}
          </div>

          {hasFinishedLoadingHasOverlappingEvent && hasOverlappingEvent && (
            <p className="call-details__overlapping-warning">
              Note: You already have another session booked during the selected
              time slot.
            </p>
          )}

          {boostCallInviteData && (
            <>
              <div className="confirm-call__choose-call-provider-container">
                <h5>Choose the online meeting provider you prefer.</h5>
                <ToolTip
                  text='We know that everyone has a preferred video conference platform, so we allow you to use your own call link. This gives you more control over the experience. If you prefer not to do this, select "Set up a link for me" and we will take care of the rest.'
                  place="right"
                  id="provider-call-tooltip"
                  className="confirm-call__provider-call-tooltip"
                />
              </div>
              <div className="confirm-call__choose-call-provider-dropdown">
                <DropdownWithLabel
                  defaultValue={
                    boostCallInviteData.custom_call_provider?.id.toString() ||
                    defaultCallProvider?.id.toString()
                  }
                  options={callProviderDropDownOptions}
                  onChange={(e: any) =>
                    setSelectedCallProvider(e.target.value.toString())
                  }
                />
              </div>
              {needsCallUrl && (
                <>
                  <h5 className="confirm-call__call-url-input-text">
                    {boostCallInviteData.custom_call_provider
                      ? 'Create a meeting link in the platform you selected above. '
                      : 'Create a meeting link.'}{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://digitalboost.org.uk/custom-link-instruction"
                    >
                      (How to do that?)
                    </a>
                    <br />
                    Copy and pastethe session link into the field below. We'll
                    share it with your mentee and add it to your Dashboard.
                  </h5>
                  <div>
                    <CallUrlInput
                      callUrl={callUrl}
                      setCallURL={setCallUrl}
                      className="confirm-call__call-url-input"
                    />
                  </div>
                </>
              )}
            </>
          )}
          <h3
            className={`error ${
              !acceptBoostCallInviteError && 'confirm-call__error-hidden'
            }`}
          >
            {acceptBoostCallInviteError || '.'}
          </h3>
          <div className="confirm-call__button-container">
            <Button
              loading={isLoadingAcceptBoostCallInvite}
              buttonType={'tertiary'}
              text="Confirm Boost Call"
              onClickFunction={onConfirm}
            />

            <Button
              buttonType={'secondary'}
              text="Propose Another Time"
              onClickFunction={() => proposeAnotherTime()}
              className="propose-another-time-button"
            />
          </div>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default ConfirmCall;
