import useRedux from '../../hooks/useRedux';
import useAutoFetch from '../../hooks/useAutoFetch';
import {
  fetchBoostCallEventsAction,
  selectBoostCallEvents,
  selectHasFinishedLoadingBoostCallEvents,
  selectHasNotFetchedBoostCallEvents,
} from './index';
import {
  getCancelledEvents,
  getCompletedEvents,
  getUpcomingEvents,
} from './utils';

type Props = { id?: number; autoFetch: boolean };

const useGetBoostCallEvents = (
  { id, autoFetch = true }: Props = { autoFetch: true }
) => {
  // HOOKS
  const { useSelector, dispatch } = useRedux();
  useAutoFetch(fetchBoostCallEventsAction, selectHasNotFetchedBoostCallEvents, {
    autoFetch,
  });

  // ACTIONS
  const fetchBoostCallEvents = () => dispatch(fetchBoostCallEventsAction());

  // SELECTORS
  const boostCallEvents = useSelector(selectBoostCallEvents);
  const completedEvents = getCompletedEvents(boostCallEvents);
  const cancelledEvents = getCancelledEvents(boostCallEvents);
  const upcomingEvents = getUpcomingEvents(boostCallEvents);

  const hasFinishedLoadingBoostCallEvents = useSelector(
    selectHasFinishedLoadingBoostCallEvents
  );

  return {
    fetchBoostCallEvents,
    completedBoostCallEvents: completedEvents,
    cancelledBoostCallEvents: cancelledEvents,
    upcomingBoostCallEvents: upcomingEvents,
    hasFinishedLoadingBoostCallEvents,
    refetchBoostCallEvents: fetchBoostCallEvents,
  };
};

export default useGetBoostCallEvents;
