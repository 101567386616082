import { logRocketIdentify } from './auth';

import { UserContextType } from '../context/UserProfile';
import { UserLocation } from '../types';

import { FetchAllProfileData } from '../apiCalls/user';

type Props = {
  fetchAllProfileData: FetchAllProfileData;
  autoDetectTimezone?: (() => Promise<UserLocation>) | null;
  setUserProfileContext: UserContextType['setUserProfileContext'];
  setVolunteerProfileContext: UserContextType['setVolunteerProfileContext'];
  setUserLocationContext: UserContextType['setUserLocationContext'];
  setPartnerContext: UserContextType['setPartnerContext'];
};

// Fetch UserProfile, VolunteerProfile && UserLocation -> enforce auth protection
export const fetchAllProfileDataAndUpdateContext = async ({
  fetchAllProfileData,
  autoDetectTimezone,
  setUserProfileContext,
  setVolunteerProfileContext,
  setUserLocationContext,
  setPartnerContext,
}: Props) => {
  setUserProfileContext({ hasFinishedUserProfileFetch: false });
  setVolunteerProfileContext({ hasFinishedVolunteerProfileFetch: false });
  setUserLocationContext({ hasFinishedLocationFetch: false });
  setPartnerContext({ hasFinishedPartnerFetch: false });
  // Fetch UserProfile, VolunteerProfile && UserLocation
  const { userProfileResult, volProfileResult, userLocationResult } =
    await fetchAllProfileData();

  // Initialise LogRocket
  if (
    userProfileResult.status === 'fulfilled' &&
    !!userProfileResult.value &&
    volProfileResult.status === 'fulfilled' &&
    !!volProfileResult.value
  ) {
    try {
      logRocketIdentify({
        userProfile: userProfileResult.value,
        volunteerProfile: volProfileResult.value,
      });
    } catch (err) {
      console.log('err:', err);
    }
  }
  if (userProfileResult.status === 'fulfilled') {
    setUserProfileContext({
      userProfile: userProfileResult.value,
      hasFinishedUserProfileFetch: true,
    });
  } else {
    setUserProfileContext({ hasFinishedUserProfileFetch: true });
  }

  if (volProfileResult.status === 'fulfilled') {
    setVolunteerProfileContext({
      volunteerProfile: volProfileResult.value,
      hasFinishedVolunteerProfileFetch: true,
    });
  } else {
    setVolunteerProfileContext({ hasFinishedVolunteerProfileFetch: true });
  }

  if (userLocationResult.status === 'fulfilled' && !!userLocationResult.value) {
    if (!!autoDetectTimezone) {
      try {
        await autoDetectTimezone();
        setUserLocationContext({
          userLocation: userLocationResult.value,
          hasFinishedLocationFetch: true,
        });
      } catch (err) {
        setUserLocationContext({ hasFinishedLocationFetch: true });
      }
    }
  } else {
    setUserLocationContext({ hasFinishedLocationFetch: true });
  }
  return {
    userProfileResult,
    volProfileResult,
    userLocationResult,
  };
};
