import React, { useEffect } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import styles from './messaging-indicator.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import useRedux from '../../hooks/useRedux';
import {
  getUnreadMessagesCountAction,
  selectUnreadMessagesCount,
} from '../../apiHooks/messagingCentre';
import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

type Props = {
  className?: string;
};

const MessagingIndicator = ({ className }: Props) => {
  const navigate = useNavigate();
  const { useSelector, dispatch } = useRedux();

  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  useEffect(() => {
    dispatch(getUnreadMessagesCountAction());
  }, [dispatch]);
  const unreadMessagesCount = useSelector(selectUnreadMessagesCount);

  return showMessagingUI ? (
    <StyledBadge badgeContent={unreadMessagesCount} color="info">
      <div
        className={`${styles.container} ${className ? className : ''}`}
        onClick={() => navigate('/messaging-centre')}
      >
        <EmailOutlinedIcon fontSize="medium" />
      </div>
    </StyledBadge>
  ) : null;
};

export default MessagingIndicator;
