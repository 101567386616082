import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SiGooglecalendar } from 'react-icons/si';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { getUrlParam } from '../../utils/url';
import DropdownWithLabel from '../../components/DropdownWithLabel';
import useGetBoostCallInvite from '../../apiHooks/boostCallInvites/useGetBoostCallInvite';
import { defaultTimeFormat } from '../../utils/constants';
import useGetCallProviders from '../../apiHooks/coreData/useGetCallProviders';

import 'react-datepicker/dist/react-datepicker.css';
import './confirm-call.scss';
import './propose-another-call.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import ToolTip from '../../atoms/ToolTip';
import CallUrlInput from '../../atoms/CallUrlInput';
import { DashboardFilledButton } from '../../styling/buttons';

const ProposeAnotherCall = () => {
  const code = getUrlParam('code') as string;
  const navigate = useNavigate();
  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};

  const {
    boostCallInviteData,
    proposeNewTimeUtils,
    hasFinishedLoadingBoostCallInvite,
    multipleSessionsNotAllowed,
    hasAlreadyAcceptedOneTimeslot,
    hasAlreadyProposedNewTime,
  } = useGetBoostCallInvite({ code });
  const {
    volunteer_name: volunteerName,
    requester_name: requesterName,
    custom_call_provider: usesCustomCallProvider,
  } = boostCallInviteData || {};
  const {
    proposeNewTime,
    proposedStartDate,
    setProposedStartDate,
    hasSentProposeNewTime,
    setSelectedCallProvider,
    callUrl,
    setCallUrl,
    needsCallUrl,
    proposeNewTimeError,
  } = proposeNewTimeUtils || {};

  const {
    callProviderDropDownOptions,
    defaultCallProvider,
    hasFinishedLoadingCallProviders,
  } = useGetCallProviders();

  const proposeAnotherCall = async () => {
    try {
      await proposeNewTime();
      window.scrollTo(0, 0);
    } catch (err) {}
  };

  if (!hasFinishedLoadingBoostCallInvite || !hasFinishedLoadingCallProviders)
    return (
      <div className="propose-another-call__loading-container">
        {proposeNewTimeError && (
          <h1 className="propose-another-call__error propose-another-call__title-container">
            {' '}
            {proposeNewTimeError}
          </h1>
        )}
        <img
          className="propose-another-call__loading-spinner"
          src="/loading.gif"
          alt="loading"
        />
      </div>
    );

  if (hasAlreadyProposedNewTime) {
    return (
      <div className="thank-you-container  propose-another-call__container">
        <div className="thank-you">
          <h3>Thank you {volunteerName}</h3>
          <p>You have already proposed another time to {requesterName}.</p>
          <p>We will let you know as soon as we have heard from them.</p>
          <br />
          <br />
          <p>Regards,</p>
          <p>The Digital Boost Team</p>
          <br />
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '250px' }}
            onClick={() => navigate('/dashboard/actions')}
          >
            Return To Dashboard
          </DashboardFilledButton>
        </div>
      </div>
    );
  } else if (hasAlreadyAcceptedOneTimeslot) {
    return (
      <div className="thank-you-container  propose-another-call__container">
        <div className="thank-you">
          <h3>Thank you {volunteerName}</h3>
          <p>You have already accepted another time-slot for this request.</p>
          <p>Please see your dashboard for details on the scheduled call.</p>
          <br />
          <br />
          <p>Regards,</p>
          <p>The Digital Boost Team</p>
          <br />
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '250px' }}
            onClick={() => navigate('/dashboard/actions')}
          >
            Return To Dashboard
          </DashboardFilledButton>
        </div>
      </div>
    );
  } else if (hasSentProposeNewTime) {
    return (
      <div className="thank-you-container  propose-another-call__container">
        <div className="thank-you">
          <h3>Thank you {volunteerName}</h3>
          <p>
            We have let {requesterName} know you would like to meet, but are
            proposing another time.
          </p>
          <p>We will let you know as soon as we have heard from them.</p>
          <br />
          <br />
          <p>Regards,</p>
          <p>The Digital Boost Team</p>
          <br />
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '250px' }}
            onClick={() => navigate('/dashboard/actions')}
          >
            Return To Dashboard
          </DashboardFilledButton>
        </div>
      </div>
    );
  } else if (timezone) {
    if (multipleSessionsNotAllowed) {
      return (
        <div className="confirm-call propose-another-call__container">
          <div className="propose-another-call__content-wrapper">
            <h2>Sorry!</h2>
            <p>
              This mentoring request has already been accepted by another
              mentor.
            </p>
            <p>
              Thank you for volunteering - please keep an eye out for future
              requests!
            </p>
            <br />
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '250px' }}
              onClick={() => navigate('/dashboard/actions')}
            >
              Return To Dashboard
            </DashboardFilledButton>
          </div>
        </div>
      );
    }
    return (
      <div className="confirm-call propose-another-call__container">
        <div className="propose-another-call__content-wrapper">
          <h2>Propose another time for the call</h2>
          <p>
            Please select another date and time for your call request below.
          </p>
          <p>Your timezone is set to {timezone}.</p>
          <div className="propose-another-call__choose-times">
            <div className="c-container">
              <div className="date-container">
                <div className="calendar">
                  <SiGooglecalendar size={25} />
                </div>
                <DatePicker
                  placeholderText="Click to select a time"
                  selected={proposedStartDate as Date}
                  onChange={(date) => setProposedStartDate(date as Date)}
                  dateFormat={`dd MMMM yyyy ${defaultTimeFormat}`}
                  onInputClick={() =>
                    !proposedStartDate &&
                    setProposedStartDate(
                      moment()
                        .add(2, 'day')
                        .minute(Math.round(moment().minute() / 15) * 15)
                        .second(0)
                        .toDate()
                    )
                  }
                  showTimeSelect
                  filterDate={(date) => moment().add(1, 'day') < moment(date)}
                  timeIntervals={15}
                  disabledKeyboardNavigation
                />
              </div>
            </div>
            {boostCallInviteData && (
              <>
                <div className="propose-another-call__choose-call-provider-container">
                  <h5>Choose the online meeting provider you prefer.</h5>
                  <ToolTip
                    text='We know that everyone has a preferred video conference platform, so we allow you to use your own call link. This gives you more control over the experience. If you prefer not to do this, select "Set up a link for me" and we will take care of the rest.'
                    place="right"
                    id="provider-call-tooltip"
                    className="propose-another-call__provider-call-tooltip"
                  />
                </div>
                <div className="propose-another-call__choose-call-provider-dropdown">
                  <DropdownWithLabel
                    defaultValue={
                      boostCallInviteData.custom_call_provider?.id.toString() ||
                      defaultCallProvider?.id.toString()
                    }
                    options={callProviderDropDownOptions}
                    onChange={(e: any) =>
                      setSelectedCallProvider(e.target.value.toString())
                    }
                  />
                </div>
                {needsCallUrl && (
                  <>
                    <h5 className="propose-another-call__call-url-input-text">
                      {usesCustomCallProvider
                        ? 'Create a meeting link in the platform you selected. '
                        : 'Create a meeting link.'}{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://digitalboost.org.uk/custom-link-instruction"
                      >
                        (How to do that?)
                      </a>
                      <br />
                      Copy and paste the session link into the field below.
                      We'll share it with your mentee and add it to your
                      Dashboard.
                    </h5>
                    <div>
                      <CallUrlInput
                        callUrl={callUrl}
                        setCallURL={setCallUrl}
                        className="propose-another-call__call-url-input"
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <h3
            className={`error ${
              !proposeNewTimeError && 'propose-another-call__error-hidden'
            }`}
          >
            {proposeNewTimeError || '.'}
          </h3>
          <DashboardFilledButton
            variant="contained"
            sx={{ width: 'auto' }}
            onClick={() => proposeAnotherCall()}
          >{`Send new time to ${requesterName}`}</DashboardFilledButton>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ProposeAnotherCall;
