// NewCard.js

import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TaxonomyPopup from '../taxonomyPopup';
import './style.scss';
import SelectAvailabilityTask from '../selectAvailabilityTask';
import { createActionTask } from '../../../api/actionPlan';
import { CardTextField, DashboardFilledButton } from '../../../styling/buttons';
import { CardsType } from '../../../types';
interface NewCardProps {
  planId: string;
  isOpen: boolean;
  onClose: () => void;
  status: string;
  cardDetails: CardsType | undefined;
  setCounterChange: () => void;
}

const NewCardMobile = ({
  planId,
  onClose,
  isOpen,
  status,
  cardDetails,
  setCounterChange,
}: NewCardProps) => {
  const [newCardName, setNewCardName] = useState<string | undefined>(undefined);
  const color = status === 'todo' ? '#FCEBEB' : '#FFF9E4';
  const [newDate, setNewDate] = useState<string | undefined>(undefined);

  const [description, setDescription] = useState<string>('');
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  useEffect(() => {
    if (isOpen) {
      setNewCardName(undefined);
      setNewDate(undefined);
      setDescription('');
      setSelectedTaxonomy(undefined);
      setErrorMessage(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (cardDetails?.interest?.id)
      setSelectedTaxonomy(cardDetails?.interest?.id.toString());
  }, [cardDetails?.interest?.id]);
  const handleSaveCard = async () => {
    if (!newCardName || newCardName.trim().length < 1) {
      setErrorMessage('Please give the card a title');
      return;
    } else if (newCardName) {
      try {
        const result = await createActionTask(
          newCardName,
          parseInt(planId),
          newDate ? new Date(newDate) : undefined,
          description,
          parseInt(selectedTaxonomy),
          status
        );
        if (result.success) {
          setCounterChange();
          onClose();
        }
      } catch (error) {
        console.error('Error creating task:', error);
      }
    } else {
      setErrorMessage(' Name is required to create a task');
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        sx={{
          padding: 0,

          '.MuiPaper-root': {
            padding: 0,
            borderRadius: '15px',
            background: ' #FFF',
            width: '300px',
            minHeight: '372px',
            maxHeight: '90vh',
            paddingLeft: '10px',
            backgroundColor: `${color}`,
            maxWidth: '100vw',
            flexShrink: 0,
          },
        }}
      >
        <div className="close-dialog-icon">
          <div className="card-title">Add a new task</div>
          <IconButton
            color="primary"
            onClick={async () => {
              onClose();
            }}
            sx={{ padding: '0px', marginRight: '30px' }}
          >
            <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
          </IconButton>
        </div>

        <DialogContent sx={{ padding: 0, width: '280px' }}>
          <div className="empty-card-container">
            <div className="row-card-mobile">
              <div className="row-card-mobile-no-padding">
                <div className="table-text">Task name*</div>
                <CardTextField
                  inputProps={{ maxLength: 50 }}
                  placeholder="Type the task name"
                  sx={{
                    width: '240px',
                    margin: 0,
                  }}
                  type="text"
                  onChange={(e) => setNewCardName(e.target.value)}
                />
                {errorMessage ? (
                  <div className="error-message">{errorMessage}</div>
                ) : null}
              </div>
              <div className="row-card-mobile-no-padding">
                <div className="table-text"> Deadline</div>
                <SelectAvailabilityTask setNewDate={setNewDate} />
              </div>
              <div className="row-card-mobile-no-padding">
                <div className="table-text"> Topic</div>
                <TaxonomyPopup
                  setSelectedTaxonomy={setSelectedTaxonomy}
                  selectedTaxonomy={selectedTaxonomy}
                />
              </div>

              <div className="row-card-mobile-no-padding">
                <div className="table-text"> Description</div>
                <CardTextField
                  multiline
                  inputProps={{ maxLength: 800 }}
                  sx={{
                    width: '240px',
                  }}
                  placeholder="Add a description"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignSelf: 'center',
            paddingBottom: '30px',
            width: ' 100%',

            justifyContent: 'center',
          }}
        >
          <DashboardFilledButton
            variant="contained"
            onClick={handleSaveCard}
            sx={{
              width: '240px',
              height: '45px',
              padding: '12px 36px',
            }}
          >
            Save{' '}
          </DashboardFilledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewCardMobile;
