import React from 'react';
import './full-screen-loading.scss';

const FullScreenLoading = () => {
  return (
    <div className="full-screen-loading__container">
      <img src={'/loading.gif'} alt="loading" />
    </div>
  );
};

export default FullScreenLoading;
