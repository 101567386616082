import React from 'react';
import './signup-options.scss';
// import { useSelector } from 'react-redux';
// import { selectPartnerProfile } from '../../apiHooks/partnerProfile';

const SignUpOption = () => {
  // const partnerProfile = useSelector(selectPartnerProfile);

  // const linkedIn = () => {
  //   let linkedInURL = `${process.env.REACT_APP_API_URL}api/core/linkedin_login/`;
  //   if (partnerProfile) {
  //     linkedInURL += `?partner_id=${partnerProfile.id}`;
  //   }
  //   window.location.href = linkedInURL;
  // };

  return (
    <div className="sign-up">
      <h1> Welcome! Let’s set up your profile </h1>
      {/* 
      <div>
        Would you like to use LinkedIn to autofill your profile quickly?
      </div>

      <button className="linked-in-button" onClick={() => linkedIn()}>
        {' '}
        <img alt="linkedin" src="/linkedin.png" />{' '}
        <span>Autofill with Linkedin</span>{' '}
      </button>

      <div>
        You will be redirected to authorise Founders4Schools, our parent charity{' '}
      </div>

      <div className="border" /> */}

      <a href="/profile-create"> Click here to set up your profile manually </a>
    </div>
  );
};

export default SignUpOption;
