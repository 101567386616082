import { useAxios } from '../api';

const formatTaxonomyResponseData = (taxonomyResponseData: any) =>
  taxonomyResponseData &&
  taxonomyResponseData.results.reduce((acc: any[], skill: any) => {
    if (skill.parent) {
      if (acc[skill.parent]) {
        if (acc[skill.parent].child) {
          const existingChild = acc[skill.parent].child || [];
          return {
            ...acc,
            [skill.parent]: {
              ...acc[skill.parent],
              child: [...existingChild, skill],
            },
          };
        } else {
          return {
            ...acc,
            [skill.parent]: {
              ...acc[skill.parent],
              child: [skill],
            },
          };
        }
      } else {
        acc[skill.parent] = {
          ...acc[skill.parent],
          child: [skill],
        };
        return acc;
      }
    } else {
      if (!acc[skill.id]) {
        acc[skill.id] = {
          ...skill,
          child: [],
        };
      } else {
        acc[skill.id] = {
          ...skill,
          child: acc[skill.id].child,
        };
      }
      return acc;
    }
  }, {});

const useGetTaxonomy = (ids?: any[]) => {
  const searchIdString = ids ? ids.map((id: any) => `&id=${id}`).join('') : '';
  const [{ data, loading, error }, refetch] = useAxios(
    `api/taxonomy/interests/?limit=1000${searchIdString}`
  );
  const taxonomyDataFormatted = formatTaxonomyResponseData(data);
  const taxonomyById =
    data &&
    data.results.reduce((acc: any, { id, ...restCur }: any) => {
      acc[id] = { ...restCur };
      return acc;
    }, {});

  return {
    taxonomyData: data && data.results,
    taxonomyDataFormatted: taxonomyDataFormatted,
    taxonomyByParentFlat: Object.values(taxonomyDataFormatted || {}),
    taxonomyById: taxonomyById,
    hasFinishedLoadingTaxonomy: !loading,
    taxonomyError: error,
    refetchTaxonomy: refetch,
  };
};

export default useGetTaxonomy;
