import api from '../api';
import { getAccessToken, getRefreshToken } from '../utils/auth';
import {
  UserProfile,
  VolunteerProfile,
  UserLocation,
  Partner,
  PartnersResponse,
} from '../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPartnerProfileStatus,
  setPartnerProfile,
  setPartnerProfileStatus,
} from '../apiHooks/partnerProfile';
import { updateTheme } from '../redux/theme';

export const getUserProfile = async () => {
  const response = await api.get<UserProfile>(`api/core/users/me/`);
  if (response.status === 404) throw new Error('User Details not found');
  return response.data;
};

export const useFetchPremiumPartnerbySubdomain = async () => {
  const dispatch = useDispatch();

  let subdomain = window.location.host.split('.')[0];
  const partnerProfileStatus = useSelector(selectPartnerProfileStatus);

  if (partnerProfileStatus === 'init') {
    const partnerResponse = await api.get<PartnersResponse>(
      `/api/partner/partners/search/?subdomain=${subdomain}`
    );

    const partnerData = partnerResponse.data.results[0];
    if (partnerData) {
      dispatch(setPartnerProfile(partnerData)); // Dispatch the action to update Redux store
      dispatch(setPartnerProfileStatus('fulfilled'));
      if (partnerData?.white_label) {
        var PartnerTheme = {
          palette: {
            primary: {
              main: partnerData?.primary_color || '#583372',
              light: partnerData?.tertiary_color || '#f2f2f2',
              dark: '#f2f2f2',
              contrastText: partnerData?.contrast_text_primary || '#FFFFFF',
            },
            secondary: {
              main: partnerData?.secondary_color || '#f7f7f7',
            },
          },
        };
        dispatch(updateTheme(PartnerTheme));
      }

      return Promise.resolve(partnerData);
    } else {
      dispatch(setPartnerProfileStatus('rejected'));
      return Promise.resolve(undefined);
    }
  } else return;
};

export const getVolProfile = async () => {
  if (!!getAccessToken() || !!getRefreshToken()) {
    const response = await api.get<VolunteerProfile>(
      `api/volunteer/volunteers/me/`
    );

    if (response.data.partner) {
      const partnerResponse = await api.get<Partner>(
        `api/partner/partners/${response.data.partner}`
      );

      response.data.partnerInfo = partnerResponse.data;
    }

    return response.data;
  }
};

export const getUserLocation = async () => {
  try {
    if (!!getAccessToken() || !!getRefreshToken()) {
      const response = await api.get<UserLocation>(
        `api/location/locations/me/`
      );
      return response.data;
    } else {
      throw new Error('Authentication tokens are not available');
    }
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error fetching user location: ${error.message}`);
    } else {
      throw error;
    }
  }
};

export type FetchAllProfileData = () => Promise<{
  userProfileResult: PromiseSettledResult<UserProfile | undefined>;
  volProfileResult: PromiseSettledResult<VolunteerProfile | undefined>;
  userLocationResult: PromiseSettledResult<UserLocation | undefined>;
}>;

export const fetchAllProfileData: FetchAllProfileData = async () => {
  // Promise.allSettled will return either:
  //   { status: "fulfilled", value: value },
  //   { status: "rejected",  reason: Error: an error }
  const [userProfileResult, volProfileResult, userLocationResult] =
    await Promise.allSettled([
      getUserProfile(),
      getVolProfile(),
      getUserLocation(),
    ]);
  return {
    userProfileResult,
    volProfileResult,
    userLocationResult,
  };
};
