import { useAxios } from '../../api';
import { CallProvider, CallProviders } from '../../types';

const useCallProviders = () => {
  const [{ data, loading, error }, refetch] = useAxios<CallProviders>(
    `api/core/callprovider/?limit=1000`
  );

  const callProviders = data?.results;
  const callProviderDropDownOptions = callProviders?.map((item) => {
    return {
      text:
        item.name === 'Jitsi'
          ? 'Set up a Jitsi link for me'
          : item.display_name,
      value: item.id.toString(),
    };
  });

  const sortedCallProviderDropDownOptions = callProviderDropDownOptions
    ?.filter(({ value }: any) => value !== '3')
    .concat(
      callProviderDropDownOptions?.filter(({ value }: any) => value === '3')
    );

  const defaultCallProvider = callProviders?.filter(
    ({ name }: CallProvider) => name === ''
  )?.[0];

  return {
    callProviders: callProviders || [],
    callProviderDropDownOptions: sortedCallProviderDropDownOptions || [],
    defaultCallProvider: defaultCallProvider,
    hasFinishedLoadingCallProviders: !loading,
    callProviderError: error,
    refetchCallProviders: refetch,
  };
};

export default useCallProviders;
