import React from 'react';
import { IoIosClose } from 'react-icons/io';
import './tag.scss';

interface Props {
  text: string;
  onRemove?: () => void;
  type?: 'primary' | 'secondary' | 'tertiary';
  onClick?: () => void;
  className?: string;
  dataCy?: string;
}

const Tag = ({
  onRemove,
  text,
  type = 'primary',
  onClick,
  className,
  dataCy,
  ...props
}: Props) => {
  return (
    <span
      className={`tag tag-${type} ${!!onClick ? 'tag__clickable' : ''} ${
        className ? className : ''
      }`}
      onClick={onClick || onRemove || (() => {})}
      data-cy="selected-skill"
      {...props}
    >
      {text} {onRemove && <IoIosClose size={22} />}
    </span>
  );
};
export default Tag;
