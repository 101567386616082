import React, { memo, useCallback, useEffect, useState } from 'react';
import { BoostCallEvent, GroupEvent } from '../../types';
import { getUrlParam } from '../../utils/url';
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreenLoading from '../FullScreenLoading';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import DashboardInfo from './dashboardInfo';
import useLogin from '../../apiHooks/auth/useLogin';
import useWindowSize from '../../hooks/useWindowSize';
import './dashboard.scss';
import {
  bookingsBadge,
  displayIcon,
  requestsBadge,
  TabPanel,
  tabs,
} from './utilities';
import { useGetPreferences } from '../../pages/Preferences/functions';

import useGetTotalSessions from '../../apiHooks/stats/useGetTotalSessions';
import useGetBoostCallRequests from '../../apiHooks/boostCalls/useGetBoostCallRequests';
import useGetGroupEventScheduled from '../../apiHooks/groupEvents/useGetGroupEventScheduled';
import useGetGroupEventRequests from '../../apiHooks/groupEvents/useGetGroupEventRequests';
import useGetBoostCallEventsScheduled from '../../apiHooks/boostCalls/useGetBoostCallEventsScheduled';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useGetTotalOrgsHelped from '../../apiHooks/stats/useGetTotalOrgsHelped';
import useGetBoostCallCommunityRequests from '../../apiHooks/boostCalls/useGetBoostCallCommunityRequests';
import useGetBoostCallEventsCompleted from '../../apiHooks/boostCalls/useGetBoostCallEventsCompleted';
import useGetBoostCallGroupEventsCompleted from '../../apiHooks/boostCalls/useGetBoostCallGroupEventsCompleted';
import useGetFollowUpRequests from '../../apiHooks/followUpRequests/useGetFollowUpRequests';
import { Badge } from '@mui/material';
import { Tab, TabsList } from '../../styling/tabs';
import { useUserProfile } from '../../context/UserProfile';
import DashboardCompleteProfile from './components/dashboardCompleteProfile';
import { useSystemTogglesContext } from '../../context/SystemToggles';

interface Props {
  tab: string;
  subTab?: string;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Dashboard = ({ tab, subTab }: Props) => {
  const { logout } = useLogin();
  if (tab === 'logout') logout();
  const { toggles } = useSystemTogglesContext();
  const showActionPlan = toggles['ACTION_PLAN'];
  const [isMobile, setIsMobile] = useState(false);
  const { width = window.innerWidth } = useWindowSize();

  let history = useNavigate();
  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};
  var allRequests: any[] = [];
  const st = getUrlParam('st');
  const [loadedOnce, setLoadedOnce] = useState(false);
  let { totalSessions } = useGetTotalSessions();
  const { volunteerProfile } = useUserProfile();
  const [displayTabs, setDisplayTabs] = useState(tabs);
  useEffect(() => {
    if (
      volunteerProfile?.partner !== null &&
      volunteerProfile?.partnerInfo?.hide_resources === true
    ) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'Resources');
      setDisplayTabs(updatedTabs);
    }
  }, [volunteerProfile]);
  if (totalSessions === undefined) totalSessions = [];
  useEffect(() => {
    if (!showActionPlan) {
      const updatedTabs = tabs.filter((tab) => tab.label !== 'Action Plans');
      setDisplayTabs(updatedTabs);
    }
  }, [showActionPlan]);

  const {
    groupEventRequestData,
    refetchGroupEventRequest,
    hasFinishedLoadingGroupEventRequest,
  } = useGetGroupEventRequests({ status: 'invited' });

  const { boostCallGroupEventsCompleted } =
    useGetBoostCallGroupEventsCompleted();

  const {
    boostCallRequests,
    hasFinishedLoadingBoostCallRequests,
    refetchBoostCallRequests,
  } = useGetBoostCallRequests();

  const interests = useGetPreferences().interests || [];
  const {
    boostCallCommunityRequests,
    refetchBoostCallCommunityRequests,
    hasFinishedLoadingBoostCallCommunityRequests,
  } = useGetBoostCallCommunityRequests(interests);

  const { groupEventScheduledData, refetchGroupEventScheduled } =
    useGetGroupEventScheduled({});

  const { boostCallEventsCompleted, refetchBoostCallEventsCompleted } =
    useGetBoostCallEventsCompleted();

  const {
    boostCallEventsScheduled,
    hasFinishedLoadingBoostCallEventsScheduled,
    refetchBoostCallEventsScheduled,
  } = useGetBoostCallEventsScheduled();

  const {
    pendingFollowUpRequests,
    hasFinishedLoadingFollowUpRequest,
    refetchFollowUpRequest,
  } = useGetFollowUpRequests(); // FollowUpRequests

  const upcomingCallsAndGroupEvents = [
    ...(groupEventScheduledData || []),
    ...boostCallEventsScheduled,
  ].sort(
    (a: BoostCallEvent | GroupEvent, b: BoostCallEvent | GroupEvent) =>
      new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
  );

  const refetchEvents = useCallback(() => {
    refetchBoostCallEventsScheduled();
    refetchBoostCallEventsCompleted();
    refetchGroupEventRequest();
    refetchBoostCallRequests();
    refetchBoostCallCommunityRequests();
    refetchGroupEventScheduled();
    refetchFollowUpRequest();
  }, [
    refetchBoostCallEventsScheduled,
    refetchBoostCallEventsCompleted,
    refetchBoostCallCommunityRequests,
    refetchGroupEventRequest,
    refetchBoostCallRequests,
    refetchGroupEventScheduled,
    refetchFollowUpRequest,
  ]);
  const { totalOrgsHelped } = useGetTotalOrgsHelped();

  const isLoading =
    !hasFinishedLoadingBoostCallRequests ||
    !hasFinishedLoadingBoostCallEventsScheduled ||
    !hasFinishedLoadingGroupEventRequest ||
    !hasFinishedLoadingBoostCallCommunityRequests ||
    !hasFinishedLoadingFollowUpRequest;

  const addToCalendarEvents = useCallback(async () => {
    if (st) {
      boostCallEventsScheduled.find((event) => event.start_time === st);
    }
  }, [st, boostCallEventsScheduled]);

  useEffect(() => {
    addToCalendarEvents();
    // @ts-ignore
    setTimeout(() => window.addeventatc?.refresh(), 1000);
  });

  useEffect(() => {
    const handleResize = () => {
      if (width < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
  }, [width]);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/dashboard/actions')) {
      refetchEvents();
      setLoadedOnce(true);
    }
  }, [location.pathname, refetchEvents]);

  const data = {
    communityRequests: boostCallCommunityRequests,
    completedBoostCallEvents: boostCallEventsCompleted,
    boostCallGroupEventsCompleted: boostCallGroupEventsCompleted,
    upcomingCallsAndGroupEvents: [
      ...(groupEventScheduledData || []),
      ...boostCallEventsScheduled,
    ],
    upcomingRequests: boostCallRequests,
    groupEventRequestData: groupEventRequestData,
    refetchGroupEventRequest: refetchGroupEventRequest,
    timezone: timezone,
    totalSessions: totalSessions,
    orgsHelped: totalOrgsHelped,
    interests: interests,
    followupRequests: pendingFollowUpRequests,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    if (newValue === 6) logout();
    else {
      history(`/dashboard/${newValue}`);
    }
  };
  if (groupEventRequestData !== undefined)
    allRequests = [
      ...boostCallRequests.filter((event) => event.organisation !== null),
      ...groupEventRequestData,
    ];
  else
    allRequests = [...boostCallRequests].filter(
      (event) => event.organisation !== null
    );

  if (volunteerProfile === null || volunteerProfile === undefined)
    return <DashboardCompleteProfile />;
  else if (!isLoading || loadedOnce)
    return (
      <div>
        {isMobile ? (
          <div className="dashboard-container-info">
            <DashboardInfo tab={tab} data={data} subTab={subTab} />
          </div>
        ) : (
          <div className="container__dashboard-page">
            <div>
              <TabsUnstyled value={tab} onChange={handleChange}>
                <TabsList>
                  {displayTabs.map((tab) => (
                    <Tab key={tab.link} value={tab.link} {...a11yProps(0)}>
                      <div className="dashboard-tab-icon-label" key={tab.link}>
                        {displayIcon(tab.label)} {tab.label}{' '}
                        {tab.label === 'Bookings'
                          ? bookingsBadge(upcomingCallsAndGroupEvents)
                          : null}{' '}
                        {tab.label === 'Requests' && allRequests.length > 0 ? (
                          requestsBadge(allRequests)
                        ) : tab.label === 'Requests' &&
                          boostCallCommunityRequests.length > 0 ? (
                          <Badge
                            sx={{
                              marginLeft: '15px',
                              '& .MuiBadge-badge': {
                                color: 'white',
                                backgroundColor: '#ff5f5f',
                              },
                            }}
                            overlap="circular"
                            badgeContent="!"
                          />
                        ) : null}
                      </div>
                    </Tab>
                  ))}
                </TabsList>
                <TabPanel value={tab} index={0} key={0} />
                <TabPanel value={tab} index={1} key={1} />
                <TabPanel value={tab} index={2} key={2} />
                <TabPanel value={tab} index={3} key={3} />
                <TabPanel value={tab} index={4} key={4} />
                <TabPanel value={tab} index={5} key={5} />
              </TabsUnstyled>
            </div>
            <div className="dashboard-container-info">
              <DashboardInfo tab={tab} subTab={subTab} data={data} />
            </div>
          </div>
        )}
      </div>
    );
  else return <FullScreenLoading />;
};

export default memo(Dashboard);
