import { useState } from 'react';
import { useAxios } from '../../api';
import { BoostCallEvent } from '../../types';

type Props = {
  id: number | string;
};
type RescheduleBoostCallProps = {
  newStartDateTime: string;
  newCallUrl?: string;
};

const useRescheduleBoostCall = ({ id }: Props) => {
  const [hasFinishedRescheduling, setHasFinishedRescheduling] = useState(false);

  const [{ data, loading, error }, executeCall] = useAxios<BoostCallEvent>(
    {
      url: `api/event/boostcallevents/${id}/reschedule/`,
      method: 'POST',
    },
    { manual: true }
  );

  const typedRescheduleBoostCall = async ({
    newStartDateTime,
    newCallUrl,
  }: RescheduleBoostCallProps) => {
    const newCallUrlParam = newCallUrl ? { new_call_url: newCallUrl } : {};
    try {
      await executeCall({
        data: {
          new_start_datetime: newStartDateTime,
          ...newCallUrlParam,
        },
      });
      setHasFinishedRescheduling(true);
    } catch (err) {}
  };

  return {
    rescheduleBoostCall: typedRescheduleBoostCall,
    rescheduleBoostCallData: data,
    isLoadingRescheduleBoostCall: loading,
    hasFinishedLoadingRescheduleBoostCall: hasFinishedRescheduling,
    rescheduleBoostCallError: error,
  };
};

export default useRescheduleBoostCall;
