import React from 'react';
import './text-area-with-label.scss';

interface Props {
  label?: string | undefined;
  placeHolder?: string | undefined;
  onInput?: any;
  rows?: number;
  cols?: number;
  defaultValue?: any;
  readOnly?: boolean;
  className?: string;
}

const TextAreadWithLabel = ({
  label,
  placeHolder,
  defaultValue,
  rows,
  cols,
  onInput = () => {
    console.log('onInput');
  },
  className,
}: Props) => {
  return (
    <div className={`text-area-with-label ${className ? className : ''}`}>
      {label && <label>{label}</label>}

      <textarea
        onInput={onInput}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        rows={rows}
        cols={cols}
      />
    </div>
  );
};

export default TextAreadWithLabel;
