import { useAxios } from '../../api';

const useGetBoostCallCommunityRequests = (interests: number[]) => {
  var baseURL = `api/event/boostcallrequests/pending-boostcall-requests/?`;
  for (var interest in interests) {
    baseURL = baseURL + `interests=${interests[interest]}&`;
  }
  baseURL = baseURL.slice(0, -1);

  const [{ data, loading, error }, refetch] = useAxios<any>({
    url: baseURL,
  });
  return {
    boostCallCommunityRequests: data?.results || [],
    hasFinishedLoadingBoostCallCommunityRequests: !loading,
    boostCallCommunityRequestError: error,
    refetchBoostCallCommunityRequests: refetch,
  };
};
export default useGetBoostCallCommunityRequests;
