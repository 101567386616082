import api from '../api';
import { getErrorMessageFromResponse } from '../utils/error';

export const getSystemToggles = async () => {
  try {
    const response = await api.get(`api/systemtoggle/systemtoggle/?limit=100`);

    if (response) {
      return response.data;
    }
  } catch (err) {
    const errorMessage = getErrorMessageFromResponse(err);
    console.log('systemToggles fetch error:', errorMessage);
    return false;
  }
};
