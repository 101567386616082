import React from 'react';
import ButtonWithDropdown from '../../../atoms/ButtonWithDropdown';

import './style.scss';

interface MessageActionButtonProps {
  clickBlock?: () => void;
  blocked: boolean;
}

const MessageActionButton = ({
  clickBlock,
  blocked,
}: MessageActionButtonProps) => (
  <ButtonWithDropdown
    className="message-preview-dropdown-button"
    dropdownClassName="message-preview-dropdown"
    buttonContent={
      <img
        src="ellipsis.svg"
        width="24px"
        height="24px"
        alt="more options button"
      />
    }
  >
    <button
      className="messages-preview-block-button"
      onClick={clickBlock && clickBlock}
    >
      <img src="block.svg" width="16px" height="16px" alt="block icon" />
      {blocked ? 'Unblock' : 'Block'}
    </button>
  </ButtonWithDropdown>
);

export default MessageActionButton;
