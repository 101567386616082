import React, { useEffect, useState, useContext, useCallback } from 'react';
import api from '../../api';
import { useNavigate } from 'react-router-dom';
import { CarouselProvider, CarouselContext } from 'pure-react-carousel';
import ScoringGrid from './ScoringGrid';
import { getUrlParam } from '../../utils/url';
import { getErrorMessageFromResponse } from '../../utils/error';
import { sendFollowUpRequest } from '../../apiCalls/nps';
import { getCallDetails } from '../../apiCalls/boostCalls';
import useWindowSize from '../../hooks/useWindowSize';
import { BoostCallEvent } from '../../types';
import { Checkbox, FormControl, FormGroup, Switch } from '@mui/material';
import './nps-score.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
import TextAreadWithLabel from '../../atoms/TextAreaWithLabel';
import ToolTip from '../../atoms/ToolTip';
import {
  CustomFormControlLabel,
  CustomFormLabel,
  CustomTypography,
} from '../../styling/generalStyling';
import {
  DashboardEmptyButton,
  DashboardFilledButton,
} from '../../styling/buttons';
import TrustBox from '../TrustBox';

interface Props {
  eventIdFromUser?: number | undefined;
  setDisableAfterFeedbackGiven?: any | undefined;
}
type SendFeedbackProps = {
  overrideScore?: number;
  overrideUserReasons?: number[];
  overrideNoShow?: boolean;
  overrideHideName?: boolean;
  overrideEventDidNotHappen?: boolean;
};

const NpsScore = ({ eventIdFromUser, setDisableAfterFeedbackGiven }: Props) => {
  const carouselContext = useContext(CarouselContext);
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 750;

  const { setStoreState: setCarouselStoreState } = carouselContext;
  const [currentSlide, setCurrentSlide] = useState<number>(
    carouselContext.state.currentSlide
  );
  useEffect(() => {
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide);
    };
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  const noShowParam = getUrlParam('no_show');
  const boostCallEventIdParam = getUrlParam('id');

  const [feedBackAlreadyGiven, setFeedbackAlreadyGiven] =
    useState<boolean>(false);
  const [boostCallScoreID, setBoostCallScoreId] = useState();
  const [boostCallEvent, setBoostCallEvent] = useState<BoostCallEvent | null>(
    null
  );
  const [score, setScore] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<string>('');
  const [hideName, setHideName] = useState<boolean>(false);
  const [noShow, setNoShow] = useState<boolean>(false);
  const [eventDidNotHappen, setEventDidNotHappen] = useState<boolean>(false);
  const [reasons, setReasons] = useState<any>([]);
  const [userReasons, setUserReasons] = useState<number[]>([]);
  const [thankYou, setThankYou] = useState<any>(false);
  const [userFollowUpSelection, setUserFollowUpSelection] =
    useState<string>('');
  const [followUpText, setFollowUpText] = useState<string>('');
  const [followUpComplete, setFollowUpComplete] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [followUpErrorMessage, setFollowUpErrorMessage] = useState<string>('');
  const [prevSlide, setPrevSlide] = useState<number | null>(null);
  const [canShareFeedback, setCanShareFeedback] = useState<boolean>(true);

  const navigate = useNavigate();
  const getFeedbackType = (score: number) => {
    if (score > -1 && score < 7) {
      setHideName(true);
      return 'detractor';
    } else if (score < 9) {
      setHideName(false);
      return 'neutral';
    } else {
      setHideName(false);
      return 'promoter';
    }
  };
  useEffect(() => {
    if (eventDidNotHappen) {
      setHideName(true);
    }
  }, [eventDidNotHappen]);

  const thankYouTitleText = noShow
    ? "We're sorry!"
    : 'Thank you so much for your feedback!';
  const detractorThankYouBodyText = noShow
    ? 'Thank you for letting us know that this experience didn’t meet your expectations.\nPlease be aware that learners who do not show up several times are removed from our service so that your peers will not experience similar issues in the future.'
    : 'Please be aware that learners who receive poor reviews from several mentors are removed from our service so that your peers will not experience similar issues in the future.';

  // optional parameters allow overriding at point of calling
  const sendFeedback = useCallback(
    async ({
      overrideScore,
      overrideUserReasons,
      overrideNoShow,
      overrideHideName,
      overrideEventDidNotHappen,
    }: SendFeedbackProps = {}) => {
      if (!boostCallEvent) return;
      let shareFeedback = false;

      if (score != null && score >= 7) {
        shareFeedback = canShareFeedback;
      }
      const postData = {
        review: feedback,
        event_id: boostCallEvent.event_id,
        score:
          typeof overrideScore === 'number'
            ? overrideScore
            : eventDidNotHappen && noShow
            ? 0
            : score,
        hide_reviewer_details: overrideHideName ?? hideName,
        organisation: boostCallEvent.organisation.id,
        volunteer: boostCallEvent.volunteer.id,
        no_show: overrideNoShow ?? noShow,
        event_did_not_happen: overrideEventDidNotHappen ?? eventDidNotHappen,
        score_type: 'volunteer_scoring_organisation',
        volunteer_scoring_organisation: true,
        boostcall_event: boostCallEvent.id,
        reasons: overrideUserReasons || userReasons,
        can_share_feedback: shareFeedback,
      };

      api
        .post(`api/netpromoterscore/boostcallscores/`, postData)
        .then((response) => {
          if (response) {
            if (setDisableAfterFeedbackGiven) {
              setDisableAfterFeedbackGiven(true);
            }

            setBoostCallScoreId(response.data.id);
            return true;
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data[Object.keys(err.response.data)[0]]);
          return false;
        });
    },
    [
      boostCallEvent,
      feedback,
      userReasons,
      score,
      noShow,
      hideName,
      setDisableAfterFeedbackGiven,
      eventDidNotHappen,
      canShareFeedback,
    ]
  );

  const onScoreSet = useCallback(
    (scoreValue: number) => {
      if (!boostCallEvent) return;
      setScore(scoreValue);
    },
    [boostCallEvent]
  );

  const getReasons = async () => {
    const reasonsResponse = (
      await api.get(`api/netpromoterscore/reason/?limit=100`)
    ).data.results;
    reasonsResponse.sort((a: any, b: any) => a.id - b.id);
    setReasons(reasonsResponse.filter((r: any) => !!r.show_to_volunteer));
  };

  const getFeedbackGiven = async (
    eventIdFromUser?: number,
    setDisableAfterFeedbackGiven?: any | undefined
  ) => {
    let boostCallEventId;
    if (eventIdFromUser) {
      boostCallEventId = eventIdFromUser;
    } else {
      boostCallEventId = boostCallEventIdParam;
    }

    const isFeedbackGiven = (
      await api.get(
        `api/netpromoterscore/boostcallscores/score/?boostcall_event_id=${boostCallEventId}`
      )
    ).data.is_score_sent;
    setFeedbackAlreadyGiven(isFeedbackGiven);
  };

  // Handles initial data fetching & handles URL params
  useEffect(() => {
    (async () => {
      try {
        await getFeedbackGiven(eventIdFromUser);
        // Fetch call data
        const boostCallResponseData = await getCallDetails({
          type: 'events',
          id: eventIdFromUser || (boostCallEventIdParam as string),
        });
        setBoostCallEvent(boostCallResponseData);

        // Handle URL param: ?score=
        let qsScore = getUrlParam('score') as string;
        if (qsScore && typeof qsScore === 'string') {
          setScore(parseInt(qsScore));
          setCarouselStoreState({ currentSlide: parseInt(qsScore) });
        }
        // Fetch reasons
        await getReasons();
      } catch (err) {
        setErrorMessage(getErrorMessageFromResponse(err));
        return false;
      }
    })();
    // eslint-disable-next-line
  }, [eventIdFromUser, setCarouselStoreState]);

  // Handles setting score any time currentSlide is changed, including based on ?score= url param
  useEffect(() => {
    if (prevSlide !== currentSlide) {
      onScoreSet(currentSlide);
      if (
        prevSlide &&
        getFeedbackType(currentSlide) !== getFeedbackType(prevSlide)
      ) {
        setUserReasons([]);
        setNoShow(false);
        setEventDidNotHappen(false);
      }
      setPrevSlide(currentSlide);
      if (currentSlide > -1 && currentSlide < 7) {
        setHideName(true);
      } else {
        setHideName(false);
      }
    }
  }, [currentSlide, onScoreSet, prevSlide]);

  const checkIsNoShowReason = (reason: any) =>
    reason && reason.no_show && reason.show_to_volunteer;
  const addRemoveReason = (id: number) => {
    const newReasons = [...userReasons];
    if (newReasons.includes(id)) {
      newReasons.forEach((reason, i) => {
        if (reason === id) {
          newReasons.splice(i, 1);
        }
      });
    } else {
      newReasons.push(id);
    }

    setUserReasons(newReasons);
  };

  // Handles updating reasons based on no_show url param AFTER reasons has been fetched
  useEffect(() => {
    if (!reasons || !boostCallEvent) return;

    // Handle URL param: ?no_show=
    if (noShowParam && typeof noShowParam === 'string') {
      setScore(null);
      setEventDidNotHappen(true);
      setCarouselStoreState({ currentSlide: 0 });
    }
    // eslint-disable-next-line
  }, [boostCallEvent, reasons]);

  if (thankYou && boostCallEvent && boostCallEvent.organisation.first_name) {
    if (followUpComplete)
      return (
        <div className="nps-score__thankyou-container">
          <div className="nps_score__thankyou-inner-container">
            <div className="nps_score__thankyou">
              <h2>{`Thank you so much for your feedback & follow-up invitation!`}</h2>

              <div className="nps-score__trust-container nps-score__trust-container-margin">
                <p>
                  Enjoying volunteering with us? Help us support more founders
                  by sharing your experience on Trustpilot 👇
                </p>
                <TrustBox />
              </div>

              <div className="nps-score__thankyou-button-container nps-score__followup-thankyou-buttons">
                <DashboardFilledButton
                  variant="contained"
                  sx={{ width: '200px', marginTop: '12px' }}
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                >
                  Back to Dashboard
                </DashboardFilledButton>
              </div>
            </div>
          </div>
        </div>
      );
    return (
      <div className="nps-score__thankyou-container">
        <div className="nps_score__thankyou-inner-container">
          <div className="nps_score__thankyou">
            {errorMessage && (
              <h1 className="nps-score__error nps-score__title-container">
                {' '}
                {errorMessage}
              </h1>
            )}

            <h2>{thankYouTitleText}</h2>

            {typeof score === 'number' && score < 7 ? (
              <p>{detractorThankYouBodyText}</p>
            ) : typeof score === 'number' && score >= 9 ? (
              <>
                <h3 className="nps-score__followup-subtitle">
                  Would you be open to meeting with{' '}
                  {boostCallEvent.organisation.first_name} again if they wanted
                  to?
                </h3>
                <p className="nps-score__followup-description">
                  If you are open to another session we will let{' '}
                  {boostCallEvent.organisation.first_name} know, and notify you
                  when {boostCallEvent.organisation.first_name} has offered
                  their availability.
                </p>
                <div className="nps-score__followup-radio-container">
                  <label className="nps_score__followup-radio">
                    <input
                      type="radio"
                      value="yes"
                      checked={userFollowUpSelection === 'yes'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUserFollowUpSelection(e.target.value)
                      }
                    />
                    <span>
                      Yes, I want a follow-up session with{' '}
                      {boostCallEvent.organisation.first_name}
                    </span>
                  </label>
                  <label className="nps_score__followup-radio">
                    <input
                      type="radio"
                      value="no"
                      checked={userFollowUpSelection === 'no'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUserFollowUpSelection(e.target.value)
                      }
                    />
                    <span>No</span>
                  </label>
                </div>
                <TextAreadWithLabel
                  onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setFollowUpText(e.target.value);
                  }}
                  rows={10}
                  cols={4}
                  placeHolder={
                    'What would you like to have the follow-up session on, and when would you suggest it happen?'
                  }
                  className={`nps-score__text-area ${
                    userFollowUpSelection !== 'yes'
                      ? 'nps-score__text-area--collapsed'
                      : ''
                  }`}
                />
                {userFollowUpSelection === 'no' && (
                  <div className="nps-score__trust-container">
                    <p>
                      Enjoying volunteering with us? Help us support more
                      founders by sharing your experience on Trustpilot 👇
                    </p>
                    <TrustBox />
                  </div>
                )}
              </>
            ) : (
              <p>
                {
                  'We will share it with the organisation so that they know how it went and how they can improve.'
                }
              </p>
            )}

            {followUpErrorMessage && (
              <h1 className="nps-score__error nps-score__title-container">
                {' '}
                {followUpErrorMessage}
              </h1>
            )}
            <div className="nps-score__thankyou-button-container">
              {!noShowParam && (
                <DashboardEmptyButton
                  variant="outlined"
                  sx={{ width: '200px' }}
                  onClick={() => setThankYou(false)}
                >
                  Back
                </DashboardEmptyButton>
              )}
              <DashboardFilledButton
                variant="contained"
                sx={{ width: '200px' }}
                onClick={
                  userFollowUpSelection === 'yes'
                    ? () =>
                        sendFollowUpRequest({
                          postData: {
                            score: { id: boostCallScoreID },
                            is_follow_up_wanted: true,
                            follow_up_text: followUpText,
                            interests: boostCallEvent.interests_ids,
                          },
                          onSuccess: () => setFollowUpComplete(true),
                          handleErrorMessage: setFollowUpErrorMessage,
                        })
                    : () => {
                        navigate('/dashboard');
                      }
                }
                disabled={
                  (typeof score === 'number' &&
                    score > 8 &&
                    !userFollowUpSelection) ||
                  (userFollowUpSelection === 'yes' && !followUpText.length)
                }
              >
                {userFollowUpSelection === 'yes' ? 'Send' : 'Back to Dashboard'}
              </DashboardFilledButton>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (feedBackAlreadyGiven) {
    return (
      <div className="nps-score__feedbackalreadygiven-container">
        <div className="nps_score__feedbackalreadygiven">
          {errorMessage && (
            <h1 className="nps-score__error nps-score__title-container">
              {' '}
              {errorMessage}
            </h1>
          )}
          <h2>Done!</h2>
          <p>You’ve already given feedback on this session - thank you!</p>
          <div className="nps-score__feedbackalreadygiven-buttons-container">
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '200px' }}
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Back to Dashboard
            </DashboardFilledButton>
          </div>
        </div>
      </div>
    );
  } else if (boostCallEvent) {
    return (
      <div className="nps-score">
        <div className="nps-score__container">
          <div className="nps-score__mobile-card nps-score__title-container">
            {errorMessage && (
              <h1 className="nps-score__error"> {errorMessage}</h1>
            )}
            <h3 className="nps-score__title">
              How likely are you to recommend{' '}
              {boostCallEvent.organisation.first_name} as a learner?
            </h3>
          </div>

          <CustomFormControlLabel
            control={
              <Checkbox
                checked={eventDidNotHappen}
                onChange={() => {
                  setEventDidNotHappen(!eventDidNotHappen);
                  if (!eventDidNotHappen) {
                    setScore(null);
                    setUserReasons([]);
                    setNoShow(false);
                  }
                }}
                color="primary"
              />
            }
            label={<CustomTypography>Call did not happen</CustomTypography>}
          />

          {!eventDidNotHappen && (
            <div className="nps-score__mobile-card nps-score__grid-container">
              <ScoringGrid
                score={score}
                onScoreSet={onScoreSet}
                setCarouselStoreState={setCarouselStoreState}
                mobile={!!isMobile}
                currentSlide={currentSlide}
              />
              <div className="nps-score__grid-descriptor-text">
                <span>{`${
                  isMobile ? 'Less likely' : 'Not likely at all'
                }`}</span>{' '}
                <span></span>
                {`${isMobile ? 'More likely' : 'Very likely'}`}
              </div>
            </div>
          )}
          {typeof score === 'number' && (
            <>
              <div className="nps-score__details-container nps-score__mobile-card">
                {score < 7 && (
                  <>
                    <div className="info-text">
                      We’re sorry to hear that your call with{' '}
                      {boostCallEvent.organisation.first_name} wasn’t a success.
                    </div>
                    <CustomFormLabel
                      sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: 'primary',
                      }}
                    >
                      Please take a few minutes to let us know what happened.
                    </CustomFormLabel>
                    <FormGroup sx={{ margin: 0 }}>
                      {reasons
                        .filter((r: any) => r.score_group === 'detractor')
                        .map((reason: any) => {
                          const isNowShowReason = checkIsNoShowReason(reason);
                          return (
                            <CustomFormControlLabel
                              control={
                                <Checkbox
                                  checked={userReasons.includes(reason.id)}
                                  onChange={() => {
                                    addRemoveReason(reason.id);
                                    if (!!isNowShowReason) setNoShow(!noShow);
                                  }}
                                  color="primary"
                                />
                              }
                              label={
                                <CustomTypography>
                                  {reason.description}
                                </CustomTypography>
                              }
                            />
                          );
                        })}
                    </FormGroup>
                  </>
                )}
                {score >= 7 && (
                  <>
                    {score > 8 && (
                      <div className="info-text">
                        We’re so happy your call was a success!
                      </div>
                    )}

                    {score > 8 ? (
                      <div>
                        <h3>
                          Please let us know what made your learner amazing here
                          👇
                        </h3>
                      </div>
                    ) : (
                      <div>
                        <h3>Thank you for your feedback!</h3>
                        <h3>
                          Is there anything that your learner could do
                          differently to improve their sessions in the future?
                        </h3>
                      </div>
                    )}

                    {score > 8 && (
                      <div>
                        <CustomFormLabel
                          sx={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: 'primary',
                          }}
                        >
                          Why was your call satisfactory?
                        </CustomFormLabel>
                        <FormGroup sx={{ margin: 0 }}>
                          {reasons
                            .filter((r: any) => r.score_group === 'promoter')
                            .map((reason: any) => {
                              return (
                                <CustomFormControlLabel
                                  control={
                                    <Checkbox
                                      checked={userReasons.includes(reason.id)}
                                      onChange={() =>
                                        addRemoveReason(reason.id)
                                      }
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <CustomTypography>
                                      {reason.description}
                                    </CustomTypography>
                                  }
                                />
                              );
                            })}
                        </FormGroup>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="nps-score__mobile-card nps-score__feedback-container">
                {score >= 7 && score <= 8 ? (
                  <TextAreadWithLabel
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFeedback(e.target.value);
                    }}
                    rows={3}
                    cols={4}
                    placeHolder={
                      'If you’re happy to share feedback with us, please share it here                    '
                    }
                  />
                ) : score > 8 ? (
                  <TextAreadWithLabel
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFeedback(e.target.value);
                    }}
                    rows={3}
                    cols={4}
                    placeHolder={
                      'Our community loves to see feedback on their Dashboard and it keeps them motivated on their journey.'
                    }
                    label={`If you enjoyed your session with ${boostCallEvent.organisation.first_name}, we encourage you to leave them a note on their Dashboard!`}
                  />
                ) : (
                  <TextAreadWithLabel
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFeedback(e.target.value);
                    }}
                    rows={3}
                    cols={4}
                    placeHolder={
                      'If you’re happy to share additional feedback with us, please share it here                    '
                    }
                    label={
                      'All negative feedback is anonymous to protect your privacy and help you to feel safe sharing this with us.                    '
                    }
                  />
                )}

                <div>
                  <Switch
                    checked={hideName}
                    onChange={() => setHideName(!hideName)}
                  />
                  Share anonymously
                </div>

                {score >= 7 && (
                  <div>
                    <Switch
                      checked={canShareFeedback}
                      onChange={() => {
                        setCanShareFeedback(!canShareFeedback);
                      }}
                    />
                    I’m happy for Digital Boost to share my feedback in
                    marketing materials
                    <ToolTip
                      text={`We love to highlight the amazing mentoring that happens here at Digital Boost! So from time to time, we celebrate our businesses and mentors on our social channels.  Please let us know if you're happy for us to share your feedback, it will be shared anonymously if you choose to hide your name and details. If you choose to allow us to share your feedback, you might be tagged in Digital Boost posts.`}
                      id="nps-tagged"
                      className="nps-tagged-tooltip"
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {eventDidNotHappen && (
            <div className="nps-score__details-container nps-score__mobile-card">
              <FormControl component="fieldset" variant="standard">
                <div className="info-text">
                  We’re sorry to hear that your call with{' '}
                  {boostCallEvent.organisation.first_name} did not happen.
                </div>

                <CustomFormLabel
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: 'primary',
                  }}
                >
                  Please take a few minutes to let us know why it did not
                  happen.
                </CustomFormLabel>
                <FormGroup sx={{ margin: 0 }}>
                  {reasons
                    .filter((r: any) => r.event_did_not_happen === true)
                    .map((reason: any) => {
                      const isNowShowReason = checkIsNoShowReason(reason);
                      return (
                        <CustomFormControlLabel
                          control={
                            <Checkbox
                              checked={userReasons.includes(reason.id)}
                              onChange={() => {
                                addRemoveReason(reason.id);
                                if (!!isNowShowReason) setNoShow(!noShow);
                              }}
                              name={reason.description}
                              color="primary"
                            />
                          }
                          label={
                            <CustomTypography>
                              {reason.description}
                            </CustomTypography>
                          }
                        />
                      );
                    })}
                </FormGroup>
              </FormControl>
              <div className="nps-score__mobile-card nps-score__feedback-container">
                <TextAreadWithLabel
                  onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setFeedback(e.target.value);
                  }}
                  rows={3}
                  cols={4}
                  placeHolder={
                    'If you’re happy to share additional feedback with us, please share it here                    '
                  }
                  label={
                    'All negative feedback is anonymous to protect your privacy and help you to feel safe sharing this with us.                    '
                  }
                />
                <div>
                  <Switch
                    checked={hideName}
                    onChange={() => setHideName(!hideName)}
                  />
                  Share anonymously
                </div>
              </div>
            </div>
          )}

          <div className="nps-score__button-container">
            <DashboardEmptyButton
              variant="outlined"
              sx={{ width: '250px' }}
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              {' '}
              Cancel
            </DashboardEmptyButton>
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '250px' }}
              disabled={!(typeof score === 'number') && !eventDidNotHappen}
              onClick={() => {
                sendFeedback().then(() => {
                  window.scrollTo(0, 0);
                  setThankYou(true);
                });
              }}
            >
              Send
            </DashboardFilledButton>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="nps-score__loading-container">
        {errorMessage && (
          <h1 className="nps-score__error nps-score__title-container">
            {' '}
            {errorMessage}
          </h1>
        )}
        <img
          className="nps-score__loading-spinner"
          src="/loading.gif"
          alt="loading"
        />
      </div>
    );
  }
};

const WrappedInProvider = (props: Props) => (
  <CarouselProvider
    totalSlides={11}
    naturalSlideWidth={1}
    naturalSlideHeight={1}
    visibleSlides={1}
    currentSlide={5} // this variable determines the default slide only
    className="scoring-grid-mobile"
  >
    <NpsScore {...props} />
  </CarouselProvider>
);

export default WrappedInProvider;
