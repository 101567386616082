import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  CustomFormLabel,
  CustomFormControlLabel,
  CustomTypography,
} from '../../../styling/generalStyling';
import useFetchPartner from '../../../apiHooks/auth/useFetchPartner';
import FullScreenLoading from '../../../components/FullScreenLoading';

type Props = {
  setValue: (value: any, input: string) => void;
  value: any;
};

export default function EditNewsletterForm({ value, setValue }: Props) {
  const { fetchPartnerResponseData, isLoadingPartner } = useFetchPartner({
    id: value.partner,
    setCookies: true,
  });

  if (isLoadingPartner) return <FullScreenLoading />;
  else
    return (
      <FormControl>
        <CustomFormLabel>
          {' '}
          We'd love to send you newsletters to keep you updated on all of our
          new features and services - we promise we won't spam you!
        </CustomFormLabel>
        <RadioGroup
          aria-labelledby="newsletter-radio-buttons-group-label"
          defaultValue={value.preferences}
          name="radio-buttons-group"
 
        >
          <CustomFormControlLabel
            value="true"
            onClick={() => setValue(true, 'newsletter')}
            control={<Radio />}
            label={
              <CustomTypography>
                I want to receive newsletters from{' '}
                {fetchPartnerResponseData
                  ? `${fetchPartnerResponseData.name}`
                  : `Digital Boost`}
              </CustomTypography>
            }
          />
          <CustomFormControlLabel
            value="false"
            onClick={() => setValue(false, 'newsletter')}
            control={<Radio />}
            label={
              <CustomTypography>
                I do not want to receive newsletters from{' '}
                {fetchPartnerResponseData
                  ? `${fetchPartnerResponseData.name}`
                  : `Digital Boost`}
              </CustomTypography>
            }
          />
        </RadioGroup>
      </FormControl>
    );
}
