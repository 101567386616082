import React from 'react';
import { displayTime } from '../../utils/time';
import { VolunteerProfile, OrganisationProfile } from '../../types';
import './message.scss';
import { defaultTimeFormat } from '../../utils/constants';
import useSelectProfileData from '../../hooks/useSelectProfileData';

type Props = {
  text: string;
  created: string;
  user: VolunteerProfile | OrganisationProfile;
  className?: string;
};

const Message = ({ text, created, user, className }: Props) => {
  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};
  const { profile_picture, first_name } = user;

  const createdTime =
    timezone &&
    `${displayTime(created, timezone, 'DD MMMM')} at ${displayTime(
      created,
      timezone,
      defaultTimeFormat
    )}`;

  return (
    <div className={`message__container ${className ? className : ''}`}>
      <div className="message__profile-wrapper">
        <img
          src={profile_picture || './avatar.png'}
          className="message__profile-picture"
          alt="user"
        />
        <div className="message__profile-text-wrapper">
          <span className="message__name">{first_name}</span>
          <span className="message__date">{createdTime}</span>
        </div>
      </div>
      <div className="message__body-wrapper">
        <div className="message__text-container">{text}</div>
      </div>
    </div>
  );
};

export default Message;
