import moment from 'moment-timezone';
import { defaultTimeFormat } from './constants';

export const getMomentWithTimezone = (
  dateString?: string,
  timeZone?: string
) => {
  // uses the Internationalization API in the browser to determine the user's time zone
  const guessedTimeZone = moment.tz.guess();

  return moment(dateString).tz(timeZone || guessedTimeZone);
};

export const displayTime = (
  dateString: string,
  timeZone = moment.tz.guess(),
  format: string
) => {
  return moment(dateString).tz(timeZone)?.format(format);
};

export const parseTimeZoneCode = (date: string, timeZoneString: string) => {
  if (!date) return '';
  const momentDate = getMomentWithTimezone(date, timeZoneString);
  if (!momentDate.isValid()) {
    console.log('Invalid date formatting attempted');
    return null;
  }

  // Timezonecodes without any letters like '-03' are confusing
  // This filters out any that are only numbers
  const timeZoneCode = momentDate.format('z') || '';
  const hasLettersRegex = new RegExp(/.*([A-Z])/);
  const hasLetters = hasLettersRegex.test(timeZoneCode);

  return hasLetters ? timeZoneCode : '';
};

export const formatDate = (
  date?: string,
  timeZoneString?: string,
  duration?: number
) => {
  if (!date) return {};
  const momentStartDate = getMomentWithTimezone(date, timeZoneString);
  if (!momentStartDate.isValid()) {
    console.log('Invalid date formatting attempted');
    return null;
  }

  const momentEndDate = getMomentWithTimezone(date, timeZoneString).add(
    60,
    'minutes'
  );
  const timeZoneCode = timeZoneString
    ? parseTimeZoneCode(date, timeZoneString)
    : '';

  return {
    date: {
      day: momentStartDate.format('D'),
      month: momentStartDate.format('MMMM'),
      year: momentStartDate.format('YY'),
      monthThreeLetterFormat: momentEndDate.format('MMM'),
    },
    time: {
      hour: momentStartDate.format(defaultTimeFormat),
      day: momentStartDate.format('dddd'),
      timeZone: timeZoneString || '',
      timeZoneCode: timeZoneCode,
    },
    endDate: momentEndDate
      ? {
          date: {
            day: momentEndDate.format('D'),
            month: momentEndDate.format('MMMM'),
          },
          time: {
            hour: momentEndDate.format(defaultTimeFormat),
            day: momentEndDate.format('dddd'),
            timeZone: timeZoneString || '',
            timeZoneCode: timeZoneCode,
          },
        }
      : null,
  };
};

export const filterByUKBusinessHours = (
  selectedTime: Date,
  startingHour: number,
  endingHour: number
) => {
  const selectedDate = new Date(selectedTime);
  const now = new Date(Date.now());
  const ukHour = moment(now).tz('Europe/London').format('HH');
  const utcHour = moment(now).tz('UTC').format('HH');
  const ukIsInBST = ukHour !== utcHour; // check if UK is currently in GMT or BST (GMT === UTC)

  const startHour = ukIsInBST ? startingHour - 1 : startingHour;
  const endHour = ukIsInBST ? endingHour - 1 : endingHour;

  const date8AM = new Date(
    selectedTime.getFullYear(),
    selectedTime.getMonth(),
    selectedTime.getDate(),
    8,
    0
  ).setUTCHours(startHour);
  const date6PM = new Date(
    selectedTime.getFullYear(),
    selectedTime.getMonth(),
    selectedTime.getDate(),
    18,
    0
  ).setUTCHours(endHour);

  return date8AM <= selectedDate.getTime() && date6PM >= selectedDate.getTime();
};
