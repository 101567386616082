import React, { memo } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { PersonOutlined } from '@mui/icons-material';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

export const tabs = [
  {
    label: 'Dashboard',
    link: 'actions',
  },
  {
    label: 'Bookings',
    link: 'bookings',
  },
  {
    label: 'Requests',
    link: 'requests',
  },
  {
    label: 'Action Plans',
    link: 'actionplan',
  },
  {
    label: 'Your Impact',
    link: 'impact',
  },
  {
    label: 'Resources',
    link: 'resources',
  },
  {
    label: 'Account',
    link: 'account',
  },
  {
    label: 'Preferences',
    link: 'preferences',
  },
  {
    label: 'Logout',
    link: 'logout',
  },
];

export const displayIcon = (label: string) => {
  if (label === 'Dashboard') return <HomeOutlinedIcon />;
  else if (label === 'Requests') return <LayersOutlinedIcon />;
  else if (label === 'Your Impact') return <ThumbUpAltOutlinedIcon />;
  else if (label === 'Bookings') return <SentimentSatisfiedOutlinedIcon />;
  else if (label === 'Account') return <PersonOutlined />;
  else if (label === 'Action Plans') return <AssignmentOutlinedIcon />;
  else if (label === 'Preferences') return <TuneOutlinedIcon />;
  else if (label === 'Logout') return <FileUploadOutlinedIcon />;
  else if (label === 'Resources') return <MenuBookOutlinedIcon />;
  else return null;
};

export const requestsBadge = (allRequests: any[]) => (
  <span> ({allRequests.length})</span>
);

export const bookingsBadge = (upcomingCallsAndGroupEvents: any[]) => (
  <span>
    {' '}
    (
    {
      upcomingCallsAndGroupEvents.filter((event) => event.organisation !== null)
        .length
    }
    )
  </span>
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}

export interface SessionStatistic {
  year: number;
  month: number;
  duration: number;
}

export const TabPanel = memo((props: TabPanelProps) => {
  let display;
  const { children, value, index, ...other } = props;
  if (value === 'actions') display = 0;
  else if (value === 'bookings') display = 1;
  else if (value === 'requests') display = 2;
  else if (value === 'impact') display = 3;
  else if (value === 'account') display = 4;
  else if (value === 'preferences') display = 5;
  return (
    <div
      role="tabpanel"
      hidden={display !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    ></div>
  );
});
