import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

/* --- SLICE --- */

interface GroupSessionTypeNamesState {
  masterclassTitle: string;
  webinarTitle: string;
  workshopTitle: string;
}

const initialState: GroupSessionTypeNamesState = {
  masterclassTitle: 'Masterclass',
  webinarTitle: 'Webinar',
  workshopTitle: 'Workship',
};

const groupSessionTypeNamesSlice = createSlice({
  name: 'groupSessionTypeNames',
  initialState,
  reducers: {
    setGroupEventMasterclassTypeName: (state, action) => {
      state.masterclassTitle = action.payload;
    },
    setGroupEventWebinarTypeName: (state, action) => {
      state.webinarTitle = action.payload;
    },
    setGroupEventWorkshopTypeName: (state, action) => {
      state.workshopTitle = action.payload;
    },
    clearGroupEventTypeName: (state) => {
      state.masterclassTitle = 'Masterclass';
      state.webinarTitle =  'Webinar';
      state.workshopTitle = 'Workship';
    },
  },
});

/* --- SELECTORS --- */

export const selectGroupEventMasterclassName = (state: RootState) =>
  state.groupSessionTypeNames.masterclassTitle;

  export const selectGroupEventWebinarName = (state: RootState) =>
  state.groupSessionTypeNames.webinarTitle;

  export const selectGroupEventWorkshopName = (state: RootState) =>
  state.groupSessionTypeNames.workshopTitle;

/* --- ACTIONS --- */

export const {
  setGroupEventMasterclassTypeName,
  setGroupEventWebinarTypeName,
  setGroupEventWorkshopTypeName,
  clearGroupEventTypeName,
} = groupSessionTypeNamesSlice.actions;

export default groupSessionTypeNamesSlice.reducer;
