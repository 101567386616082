import { useAxios } from '../../api';
import { getErrorMessageFromResponse } from '../../utils/error';

import { UserLocation } from '../../types';

const useUpdateTimezoneAndGetLocationData = () => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [{ data, loading, error }, executePost] = useAxios<UserLocation>(
    {
      url: `api/location/locations/me/`,
      method: 'PATCH',
      data: {
        timezone: browserTimezone,
      },
    },
    { manual: true }
  );

  const updateTimezoneAndGetLocationData = async () => {
    const { data } = await executePost();
    return data;
  };

  return {
    updateTimezoneAndGetLocationData,
    updateTimezoneResponseData: data,
    isLoadingUpdateTimezone: loading,
    updateTimezoneError: error ? getErrorMessageFromResponse(error) : null,
  };
};

export default useUpdateTimezoneAndGetLocationData;
