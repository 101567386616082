import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as ArrowIcon } from './arrow-down-icon.svg';
import { FormControl } from '@mui/material';
interface LibrarySortProps {
  sort: string;
  handleSort: (event: SelectChangeEvent<string>) => Promise<void>;
}
const LibrarySort = ({ sort, handleSort }: LibrarySortProps) => {
  return (
    <FormControl variant="standard" sx={{ minWidth: 80, marginRight: '40px' }}>
      <Select
        labelId="select-attachment-sort"
        id="select-attachment-sort"
        IconComponent={ArrowIcon}
        value={sort}
        sx={{
          fontFamily: 'Gilroy',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: '500',
        }}
        onChange={handleSort}
        label="Sort"
        disableUnderline
      >
        <MenuItem sx={{ fontFamily: 'Gilroy' }} value={'desc'}>
          Newest
        </MenuItem>
        <MenuItem sx={{ fontFamily: 'Gilroy' }} value={'asc'}>
          Oldest
        </MenuItem>
        <MenuItem sx={{ fontFamily: 'Gilroy' }} value={'size'}>
          Size
        </MenuItem>
        <MenuItem sx={{ fontFamily: 'Gilroy' }} value={'alphabetical'}>
          A-Z
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LibrarySort;
