import { apiUrl } from '../../api';
import axios from 'axios';
import Cookie from 'js-cookie';
import { loginWithRefreshToken } from '../../utils/auth';

type Props = {
  id: string | number;
  callProvider: string;
  timeSlot: number | undefined;
  callUrl?: string;
};

const useAcceptCommunityRequest = () => {
  const acceptRequest = async ({
    id,
    callProvider,
    timeSlot,
    callUrl,
  }: Props) => {
    //verify token and refresh if needed
    var accessToken = Cookie.get('accessTokenDB');
    if (accessToken === undefined) {
      await loginWithRefreshToken();
      accessToken = Cookie.get('accessTokenDB');
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return accessToken
      ? await axios
          .post(
            `${apiUrl}api/event/boostcallrequests/${id}/acceptboostcallrequest/`,
            {
              call_provider: parseInt(callProvider),
              time_slot: timeSlot,
              call_url: callUrl,
            },
            config
          )
          .then((response: { data: any }) => {
            return response.data;
          })
          .catch((error: any) => {
            return { message: error?.response?.data };
          })
      : { message: 'You do not have permission to accept this request' };
  };

  return {
    acceptRequest,
  };
};

export default useAcceptCommunityRequest;
