import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { InputLabel } from '@mui/material';

export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow />
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#333333',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#333333',
  },
}));

export const CustomRequestsLabel = styled(Typography)({
  fontSize: '17px',
  marginBottom: '5px',
  fontWeight: 'bold',
  lineHeight: '1',
  color: '#333',
  fontFamily: 'Gilroy',
  textTransform: 'none',
});
export const CustomMobileRequestsLabel = styled(Typography)({
  fontSize: '14px',
  marginBottom: '5px',
  fontWeight: 'bold',
  lineHeight: '1',
  color: '#333',
  fontFamily: 'Gilroy',
  textTransform: 'none',
});
export const CustomTimeLabel = styled(InputLabel)({
  fontSize: '14px',
  color: '#333',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  textAlign: 'left',
});

export const CustomRequestsLabelMobile = styled(Typography)({
  fontSize: '14px',
  marginBottom: '5px',
  fontWeight: 'bold',
  lineHeight: '1',
  color: '#333',
  fontFamily: 'Gilroy',
  textTransform: 'none',
});

export const GreenButton = styled(Button)({
  fontFamily: 'Gilroy',
  backgroundColor: 'c2c2c2',
  borderRadius: '32px',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#0f0f22',
  width: '270px',
  margin: '12px 46px 0 59px',
  height: '44px',
});

export const LoadMoreButton = styled(Button)({
  backgroundColor: '#ffffff',
  width: '100%',
  '&:hover': {
    backgroundColor: '#fff',
    color: 'black',
  },
  height: '60px',
  color: '#2f80ed',
  textTransform: 'none',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
});
