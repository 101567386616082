import { useAxios } from '../../api';

type GroupEventAgendaResponse = {
  results: { id: number; interest: number; description: string }[];
};

const useGetGroupEventAgenda = (ids?: any[]) => {
  const [{ data, loading, error }, refetch] =
    useAxios<GroupEventAgendaResponse>('api/groupevent/agenda/?limit=1000');

  const groupEventAgendaByInterestId = data?.results.reduce(
    (acc: any, cur: any) => {
      acc[cur.interest] = cur.description;
      return acc;
    },
    {}
  );

  return {
    groupEventAgendaByInterestId,
    hasFinishedLoadingGroupEventAgenda: !loading,
    groupEventAgendaError: error,
    refetchGroupEventAgenda: refetch,
  };
};

export default useGetGroupEventAgenda;
