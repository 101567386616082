import React from 'react';
import moment from 'moment-timezone';
import { IoIosClose } from 'react-icons/io';

import './timeslot.scss';

type Props = {
  time: string;
  timeZone?: string;
  onClick?: () => void;
  onRemove?: () => void;
  className?: string;
  key?: string | number;
};

const TimeSlotTag = ({
  time,
  timeZone,
  onClick,
  onRemove,
  className,
  key,
}: Props) => {
  const keyProps = key ? { key: `timeslot-${key}` } : {};
  const onClickProps = onClick ? { onClick: onClick } : {};

  const date = timeZone ? moment(time).tz(timeZone) : moment(time);

  return (
    <div
      className={`time-slot__container ${
        onClick ? 'time-slot__clickable' : ''
      } ${className ? className : ''}`}
      {...onClickProps}
      {...keyProps}
    >
      <div className="time-slot__date">{date.format('MMMM Do')}</div>
      <div className="time-slot__time">{date.format('HH:mm')}</div>
      {onRemove && (
        <IoIosClose
          size={22}
          className={`tag__close-button`}
          onClick={onRemove}
        />
      )}
    </div>
  );
};

export default TimeSlotTag;
