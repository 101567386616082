// EditCardPopup.js

import React, { memo, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CardTextField, DashboardFilledButton } from '../../styling/buttons';
import './cardPlan.scss';
import { updateActionTask } from '../../api/actionPlan';
import TaxonomyPopup from './taxonomyPopup';
import { CardsType } from '../../types';
import SelectAvailabilityTask from './selectAvailabilityTask';
interface EditCardPopupProps {
  onClose: () => void;
  cardDetails: CardsType;
  cardEdit: boolean;
  setCounterChange: () => void;
}

const EditCardPopup = ({
  onClose,
  cardDetails,
  cardEdit,
  setCounterChange,
}: EditCardPopupProps) => {
  const [editCardPopupName, setEditCardPopupName] = useState<
    string | undefined
  >(undefined);
  const color =
    cardDetails.status === 'todo'
      ? '#FCEBEB'
      : cardDetails.status === 'in_progress'
      ? '#FFF9E4'
      : '#ecfbf0';

  const [newDate, setNewDate] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string>('');
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<any>(null);
  const [errorMessage] = useState<null | string>(null);

  const handleUpdateCard = async () => {
    if (cardDetails) {
      const EditCardPopupDetails: any = {
        title: editCardPopupName ? editCardPopupName : cardDetails.title,
        description: description,
        interest: cardDetails.interest,
        status: cardDetails.status,
        action_plan: 0,
        created: '',
        created_by: 0,
        deadline: newDate ? newDate : cardDetails.deadline,
        id: cardDetails.id,
      };
      try {
        const result = await updateActionTask(
          EditCardPopupDetails,
          cardDetails.status,
          selectedTaxonomy
        );

        if (result.success) {
          setCounterChange();
          onClose();
        }
      } catch (error) {
        console.error('Error creating task:', error);
      }
    } else {
      console.error('Error: Name and date are required to create a task');
    }
  };
  useEffect(() => {
    setEditCardPopupName(undefined);
    setNewDate(undefined);
    setDescription('');
    if (cardDetails?.interest?.id)
      setSelectedTaxonomy(cardDetails?.interest?.id);
    else setSelectedTaxonomy(undefined);
  }, [cardDetails]);
  return (
    <div>
      <Dialog
        open={cardEdit}
        onClose={onClose}
        sx={{
          padding: 0,
          '.MuiPaper-root': {
            padding: 0,
            borderRadius: '15px',
            background: ' #FFF',
            width: '840px',
            minHeight: '372px',
            maxHeight: '90vh',
            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: `${color}`,
            maxWidth: '100vw',
            flexShrink: 0,
          },
        }}
      >
        <IconButton
          color="primary"
          onClick={async () => {
            onClose();
          }}
          sx={{
            padding: '0px',
            marginRight: '36px',
            alignSelf: 'flex-end',
            marginTop: '36px',
          }}
        >
          <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
        </IconButton>

        <div className="card-title" style={{ marginTop: '-20px' }}>
          Edit task
        </div>

        <DialogContent sx={{ padding: 0, width: '750px' }}>
          <div className="empty-card-container">
            <div className="row-card">
              <div className="table-text">Task name</div>
              <div className="table-text"> Deadline</div>
              <div className="table-text"> Topic</div>
            </div>
            <div className="row-card">
              <CardTextField
                sx={{
                  width: '240px',
                  margin: 0,
                }}
                defaultValue={cardDetails.title}
                type="text"
                onChange={(e) => setEditCardPopupName(e.target.value)}
              />

              <SelectAvailabilityTask
                setNewDate={setNewDate}
                date={cardDetails.deadline}
              />

              <TaxonomyPopup
                setSelectedTaxonomy={setSelectedTaxonomy}
                selectedTaxonomy={selectedTaxonomy}
              />
            </div>
            <div className="row-card">
              <CardTextField
                multiline
                inputProps={{ maxLength: 800 }}
                sx={{
                  width: '700px',
                  marginTop: '32px',
                  borderRadius: '10px',
                  border: '1px solid var(--Shade-Grey-5, #333)',
                }}
                defaultValue={cardDetails.description}
                placeholder="Add a description"
                type="text"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignSelf: 'center',
            paddingBottom: '31px',
            width: ' 100%',
            justifyContent: 'center',
          }}
        >
          <DashboardFilledButton
            variant="contained"
            onClick={handleUpdateCard}
            sx={{
              width: '290px',
              height: '52px',
              padding: '12px 36px',
            }}
          >
            Save{' '}
          </DashboardFilledButton>
        </DialogActions>
        {errorMessage ? <div className="error">{errorMessage}</div> : null}
      </Dialog>
    </div>
  );
};

export default memo(EditCardPopup);
