import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../atoms/Button';

import styles from './not-found.module.scss';

const PageNotFound = () => {
  return (
    <div className={styles.container}>
      <h1>Page not found</h1>
      <p>
        We can't seem to find the page you're looking for. Click the button
        below to go to dashboard.
      </p>
      <Link to="/dashboard">
        <Button buttonType="tertiary" text="Go to dashboard" />
      </Link>
    </div>
  );
};

export default PageNotFound;
