import { useAxios } from '../../api';
import { BoostCallEvents, BoostCallEvent } from '../../types';

const useGetBoostCallEventsCompleted = () => {
  const [{ data, loading, error }, refetch] = useAxios<BoostCallEvents>({
    url: `api/event/boostcallevents/search/?status=complete&limit=100`,
  });

  return {
    boostCallEventsCompletedCount: data?.count || 0,
    boostCallEventsCompleted: (data?.results as BoostCallEvent[]) || [],
    hasFinishedLoadingBoostCallEventsCompleted: !loading,
    boostCallEventsCompletedError: error,
    refetchBoostCallEventsCompleted: refetch,
  };
};

export default useGetBoostCallEventsCompleted;
