import { useAxios } from '../../api';
import { getErrorMessageFromResponse } from '../../utils/error';

type ResendVerificationProps = {
  email: string;
};

const useResendVerification = () => {
  const [{ data, loading, error }, executeResendVerification] = useAxios(
    {
      url: `api/core/sendactivation/`,
      method: 'POST',
      headers: {},
    },
    { manual: true }
  );

  const typedResendVerification = async ({
    email,
  }: ResendVerificationProps) => {
    const resendVerificationResponse = await executeResendVerification({
      data: {
        email: email,
        user_type: 'volunteer',
      },
    });
    return resendVerificationResponse;
  };

  return {
    resendVerification: typedResendVerification,
    resendVerificationResponseData: data,
    isLoadingResendVerification: loading,
    resendVerificationError: error ? getErrorMessageFromResponse(error) : null,
  };
};

export default useResendVerification;
